import {
  POST_ONLINE_SESSION_START,
  POST_ONLINE_SESSION_SUCCESS,
  POST_ONLINE_SESSION_FAILURE,
  GET_ONLINE_SESSIONS_START,
  GET_ONLINE_SESSIONS_SUCCESS,
  GET_ONLINE_SESSIONS_FAILURE,
  GET_ONLINE_SESSION_BY_ID_START,
  GET_ONLINE_SESSION_BY_ID_SUCCESS,
  GET_ONLINE_SESSION_BY_ID_FAILURE,
  DELETE_ONLINE_SESSION_START,
  DELETE_ONLINE_SESSION_SUCCESS,
  DELETE_ONLINE_SESSION_FAILURE,
  EDIT_ONLINE_SESSION_BY_ID_START,
  EDIT_ONLINE_SESSION_BY_ID_SUCCESS,
  EDIT_ONLINE_SESSION_BY_ID_FAILURE,
  GET_ONLINE_SESSIONS_SUCCESS_LONG_POLLING,
  GET_ONLINE_SESSION_BY_BATCH_ID_START,
  GET_ONLINE_SESSION_BY_BATCH_ID_SUCCESS,
  GET_ONLINE_SESSION_BY_BATCH_ID_FAILURE,
  EDIT_REALOAD_SUCCESS,
  POST_CUSTOM_SESSION_START,
  POST_CUSTOM_SESSION_SUCCESS,
  POST_CUSTOM_SESSION_FAILURE,
} from '../actions'

import { checkStatus } from '../helpers.js'

const postOnlineSessionStart = () => {
  return {
    type: POST_ONLINE_SESSION_START,
  }
}

const postOnlineSessionSuccess = () => {
  return {
    type: POST_ONLINE_SESSION_SUCCESS,
  }
}

const postOnlineSessionFailure = () => {
  return {
    type: POST_ONLINE_SESSION_FAILURE,
  }
}

const onlineSessionApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/online-sessions`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postOnlineSession = (data) => {
  const { jwt } = data
  return async (dispatch) => {
    dispatch(postOnlineSessionStart())
    try {
      const receivedData = await onlineSessionApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(fetchOnlineSessions({ jwt }))
      dispatch(postOnlineSessionSuccess())
    } catch (err) {
      dispatch(postOnlineSessionFailure())
    }
  }
}

const getOnlineSessionsStart = () => {
  return {
    type: GET_ONLINE_SESSIONS_START,
  }
}

const getOnlineSessionsSuccess = (data) => {
  const { online_sessions_res } = data
  return {
    type: GET_ONLINE_SESSIONS_SUCCESS,
    online_sessions_res,
  }
}

const getOnlineSessionsLongPillingSuccess = (data) => {
  const { online_sessions_res } = data
  return {
    type: GET_ONLINE_SESSIONS_SUCCESS_LONG_POLLING,
    online_sessions_res,
  }
}

const getOnlineSessionsFailure = () => {
  return {
    type: GET_ONLINE_SESSIONS_FAILURE,
  }
}

const onlineSessionsApiCall = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/me/online-sessions`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchOnlineSessions = ({ jwt }) => {
  return async (dispatch) => {
    dispatch(getOnlineSessionsStart())
    try {
      const receivedData = await onlineSessionsApiCall({
        jwt,
      })
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getOnlineSessionsSuccess(response?.data))
    } catch (err) {
      dispatch(getOnlineSessionsFailure())
    }
  }
}

export const fetchOnlineSessionsLongPolling = (data) => {
  return async (dispatch) => {
    try {
      const receivedData = await onlineSessionsApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getOnlineSessionsLongPillingSuccess(response?.data))
    } catch (err) {
      dispatch(getOnlineSessionsFailure())
    }
  }
}

const getOnlineSessionByIdStart = () => {
  return {
    type: GET_ONLINE_SESSION_BY_ID_START,
  }
}

const getOnlineSessionByIdSuccess = (data) => {
  const { online_session } = data
  return {
    type: GET_ONLINE_SESSION_BY_ID_SUCCESS,
    online_session,
  }
}

const getOnlineSessionByIdFailure = () => {
  return {
    type: GET_ONLINE_SESSION_BY_ID_FAILURE,
  }
}

const onlineSessionByIdApiCall = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/online-sessions/${id}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchOnlineSessionById = (data) => {
  return async (dispatch) => {
    dispatch(getOnlineSessionByIdStart())
    try {
      const receivedData = await onlineSessionByIdApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getOnlineSessionByIdSuccess(response?.data))
    } catch (err) {
      dispatch(getOnlineSessionByIdFailure())
    }
  }
}
//edit online sessions
const editOnlineSessionByIdStart = () => {
  return {
    type: EDIT_ONLINE_SESSION_BY_ID_START,
  }
}

const editOnlineSessionByIdSuccess = (data) => {
  const { online_session } = data
  return {
    type: EDIT_ONLINE_SESSION_BY_ID_SUCCESS,
    online_session,
  }
}

export const editRealoadSuccess = (data) => {
  const { reaload } = data
  return {
    type: EDIT_REALOAD_SUCCESS,
    reaload,
  }
}

const editOnlineSessionByIdFailure = () => {
  return {
    type: EDIT_ONLINE_SESSION_BY_ID_FAILURE,
  }
}

const editonlineSessionByIdApiCall = ({ jwt, id, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/online-sessions/${id}`
  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const editOnlineSessionById = (data) => {
  return async (dispatch) => {
    dispatch(editOnlineSessionByIdStart())
    try {
      const receivedData = await editonlineSessionByIdApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(editOnlineSessionByIdSuccess(response?.data))
    } catch (err) {
      dispatch(editOnlineSessionByIdFailure())
    }
  }
}

const deleteOnlineSessionStart = () => {
  return {
    type: DELETE_ONLINE_SESSION_START,
  }
}

const deleteOnlineSessionSuccess = (data) => {
  return {
    type: DELETE_ONLINE_SESSION_SUCCESS,
    data,
  }
}

const deleteOnlineSessionFailure = () => {
  return {
    type: DELETE_ONLINE_SESSION_FAILURE,
  }
}

const deleteOnlineSessionApiCall = ({ jwt, sessionId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/online-sessions/${sessionId}`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const deleteOnlineSession = (data) => {
  return async (dispatch, getState) => {
    dispatch(deleteOnlineSessionStart())
    try {
      const receivedData = await deleteOnlineSessionApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      const { onlineSession } = getState()
      const { onlineSessions } = onlineSession
      const filteredData = onlineSessions.filter(
        (session) => session._id !== data?.sessionId
      )
      dispatch(deleteOnlineSessionSuccess(filteredData))
    } catch (err) {
      dispatch(deleteOnlineSessionFailure())
    }
  }
}

//get Online Sessions by Batch id
const getOnlineSessionByBatchIdStart = () => {
  return {
    type: GET_ONLINE_SESSION_BY_BATCH_ID_START,
  }
}

const getOnlineSessionByBatchIdSuccess = (data) => {
  return {
    type: GET_ONLINE_SESSION_BY_BATCH_ID_SUCCESS,
    data: data?.online_session,
  }
}

const getOnlineSessionByBatchIdFailure = () => {
  return {
    type: GET_ONLINE_SESSION_BY_BATCH_ID_FAILURE,
  }
}

const onlineSessionByBatchIdApiCall = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/online-sessions/batch/${id}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const getOnlineSessionByBatchId = (data) => {
  const { jwt } = data
  return async (dispatch) => {
    dispatch(getOnlineSessionByBatchIdStart())
    try {
      const receivedData = await onlineSessionByBatchIdApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getOnlineSessionByBatchIdSuccess(response?.data))
    } catch (err) {
      dispatch(getOnlineSessionByBatchIdFailure())
    }
  }
}

//custom session
const postCustomSessionStart = () => {
  return {
    type: POST_CUSTOM_SESSION_START,
  }
}

const postCustomSessionSuccess = () => {
  return {
    type: POST_CUSTOM_SESSION_SUCCESS,
  }
}

const postCustomSessionFailure = () => {
  return {
    type: POST_CUSTOM_SESSION_FAILURE,
  }
}

const customSessionApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/online-sessions/student-id`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postCustomSession = (data) => {
  const { jwt } = data
  return async (dispatch) => {
    dispatch(postCustomSessionStart())
    try {
      const receivedData = await customSessionApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(fetchOnlineSessions({ jwt }))
      dispatch(postCustomSessionSuccess())
    } catch (err) {
      dispatch(postCustomSessionFailure())
    }
  }
}
