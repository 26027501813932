import React from 'react'
import RightArrow from '../../../assets/images/rightArrow.png'

const ChaptersCard = (props) => {
  return (
    <div onClick={props.onClick} className="chaptersCard row">
      <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 chaptersCard__img">
        <img src={props.img} alt="CWX" />
        <div className="clear"></div>
      </div>
      <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 chaptersCard__description ">
        <h5>{props.chapterName}</h5>
        {props.description !== '' && props.description !== undefined && (
          <p>{props.description}</p>
        )}
        {props.noOfTopics !== '' && props.noOfTopics !== undefined && (
          <p className="chaptersCard__total">
            Number of topics: <span>{props.noOfTopics}</span>
          </p>
        )}
        {props.availableTopics !== '' && props.availableTopics !== undefined && (
          <p className="chaptersCard__availableTopics">
            Available topics: <span>{props.availableTopics}</span>
          </p>
        )}
        {props.completed !== '' && props.completed !== undefined && (
          <p className="chaptersCard__noOfTopics">
            Completed: <span>{props.completed}</span>
          </p>
        )}
        {props.total !== '' && props.total !== undefined && (
          <p className="chaptersCard__total">
            Total: <span>{props.total}</span>
          </p>
        )}
        <div className="clear"></div>
      </div>
      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
        {props.percentageCompleted !== '' &&
          props.percentageCompleted !== undefined && (
            <div
              className="progress"
              data-percentage={props.percentageCompleted}
            >
              <span className="progress-left">
                <span className="progress-bar"></span>
              </span>
              <span className="progress-right">
                <span className="progress-bar"></span>
              </span>
              <div className="progress-value">
                <div>{props.percentageCompleted}%</div>
              </div>
            </div>
          )}
        <div className="clear"></div>
      </div>
      <img className="chaptersCard__arrow" src={RightArrow} alt="cwx" />
    </div>
  )
}

export default ChaptersCard
