import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { usePrevious } from '../helpers'

//assets
import Card from '../assets/molecules/card'
import Button from '../assets/molecules/button'

//actions
import { postOnlineSession } from '../../redux/action/onlineSession'

//reducer
//account
import { getJwt } from '../../redux/reducer/account'
import { getBatchSubjects } from '../../redux/reducer/subject'
import { getIsLoading, getIsError } from '../../redux/reducer/onlineSession'

import Loader from '../assets/molecules/loader'

const Create = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  //selector
  const jwt = useSelector(getJwt)
  const error = useSelector(getIsError)
  const isLoading = useSelector(getIsLoading)
  const isLoadingPrev = usePrevious(isLoading)
  const subjects = useSelector(getBatchSubjects)

  let [formData, setFormData] = useState({
    startDate: new Date(),
    endDate: new Date(),
  })
  let [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (error) {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }, [error])

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isLoading &&
      !isLoading &&
      !error
    ) {
      history.push({
        pathname: '/me/faculty/sessions',
        state: { status: 'success' },
      })
    }
  }, [error, history, isLoading, isLoadingPrev])

  const onChange = (e) => {
    e.preventDefault()
    if (e.target.name === 'subject') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value.split('-')[0],
        batchId: e.target.value.split('-')[1],
      })
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    errorMessage && setErrorMessage('')
  }

  const onChangeDate = (date, name) => {
    setFormData({ ...formData, [name]: new Date(date) })
    errorMessage && setErrorMessage('')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (
      formData?.title &&
      formData?.startDate &&
      formData?.endDate &&
      formData?.batchId &&
      formData?.subject
    ) {
      const payload = {
        title: formData.title,
        start_time: formData.startDate,
        end_time: formData.endDate,
        batch_id: formData.batchId,
        batch_subject_id: formData.subject,
      }
      dispatch(postOnlineSession({ jwt, payload }))
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  return (
    <div className="tabs__container">
      <section>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Online Session</li>
            <li className="breadcrumb-item active">Create</li>
          </ol>
        </nav>
      </section>

      <section>
        <h4>Online Session</h4>
        <Card columnNo="1" title="Create Session" titleColor={1}>
          <input
            name="title"
            value={formData.title}
            type="text"
            placeholder="Title"
            onChange={onChange}
          />
          <label>Start Date:</label>
          <DatePicker
            name="startDate"
            selected={formData?.startDate}
            onChange={(e) => onChangeDate(e, 'startDate')}
            placeholderText="Start Date & time"
            dateFormat="dd/MM/yyyy HH:mm"
            minDate={new Date()}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
          />
          <label>End Date:</label>
          <DatePicker
            name="endDate"
            selected={formData?.endDate}
            onChange={(e) => onChangeDate(e, 'endDate')}
            placeholderText="End Date & time"
            dateFormat="dd/MM/yyyy HH:mm"
            minDate={new Date()}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
          />
          <select name="subject" onChange={(e) => onChange(e)}>
            <option value="">Please select subject</option>
            {subjects.map((subject, index) => {
              return (
                <option
                  key={index}
                  value={`${subject._id}-${subject?.batch_id}`}
                >
                  {subject?.subject_title}
                </option>
              )
            })}
          </select>
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              className="button--small"
              color="blue"
              text="Create"
              click={(e) => onSubmit(e)}
            />
          )}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </Card>
      </section>
    </div>
  )
}

export default Create
