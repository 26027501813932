import React from 'react'

const HomeCard = (props) => {
  return (
    <div
      onClick={!props.disable ? props.onClick : null}
      className="homeCard row"
    >
      <div className="col-xl-3  col-lg-3  col-md-12 col-sm-12 homeCard__img">
        <img src={props.img} alt="CWX" />
        <div className="clear"></div>
      </div>
      <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 homeCard_content">
        <div>
          <h3>{props.heading}</h3>
          <p
            className="homeCard__para"
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
          <div className="homeCard__details">
            <h5 className="homeCard__subject">{props.subject}</h5>
            <h5 className="homeCard__chapter">{props.chapter}</h5>
            <h5 className="homeCard__timing">{props.timing}</h5>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="col-xl-2 col-lg-3 col-md-12 col-sm-12 display__flex homeCard__wrap3">
        <div>
          <button
            className={props.buttonClass}
            disabled={props.disable}
            style={{ opacity: props.disable ? 0.5 : null }}
            onClick={props.onClick}
          >
            {props.button}
          </button>
          <p className="homeCard__teacher">Teacher: {props.teacher}</p>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  )
}

export default HomeCard
