import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Tab, Tabs } from 'react-bootstrap'
import moment from 'moment'

//assets
import Loader from '../assets/molecules/loader'
import LeftRightLayout from '../assets/layout/leftrightlayout'
import Col75 from '../assets/layout/leftrightlayout/column75'
import Col25 from '../assets/layout/leftrightlayout/column25'
import ChaptersCard from '../assets/molecules/chaptersCard'
import Accordion from '../assets/layout/accordion'
import TeachersCard from '../assets/molecules/teachersCard'
import DoubtsCard from '../assets/molecules/doubtsCard'
import TeachersSubjectCard from '../assets/molecules/teachersSubjectCard'
import ExamsCard from '../assets/molecules/examsCard'
import TeachersIcon from '../../assets/images/profile.png'
import ProfilePic from '../../assets/images/profile.png'
import Popup from '../assets/layout/popupRight'
import ReplyCard from '../assets/molecules/replyCard'
import InputSend from '../assets/molecules/inputSend'
import Select from '../assets/molecules/select'
import PopupConfirm from '../../components/assets/layout/popupConfirm'

//action
//subject(chapter actions also implemented in subject)
import { fetchSubjects } from '../../redux/action/subject'
import { fetchChaptersBySubjectId } from '../../redux/action/chapter'
import { fetchAssessments } from '../../redux/action/assessment'
//ui
import {
  setSubject,
  setChapter,
  setBatchSubjectId,
  setAssessment,
  setTopic,
} from '../../redux/action/ui'
import { fetchTopicsByTeachers } from '../../redux/action/topic'
import {
  postReplyDoubt,
  fetchDoubtsResponsesByIds,
  editStudentDoubtsById,
  deleteStudentDoubtsById,
} from '../../redux/action/doubt'

//reducer
//subject(chapters also implemented in subject)
import { getSubjects } from '../../redux/reducer/subject'
import {
  // getIsError,
  getChapters,
  getIsChaptersLoading,
} from '../../redux/reducer/chapter'
import {
  getIsFacultiesLoading,
  getFaculties,
} from '../../redux/reducer/faculty'

//account
import { getJwt, getUsername } from '../../redux/reducer/account'
import { getAssessments } from '../../redux/reducer/assessment'
import {
  getSubjectId,
  getSubjectTitle,
  getBatchSubjectId,
} from '../../redux/reducer/ui'
import {
  getDoubts,
  getResponsesGroupByDoubtId,
  // getIsDoubtsLoading,
  // getIsError,
} from '../../redux/reducer/doubt'
import {
  getTopicsGroupByFaculty,
  getIsLoadingTopicsByTeachers,
  getIsErrorTopicsByTeachers,
} from '../../redux/reducer/topic'

const Chapter = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  //selectors
  //account
  const jwt = useSelector(getJwt)
  //ui
  const subjectId = useSelector(getSubjectId)
  const subjectTitle = useSelector(getSubjectTitle)
  const batchSubjectId = useSelector(getBatchSubjectId)

  //subject(chapters)
  const subjects = useSelector(getSubjects)
  const chapters = useSelector(getChapters)
  const isLoading = useSelector(getIsChaptersLoading)
  const isFacultiesLoading = useSelector(getIsFacultiesLoading)
  const faculties = useSelector(getFaculties)
  const doubts = useSelector(getDoubts)
  let assessments = useSelector(getAssessments)
  const topicsGroupByFaculty = useSelector(getTopicsGroupByFaculty)
  const responsesGroupByDoubtId = useSelector(getResponsesGroupByDoubtId)
  const username = useSelector(getUsername)
  // const isDoubtsLoading = useSelector(getIsDoubtsLoading)
  // const isDoubtError = useSelector(getIsError)

  useEffect(() => {
    dispatch(fetchChaptersBySubjectId({ jwt, subjectId }))
    dispatch(fetchAssessments({ jwt }))
    subjects.length === 0 && dispatch(fetchSubjects({ jwt }))
  }, [dispatch, jwt, subjectId, subjects.length])

  const [localFaculties, setLocalFaculties] = useState([])
  const [localDoubts, setLocalDoubts] = useState([])
  const [localAssessments, setLocalAssessments] = useState([])
  const [doubtClicked, setDoubtClicked] = useState()

  useEffect(() => {
    setLocalFaculties(
      faculties.filter((faculty) =>
        faculty.subjects.some((subject) => subjectId === subject.id)
      )
    )
    setLocalDoubts(
      doubts.filter((doubt) => doubt.batch_subject_id === batchSubjectId)
    )
    setLocalAssessments(
      assessments.filter(
        (assessment) => assessment.batch_subject_id === batchSubjectId
      )
    )
  }, [faculties, doubts, assessments, subjectId, batchSubjectId])

  useEffect(() => {
    if (faculties.length) {
      const facultyIds = faculties?.map((faculty) => faculty._id)
      dispatch(fetchTopicsByTeachers({ jwt, facultyIds }))
    }
  }, [dispatch, faculties, jwt])

  useEffect(() => {
    if (doubts.length) {
      const doubtIds = doubts?.map((doubt) => doubt._id)
      dispatch(fetchDoubtsResponsesByIds({ jwt, doubtIds }))
    }
  }, [dispatch, doubts, jwt])

  const getCurrentSubject = (subjectId) => {
    const filteredList = subjects?.filter(
      (subject) => subject.subject_id === subjectId
    )
    const currentSubject = filteredList?.length > 0 ? filteredList[0] : ''
    return currentSubject
  }

  const onChangeSubject = (e) => {
    e.preventDefault()
    const subject_title =
      e.target.options[e.target.options.selectedIndex].innerText
    dispatch(setSubject(e.target.value, subject_title))
    const currentSubject = getCurrentSubject(e.target.value)
    dispatch(setBatchSubjectId(currentSubject?._id))
  }

  const onChapterSelect = (chapterId, chapterTitle, chapterData) => {
    dispatch(setChapter(chapterId, chapterTitle))
    history.push({
      pathname: '/me/subjects/chapters/topics',
      state: { chapterData },
    })
  }

  const onAssessmentSelect = (id, title, batchSubjectId, taken, status) => {
    dispatch(setAssessment(id, title))
    dispatch(setBatchSubjectId(batchSubjectId))
    if (status === 'CLOSED') {
      history.push(`/assessments/results`)
    } else {
      if (!taken) {
        history.push(`/assessments/questions`)
      }
    }
  }

  const onTopicSelect = (topicId, topicTitle) => {
    dispatch(setTopic(topicId, topicTitle))
    history.push('/me/subjects/chapters/topics/detail')
  }

  const [popClassName, setPopClassName] = useState('hidePop')
  const openPopup = (doubt) => {
    setDoubtClicked(doubt)
    setPopClassName('showPop')
  }
  const closePopup = () => {
    setPopClassName('hidePop')
    setDoubtClicked()
  }

  const [editText, setEditText] = useState()
  const [selectedDoubt, setSelectedDoubt] = useState('')
  const [editDoubtPopClassName, setEditDoubtPopClassName] = useState('hidePop')
  const [deleteDoubtPopClassName, setDeleteDoubtPopClassName] = useState(
    'hidePop'
  )

  const openEditDoubtPopup = (doubt) => {
    setEditDoubtPopClassName('showPop')
    setSelectedDoubt(doubt)
    setEditText(doubt.doubt_text)
  }

  const openDeleteDoubtPopup = (doubt) => {
    setDeleteDoubtPopClassName('showPop')
    setSelectedDoubt(doubt)
  }

  const closeEditDoubtPopup = () => {
    setEditText('')
    setEditDoubtPopClassName('hidePop')
  }

  const closeDeleteDoubtPopup = () => {
    setDeleteDoubtPopClassName('hidePop')
  }

  const onEditDoubt = () => {
    dispatch(
      editStudentDoubtsById({
        jwt,
        doubtId: selectedDoubt._id,
        payload: {
          doubt_text: editText,
        },
      })
    )
    setEditText('')
    closeEditDoubtPopup()
  }

  const onDeleteDoubt = () => {
    dispatch(deleteStudentDoubtsById({ doubtId: selectedDoubt._id, jwt }))
    setSelectedDoubt('')
    closeDeleteDoubtPopup()
  }

  return (
    <div className="tabs__container">
      <section>
        <h1>{subjectTitle}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="/me/subjects">{subjectTitle}</Link>
            </li>
            <li className="breadcrumb-item active">Chapters</li>
          </ol>
        </nav>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col75>
            <Select
              onChange={(e) => onChangeSubject(e)}
              defaultValue={subjectId}
            >
              <option value="">Subjects</option>
              {subjects?.map((subject, index) => {
                return (
                  <option
                    value={subject.subject_id}
                    key={index}
                    selected={subject.subject_id === subjectId}
                  >
                    {subject.subject_title}
                  </option>
                )
              })}
            </Select>
            {isLoading ? (
              <Loader />
            ) : subjectId ? (
              <section>
                {chapters?.length ? (
                  chapters
                    ?.filter((item) => item?.visibility)
                    ?.map((chapter, index) => {
                      let percentageCompleted = Math.round(
                        (chapter.read_topic_count / chapter.topic_count) * 100
                      )
                      return (
                        <ChaptersCard
                          key={index}
                          img="https://cwxdata.s3.ap-south-1.amazonaws.com/phy.jpg"
                          chapterName={chapter.title}
                          onClick={() =>
                            onChapterSelect(chapter._id, chapter.title, chapter)
                          }
                          availableTopics={chapter.visible_topic_count}
                          completed={chapter.read_topic_count}
                          total={chapter.topic_count}
                          percentageCompleted={percentageCompleted}
                        />
                      )
                    })
                ) : (
                  <div>No Chapters</div>
                )}
                <div className="clear"></div>
              </section>
            ) : (
              <div> Please Select a Subject </div>
            )}
            <PopupConfirm
              className={editDoubtPopClassName}
              close={() => closeEditDoubtPopup()}
              onClick={() => onEditDoubt()}
              buttonText="Save Doubt"
              popupHead="Edit Doubt"
            >
              <textarea
                rows="5"
                value={editText || ''}
                onChange={(e) => setEditText(e.target.value)}
              ></textarea>
            </PopupConfirm>
            <PopupConfirm
              className={deleteDoubtPopClassName}
              close={() => closeDeleteDoubtPopup()}
              onClick={() => onDeleteDoubt()}
              buttonText="yes"
              popupHead="Delete Doubts"
            >
              <h2>Are you sure want to delete?</h2>
            </PopupConfirm>
          </Col75>
          <Col25>
            <Tabs>
              <Tab
                eventKey="teachers"
                title={`Teachers (${localFaculties.length})`}
              >
                <Accordion>
                  {isFacultiesLoading ? (
                    <Loader />
                  ) : (
                    localFaculties?.map((faculty, i) => {
                      const facultyTopicsBelongsToSubject = topicsGroupByFaculty?.[
                        faculty._id
                      ]?.filter((topic) => topic.subject_id === subjectId)
                      return (
                        <TeachersCard
                          key={i}
                          itemNo={i + 1}
                          icon={TeachersIcon}
                          teachersName={faculty.name}
                          designation={faculty.designation}
                        >
                          <h5>List of topics</h5>
                          {facultyTopicsBelongsToSubject?.length ? (
                            facultyTopicsBelongsToSubject.map(
                              (topic, index) => {
                                return (
                                  <TeachersSubjectCard
                                    key={index}
                                    onClick={() =>
                                      onTopicSelect(topic._id, topic.title)
                                    }
                                    topic={topic.title}
                                  />
                                )
                              }
                            )
                          ) : (
                            <p>No topics available</p>
                          )}
                        </TeachersCard>
                      )
                    })
                  )}
                </Accordion>
              </Tab>
              <Tab
                className="doubtsTab"
                eventKey="doubts"
                title={`Doubts (${localDoubts?.length})`}
              >
                {localDoubts?.length > 0 ? (
                  localDoubts
                    ?.sort(
                      (a, b) =>
                        new Date(b?.created_at) - new Date(a?.created_at)
                    )
                    ?.map((doubt, index) => {
                      return (
                        <DoubtsCard
                          picture={ProfilePic}
                          name={
                            doubt?.author_name
                              ? doubt?.author_name === username
                                ? 'You'
                                : 'Anonymous'
                              : ''
                          }
                          subject={subjectTitle}
                          chapter={doubt.topic_title}
                          time={moment(doubt.created_at).fromNow()}
                          content={doubt.doubt_text}
                          responses={
                            responsesGroupByDoubtId?.[doubt._id]?.length || 0
                          }
                          buttonText={`SEE TEACHER REPLIES (${
                            responsesGroupByDoubtId?.[doubt._id]?.length || 0
                          })`}
                          replyButton={() => openPopup(doubt)}
                          editOnClick={() => {
                            openEditDoubtPopup(doubt)
                          }}
                          deleteOnClick={() => {
                            openDeleteDoubtPopup(doubt)
                          }}
                        />
                      )
                    })
                ) : (
                  <p>No doubts available</p>
                )}
              </Tab>
              {/* <Tab
                className="examsCard__wrapper"
                eventKey="exams"
                title={`Exams (${localAssessments?.length})`}
              >
                {localAssessments?.length > 0 ? (
                  localAssessments?.map((assessment, index) => {
                    return (
                      <ExamsCard
                        onClick={() => {
                          onAssessmentSelect(
                            assessment._id,
                            assessment.title,
                            assessment.batch_subject_id,
                            assessment?.has_taken,
                            assessment?.status
                          )
                        }}
                        subjectName={assessment.title}
                        subject={subjectTitle}
                        submit={
                          assessment?.status !== 'CLOSED'
                            ? assessment?.has_taken
                              ? `Submited on ${moment(
                                  assessment?.updated_at
                                ).format('DD-MM-YYYY')}`
                              : 'Not Submitted'
                            : 'View Published Results'
                        }
                      />
                    )
                  })
                ) : (
                  <p>No assessment available</p>
                )}
              </Tab> */}
            </Tabs>
            <Popup
              className={popClassName}
              close={() => closePopup()}
              onClick=""
              buttonText="Save Doubt"
              popupHead="Edit Doubt"
            >
              <DoubtsCard
                picture={ProfilePic}
                name={
                  doubtClicked?.author_name
                    ? doubtClicked?.author_name === username
                      ? 'You'
                      : 'Anonymous'
                    : ''
                }
                subject={subjectTitle}
                chapter={doubtClicked?.topic_title}
                time={moment(doubtClicked?.created_at).fromNow()}
                content={doubtClicked?.doubt_text}
                responses={doubtClicked?.response_count}
                editOnClick={() => {
                  setPopClassName('hidePop')
                  openEditDoubtPopup(doubtClicked)
                }}
                deleteOnClick={() => {
                  setPopClassName('hidePop')
                  openDeleteDoubtPopup(doubtClicked)
                }}
              />
              {responsesGroupByDoubtId?.[doubtClicked?._id]?.map((response) => {
                return (
                  <ReplyCard
                    picture={ProfilePic}
                    name={response.author_name}
                    time={moment(response.created_at).fromNow()}
                    content={response.response_text}
                  />
                )
              })}
              <InputSend
                placeholder="Reply your comment"
                onClick={(respondMsg) => {
                  const payload = {
                    response_text: respondMsg,
                    doubt_id: doubtClicked._id,
                    subject_id: doubtClicked.batch_subject_id,
                  }
                  dispatch(postReplyDoubt({ jwt, payload }))
                }}
              />
            </Popup>
          </Col25>
        </LeftRightLayout>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default Chapter
