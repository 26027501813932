import React from 'react'

const ListAccordian = (props) => {
  return (
    <React.Fragment>
      <input type="radio" name="accordion" id={'accItem' + props.itemNo} />
      <div className="box">
        <div onClick={(e) => props.onClick(e)}>
          <label
            id="title"
            className="box-title"
            htmlFor={'accItem' + props.itemNo}
          >
            <div
              className={
                'studentsCardWithMarks studentsCardWithMarks--' +
                props.className
              }
            >
              <div className="studentsCard__img">
                <img src={props.picture} alt="CWX" />
              </div>
              <div className="studentsCard__content">
                <div>
                  <h5>
                    {props.candidateName}{' '}
                    <span
                      className={
                        'studentsCard__score studentsCard__score--' +
                        props.evaluated
                      }
                    >
                      {/* <span className="studentsCard__checkMark">&#10003;</span> */}
                      {props.score}
                    </span>
                  </h5>
                  <p>{props.details}</p>
                </div>
              </div>
            </div>
          </label>
        </div>
        <label className="box-close" htmlFor="acc-close"></label>
        <div className="box-content">{props.children}</div>
      </div>
    </React.Fragment>
  )
}

export default ListAccordian
