import React, { useState } from 'react'

const TopicsCardWithOptions = (props) => {
  const [optionState, setoptionState] = useState(true)

  const onToggleOptions = () => {
    setoptionState(!optionState)
  }
  const createMarkup = (data) => {
    return { __html: data }
  }
  return (
    <div onClick={props.onClick} className="topicsCard row">
      <div className="col-xl-2  col-lg-2  col-md-12 col-sm-12 topicsCard__img">
        {props?.studyPlan ? (
          <div className="study-plan__wrapper">
            <p className="study-plan__text">Study Plan</p>
          </div>
        ) : null}
        <img src={props.img} alt="CWX" />
        <div className="clear"></div>
      </div>
      <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 topicsCard__content">
        <div>
          <h3>{props.heading}</h3>
          <p
            className="topicsCard__para"
            dangerouslySetInnerHTML={createMarkup(props.description)}
          >
            {}
          </p>
          <p className="topicsCard__para">{props.description1}</p>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="col-xl-2 col-lg-3 col-md-12 col-sm-12 display__flex topicsCard__wrap3">
        <div>
          <button onClick={props.onClick} className={props.buttonClass}>
            {props.button}
          </button>
          <p>{props.extraContent}</p>
          <div className="clear"></div>
        </div>
        <div className="dotsContainer">
          <div className="position-relative">
            <ul
              onClick={(event) => {
                event.stopPropagation()
                onToggleOptions()
              }}
              className="doubtsCard__dotsContainer"
            >
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <ul
              className={
                'options__container ' + (optionState ? '' : 'collapse')
              }
            >
              <li
                className="edit"
                onClick={(event) => {
                  event.stopPropagation()
                  onToggleOptions()
                  props.editOnClick()
                }}
              >
                Edit
              </li>
              <li
                className="delete"
                onClick={(event) => {
                  event.stopPropagation()
                  onToggleOptions()
                  props.deleteOnClick()
                }}
              >
                Delete
              </li>
            </ul>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  )
}

export default TopicsCardWithOptions
