import Audio from '../assets/images/old/audio.png'
import Video from '../assets/images/old/video.png'
import Image from '../assets/images/old/image.png'

export const getById = ({ data, key }) => {
  let byId = {}
  const id = key ? key : '_id'
  data.forEach((s) => {
    byId[s[id]] = s
  })
  return byId
}

export const topicType = (type) => {
  switch (type) {
    case 'audio':
      return Audio
    case 'video':
      return Video
    case 'image':
      return Image
    default:
      return Video
  }
}
