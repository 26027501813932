import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'underscore'
import ProfilePic from '../../assets/images/old/profile-pic.png'

import Col from '../assets/molecules/column'
import ProfileCard from '../assets/molecules/profileCard'
import Loader from '../assets/molecules/loader'

//action
import { fetchFacultiesOfCurrentUser } from '../../redux/action/faculty'

//reducer
import {
  getIsFacultiesLoading,
  getFaculties,
} from '../../redux/reducer/faculty'
import { getJwt } from '../../redux/reducer/account'

const MyTeachers = () => {
  const dispatch = useDispatch()

  const jwt = useSelector(getJwt)
  const isLoading = useSelector(getIsFacultiesLoading)
  const faculties = useSelector(getFaculties)

  useEffect(() => {
    dispatch(fetchFacultiesOfCurrentUser({ jwt }))
  }, [dispatch, jwt])

  return (
    <div className="tabs__container">
      <section>
        <Col no="1">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active">My teachers</li>
            </ol>
          </nav>
          <div className="clear"></div>
        </Col>
        <div className="clear"></div>
      </section>
      <section className="margin--top100" style={{ marginTop: '150px' }}>
        {isLoading ? (
          <Loader />
        ) : faculties?.length ? (
          faculties.map((faculty, index) => {
            return (
              <div>
                <ProfileCard
                  className="no--effect margin--top50"
                  columnNo="4"
                  color={index + 1}
                  img={
                    faculty.avatar !== 'string' ? faculty.avatar : ProfilePic
                  }
                >
                  <h3>{faculty.name}</h3>
                  <p>Designation: {faculty.designation}</p>
                  <p>
                    Subjects: {_.pluck(faculty.subjects, 'title').join(', ')}
                  </p>
                </ProfileCard>
              </div>
            )
          })
        ) : (
          <p>No faculties to list</p>
        )}
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default MyTeachers
