import {
  APP_LOGOUT,
  GET_DOUBTS_BY_TOPIC_ID_START,
  GET_DOUBTS_BY_TOPIC_ID_SUCCESS,
  GET_DOUBTS_BY_TOPIC_ID_FAILURE,
  GET_DOUBTS_BY_SUBJECT_ID_START,
  GET_DOUBTS_BY_SUBJECT_ID_SUCCESS,
  GET_DOUBTS_BY_SUBJECT_ID_FAILURE,
  ASK_DOUBT_START,
  ASK_DOUBT_SUCCESS,
  ASK_DOUBT_FAILURE,
  GET_STUDENT_DOUBTS_START,
  GET_STUDENT_DOUBTS_SUCCESS,
  GET_STUDENT_DOUBTS_FAILURE,
  EDIT_STUDENT_DOUBTS_START,
  EDIT_STUDENT_DOUBTS_SUCCESS,
  EDIT_STUDENT_DOUBTS_FAILURE,
  DELETE_STUDENT_DOUBTS_START,
  DELETE_STUDENT_DOUBTS_SUCCESS,
  DELETE_STUDENT_DOUBTS_FAILURE,
  REPLY_DOUBT_START,
  REPLY_DOUBT_SUCCESS,
  REPLY_DOUBT_FAILURE,
  GET_DOUBT_RESPONSE_START,
  GET_DOUBT_RESPONSE_SUCCESS,
  GET_DOUBT_RESPONSE_FAILURE,
  GET_DOUBTS_START,
  GET_DOUBTS_SUCCESS,
  GET_DOUBTS_FAILURE,
} from '../actions'

import _ from 'underscore'

let initialState = {
  error: false,
  isDoubtLoading: false,
  isEditDoubtLoading: false,
  isDoubtResponseLoading: false,
  isDoubtDeleting: false,
  isDoubtReplying: false,
  doubts: [],
  response: [],
  responses: [],
}

export const doubt = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_DOUBTS_BY_TOPIC_ID_START:
      return {
        ...state,
        error: false,
        isDoubtLoading: true,
      }
    case GET_DOUBTS_BY_TOPIC_ID_SUCCESS:
      return {
        ...state,
        isDoubtLoading: false,
        error: false,
        doubts: action.doubts,
      }
    case GET_DOUBTS_BY_TOPIC_ID_FAILURE:
      return {
        ...state,
        isDoubtLoading: false,
        error: true,
      }
    case GET_DOUBTS_BY_SUBJECT_ID_START:
      return {
        ...state,
        error: false,
        isDoubtLoading: true,
      }
    case GET_DOUBTS_BY_SUBJECT_ID_SUCCESS:
      return {
        ...state,
        isDoubtLoading: false,
        error: false,
        doubts: action.doubts,
      }
    case GET_DOUBTS_BY_SUBJECT_ID_FAILURE:
      return {
        ...state,
        isDoubtLoading: false,
        error: true,
      }
    case ASK_DOUBT_START:
      return {
        ...state,
        error: false,
        isDoubtLoading: true,
      }
    case ASK_DOUBT_SUCCESS:
      return {
        ...state,
        isDoubtLoading: false,
        error: false,
        doubts: action.doubts,
      }
    case ASK_DOUBT_FAILURE:
      return {
        ...state,
        isDoubtLoading: false,
        error: true,
      }
    case GET_STUDENT_DOUBTS_START:
      return {
        ...state,
        error: false,
        isDoubtLoading: true,
      }
    case GET_STUDENT_DOUBTS_SUCCESS:
      return {
        ...state,
        isDoubtLoading: false,
        error: false,
        doubts: action.doubts,
      }
    case GET_STUDENT_DOUBTS_FAILURE:
      return {
        ...state,
        isDoubtLoading: false,
        error: true,
      }
    case EDIT_STUDENT_DOUBTS_START:
      return {
        ...state,
        error: false,
        isEditDoubtLoading: true,
      }
    case EDIT_STUDENT_DOUBTS_SUCCESS:
      return {
        ...state,
        isEditDoubtLoading: false,
        error: false,
        // doubts: action.doubts,
      }
    case EDIT_STUDENT_DOUBTS_FAILURE:
      return {
        ...state,
        isEditDoubtLoading: false,
        error: true,
      }
    case DELETE_STUDENT_DOUBTS_START:
      return {
        ...state,
        error: false,
        isDoubtDeleting: true,
      }
    case DELETE_STUDENT_DOUBTS_SUCCESS:
      return {
        ...state,
        isDoubtDeleting: false,
        error: false,
      }
    case DELETE_STUDENT_DOUBTS_FAILURE:
      return {
        ...state,
        isDoubtDeleting: false,
        error: true,
      }
    case REPLY_DOUBT_START:
      return {
        ...state,
        error: false,
        isDoubtReplying: true,
      }
    case REPLY_DOUBT_SUCCESS:
      return {
        ...state,
        isDoubtReplying: false,
        error: false,
      }
    case REPLY_DOUBT_FAILURE:
      return {
        ...state,
        isDoubtReplying: false,
        error: true,
      }
    case GET_DOUBT_RESPONSE_START:
      return {
        ...state,
        error: false,
        isDoubtResponseLoading: true,
      }
    case GET_DOUBT_RESPONSE_SUCCESS:
      return {
        ...state,
        isDoubtResponseLoading: false,
        error: false,
        response: action.response,
      }
    case GET_DOUBT_RESPONSE_FAILURE:
      return {
        ...state,
        isDoubtResponseLoading: false,
        error: true,
      }
    case GET_DOUBTS_START:
      return {
        ...state,
        error: false,
        isDoubtLoading: true,
      }
    case GET_DOUBTS_SUCCESS:
      return {
        ...state,
        isDoubtLoading: false,
        error: false,
        doubts: action.doubts,
      }
    case GET_DOUBTS_FAILURE:
      return {
        ...state,
        isDoubtLoading: false,
        error: true,
      }

    default:
      return state
  }
}

export const getIsDoubtsLoading = (state) => state.doubt.isDoubtLoading
export const getIsDoubtDeleting = (state) => state.doubt.isDoubtDeleting
export const getIsDoubtReplying = (state) => state.doubt.isDoubtReplying
export const getIsEditDoubtLoading = (state) => state.doubt.isEditDoubtLoading
export const getDoubts = (state) => state.doubt.doubts
export const getDoubtState = (state) => state.doubt

export const getIsDoubtsResponseLoading = (state) =>
  state.doubt.isDoubtResponseLoading
export const getResponse = (state) => state.doubt.response
export const getResponsesGroupByDoubtId = (state) =>
  _.groupBy(state.doubt.response, 'doubt_id')

export const getIsError = (state) => state.doubt.error
