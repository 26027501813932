import {
  APP_LOGOUT,
  VIMEO_UPLOAD_START,
  VIMEO_UPLOAD_FILE,
  VIMEO_UPLOAD_FAIL,
} from '../actions.js'

const initialState = {
  error: false,
  link: '',
  isLoading: false,
}

export const uploadVimeoFile = (state = initialState, action) => {
  switch (action.type) {
    case VIMEO_UPLOAD_START:
      return Object.assign({}, state, {
        isLoading: true,
        error: false,
      })
    case VIMEO_UPLOAD_FILE:
      return Object.assign({}, initialState, {
        link: action.data.link,
        isLoading: false,
        error: false,
      })
    case VIMEO_UPLOAD_FAIL:
      return Object.assign({}, state, {
        error: true,
        isLoading: false,
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

export const getIsError = (state) => state.uploadVimeoFile.error
export const getVimeoLink = (state) => state.uploadVimeoFile.link
export const getIsLoadingVimeoFile = (state) => state.uploadVimeoFile.isLoading
