import {
  GET_FACULTY_REPORT_START,
  GET_FACULTY_REPORT_SUCCESS,
  GET_FACULTY_REPORT_FAILURE,
} from '../actions'

import { checkStatus } from '../helpers.js'

const facultyReportStart = () => {
  return {
    type: GET_FACULTY_REPORT_START,
  }
}
const facultyReportSuccess = (data) => {
  const { result } = data

  return {
    type: GET_FACULTY_REPORT_SUCCESS,
    result,
  }
}

const facultyReportFailure = () => {
  return {
    type: GET_FACULTY_REPORT_FAILURE,
  }
}

const facultyReport = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/cwx/get-teacher-report`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchFacultyReport = (data) => {
  return async (dispatch) => {
    dispatch(facultyReportStart())
    try {
      const receivedData = await facultyReport(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(facultyReportSuccess(response.data))
    } catch (err) {
      dispatch(facultyReportFailure())
    }
  }
}
