import {
  APP_LOGOUT,
  GET_STUDENT_STUDY_PLAN_START,
  GET_STUDENT_STUDY_PLAN_SUCCESS,
  GET_STUDENT_STUDY_PLAN_FAILURE,
  GET_STUDENT_STUDY_BY_TOPICID_START,
  GET_STUDENT_STUDY_BY_TOPICID_SUCCESS,
  GET_STUDENT_STUDY_BY_TOPICID_FAILURE,
  POST_STUDENT_STUDY_PLAN_START,
  POST_STUDENT_STUDY_PLAN_SUCCESS,
  POST_STUDENT_STUDY_PLAN_FAILURE,
  GET_STUDENT_WEEKLY_PLAN_START,
  GET_STUDENT_WEEKLY_PLAN_SUCCESS,
  GET_STUDENT_WEEKLY_PLAN_FAILURE,
  DELETE_STUDY_PLAN_START,
  DELETE_STUDY_PLAN_SUCCESS,
  DELETE_STUDY_PLAN_FAILURE,
  GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_START,
  GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_SUCCESS,
  GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_FAILURE,
} from '../actions'

import _ from 'underscore'

let initialState = {
  error: false,
  isStudentStudyPlanLoading: false,
  postIsStudentStudyPlanLoading: false,
  studentStudyPlan: [],
  studentStudyPlanByBatch: {},
  isLoadingStudyPlanByTopicId: false,
  studyPlanByTopicId: {},
  isLoadingStudyPlanByChapterIdBatchSubId: false,
  studyPlanByChapterIdBatchSubId: {},
}

export const studyplan = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_STUDENT_STUDY_BY_TOPICID_START:
      return {
        ...state,
        isLoadingStudyPlanByTopicId: true,
        error: false,
      }
    case GET_STUDENT_STUDY_BY_TOPICID_SUCCESS:
      return {
        ...state,
        isLoadingStudyPlanByTopicId: false,
        error: false,
        studyPlanByTopicId: action.data,
      }
    case GET_STUDENT_STUDY_BY_TOPICID_FAILURE:
      return {
        ...state,
        isLoadingStudyPlanByTopicId: false,
        error: true,
      }
    case GET_STUDENT_STUDY_PLAN_START:
      return {
        ...state,
        isStudentStudyPlanLoading: true,
        error: false,
      }
    case GET_STUDENT_STUDY_PLAN_SUCCESS:
      return {
        ...state,
        isStudentStudyPlanLoading: false,
        error: false,
        studentStudyPlan: action.studyplan,
        studentStudyPlanByBatch: action.studyplanByBatch,
      }
    case GET_STUDENT_STUDY_PLAN_FAILURE:
      return {
        ...state,
        isStudentStudyPlanLoading: false,
        error: true,
      }

    case GET_STUDENT_WEEKLY_PLAN_START:
      return {
        ...state,
        isStudentStudyPlanLoading: true,
        error: false,
      }
    case GET_STUDENT_WEEKLY_PLAN_SUCCESS:
      return {
        ...state,
        isStudentStudyPlanLoading: false,
        error: false,
        studentStudyPlan: action.studyplan,
      }
    case GET_STUDENT_WEEKLY_PLAN_FAILURE:
      return {
        ...state,
        isStudentStudyPlanLoading: false,
        error: true,
      }

    case POST_STUDENT_STUDY_PLAN_START:
      return {
        ...state,
        postIsStudentStudyPlanLoading: true,
        error: false,
      }
    case POST_STUDENT_STUDY_PLAN_SUCCESS:
      return {
        ...state,
        postIsStudentStudyPlanLoading: false,
        error: false,
        studentStudyPlan: action.studyplan,
      }
    case POST_STUDENT_STUDY_PLAN_FAILURE:
      return {
        ...state,
        postIsStudentStudyPlanLoading: false,
        error: true,
      }

    case DELETE_STUDY_PLAN_START:
      return {
        ...state,
        isStudentStudyPlanLoading: true,
        error: false,
      }
    case DELETE_STUDY_PLAN_SUCCESS:
      return {
        ...state,
        isStudentStudyPlanLoading: false,
        error: false,
        studentStudyPlan: action.studyplans,
        studentStudyPlanByBatch: action.studyplanByBatch,
      }
    case DELETE_STUDY_PLAN_FAILURE:
      return {
        ...state,
        isStudentStudyPlanLoading: false,
        error: true,
      }
    case GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_START:
      return {
        ...state,
        isLoadingStudyPlanByChapterIdBatchSubId: true,
        error: false,
      }
    case GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_SUCCESS:
      return {
        ...state,
        isLoadingStudyPlanByChapterIdBatchSubId: false,
        error: false,
        studyPlanByChapterIdBatchSubId: action.studyplans,
      }
    case GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_FAILURE:
      return {
        ...state,
        isLoadingStudyPlanByChapterIdBatchSubId: false,
        error: true,
      }
    default:
      return state
  }
}

export const getIsStudentStudyPlanLoading = (state) =>
  state.studyplan.isStudentStudyPlanLoading
export const getStudentStudyPlan = (state) => state.studyplan.studentStudyPlan
export const getstudentStudyPlanByBatch = (state) =>
  state.studyplan.studentStudyPlanByBatch
export const getStudentStudyPlanGroupByDate = (state) =>
  _.groupBy(state.studyplan.studentStudyPlan, 'date')

export const getIsError = (state) => state.studyplan.error
export const getIsLoadingStudyPlanByTopicId = (state) =>
  state.studyplan.isLoadingStudyPlanByTopicId
export const getStudyPlanByTopicId = (state) =>
  state.studyplan.studyPlanByTopicId
export const getStudyPlanByChapterIdBatchSubId = (state) =>
  state.studyplan.studyPlanByChapterIdBatchSubId
export const getIsLoadingStudyPlanByChapterIdBatchSubId = (state) =>
  state.studyplan.isLoadingStudyPlanByChapterIdBatchSubId
