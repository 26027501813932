import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { usePrevious } from '../helpers'

//assets
import Card from '../assets/molecules/card'
import Button from '../assets/molecules/button'

//actions
import { getFaculties } from '../../redux/action/faculty'
import {
  fetchSubjects,
  postFacultyToBatchSubject,
} from '../../redux/action/subject'

//reducer
//account
import { getJwt } from '../../redux/reducer/account'
import {
  getSubjects,
  getIsFacultyAssignToSubjectLoading,
  getIsError,
} from '../../redux/reducer/subject'
import { getAllFaculties } from '../../redux/reducer/faculty'

import Loader from '../assets/molecules/loader'

const Create = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  //selector
  const jwt = useSelector(getJwt)
  const error = useSelector(getIsError)
  const isLoading = useSelector(getIsFacultyAssignToSubjectLoading)
  const isLoadingPrev = usePrevious(isLoading)
  const faculties = useSelector(getAllFaculties)
  const subjects = useSelector(getSubjects)

  let [formData, setFormData] = useState({})
  let [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    dispatch(getFaculties({ jwt }))
    dispatch(fetchSubjects({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (error) {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }, [error])

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isLoading &&
      !isLoading &&
      !error
    ) {
      history.push({
        pathname: '/dashboard',
        state: { status: 'success' },
      })
    }
  }, [error, history, isLoading, isLoadingPrev])

  const onChange = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (formData?.faculty && formData?.subject) {
      const payload = {
        batch_subject_id: formData.subject,
        faculty_ids: [formData.faculty],
      }
      dispatch(postFacultyToBatchSubject({ payload, jwt }))
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  return (
    <div className="tabs__container">
      <section>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Assign Faculty</li>
          </ol>
        </nav>
      </section>

      <section>
        <h4>Assign Faculty</h4>
        <Card columnNo="1" title="Assign Faculty" titleColor={1}>
          <select name="faculty" onChange={onChange}>
            <option>Please select faculty</option>
            {faculties?.map((faculty, index) => {
              return (
                <option
                  key={index}
                  value={faculty._id}
                >{`${faculty.name} - ${faculty.prn}`}</option>
              )
            })}
          </select>
          <select name="subject" onChange={onChange}>
            <option>Please select subject</option>
            {subjects?.map((subject, index) => {
              return (
                <option
                  key={index}
                  value={subject._id}
                >{`${subject.subject_title}`}</option>
              )
            })}
          </select>
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              className="button--small"
              color="blue"
              text="Add"
              click={(e) => onSubmit(e)}
            />
          )}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </Card>
      </section>
    </div>
  )
}

export default Create
