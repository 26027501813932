import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'underscore'
import moment from 'moment'

//assests
import Loader from '../assets/molecules/loader'
import LeftRightLayout from '../assets/layout/leftrightlayout'
import Col75Right from '../assets/layout/leftrightlayout/column75Right'
import Col25Left from '../assets/layout/leftrightlayout/column25Left'
import SubjectDoubtCard from '../assets/molecules/subjectDoubtsCard'
import DoubtsMainCard from '../assets/molecules/doubtsMainCard'
import Accordion from '../assets/layout/accordion'
import InputSend from '../assets/molecules/inputSend'
import DoubtsCard from '../assets/molecules/doubtsCard'
import ReplyCard from '../assets/molecules/replyCard'
import ProfilePic from '../../assets/images/profile.png'
import Popup from '../assets/layout/popupConfirm'
import NoPending from '../../assets/images/no-pending.png'

//action
import {
  fetchStudentDoubts,
  fetchDoubtsResponseByIds,
  deleteStudentDoubtsById,
  postReplyDoubt,
  editStudentDoubtsById,
} from '../../redux/action/doubt'
import { fetchUserSubjects } from '../../redux/action/subject'
import {
  setTopic,
  setSubject,
  setBatchSubjectId,
  setChapter,
} from '../../redux/action/ui'

//reducer
import { getJwt, getUsername } from '../../redux/reducer/account'
import {
  getDoubts,
  getIsDoubtsLoading,
  getIsDoubtsResponseLoading,
  getResponse,
  getIsDoubtDeleting,
  getIsDoubtReplying,
  getIsEditDoubtLoading,
} from '../../redux/reducer/doubt'
import { isSubjectsloading, getSubjects } from '../../redux/reducer/subject'

//helpers
import { usePrevious, subjectIcons } from '../helpers'

const MyDoubts = () => {
  const dispatch = useDispatch()

  //state
  const [selectedSubject, setSelectedSubject] = useState('')
  const [selectedDoubt, setSelectedDoubt] = useState('')
  const [editedDoubtText, setEditedDoubtText] = useState('')
  const [doubtsByTopics, setdoubtsByTopics] = useState({})
  const [responsesByDoubtId, setResponsesByDoubtId] = useState({})
  const [doubtsCount, setDoubtsCount] = useState([])
  const [popClassName, setPopClassName] = useState('hidePop')
  const [deletePopClassName, setDeletePopClassName] = useState('hidePop')
  const [checkedIndex, setCheckedIndex] = useState(0)

  //selector
  const jwt = useSelector(getJwt)
  const doubts = useSelector(getDoubts)
  const isLoadingDoubts = useSelector(getIsDoubtsLoading)
  const isLoadingDoubtsPrev = usePrevious(isLoadingDoubts)
  const isLoadingSubjects = useSelector(isSubjectsloading)
  const isLoadingSubjectsPrev = usePrevious(isLoadingSubjects)
  const subjects = useSelector(getSubjects)
  const isDoubtsResponseLoading = useSelector(getIsDoubtsResponseLoading)
  const isDoubtsResponseLoadingPrev = usePrevious(isDoubtsResponseLoading)
  const isDoubtDeleting = useSelector(getIsDoubtDeleting)
  const isDoubtDeletingPrev = usePrevious(isDoubtDeleting)
  const isDoubtReplying = useSelector(getIsDoubtReplying)
  const isDoubtReplyingPrev = usePrevious(isDoubtReplying)
  const isEditDoubtLoading = useSelector(getIsEditDoubtLoading)
  const isEditDoubtLoadingPrev = usePrevious(isEditDoubtLoading)
  const responses = useSelector(getResponse)
  const username = useSelector(getUsername)

  useEffect(() => {
    dispatch(fetchStudentDoubts({ jwt }))
    dispatch(fetchUserSubjects({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (!isLoadingSubjects && isLoadingSubjectsPrev !== undefined) {
      setSelectedSubject(subjects[0])
    }
  }, [isLoadingSubjects]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoadingDoubts && isLoadingDoubtsPrev !== undefined) {
      doubts &&
        doubts?.length > 0 &&
        dispatch(
          fetchDoubtsResponseByIds({
            jwt,
            doubtIds: doubts.map((doubt) => doubt?._id),
          })
        )
    }
  }, [isLoadingDoubts]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isDoubtsResponseLoading && isDoubtsResponseLoadingPrev !== undefined) {
      setResponsesByDoubtId(_.groupBy(responses, (x) => x?.doubt_id))
    }
  }, [isDoubtsResponseLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isDoubtDeleting && isDoubtDeletingPrev !== undefined) {
      dispatch(fetchStudentDoubts({ jwt }))
      closeDeletePopup()
    }
  }, [isDoubtDeleting]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isDoubtReplying && isDoubtReplyingPrev !== undefined) {
      dispatch(fetchStudentDoubts({ jwt }))
    }
  }, [isDoubtReplying]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEditDoubtLoading && isEditDoubtLoadingPrev !== undefined) {
      dispatch(fetchStudentDoubts({ jwt }))
      closePopup()
    }
  }, [isEditDoubtLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedSubject) {
      const filteredDoubts = doubts
        .filter((doubt) => doubt?.batch_subject_id === selectedSubject?._id)
        .sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
      setDoubtsCount(filteredDoubts.length)
      setdoubtsByTopics(_.groupBy(filteredDoubts, (x) => x?.topic_id))
    }
  }, [doubts, selectedSubject])

  const openPopup = (doubt) => {
    setSelectedDoubt(doubt)
    setEditedDoubtText(doubt?.doubt_text)
    setPopClassName('showPop')
  }

  const closePopup = () => {
    setPopClassName('hidePop')
  }

  const openDeletePopup = (doubt) => {
    setSelectedDoubt(doubt)
    setDeletePopClassName('showPop')
  }

  const closeDeletePopup = () => {
    setDeletePopClassName('hidePop')
  }

  const noDoubts = () => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">
            Your doubts will be collected on this page
          </h2>
          <p>
            Visit your doubts and other’s doubts to get a better clarity of a
            topic
          </p>
        </div>
      </div>
    )
  }

  const onTopicSelect = (doubt) => {
    dispatch(
      setSubject(selectedSubject?.subject_id, selectedSubject?.subject_title)
    )
    dispatch(setBatchSubjectId(doubt?.batch_subject_id))
    dispatch(setChapter(doubt?.chapter_id, doubt?.chapter_name))
    dispatch(setTopic(doubt?.topic_id, doubt?.topic_title))
  }

  const onEdit = () => {
    const payload = {
      doubt_text: editedDoubtText,
    }
    dispatch(
      editStudentDoubtsById({ payload, doubtId: selectedDoubt?._id, jwt })
    )
  }

  const onReply = (text, doubt) => {
    const payload = {
      response_text: text,
      doubt_id: doubt?._id,
      subject_id: doubt?.batch_subject_id,
    }
    dispatch(postReplyDoubt({ jwt, payload }))
  }
  const onChangeSubjectInMob = (event) => {
    const filteredSubjects = subjects.filter(
      (subject) => subject?._id === event.target.value
    )
    setSelectedSubject(filteredSubjects[0])
  }
  const onDelete = () => {
    dispatch(deleteStudentDoubtsById({ jwt, doubtId: selectedDoubt?._id }))
  }

  return (
    <div className="tabs__container">
      <section>
        <h1>Doubts</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Doubts</li>
          </ol>
        </nav>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col25Left className="padding--0">
            <h2 className="subjects--head">Subject list</h2>
            <div className="desktop">
              {isLoadingSubjects ? (
                <Loader />
              ) : subjects?.length ? (
                subjects?.map((subject, index) => {
                  return (
                    <SubjectDoubtCard
                      key={index}
                      className={
                        selectedSubject?._id === subject?._id
                          ? 'subjectDoubtsCard--active'
                          : 'subjectDoubtsCard--noactive'
                      }
                      icon={
                        subjectIcons[subject.subject_title.toLowerCase()]
                          ? subjectIcons[subject.subject_title.toLowerCase()]
                          : subjectIcons['others']
                      }
                      subjectName={subject.subject_title}
                      subjectCode={subject.subject_code}
                      noOfTopics={
                        doubts.filter(
                          (doubt) => doubt?.batch_subject_id === subject?._id
                        ).length
                      }
                      onClick={() => setSelectedSubject(subject)}
                    />
                  )
                })
              ) : (
                <p>No subjects avilable</p>
              )}
            </div>
            <div className="mob padd--LR25 text-center">
              <select
                className="select__subject"
                onChange={(event) => onChangeSubjectInMob(event)}
              >
                {isLoadingSubjects ? (
                  <Loader />
                ) : subjects?.length ? (
                  subjects?.map((subject, index) => {
                    return (
                      <option value={subject?._id}>
                        {subject.subject_title}
                      </option>
                    )
                  })
                ) : (
                  <React.Fragment>
                    <option>No subjects avilable</option>
                  </React.Fragment>
                )}
              </select>
            </div>
          </Col25Left>
          <Col75Right>
            <h2>All doubts ({doubtsCount})</h2>
            <Accordion>
              {doubtsByTopics && Object.keys(doubtsByTopics).length > 0
                ? Object.keys(doubtsByTopics).map((key, index) => {
                    return (
                      <DoubtsMainCard
                        key={index}
                        itemNo={index}
                        title={doubtsByTopics[key][0]?.topic_title}
                        noOfRepies={doubtsByTopics[key]?.length}
                        link="/me/subjects/chapters/topics/detail"
                        onClickLink={() =>
                          onTopicSelect(doubtsByTopics[key][0])
                        }
                        checked={checkedIndex === index}
                        setCheckedIndex={(item) => {
                          setCheckedIndex(item)
                        }}
                      >
                        {doubtsByTopics[key] &&
                          doubtsByTopics[key]?.map((doubt, index) => {
                            return (
                              <div key={index}>
                                <DoubtsCard
                                  picture={ProfilePic}
                                  name="You"
                                  subject=""
                                  chapter={doubt?.topic_title}
                                  time={
                                    moment().diff(doubt?.created_at, 'days') +
                                    ' days ago'
                                  }
                                  content={doubt?.doubt_text}
                                  responses={doubt?.response_count}
                                  editOnClick={() => openPopup(doubt)}
                                  deleteOnClick={() => openDeletePopup(doubt)}
                                />
                                {responsesByDoubtId &&
                                  responsesByDoubtId[doubt?._id] &&
                                  responsesByDoubtId[doubt?._id]?.map(
                                    (res, index) => {
                                      return (
                                        <ReplyCard
                                          key={index}
                                          picture={ProfilePic}
                                          name={
                                            res?.author_name
                                              ? res?.author_name === username
                                                ? 'You'
                                                : res?.author_name
                                              : ''
                                          }
                                          time={
                                            moment().diff(
                                              res?.created_at,
                                              'days'
                                            ) + ' days ago'
                                          }
                                          content={res?.response_text}
                                        />
                                      )
                                    }
                                  )}
                                <InputSend
                                  placeholder="Ask your follow-up"
                                  onClick={(text) => onReply(text, doubt)}
                                />
                              </div>
                            )
                          })}
                      </DoubtsMainCard>
                    )
                  })
                : noDoubts()}
            </Accordion>
            <Popup
              className={popClassName}
              close={() => closePopup()}
              onClick={() => onEdit()}
              buttonText="Save Doubt"
              popupHead="Edit Doubt"
            >
              <textarea
                rows="5"
                value={editedDoubtText}
                onChange={(e) => setEditedDoubtText(e.target.value)}
              ></textarea>
            </Popup>
            <Popup
              className={deletePopClassName}
              close={() => closeDeletePopup()}
              onClick={() => onDelete()}
              buttonText="yes"
              popupHead="Delete Doubt"
            >
              <h2>Are you sure want to delete?</h2>
            </Popup>
          </Col75Right>
        </LeftRightLayout>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default MyDoubts
