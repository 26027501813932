import React from 'react'

const Column = (props) => {
  return (
    <div className={'col col--' + props.no + ' ' + props.className}>
      {props.children}
    </div>
  )
}

export default Column
