import {
  GET_ASSESSMENT_START,
  GET_ASSESSMENT_SUCCESS,
  GET_ASSESSMENT_FAILURE,
  GET_ASSESSMENTS_START,
  GET_ASSESSMENTS_SUCCESS,
  GET_ASSESSMENTS_LONG_POLLING_SUCCESS,
  GET_ASSESSMENTS_FAILURE,
  GET_ASSESSMENT_QUESTIONS_BY_ID_START,
  GET_ASSESSMENT_QUESTIONS_BY_ID_SUCCESS,
  GET_ASSESSMENT_QUESTIONS_BY_ID_FAILURE,
  POST_ASSESSMENT_SUBMISSION_START,
  POST_ASSESSMENT_SUBMISSION_SUCCESS,
  POST_ASSESSMENT_SUBMISSION_FAILURE,
  GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_START,
  GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_SUCCESS,
  GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_FAILURE,
  POST_ASSESSMENT_EVALUATE_START,
  POST_ASSESSMENT_EVALUATE_SUCCESS,
  POST_ASSESSMENT_EVALUATE_FAILURE,
  GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_START,
  GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_SUCCESS,
  GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_FAILURE,
  POST_SELF_ASSESSMENT_SUBMISSION_START,
  POST_SELF_ASSESSMENT_SUBMISSION_SUCCESS,
  POST_SELF_ASSESSMENT_SUBMISSION_FAILURE,
  GET_SELF_ASSESSMENTS_BY_USER_START,
  GET_SELF_ASSESSMENTS_BY_USER_SUCCESS,
  GET_SELF_ASSESSMENTS_BY_USER_FAILURE,
  UNSET_ASSESSMENT_SUBMISSIONS,
  POST_ASSESSMENT_START,
  POST_ASSESSMENT_SUCCESS,
  POST_ASSESSMENT_FAILURE,
  POST_ASSESSMENT_CSV_URL_START,
  POST_ASSESSMENT_CSV_URL_SUCCESS,
  POST_ASSESSMENT_CSV_URL_FAILURE,
  PATCH_ASSESSMENT_START,
  PATCH_ASSESSMENT_SUCCESS,
  PATCH_ASSESSMENT_FAILURE,
  GET_ASSESSMENT_BY_ID_MARKS_START,
  GET_ASSESSMENT_BY_ID_MARKS_SUCCESS,
  GET_ASSESSMENT_BY_ID_MARKS_FAILURE,
  GET_ASSESSMENT_QUESTIONS_START,
  GET_ASSESSMENT_QUESTIONS_SUCCESS,
  GET_ASSESSMENT_QUESTIONS_FAILURE,
  PATCH_ASSESSMENT_STATUS_START,
  PATCH_ASSESSMENT_STATUS_SUCCESS,
  PATCH_ASSESSMENT_STATUS_FAILURE,
} from '../actions'

import { checkStatus } from '../helpers.js'

const getAssessment = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/assessments/${id}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchAssessment = (data) => {
  return async (dispatch) => {
    dispatch({ type: GET_ASSESSMENT_START })
    try {
      const receivedData = await getAssessment(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch({ type: GET_ASSESSMENT_SUCCESS, assessment: response?.data })
    } catch (err) {
      dispatch({ type: GET_ASSESSMENT_FAILURE })
    }
  }
}

const getAssessmentsStart = () => {
  return {
    type: GET_ASSESSMENTS_START,
  }
}

const getAssessmentsSuccess = (data) => {
  const { assessments } = data
  return {
    type: GET_ASSESSMENTS_SUCCESS,
    assessments,
  }
}

const getAssessmentsLongPollingSuccess = (data) => {
  const { assessments } = data
  return {
    type: GET_ASSESSMENTS_LONG_POLLING_SUCCESS,
    assessments,
  }
}

const getAssessmentsFailure = () => {
  return {
    type: GET_ASSESSMENTS_FAILURE,
  }
}

const getAssessments = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/me/assessments`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchAssessments = (data) => {
  return async (dispatch) => {
    dispatch(getAssessmentsStart())
    try {
      const receivedData = await getAssessments(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getAssessmentsSuccess(response?.data))
    } catch (err) {
      dispatch(getAssessmentsFailure())
    }
  }
}

export const fetchAssessmentsLongPolling = (data) => {
  return async (dispatch) => {
    dispatch(getAssessmentsStart())
    try {
      const receivedData = await getAssessments(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getAssessmentsLongPollingSuccess(response?.data))
    } catch (err) {
      dispatch(getAssessmentsFailure())
    }
  }
}

const getAssessmentQuestionsStart = () => {
  return {
    type: GET_ASSESSMENT_QUESTIONS_BY_ID_START,
  }
}

const getAssessmentQuestionsSuccess = (data) => {
  const { assessment_questions } = data
  return {
    type: GET_ASSESSMENT_QUESTIONS_BY_ID_SUCCESS,
    assessmentQuestions: assessment_questions,
  }
}

const getAssessmentQuestionsFailure = () => {
  return {
    type: GET_ASSESSMENT_QUESTIONS_BY_ID_FAILURE,
  }
}

const getAssessmentQuestions = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/assessment-questions/${id}/assessment-id`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchAssessmentQuestions = (data) => {
  return async (dispatch) => {
    dispatch(getAssessmentQuestionsStart())
    try {
      const receivedData = await getAssessmentQuestions(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getAssessmentQuestionsSuccess(response?.data))
    } catch (err) {
      dispatch(getAssessmentQuestionsFailure())
    }
  }
}

const assessmentSubmissionStart = () => {
  return {
    type: POST_ASSESSMENT_SUBMISSION_START,
  }
}

const assessmentSubmissionSuccess = () => {
  return {
    type: POST_ASSESSMENT_SUBMISSION_SUCCESS,
  }
}

const assessmentSubmissionFailure = () => {
  return {
    type: POST_ASSESSMENT_SUBMISSION_FAILURE,
  }
}

const assessmentSubmission = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/assessment-submissions`
  console.log(jwt, payload)
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postAssessmentSubmission = (data) => {
  return async (dispatch) => {
    dispatch(assessmentSubmissionStart())
    try {
      const receivedData = await assessmentSubmission(data)
      console.log(receivedData, 'receivedData')
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(assessmentSubmissionSuccess())
    } catch (err) {
      console.log(err, 'err')
      dispatch(assessmentSubmissionFailure())
    }
  }
}

const assessmentSubmissionByAIdAndStuIdStart = () => {
  return {
    type: GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_START,
  }
}

const assessmentSubmissionByAIdAndStuIdSuccess = (data) => {
  const { assessment_submissions } = data
  return {
    type: GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_SUCCESS,
    assessment_submissions,
  }
}

const assessmentSubmissionByAIdAndStuIdFailure = () => {
  return {
    type: GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_FAILURE,
  }
}

const assessmentSubmissionByAIdAndStuId = ({
  jwt,
  assessmentId,
  studentId,
}) => {
  const url = `${process.env.REACT_APP_SERVER}/api/assessment-submissions/assessment/${assessmentId}/student/${studentId}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

const getAssessmentQuestionsByTopicIdStart = () => {
  return {
    type: GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_START,
  }
}
const getAssessmentQuestionsByTopicIdSuccess = (data) => {
  const { questions } = data
  return {
    type: GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_SUCCESS,
    assessmentQuestions: questions,
  }
}

const getAssessmentQuestionsByTopicIdFailure = () => {
  return {
    type: GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_FAILURE,
  }
}

const getAssessmentQuestionsByTopicId = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/self-assessments/topic-id/${id}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchAssessmentSubmissionByAIdAndStuId = (data) => {
  return async (dispatch) => {
    dispatch(assessmentSubmissionByAIdAndStuIdStart())
    try {
      const receivedData = await assessmentSubmissionByAIdAndStuId(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(assessmentSubmissionByAIdAndStuIdSuccess(response?.data))
    } catch (err) {
      dispatch(assessmentSubmissionByAIdAndStuIdFailure())
    }
  }
}
export const fetchAssessmentQuestionsByTopicId = (data) => {
  return async (dispatch) => {
    dispatch(getAssessmentQuestionsByTopicIdStart())
    try {
      const receivedData = await getAssessmentQuestionsByTopicId(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getAssessmentQuestionsByTopicIdSuccess(response?.data))
    } catch (err) {
      dispatch(getAssessmentQuestionsByTopicIdFailure())
    }
  }
}

const assessmentEvaluateStart = () => {
  return {
    type: POST_ASSESSMENT_EVALUATE_START,
  }
}

const assessmentEvaluateSuccess = () => {
  return {
    type: POST_ASSESSMENT_EVALUATE_SUCCESS,
  }
}

const assessmentEvaluateFailure = () => {
  return {
    type: POST_ASSESSMENT_EVALUATE_FAILURE,
  }
}

const assessmentEvaluate = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/assessment-submissions/assess`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

const selfAssessmentSubmissionStart = () => {
  return {
    type: POST_SELF_ASSESSMENT_SUBMISSION_START,
  }
}

const selfAssessmentSubmissionSuccess = (data) => {
  const { self_assessment } = data
  return {
    type: POST_SELF_ASSESSMENT_SUBMISSION_SUCCESS,
    self_assessment,
  }
}

const selfAssessmentSubmissionFailure = () => {
  return {
    type: POST_SELF_ASSESSMENT_SUBMISSION_FAILURE,
  }
}

const assessmentSubmissionForTopic = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/self-assessments`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postAssessmentEvaluate = (data) => {
  return async (dispatch) => {
    dispatch(assessmentEvaluateStart())
    try {
      const receivedData = await assessmentEvaluate(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(assessmentEvaluateSuccess())
    } catch (err) {
      dispatch(assessmentEvaluateFailure())
    }
  }
}

export const postAssessmentSubmissionForTopic = (data) => {
  return async (dispatch) => {
    dispatch(selfAssessmentSubmissionStart())
    try {
      const receivedData = await assessmentSubmissionForTopic(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(selfAssessmentSubmissionSuccess(response?.data))
    } catch (err) {
      dispatch(selfAssessmentSubmissionFailure())
    }
  }
}

const getSelfAssessmentsByUserStart = () => {
  return {
    type: GET_SELF_ASSESSMENTS_BY_USER_START,
  }
}
const getSelfAssessmentsByUserSuccess = (data) => {
  const { self_assessments } = data
  return {
    type: GET_SELF_ASSESSMENTS_BY_USER_SUCCESS,
    selfAssessments: self_assessments,
  }
}

const getSelfAssessmentsByUserFailure = () => {
  return {
    type: GET_SELF_ASSESSMENTS_BY_USER_FAILURE,
  }
}

const getSelfAssessmentsByUser = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/me/self-assessments`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchSelfAssessmentsByUser = (data) => {
  return async (dispatch) => {
    dispatch(getSelfAssessmentsByUserStart())
    try {
      const receivedData = await getSelfAssessmentsByUser(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getSelfAssessmentsByUserSuccess(response?.data))
    } catch (err) {
      dispatch(getSelfAssessmentsByUserFailure())
    }
  }
}

export const unsetAssessmentSubmissions = () => {
  return async (dispatch) => {
    dispatch({ type: UNSET_ASSESSMENT_SUBMISSIONS })
  }
}

const getAssessmentList = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/assessments`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchAssessmentList = (data) => {
  return async (dispatch) => {
    dispatch(getAssessmentsStart())
    try {
      const receivedData = await getAssessmentList(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getAssessmentsSuccess(response.data))
    } catch (err) {
      dispatch(getAssessmentsFailure())
    }
  }
}

const postAssessmentStart = () => {
  return {
    type: POST_ASSESSMENT_START,
  }
}

const postAssessmentSuccess = (data) => {
  return {
    type: POST_ASSESSMENT_SUCCESS,
    data,
  }
}

const postAssessmentFailure = () => {
  return {
    type: POST_ASSESSMENT_FAILURE,
  }
}

const postAssessmentApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/assessments`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postAssessment = ({ jwt, payload }) => {
  return async (dispatch) => {
    dispatch(postAssessmentStart())
    try {
      const receivedData = await postAssessmentApiCall({ jwt, payload })
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(fetchAssessmentList({ jwt }))
      dispatch(postAssessmentSuccess(response.data))
    } catch (err) {
      dispatch(postAssessmentFailure())
    }
  }
}

const postAssessmentCsvUrlStart = () => {
  return {
    type: POST_ASSESSMENT_CSV_URL_START,
  }
}

const postAssessmentCsvUrlSuccess = (data) => {
  return {
    type: POST_ASSESSMENT_CSV_URL_SUCCESS,
    data,
  }
}

const postAssessmentCsvUrlFailure = () => {
  return {
    type: POST_ASSESSMENT_CSV_URL_FAILURE,
  }
}

const postAssessmentCsvUrlApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/assessment-questions/csv`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postAssessmentCsvUrl = ({ jwt, payload }) => {
  return async (dispatch) => {
    dispatch(postAssessmentCsvUrlStart())
    try {
      const receivedData = await postAssessmentCsvUrlApiCall({ jwt, payload })
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(postAssessmentCsvUrlSuccess(response.data))
    } catch (err) {
      dispatch(postAssessmentCsvUrlFailure())
    }
  }
}

const editAssessmentStart = () => {
  return {
    type: PATCH_ASSESSMENT_START,
  }
}

const editAssessmentSuccess = () => {
  return {
    type: PATCH_ASSESSMENT_SUCCESS,
  }
}

const editAssessmentFailure = () => {
  return {
    type: PATCH_ASSESSMENT_FAILURE,
  }
}

const editAssessmentApiCall = ({ payload, jwt, assessmentId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/assessments/${assessmentId}`
  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const editAssessment = ({ payload, assessmentId, jwt }) => {
  return async (dispatch) => {
    dispatch(editAssessmentStart())
    try {
      const receivedData = await editAssessmentApiCall({
        payload,
        assessmentId,
        jwt,
      })
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(fetchAssessmentList({ jwt }))
      dispatch(editAssessmentSuccess())
    } catch (err) {
      dispatch(editAssessmentFailure())
    }
  }
}

const getAssessmentByIdMarksStart = () => {
  return {
    type: GET_ASSESSMENT_BY_ID_MARKS_START,
  }
}

const getAssessmentByIdMarksSuccess = (data) => {
  const { assessment_marks } = data
  return {
    type: GET_ASSESSMENT_BY_ID_MARKS_SUCCESS,
    assessment_marks,
  }
}

const getAssessmentByIdMarksFilure = () => {
  return {
    type: GET_ASSESSMENT_BY_ID_MARKS_FAILURE,
  }
}

const getAssessmentByIdMarks = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/assessment-submissions/marks/${id}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchAssessmentByIdMarks = (data) => {
  return async (dispatch) => {
    dispatch(getAssessmentByIdMarksStart())
    try {
      const receivedData = await getAssessmentByIdMarks(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getAssessmentByIdMarksSuccess(response?.data))
    } catch (err) {
      dispatch(getAssessmentByIdMarksFilure())
    }
  }
}

const getAllAssessmentQuestionsStart = () => {
  return {
    type: GET_ASSESSMENT_QUESTIONS_START,
  }
}

const getAllAssessmentQuestionsSuccess = (data) => {
  const { assessment_questions } = data
  return {
    type: GET_ASSESSMENT_QUESTIONS_SUCCESS,
    assessmentQuestions: assessment_questions,
  }
}

const getAllAssessmentQuestionsFailure = () => {
  return {
    type: GET_ASSESSMENT_QUESTIONS_FAILURE,
  }
}

const getAllAssessmentQuestions = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/assessment-questions`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchAllAssessmentQuestions = (data) => {
  return async (dispatch) => {
    dispatch(getAllAssessmentQuestionsStart())
    try {
      const receivedData = await getAllAssessmentQuestions(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getAllAssessmentQuestionsSuccess(response?.data))
    } catch (err) {
      dispatch(getAllAssessmentQuestionsFailure())
    }
  }
}

const editAssessmentStatusStart = () => {
  return {
    type: PATCH_ASSESSMENT_STATUS_START,
  }
}

const editAssessmentStatusSuccess = () => {
  return {
    type: PATCH_ASSESSMENT_STATUS_SUCCESS,
  }
}

const editAssessmentStatusFailure = () => {
  return {
    type: PATCH_ASSESSMENT_STATUS_FAILURE,
  }
}

const editAssessmentStatus = ({ jwt, assessmentId, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/assessments/${assessmentId}/status `
  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const startEditAssessmentStatus = (data) => {
  return async (dispatch) => {
    dispatch(editAssessmentStatusStart())
    try {
      const receivedData = await editAssessmentStatus(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      // dispatch(fetchAssessmentList({ jwt }))
      dispatch(editAssessmentStatusSuccess())
    } catch (err) {
      dispatch(editAssessmentStatusFailure())
    }
  }
}
