import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import _ from 'underscore'
import moment from 'moment'
import { Tab, Tabs, Carousel } from 'react-bootstrap'

//helpers
import { subjectPics } from '../../helpers'

//assests
import Loader from '../../assets/molecules/loader'
import HomeCard from '../../assets/molecules/homeCard'
import Daily from './daily'
//import Weekly from './weekly'
import PopupCustom from '../../assets/layout/popupCustom'
import NoPending from '../../../assets/images/no-pending.png'
import Homework from '../../../assets/images/homework.png'
import ProfilePic from '../../../assets/images/profile.png'
import SelfAssessmentStart from '../../../assets/images/selfAssessmentStart.png'
// import Assessment from '../../../assets/images/assessment.png'

//action
import {
  fetchSubjectsData,
  fetchBatchSubjects,
} from '../../../redux/action/subject'
import {
  fetchStudentStudyPlan,
  // fetchStudentStudyWeeklyPlan,
} from '../../../redux/action/studyplan'
import {
  fetchLastViewd,
  fetchPendingItems,
} from '../../../redux/action/impressions'
import { fetchTopicsByids } from '../../../redux/action/topic'
import {
  setTopic,
  setSubject,
  setChapter,
  setBatch,
  setBatchSubjectId,
  setAssessment,
} from '../../../redux/action/ui'
import {
  fetchAssessments,
  fetchAssessmentQuestions,
} from '../../../redux/action/assessment'
import { fetchOnlineSessions } from '../../../redux/action/onlineSession'

//reducers
import { getJwt } from '../../../redux/reducer/account'
import {
  getSubjectsDataById,
  getBatchSubjects,
} from '../../../redux/reducer/subject'
import { getTopicsById } from '../../../redux/reducer/topic'
import {
  getIsStudentStudyPlanLoading,
  getStudentStudyPlan,
} from '../../../redux/reducer/studyplan'
import {
  getlastVisitedTopics,
  getisLoadingLastVisted,
  getPendingTopics,
  getisLoadingPendingTopics,
} from '../../../redux/reducer/impressions'
import {
  getAssessments,
  getAssessmentQuestions,
} from '../../../redux/reducer/assessment'
import { getOnlineSessions } from '../../../redux/reducer/onlineSession'
import { fetchAnnouncements } from '../../../redux/action/announcements'

const StudentDashboard = () => {
  const dispatch = useDispatch()

  let history = useHistory()

  let jwt = useSelector(getJwt)
  let isStudyPlanLoading = useSelector(getIsStudentStudyPlanLoading)
  let studyplans = useSelector(getStudentStudyPlan)
  let subjectsDataById = useSelector(getSubjectsDataById)
  let lastVisitedTopics = useSelector(getlastVisitedTopics)
  let isLoadingLastVisted = useSelector(getisLoadingLastVisted)
  let pendingTopics = useSelector(getPendingTopics)
  let isLoadingPendingTopics = useSelector(getisLoadingPendingTopics)
  let batchSubjects = useSelector(getBatchSubjects)
  let topics = useSelector(getTopicsById)
  let assessments = useSelector(getAssessments)
  let onlineSessions = useSelector(getOnlineSessions)
  let assessmentQuestions = useSelector(getAssessmentQuestions)

  // local
  const [date, setDate] = useState(new Date())
  const [toggleType, setToggleType] = useState('newTopics')
  const [localAssessments, setLocalAssessments] = useState([])
  const [localSessions, setLocalSessions] = useState([])
  const [examPopClassName, setExamPopClassName] = useState('hidePop')
  const [selectedAssessment, setSelectedAssessment] = useState('')

  useEffect(() => {
    const date = moment().format('YYYY-MM-DD')
    dispatch(fetchStudentStudyPlan({ jwt, date }))
    dispatch(fetchBatchSubjects({ jwt }))
    dispatch(fetchLastViewd({ jwt }))
    dispatch(fetchPendingItems({ jwt }))
    dispatch(fetchAssessments({ jwt }))
    dispatch(fetchOnlineSessions({ jwt }))
    dispatch(fetchAnnouncements({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (pendingTopics.length && lastVisitedTopics.length && studyplans.length) {
      let pendingTopicIds = pendingTopics?.map((topic) => topic.topic_id)
      let lastVisitedTopicIds = lastVisitedTopics
        ? lastVisitedTopics?.map((topic) => topic.topic_id)
        : null
      let studyplansTopicIds = studyplans
        ? studyplans?.map((topic) => topic.topic_id)
        : null
      let topicIds = _.uniq(
        pendingTopicIds?.concat(lastVisitedTopicIds.concat(studyplansTopicIds))
      )
      dispatch(fetchTopicsByids({ jwt, topicIds }))
    }
    if (assessments?.length) {
      setLocalAssessments(
        assessments?.filter(
          (assessment) =>
            assessment.has_taken !== true &&
            new Date() <= new Date(assessment?.due_date)
        )
      )
    }
    if (onlineSessions?.length) {
      setLocalSessions(
        onlineSessions?.filter(
          (session) => new Date() < new Date(session?.end_time)
        )
      )
    }
  }, [
    pendingTopics,
    lastVisitedTopics,
    assessments,
    onlineSessions,
    dispatch,
    studyplans,
    jwt,
  ])

  useEffect(() => {
    const subject_idsAll = studyplans && studyplans.map((sp) => sp.subject_id)
    const subject_idsAllTopic =
      lastVisitedTopics && lastVisitedTopics.map((to) => to.subject_id)
    const subject_idsAllBoth = subject_idsAll.concat(subject_idsAllTopic)
    const subject_ids = _.uniq(subject_idsAllBoth)
    dispatch(fetchSubjectsData({ jwt, subject_ids }))
  }, [dispatch, jwt, studyplans, lastVisitedTopics])

  useEffect(() => {
    studyplans.length &&
      setBatch(studyplans[0]?.batch_id, studyplans[0]?.batch_title)
  }, [studyplans])

  const onChangeDate = (date) => {
    setDate(date)
    let formatedDate = ''
    formatedDate = date.toISOString().slice(0, 10)
    dispatch(fetchStudentStudyPlan({ jwt, date: formatedDate }))
  }

  const getBatchSubjectById = (id) => {
    const filtered = batchSubjects?.filter(
      (batchSubject) => batchSubject._id === id
    )
    return filtered.length ? filtered[0] : {}
  }

  // const onToggleType = (type) => {
  //   setToggleType(type)

  //   let formatedDate = ''

  //   if (type === 'daily') {
  //     formatedDate = date.toISOString().slice(0, 10)
  //     dispatch(fetchStudentStudyPlan({ jwt, date: formatedDate }))
  //   } else {
  //     formatedDate = moment(date).format('w')
  //     dispatch(fetchStudentStudyWeeklyPlan({ jwt, week: formatedDate }))
  //   }
  // }

  const onclickTopics = (topic) => {
    dispatch(setTopic(topic.topic_id, topic.topic_title))
    dispatch(setSubject(topic.subject_id, topic.subject_title))
    dispatch(setChapter(topic.chapter_id, topic.chapter_title))
    dispatch(setBatchSubjectId(topic.batch_subject_id))

    history.push('/me/subjects/chapters/topics/detail')
  }

  const onClickTakeAssessment = (id, title, batchSubjectId, assessment) => {
    dispatch(setAssessment(id, title, assessment))
    dispatch(setBatchSubjectId(batchSubjectId))
    history.push(`/assessments/questions`)
  }

  const openExamPopup = () => {
    setExamPopClassName('showPop')
  }
  const closeExamPopup = () => {
    setExamPopClassName('hidePop')
  }
  const onClickedAttend = (e, session) => {
    e.preventDefault()
    // if(session?.subject_id){
    //   session['batch_subject_id'] = session['subject_id']
    //   delete session['subject_id']
    // }
    history.push({
      pathname: '/me/session/detail',
      state: { session },
    })
  }

  const displayTopics = (data) => {
    return data
      ?.filter((item) => item?.visibility)
      ?.map((topic, index) => {
        const { subject_id } = topic
        const img =
          subjectsDataById && subjectsDataById[subject_id]?.subject_logo
        const batchSubject = getBatchSubjectById(topic?.batch_subject_id)
        const topicData = topics?.[topic?.topic_id]

        return (
          <HomeCard
            key={index}
            img={subjectPics[img] ? subjectPics[img] : subjectPics['che']}
            onClick={() => onclickTopics(topic)}
            heading={topic.topic_title}
            description={topicData?.wysiwyg_state}
            subject={topic.subject_title}
            chapter={topic.chapter_title}
            timing=" "
            button="Study Topic"
            teacher={batchSubject?.faculties
              ?.map((faculty) => faculty.name)
              .join(', ')}
          />
        )
      })
  }

  const noTopicsAvailable = (type) => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">No new topics for today.</h2>
          <p>Complete your pending topics or go to subjects. </p>
          <div className="noPending__button--Wrapper">
            <button
              className="button--blue"
              onClick={() => setToggleType('pendingTopics')}
            >
              GO TO PENDING TOPICS
            </button>
            <Link to="/me/subjects">
              <button>GO TO SUBJECTS</button>
            </Link>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    )
  }
  const noPendingTopicsAvailable = (type) => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">No pending topics for today.</h2>
          <p>View your last visited topic or go to subjects. </p>
          <div className="noPending__button--Wrapper">
            <button
              className="button--blue"
              onClick={() => setToggleType('lastVisitedTopics')}
            >
              GO TO LAST VISITED TOPICS
            </button>
            <Link to="/me/subjects">
              <button>GO TO SUBJECTS</button>
            </Link>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    )
  }
  const noLastVistedTopicsAvailable = (type) => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">No last visited topics for today.</h2>
          <p>Go back to new topics or visit subjects or go to subjects. </p>
          <div className="noPending__button--Wrapper">
            <button
              className="button--blue"
              onClick={() => setToggleType('newTopics')}
            >
              GO TO NEW TOPICS
            </button>
            <Link to="/me/subjects">
              <button>GO TO SUBJECTS</button>
            </Link>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    )
  }

  const assessmentSelected = (assessment) => {
    dispatch(fetchAssessmentQuestions({ jwt, id: assessment?._id }))
    setSelectedAssessment(assessment)
    openExamPopup()
  }

  return (
    <div className="body__container">
      <section>
        <h1>Home</h1>
        {localSessions?.length || localAssessments?.length ? (
          <Carousel>
            {localSessions?.length
              ? localSessions
                  ?.filter(
                    (session) =>
                      moment(date).format('DD-MM-YYYY') ===
                      moment(session?.start_time).format('DD-MM-YYYY')
                  )
                  ?.map((session, index) => {
                    return (
                      <Carousel.Item key={index}>
                        <div className="carousel__img">
                          <img src={Homework} alt="cwx" />
                          <div className="clear"></div>
                        </div>
                        <div className="carousel__text">
                          <h2>
                            {session.title} |{' '}
                            {moment(session?.start_time).format(
                              'DD-MM-YYYY | hh:mm A'
                            )}{' '}
                            | Session
                          </h2>
                          <button
                            onClick={(e) =>
                              new Date() > new Date(session?.end_time) ||
                              moment().isBefore(
                                moment(session?.start_time).subtract(
                                  5,
                                  'minutes'
                                )
                              )
                                ? null
                                : onClickedAttend(e, session)
                            }
                            className="button--white"
                          >
                            TAKE SESSION
                          </button>
                          <div className="clear"></div>
                        </div>
                      </Carousel.Item>
                    )
                  })
              : null}
            {localAssessments?.length
              ? localAssessments
                  ?.sort(
                    (a, b) => new Date(a?.due_date) - new Date(b?.due_date)
                  )
                  ?.map((assessment, index) => {
                    return (
                      <Carousel.Item key={index}>
                        <div className="carousel__img">
                          <img src={Homework} alt="cwx" />
                          <div className="clear"></div>
                        </div>
                        <div className="carousel__text">
                          <h2>{assessment.title} Assessment Pending.</h2>
                          <p>
                            {assessment.description} |{' '}
                            {moment(assessment?.start_date).format(
                              'DD-MM-YYYY | hh:mm A'
                            )}
                          </p>
                          <button
                            onClick={() =>
                              moment().isSameOrAfter(
                                moment(assessment?.start_date)
                              )
                                ? assessmentSelected(assessment)
                                : null
                            }
                            className="button--white"
                          >
                            START THE TEST
                          </button>
                          <div className="clear"></div>
                        </div>
                        <PopupCustom
                          className={examPopClassName}
                          close={() => closeExamPopup()}
                          popupHead="Start Examination"
                        >
                          <div className="self__assessment--popup">
                            <img
                              className="self__assessmentIcon"
                              src={SelfAssessmentStart}
                              alt="START SELF ASSESMENT"
                            />
                            <h2>{selectedAssessment?.title}</h2>
                            <img
                              className="assessment__teachersIcon"
                              src={ProfilePic}
                              alt="Teacher"
                            />
                            <p className="assessment__teacherName">
                              Teacher: {selectedAssessment.author_name}
                            </p>
                            <hr></hr>
                            <p className="assessment__details">
                              Number of questions:{' '}
                              <span>{assessmentQuestions?.length}</span>{' '}
                              {/* <span className="pipe">|</span> Ideal Time to
                            Complete: <span>60 minutes (hardcoded)</span> */}
                            </p>
                            <button
                              onClick={(e) =>
                                onClickTakeAssessment(
                                  assessment._id,
                                  assessment.title,
                                  assessment.batch_subject_id,
                                  assessment
                                )
                              }
                              className="button--blue"
                            >
                              START EXAMINATION NOW
                            </button>
                            <br />
                            <button
                              onClick={() => closeExamPopup()}
                              className="button--transparent margin--0"
                            >
                              LATER
                            </button>
                          </div>
                        </PopupCustom>
                      </Carousel.Item>
                    )
                  })
              : null}
          </Carousel>
        ) : null}
      </section>
      <section className="tabs__wrapper">
        <DatePicker
          selected={date}
          onChange={(date) => onChangeDate(date)}
          placeholderText="Select Date"
          dateFormat="MMMM d, yyyy"
        />
        <Tabs activeKey={toggleType} onSelect={(k) => setToggleType(k)}>
          <Tab
            eventKey="newTopics"
            title={`New Topics (${
              studyplans?.length +
              localSessions?.filter(
                (session) =>
                  moment(date).format('DD-MM-YYYY') ===
                  moment(session?.start_time).format('DD-MM-YYYY')
              )?.length
            })`}
          >
            <div className="section--imgCard">
              {isStudyPlanLoading ? (
                <Loader />
              ) : studyplans?.length || localSessions.length ? (
                <Daily sessions={localSessions} date={date} />
              ) : (
                noTopicsAvailable()
              )}
              <div className="clear"></div>
            </div>
          </Tab>
          <Tab
            eventKey="pendingTopics"
            title={`Pending Topics (${
              pendingTopics
                ? pendingTopics?.filter((item) => item?.visibility)?.length
                : 0
            })`}
          >
            {isLoadingPendingTopics ? (
              <Loader />
            ) : pendingTopics &&
              pendingTopics?.filter((item) => item?.visibility)?.length ? (
              displayTopics(pendingTopics)
            ) : (
              noPendingTopicsAvailable()
            )}
          </Tab>
          <Tab
            eventKey="lastVisitedTopics"
            title={`Last Visited Topics (${
              lastVisitedTopics
                ? lastVisitedTopics?.filter((item) => item?.visibility)?.length
                : 0
            })`}
          >
            {isLoadingLastVisted ? (
              <Loader />
            ) : lastVisitedTopics &&
              lastVisitedTopics?.filter((item) => item?.visibility)?.length ? (
              displayTopics(lastVisitedTopics)
            ) : (
              noLastVistedTopicsAvailable()
            )}
          </Tab>
        </Tabs>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default StudentDashboard
