import React from 'react'

const select = (props) => {
  return (
    <div className={`select ${props.className}`}>
      {props?.value ? (
        <select
          name={props.name}
          onChange={props.onChange}
          value={props?.value}
        >
          {props.children}
        </select>
      ) : (
        <select name={props.name} onChange={props.onChange}>
          {props.children}
        </select>
      )}
    </div>
  )
}

export default select
