import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { usePrevious } from '../../helpers'

//assets
import Button from '../../assets/molecules/button'
import { AlertSuccess } from '../../assets/molecules/alerts'
import Loader from '../../assets/molecules/loader'

//actions
import { getFacultiesById, editFaculty } from '../../../redux/action/faculty'
import { getTeacherBatchSubjects as fetchTeacherBatchSubjects } from '../../../redux/action/batches'
import { removeFacultyToBatchSubject } from '../../../redux/action/subject'

//reducer
import { getJwt, getAcademicYear } from '../../../redux/reducer/account'
import { getTeacherBatchSubjects } from '../../../redux/reducer/batches'
import {
  getIsFacultyAssignToSubjectLoading,
  getIsError as getIsErrorBatchSubjects,
} from '../../../redux/reducer/subject'
import {
  getIsFacultiesLoading,
  getIsError,
  getAllFaculties,
} from '../../../redux/reducer/faculty'

const EditTeacher = () => {
  const dispatch = useDispatch()

  //state
  const [name, setName] = useState()
  const [gender, setGender] = useState()
  const [email, setEmail] = useState()
  const [mobile, setMobile] = useState()
  const [prn, setPrn] = useState()
  const [designation, setDesignation] = useState()
  const [showSuccess, setShowSuccess] = useState(false)
  const [press, setPress] = useState(false)

  //get param from url
  const { facultyId } = useParams()

  //selector
  const jwt = useSelector((state) => getJwt(state))
  const isFacultyLoading = useSelector((state) => getIsFacultiesLoading(state))
  const isFacultyLoadingPrev = usePrevious(isFacultyLoading)
  const error = useSelector((state) => getIsError(state))
  const faculty = useSelector((state) => getAllFaculties(state))
  const academicYear = useSelector((state) => getAcademicYear(state))
  const teacherBatchSubjects = useSelector((state) =>
    getTeacherBatchSubjects(state)
  )
  const isFacultyAssignToSubjectLoading = useSelector((state) =>
    getIsFacultyAssignToSubjectLoading(state)
  )
  const isFacultyAssignToSubjectLoadingPrev = usePrevious(
    isFacultyAssignToSubjectLoading
  )
  const isErrorBatchSubjects = useSelector((state) =>
    getIsErrorBatchSubjects(state)
  )

  useEffect(() => {
    dispatch(getFacultiesById({ jwt, id: facultyId }))
    dispatch(
      fetchTeacherBatchSubjects({
        jwt,
        academicYear: academicYear,
        facultyId: facultyId,
      })
    )
  }, [dispatch, jwt, facultyId, academicYear])

  useEffect(() => {
    if (!isFacultyLoading && isFacultyLoadingPrev !== undefined && !error) {
      setName(faculty?.name)
      setGender(faculty?.gender)
      setEmail(faculty?.email?.join(','))
      setMobile(faculty?.mobile?.join(','))
      setPrn(faculty?.prn)
      setDesignation(faculty?.designation)
    }
  }, [error, isFacultyLoading, isFacultyLoadingPrev, faculty])

  useEffect(() => {
    if (
      !isFacultyAssignToSubjectLoading &&
      isFacultyAssignToSubjectLoadingPrev !== undefined &&
      !isErrorBatchSubjects
    ) {
      dispatch(
        fetchTeacherBatchSubjects({
          jwt,
          academicYear: academicYear,
          facultyId: facultyId,
        })
      )
    }
  }, [isFacultyAssignToSubjectLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFacultyLoadingPrev !== undefined && !isFacultyLoading && !error) {
      setShowSuccess(true)
      setPress(false)
    }
  }, [error, isFacultyLoading, isFacultyLoadingPrev])

  const onSubmit = () => {
    const data = {
      name,
      gender,
      mobile: mobile.split(',')[0],
      prn,
      avatar: 'string',
      designation,
      email: email ? email.split(',')[0] : ' ',
    }
    dispatch(editFaculty({ jwt, facultyId: facultyId, payload: data }))
    setPress(true)
  }

  const onRemoveFaculty = (id) => {
    const data = {
      batch_subject_id: id,
      faculty_ids: [facultyId],
    }
    dispatch(removeFacultyToBatchSubject({ jwt, payload: data }))
  }

  return isFacultyLoading ? (
    <Loader />
  ) : !faculty ? (
    <div>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">Faculty List</li>
        </ol>
      </nav>
      <h1>This person does not exist</h1>
      <Link to="/faculty/list">
        <button>Back</button>
      </Link>
    </div>
  ) : (
    <div className="tabs__container">
      <section>
        <h4>Edit Faculty</h4>

        <h5>Faculty Name:</h5>
        <input
          name="name"
          value={name}
          type="text"
          placeholder="Faculty Name"
          onChange={(e) => setName(e.target.value)}
        />
        <h5>Gender:</h5>
        <input
          name="gender"
          value={gender}
          type="text"
          placeholder="Gender"
          onChange={(e) => setGender(e.target.value)}
        />
        <h5>Email(commas seperated):</h5>
        <input
          name="email"
          value={email}
          type="text"
          placeholder="Email(commas seperated)"
          onChange={(e) => setEmail(e.target.value)}
        />
        <h5>Mobile(commas seperated):</h5>
        <input
          name="mobile"
          value={mobile}
          type="text"
          placeholder="Mobile(commas seperated)"
          onChange={(e) => setMobile(e.target.value)}
        />
        <h5>PRN:</h5>
        <input
          name="prn"
          value={prn}
          type="text"
          placeholder="PRN"
          onChange={(e) => setPrn(e.target.value)}
        />
        <h5>Designation:</h5>
        <input
          name="designation"
          value={designation}
          type="text"
          placeholder="Designation"
          onChange={(e) => setDesignation(e.target.value)}
        />
        <Button
          className="button--small"
          color="blue"
          text="Save"
          click={(e) => onSubmit(e)}
        />
      </section>
      {teacherBatchSubjects && teacherBatchSubjects?.length ? (
        <section style={{ paddingTop: 20 }}>
          <h4>Faculty Batch Subjects</h4>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Batch</th>
                  <th>Subject</th>
                  <th></th>
                </tr>
              </thead>
              {isFacultyAssignToSubjectLoading ? (
                <Loader />
              ) : (
                <tbody>
                  {teacherBatchSubjects?.map((batch, index) => {
                    return (
                      <tr key={index}>
                        <td>{batch?.batch_title}</td>
                        <td>{batch?.subject_title}</td>
                        <td>
                          {' '}
                          <button
                            className={'button--red'}
                            onClick={() => onRemoveFaculty(batch?._id)}
                          >
                            REMOVE
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              )}
            </table>
            <div className="clear"></div>
          </div>
        </section>
      ) : null}
      {showSuccess && press ? (
        <AlertSuccess>
          You have successfully edited Faculty details.
        </AlertSuccess>
      ) : null}
    </div>
  )
}

export default EditTeacher
