import React from 'react'
import RightArrow from '../../../assets/images/rightArrow.png'

const Batches = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={'batchesCard row ' + props?.className}
    >
      <div className="col-xl-4  col-lg-4  col-md-4 col-sm-4 batchesCard__img">
        <img src={props.icon} alt="CWX" />
        <div className="clear"></div>
      </div>
      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
        <h5>{props.batchName}</h5>
        <p className="batchesCard__noOfStudents">
          No of students: {props.noOfStudents}
        </p>
        <div className="clear"></div>
      </div>
      {props?.profile ? null : (
        <img className="batchesCard__arrow" src={RightArrow} alt="cwx" />
      )}
    </div>
  )
}

export default Batches
