import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ProfilePic from '../../assets/images/old/profile-pic.png'
import _ from 'underscore'

//assests
import Loader from '../assets/molecules/loader'
import Card from '../assets/molecules/card'
import Button from '../assets/molecules/button'
import Col from '../assets/molecules/column'

//action
import {
  fetchDoubtsBySubjectId,
  fetchDoubtsResponse,
  postReplyDoubt,
} from '../../redux/action/doubt'
import { setDoubtId } from '../../redux/action/ui'

//reducers
import { getJwt } from '../../redux/reducer/account'
import {
  getDoubts,
  getIsDoubtsLoading,
  getResponse,
  getIsDoubtsResponseLoading,
} from '../../redux/reducer/doubt'
import { getSubjectId, getDoubtId } from '../../redux/reducer/ui'

const FacultyDoubts = () => {
  const dispatch = useDispatch()

  let jwt = useSelector(getJwt)
  let subjectId = useSelector(getSubjectId)
  let doubtId = useSelector(getDoubtId)
  let doubts = useSelector(getDoubts)
  let isLoadingDoubts = useSelector(getIsDoubtsLoading)
  let doubt_responses = useSelector(getResponse)
  let isLoadingResponse = useSelector(getIsDoubtsResponseLoading)

  useEffect(() => {
    dispatch(fetchDoubtsBySubjectId({ jwt, subjectId }))
  }, [dispatch, jwt, subjectId, doubtId])

  const groupByChapter = _.groupBy(doubts, 'topic_id')

  const [isReplyClicked, setIsReplyClicked] = useState({})
  const [isRepliedClicked, setIsRepliedClicked] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [answer, setAnswer] = useState('')

  const onClickReplyDoubts = (doubtId) => {
    setIsReplyClicked({ [doubtId]: true })
  }

  const onChange = (e) => {
    setAnswer(e.target.value)
    errorMessage && setErrorMessage('')
  }
  const onClickReplyAnswer = (doubt_id, subject_id) => {
    setIsReplyClicked({ [doubt_id]: false })
    const response_text = answer
    if (response_text) {
      const payload = {
        response_text,
        doubt_id,
        subject_id,
      }
      dispatch(postReplyDoubt({ payload, jwt }))
    } else {
      setErrorMessage('Please answer your doubt')
    }
  }
  const onClickReplied = (doubtId) => {
    dispatch(setDoubtId(doubtId))
    setIsRepliedClicked({ [doubtId]: !isRepliedClicked[doubtId] })
    dispatch(fetchDoubtsResponse({ jwt, doubtId }))
  }

  return (
    <div className="tabs__container">
      <section>
        <Col no="1">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item active">Doubts</li>
            </ol>
          </nav>
        </Col>
        <div className="clear"></div>
      </section>
      <section>
        {isLoadingDoubts ? (
          <Loader />
        ) : (
          groupByChapter &&
          Object.keys(groupByChapter).map((key, index) => {
            return (
              <div key={index}>
                <Card
                  className="no--effect margin--top50"
                  key={index}
                  columnNo="1"
                  title={
                    groupByChapter[key] && groupByChapter[key][0]?.topic_title
                  }
                  titleColor={1}
                >
                  <div className="comment__wrapper">
                    {groupByChapter[key] &&
                      groupByChapter[key]?.reverse().map((doubt, index) => {
                        return (
                          <div key={index} className="comment__wrap">
                            <div className="comment__profile">
                              <img src={ProfilePic} alt={doubt?.author_name} />
                              <p>{doubt?.author_name}</p>
                            </div>
                            <div className="comment__question">
                              <h5>{doubt?.doubt_text}</h5>
                              <Button
                                className="button--link reply--btn"
                                color="blue"
                                text="reply"
                                click={() => onClickReplyDoubts(doubt._id)}
                              ></Button>

                              <Button
                                className="button--link reply--btn"
                                color="blue"
                                text={`(${doubt.response_count}) replied`}
                                click={() => onClickReplied(doubt._id)}
                              ></Button>
                              {isRepliedClicked[doubt._id] && (
                                <React.Fragment>
                                  {isLoadingResponse ? (
                                    <Loader />
                                  ) : (
                                    doubt_responses?.map((response, index) => {
                                      return (
                                        <div className="reply__wrapper">
                                          <div className="comment__profile">
                                            <img
                                              src={ProfilePic}
                                              alt={response?.author_name}
                                            />
                                            <p>{response?.author_name}</p>
                                          </div>
                                          <p>{response.response_text}</p>
                                          <div className="clear"></div>
                                        </div>
                                      )
                                    })
                                  )}
                                </React.Fragment>
                              )}
                            </div>
                            {isReplyClicked[doubt._id] && (
                              <div className="comment__answer">
                                <form>
                                  <textarea
                                    onChange={(e) => onChange(e)}
                                    rows="10"
                                    name={doubt._id}
                                    value={answer}
                                    placeholder="Please type your answer"
                                  ></textarea>
                                  <Button
                                    className="button--tiny"
                                    color="blue"
                                    text="Submit"
                                    click={() =>
                                      onClickReplyAnswer(
                                        doubt._id,
                                        doubt.batch_subject_id
                                      )
                                    }
                                  ></Button>
                                </form>
                              </div>
                            )}
                            <div className="clear"></div>
                          </div>
                        )
                      })}
                    <div className="clear"></div>
                  </div>
                </Card>
              </div>
            )
          })
        )}
        <div className="clear"></div>
      </section>

      <div className="clear"></div>
    </div>
  )
}

export default FacultyDoubts
