import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'

//assets
import Col from '../../assets/molecules/column'
import Card from '../../assets/molecules/card'
import Loader from '../../assets/molecules/loader'

import Button from '../../assets/molecules/button'
import { AlertSuccess } from '../../assets/molecules/alerts'
import Popup from '../../assets/layout/popup'

// import Select from '../assets/molecules/select'

//action
//subject(chapter actions also implemented in subject)
import { fetchSubjects } from '../../../redux/action/subject'
import { fetchChaptersBySubjectId } from '../../../redux/action/chapter'

//ui
import { setChapter } from '../../../redux/action/ui'

//reducer
//subject(chapters also implemented in subject)
import { getSubjects } from '../../../redux/reducer/subject'
import {
  getChapters,
  getIsChaptersLoading,
} from '../../../redux/reducer/chapter'

//account
import { getJwt } from '../../../redux/reducer/account'

import {
  getSubjectId,
  getBatch,
  getSubjectTitle,
} from '../../../redux/reducer/ui'

import { getBatches } from '../../../redux/reducer/batches'

const Chapter = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  //selectors
  //account
  const jwt = useSelector((state) => getJwt(state))
  //ui
  const subjectId = useSelector((state) => getSubjectId(state))
  const subjectTitle = useSelector(getSubjectTitle)
  //subject(chapters)
  const subjectsAll = useSelector((state) => getSubjects(state))
  const chapters = useSelector((state) => getChapters(state))
  const isLoading = useSelector((state) => getIsChaptersLoading(state))
  const batches = useSelector((state) => getBatches(state))

  const [popClassName, setPopClassName] = useState('hidePop')
  const [deleteChapterId, setDeleteChapterId] = useState('')

  let location = useLocation()

  //ui for batch selected
  let batch = useSelector((state) => getBatch(state))

  useEffect(() => {
    dispatch(fetchChaptersBySubjectId({ jwt, subjectId }))
    if (subjectsAll.length === 0) fetchSubjects({ jwt })
  }, [dispatch, jwt, subjectId, subjectsAll, batches, batch])

  const onChapterSelect = (chapterId, chapterTitle) => {
    dispatch(setChapter(chapterId, chapterTitle))
    history.push('/me/faculty/subjects/chapters/topics')
  }
  const editChapter = (e, chapterId, chapterTitle) => {
    e.preventDefault()
    dispatch(setChapter(chapterId, chapterTitle))
    history.push(`/me/faculty/subjects/chapters/edit`)
  }

  const deletePopup = (chapterId) => {
    setPopClassName('showPop')
    setDeleteChapterId(chapterId)
  }
  const closePopup = () => {
    setPopClassName('hidePop')
  }
  const deleteChapter = (e, chapterId) => {
    e.preventDefault()
    dispatch(deleteChapter({ jwt, chapterId }))
    setPopClassName('hidePop')
  }

  return (
    <div>
      {location?.state?.status === 'success' ? (
        <AlertSuccess>You Have successfully Add a chapter</AlertSuccess>
      ) : null}

      <div className="tabs__container">
        <section>
          <Col no="1">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/dashboard">{subjectTitle}</Link>
                </li>
                <li className="breadcrumb-item active">Chapters</li>
              </ol>
            </nav>
          </Col>
        </section>
        <section>
          <Col no="1">
            <Link
              to={{
                pathname: `/me/faculty/subjects/chapters/create`,
              }}
            >
              <Button
                className="button--add"
                color="blue"
                text="+ Add Chapter"
              />
            </Link>
          </Col>
          <div className="clear"></div>
        </section>
        {isLoading ? (
          <Loader />
        ) : subjectId ? (
          <section>
            <h4>List of Chapters for Subject {subjectTitle}</h4>
            {chapters?.length ? (
              chapters?.map((chapter, index) => {
                return (
                  <Card
                    className="no--effect"
                    columnNo="2"
                    title={chapter.title}
                    titleColor={index}
                    key={index}
                  >
                    <p className="limit__text--3">{chapter.description}</p>
                    <p>No of topics: {chapter.topic_count}</p>
                    <button
                      onClick={(e) =>
                        editChapter(e, chapter._id, chapter.title)
                      }
                      className={
                        'button button--transparent button__edit color--' +
                        index
                      }
                    >
                      Edit
                    </button>
                    <button
                      onClick={(e) => deletePopup(chapter._id)}
                      className={
                        'button button--transparent button__delete color--' +
                        index
                      }
                    >
                      Delete
                    </button>
                    <button
                      onClick={() =>
                        onChapterSelect(chapter._id, chapter.title)
                      }
                      className={
                        'button button__view background--color' + index
                      }
                    >
                      View
                    </button>
                  </Card>
                )
              })
            ) : (
              <div>No Chapters</div>
            )}
            <div className="clear"></div>
          </section>
        ) : (
          <div> Please Select a Subject </div>
        )}
      </div>
      <Popup
        className={popClassName}
        close={() => closePopup()}
        onClick={(e) => deleteChapter(e, deleteChapterId)}
        buttonText="yes"
      >
        <h2>Are you sure want to delete</h2>
      </Popup>
    </div>
  )
}

export default Chapter
