import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { downloadReport } from '../../../helpers'

//action
import { fetchStudentByTopicIdReport } from '../../../../redux/action/reports'

//reducer
import { getJwt } from '../../../../redux/reducer/account'
import {
  getIsStudentByTopicIdLoading,
  getStudentByTopicId,
} from '../../../../redux/reducer/reports'
import { getBatches } from '../../../../redux/reducer/batches'

//assets
import Loader from '../../../assets/molecules/loader'

const OnlineSessionViewReport = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const report = location.state.report

  const [reportData, setReportData] = useState({})
  const jwt = useSelector(getJwt)
  const isStudentByTopicIdLoading = useSelector(getIsStudentByTopicIdLoading)
  const studentByTopicId = useSelector(getStudentByTopicId)
  let batches = useSelector(getBatches)

  const filteredBatches = batches.filter((bat) => bat._id === report?.BatchId)
  const currentBatch = filteredBatches.length > 0 ? filteredBatches[0] : {}

  useEffect(() => {
    const topicid = report.id
    dispatch(fetchStudentByTopicIdReport({ jwt, topicid }))
  }, [dispatch, jwt, report.id])

  useEffect(() => {
    if (!isStudentByTopicIdLoading) {
      const header = {
        sl_No: 'Sl No',
        student: 'Student Name',
        status: 'Attended',
      }

      const data = currentBatch?.students?.map((student, index) => {
        return {
          sl_No: index + 1,
          student: student.name,
          status:
            studentByTopicId[student.id] && studentByTopicId[student.id].length
              ? 'Attended'
              : 'Not Attended',
        }
      })
      setReportData({ header, data })
    }
  }, [isStudentByTopicIdLoading, studentByTopicId]) // eslint-disable-line react-hooks/exhaustive-deps

  const downloadPdf = () => {
    const data = {
      reportData: reportData?.data,
      topicName: report.Title,
      batchName: report.Batch,
      subjectName: report.Subject,
      sessionDate: report.Start_Time,
    }
    downloadReport({
      nameOfTemplate: 'cwxLiveClassSessionReport',
      dataForTemplate: data,
      downloadedFileName: `onlineSessionReport`,
    })
  }

  return isStudentByTopicIdLoading ? (
    <Loader />
  ) : (
    <div className="tabs__container">
      <section>
        <nav className="padding--leftRight">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Online Session Report</li>
          </ol>
        </nav>
        <div className="clear"></div>
      </section>
      <section>
        <button className="button--blue" onClick={() => downloadPdf()}>
          Pdf
        </button>
      </section>
      <br />
      <h3>{report.Title}</h3>
      <h3>{report.Batch}</h3>
      <h3>{report.Subject}</h3>
      <h3>Online Session Date : {report.Start_Time}</h3>

      {reportData.header && reportData.data ? (
        <section style={{ paddingTop: 20 }}>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {Object.keys(reportData.header).map((key, index) => {
                    return <th key={index}>{reportData.header[key]}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                {reportData.data.map((report, index) => {
                  return (
                    <tr key={index}>
                      {Object.keys(reportData.header).map((key, index) => {
                        return <td key={index}>{report[key]}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="clear"></div>
          </div>
        </section>
      ) : null}
      <div className="clear"></div>
    </div>
  )
}

export default OnlineSessionViewReport
