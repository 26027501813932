import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { usePrevious } from '../../helpers'

//assets
import Card from '../../assets/molecules/card'
import Button from '../../assets/molecules/button'

//actions
//subject
import { postCreateChapter } from '../../../redux/action/chapter'

//reducer
//account
import { getJwt } from '../../../redux/reducer/account'
import {
  getIsChaptersLoading,
  getIsError,
} from '../../../redux/reducer/chapter'
import { getSubjectId, getSubjectTitle } from '../../../redux/reducer/ui'

import Loader from '../../assets/molecules/loader'

const CreateChapter = () => {
  const dispatch = useDispatch()
  //get param from url
  const history = useHistory()

  //selector
  const jwt = useSelector((state) => getJwt(state))
  const error = useSelector((state) => getIsError(state))
  const isLoading = useSelector((state) => getIsChaptersLoading(state))
  const isLoadingPrev = usePrevious(isLoading)
  const subjectId = useSelector(getSubjectId)
  const subjectTitle = useSelector(getSubjectTitle)

  let [formData, setFormData] = useState({})
  let [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (error) {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }, [error])

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isLoading &&
      !isLoading &&
      !error
    ) {
      history.push({
        pathname: '/me/faculty/subjects/chapters',
        state: { status: 'success' },
      })
    }
  }, [error, history, isLoading, isLoadingPrev])

  const onChange = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (formData?.title && formData?.description) {
      dispatch(postCreateChapter({ formData, subjectId, jwt }))
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  return (
    <div className="tabs__container">
      <section>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/dashboard">{subjectTitle}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/me/faculty/subjects/chapters">Chapters</Link>
            </li>
            <li className="breadcrumb-item active">Add Chapter</li>
          </ol>
        </nav>
      </section>

      <section>
        <h4>Add Chapter</h4>
        <Card columnNo="1" title="Create Chapter" titleColor={1}>
          <input
            name="title"
            value={formData.title}
            type="text"
            placeholder="Title"
            onChange={onChange}
          />
          <textarea
            rows="10"
            name="description"
            value={formData.description}
            placeholder="Description"
            onChange={onChange}
          ></textarea>
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              className="button--small"
              color="blue"
              text="Add"
              click={(e) => onSubmit(e)}
            />
          )}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </Card>
      </section>
    </div>
  )
}

export default CreateChapter
