import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import _ from 'underscore'
import moment from 'moment'

//helpers
import { subjectPics } from '../../helpers'

//assests
import HomeCard from '../../assets/molecules/homeCard'

//action

import {
  setTopic,
  setSubject,
  setChapter,
  setBatchSubjectId,
} from '../../../redux/action/ui'
import { fetchTopicsByids } from '../../../redux/action/topic'

//reducers
import { getJwt } from '../../../redux/reducer/account'
import {
  getSubjectsDataById,
  getBatchSubjects,
} from '../../../redux/reducer/subject'
import { getStudentStudyPlan } from '../../../redux/reducer/studyplan'
import { getTopicsById } from '../../../redux/reducer/topic'

const StudyPlanDaily = (props) => {
  const dispatch = useDispatch()
  let history = useHistory()

  const { sessions } = props

  let jwt = useSelector(getJwt)
  let studyplans = useSelector(getStudentStudyPlan)
  let subjectsDataById = useSelector(getSubjectsDataById)
  let topics = useSelector(getTopicsById)
  let batchSubjects = useSelector(getBatchSubjects)
  const onclick = (studyplan) => {
    dispatch(setTopic(studyplan.topic_id, studyplan.topic_title))
    dispatch(setSubject(studyplan.subject_id, studyplan.subject_title))
    dispatch(setChapter(studyplan.chapter_id, studyplan.chapter_title))
    dispatch(setBatchSubjectId(studyplan.batch_subject_id))
    history.push('/me/subjects/chapters/topics/detail')
  }

  const onclickSession = (session) => {
    history.push({
      pathname: '/me/session/detail',
      state: { session },
    })
  }

  useEffect(() => {
    let studyplanIds = studyplans.map((topic) => topic.topic_id)
    let topicIds = _.uniq(studyplanIds)
    dispatch(fetchTopicsByids({ jwt, topicIds }))
  }, [studyplans, dispatch, jwt])

  const getBatchSubjectById = (id) => {
    const filtered = batchSubjects?.filter(
      (batchSubject) => batchSubject._id === id
    )
    return filtered.length ? filtered[0] : {}
  }

  const isCurrentTimeBetweenStartAndEnd = (start, end) => {
    return (
      moment(new Date()).isSameOrAfter(start) &&
      moment(new Date()).isBefore(end)
    )
  }

  return (
    <React.Fragment>
      {studyplans?.map((studyplan, index) => {
        const { subject_id } = studyplan
        const img =
          subjectsDataById && subjectsDataById[subject_id]?.subject_logo

        const batchSubject = getBatchSubjectById(studyplan?.batch_subject_id)
        const topicData = topics?.[studyplan?.topic_id]

        return (
          <HomeCard
            key={index}
            img={subjectPics[img] ? subjectPics[img] : subjectPics['che']}
            onClick={() =>
              props?.date
                ? moment(props?.date).isSameOrBefore(moment())
                  ? onclick(studyplan)
                  : null
                : onclick(studyplan)
            }
            heading={studyplan.topic_title}
            description={topicData?.wysiwyg_state}
            subject={studyplan.subject_title}
            chapter={studyplan.chapter_title}
            timing={
              studyplan?.start_time && studyplan?.end_time
                ? moment(studyplan?.start_time).format('hh:mm A') +
                  ' - ' +
                  moment(studyplan?.end_time).format('hh:mm A')
                : ''
            }
            button="Study Topic"
            teacher={batchSubject?.faculties
              ?.map((faculty) => faculty.name)
              .join(', ')}
          />
        )
      })}
      {sessions
        ?.filter(
          (session) =>
            moment(props?.date).format('DD-MM-YYYY') ===
            moment(session?.start_time).format('DD-MM-YYYY')
        )
        ?.map((session, index) => {
          const subjectId = session?.batch_subject_id
            ? session?.batch_subject_id
            : session?.subject_id
          const batchSubject = getBatchSubjectById(subjectId)

          const img =
            subjectsDataById &&
            subjectsDataById[batchSubject.subject_id]?.subject_logo

          return (
            <HomeCard
              key={index}
              img={subjectPics[img] ? subjectPics[img] : subjectPics['che']}
              onClick={() => onclickSession(session)}
              heading={session.title}
              description=""
              subject={batchSubject.subject_title}
              // chapter={studyplan.chapter_title}
              timing={
                session?.start_time && session?.end_time
                  ? moment(session?.start_time).format('hh:mm A') +
                    ' - ' +
                    moment(session?.end_time).format('hh:mm A')
                  : ''
              }
              button="join session"
              buttonClass={'button--blue'}
              disable={
                new Date() > new Date(session?.end_time) ||
                moment().isBefore(
                  moment(session?.start_time).subtract(5, 'minutes')
                )
              }
              teacher={session.author_name}
            />
          )
        })}
    </React.Fragment>
  )
}

export default StudyPlanDaily
