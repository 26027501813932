import {
  GET_DOUBTS_BY_TOPIC_ID_START,
  GET_DOUBTS_BY_TOPIC_ID_SUCCESS,
  GET_DOUBTS_BY_TOPIC_ID_FAILURE,
  GET_DOUBTS_BY_SUBJECT_ID_START,
  GET_DOUBTS_BY_SUBJECT_ID_SUCCESS,
  GET_DOUBTS_BY_SUBJECT_ID_FAILURE,
  ASK_DOUBT_START,
  ASK_DOUBT_SUCCESS,
  ASK_DOUBT_FAILURE,
  GET_STUDENT_DOUBTS_START,
  GET_STUDENT_DOUBTS_SUCCESS,
  GET_STUDENT_DOUBTS_FAILURE,
  EDIT_STUDENT_DOUBTS_START,
  EDIT_STUDENT_DOUBTS_SUCCESS,
  EDIT_STUDENT_DOUBTS_FAILURE,
  DELETE_STUDENT_DOUBTS_START,
  DELETE_STUDENT_DOUBTS_SUCCESS,
  DELETE_STUDENT_DOUBTS_FAILURE,
  REPLY_DOUBT_START,
  REPLY_DOUBT_SUCCESS,
  REPLY_DOUBT_FAILURE,
  GET_DOUBT_RESPONSE_START,
  GET_DOUBT_RESPONSE_SUCCESS,
  GET_DOUBT_RESPONSE_FAILURE,
  GET_DOUBTS_START,
  GET_DOUBTS_SUCCESS,
  GET_DOUBTS_FAILURE,
} from '../actions'

import { checkStatus } from '../helpers.js'

const doubtsByTopicIdStart = () => {
  return {
    type: GET_DOUBTS_BY_TOPIC_ID_START,
  }
}

const doubtsByTopicIdSuccess = (data) => {
  const { doubts } = data
  return {
    type: GET_DOUBTS_BY_TOPIC_ID_SUCCESS,
    doubts,
  }
}

const doubtsByTopicIdFailure = () => {
  return {
    type: GET_DOUBTS_BY_TOPIC_ID_FAILURE,
  }
}

const doubtsByTopicId = ({ jwt, topicId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/doubts/topic/${topicId}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchDoubtsByTopicId = (data) => {
  return async (dispatch) => {
    dispatch(doubtsByTopicIdStart())
    try {
      const receivedData = await doubtsByTopicId(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(doubtsByTopicIdSuccess(response.data))
    } catch (err) {
      dispatch(doubtsByTopicIdFailure())
    }
  }
}

const askDoubtStart = () => {
  return {
    type: ASK_DOUBT_START,
  }
}

const askDoubtSuccess = (doubts) => {
  return {
    type: ASK_DOUBT_SUCCESS,
    doubts,
  }
}

const askDoubtFailure = () => {
  return {
    type: ASK_DOUBT_FAILURE,
  }
}

const askDoubt = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/doubts`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const fetchAskDoubt = (data) => {
  return async (dispatch, getState) => {
    dispatch(askDoubtStart())
    try {
      const receivedData = await askDoubt(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      const { doubts } = getState().doubt
      doubts.push(response?.data?.doubt)
      dispatch(askDoubtSuccess(doubts))
    } catch (err) {
      dispatch(askDoubtFailure())
    }
  }
}

const getStudentDoubtsStart = () => {
  return {
    type: GET_STUDENT_DOUBTS_START,
  }
}

const getStudentDoubtsSuccess = (data) => {
  return {
    type: GET_STUDENT_DOUBTS_SUCCESS,
    doubts: data?.doubts,
  }
}

const getStudentDoubtsFailure = () => {
  return {
    type: GET_STUDENT_DOUBTS_FAILURE,
  }
}

const studentDoubts = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/me/doubts`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchStudentDoubts = (data) => {
  return async (dispatch) => {
    dispatch(getStudentDoubtsStart())
    try {
      const receivedData = await studentDoubts(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getStudentDoubtsSuccess(response?.data))
    } catch (err) {
      dispatch(getStudentDoubtsFailure())
    }
  }
}

const doubtsBySubjectIdStart = () => {
  return {
    type: GET_DOUBTS_BY_SUBJECT_ID_START,
  }
}

const doubtsBySubjectIdSuccess = (data) => {
  const { doubts } = data
  return {
    type: GET_DOUBTS_BY_SUBJECT_ID_SUCCESS,
    doubts,
  }
}

const doubtsBySubjectIdFailure = () => {
  return {
    type: GET_DOUBTS_BY_SUBJECT_ID_FAILURE,
  }
}

const doubtsBySubjectId = ({ jwt, subjectId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/doubts/batch-subject/${subjectId}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchDoubtsBySubjectId = (data) => {
  return async (dispatch) => {
    dispatch(doubtsBySubjectIdStart())
    try {
      const receivedData = await doubtsBySubjectId(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(doubtsBySubjectIdSuccess(response.data))
    } catch (err) {
      dispatch(doubtsBySubjectIdFailure())
    }
  }
}

const replyDoubtStart = () => {
  return {
    type: REPLY_DOUBT_START,
  }
}

const replyDoubtSuccess = () => {
  return {
    type: REPLY_DOUBT_SUCCESS,
  }
}

const replyDoubtFailure = () => {
  return {
    type: REPLY_DOUBT_FAILURE,
  }
}

const replyDoubt = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/doubts/respond`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postReplyDoubt = ({ payload, jwt, topicId }) => {
  return async (dispatch) => {
    dispatch(replyDoubtStart())
    try {
      const receivedData = await replyDoubt({ jwt, payload })
      checkStatus(receivedData)

      dispatch(fetchDoubtsBySubjectId({ jwt, subjectId: payload?.subject_id }))
      topicId &&
        dispatch(fetchDoubtsResponse({ jwt, doubtId: payload.doubt_id }))
      topicId
        ? dispatch(fetchDoubtsByTopicId({ jwt, topicId }))
        : dispatch(fetchDoubts({ jwt }))
      dispatch(replyDoubtSuccess())
    } catch (err) {
      console.log(err)

      dispatch(replyDoubtFailure())
    }
  }
}

const doubtsResponseStart = () => {
  return {
    type: GET_DOUBT_RESPONSE_START,
  }
}

const doubtsResponseSuccess = (data) => {
  const { response } = data
  return {
    type: GET_DOUBT_RESPONSE_SUCCESS,
    response,
  }
}

const doubtsAllResponseSuccess = (data) => {
  const { responses } = data
  return {
    type: GET_DOUBT_RESPONSE_SUCCESS,
    response: responses,
  }
}

const doubtsResponseFailure = () => {
  return {
    type: GET_DOUBT_RESPONSE_FAILURE,
  }
}

const doubtsResponseById = ({ jwt, doubtId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/doubts/${doubtId}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchDoubtsResponse = (data) => {
  return async (dispatch) => {
    dispatch(doubtsResponseStart())
    try {
      const receivedData = await doubtsResponseById(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(doubtsResponseSuccess(response.data))
    } catch (err) {
      dispatch(doubtsResponseFailure())
    }
  }
}

//get doubts respose by doubt ids
const doubtsResponseByIds = ({ jwt, doubtIds }) => {
  let urlData = ''
  if (doubtIds && doubtIds.length > 0) {
    doubtIds.forEach((id, index) => {
      urlData = urlData.concat(index ? '&ids=' + id : 'ids=' + id)
    })
  }
  const url = `${process.env.REACT_APP_SERVER}/api/doubts/ids?${urlData}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchDoubtsResponseByIds = (data) => {
  return async (dispatch) => {
    dispatch(doubtsResponseStart())
    try {
      const receivedData = await doubtsResponseByIds(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(doubtsAllResponseSuccess(response.data))
    } catch (err) {
      dispatch(doubtsResponseFailure())
    }
  }
}

const editStudentDoubtsStart = () => {
  return {
    type: EDIT_STUDENT_DOUBTS_START,
  }
}

const editStudentDoubtsSuccess = (data) => {
  // const { doubts } = data;
  return {
    type: EDIT_STUDENT_DOUBTS_SUCCESS,
    // doubts,
  }
}

const editStudentDoubtsFailure = () => {
  return {
    type: EDIT_STUDENT_DOUBTS_FAILURE,
  }
}

const editStudentDoubts = ({ payload, jwt, doubtId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/doubts/${doubtId}`
  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const editStudentDoubtsById = (data) => {
  return async (dispatch) => {
    dispatch(editStudentDoubtsStart())
    try {
      // const payload = {
      //   doubt_text: doubtText,
      // }
      const receivedData = await editStudentDoubts(data)
      checkStatus(receivedData)
      // const response = await validatedData.json();
      dispatch(fetchDoubts(data))
      dispatch(editStudentDoubtsSuccess())
    } catch (err) {
      dispatch(editStudentDoubtsFailure())
    }
  }
}

const deleteStudentDoubtStart = () => {
  return {
    type: DELETE_STUDENT_DOUBTS_START,
  }
}

const deleteStudentDoubtSuccess = () => {
  return {
    type: DELETE_STUDENT_DOUBTS_SUCCESS,
  }
}

const deleteStudentDoubtFailure = () => {
  return {
    type: DELETE_STUDENT_DOUBTS_FAILURE,
  }
}

const deleteStudentDoubt = ({ jwt, doubtId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/doubts/${doubtId}`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const deleteStudentDoubtsById = (data) => {
  return async (dispatch) => {
    dispatch(deleteStudentDoubtStart())
    try {
      const receivedData = await deleteStudentDoubt(data)
      checkStatus(receivedData)
      // const response = await validatedData.json();
      const { isFromTopicDetail, topicId, jwt } = data
      isFromTopicDetail
        ? dispatch(fetchDoubtsByTopicId({ jwt, topicId }))
        : dispatch(fetchDoubts({ jwt }))
      dispatch(deleteStudentDoubtSuccess())
    } catch (err) {
      dispatch(deleteStudentDoubtFailure())
    }
  }
}

const getDoubtsStart = () => {
  return {
    type: GET_DOUBTS_START,
  }
}

const getDoubtsSuccess = (data) => {
  const { doubts } = data
  return {
    type: GET_DOUBTS_SUCCESS,
    doubts,
  }
}

const getDoubtsFailure = () => {
  return {
    type: GET_DOUBTS_FAILURE,
  }
}

const getDoubts = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/doubts`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchDoubts = ({ jwt }) => {
  return async (dispatch) => {
    dispatch(getDoubtsStart())
    try {
      const receivedData = await getDoubts({ jwt })
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getDoubtsSuccess(response.data))
    } catch (err) {
      dispatch(getDoubtsFailure())
    }
  }
}

const getDoubtsResponsesByIds = ({ jwt, doubtIds }) => {
  let urlData = ''
  if (doubtIds && doubtIds.length > 0) {
    doubtIds.forEach((id, index) => {
      urlData = urlData.concat(index ? '&ids=' + id : 'ids=' + id)
    })
  }
  const url = `${process.env.REACT_APP_SERVER}/api/doubts/ids?${urlData}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchDoubtsResponsesByIds = (data) => {
  return async (dispatch) => {
    dispatch(doubtsResponseStart())
    try {
      const receivedData = await getDoubtsResponsesByIds(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(doubtsResponseSuccess({ response: response.data.responses }))
    } catch (err) {
      dispatch(doubtsResponseFailure())
    }
  }
}
