import React, { useState } from 'react'
import moment from 'moment'

import Icon from '../../../assets/images/class.png'

const AssessmentCard = (props) => {
  let { details } = props
  const [optionState, setoptionState] = useState(true)

  const onToggleOptions = () => {
    setoptionState(!optionState)
  }

  return (
    <div className="assessmentCard__withOption">
      <div
        className="assessmentCard__dotswrap"
        onClick={(event) => {
          event.stopPropagation()
          onToggleOptions()
        }}
      >
        <div className="dotsContainer">
          <div className="position-relative">
            <ul className="doubtsCard__dotsContainer">
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <ul
              className={
                'options__container ' + (optionState ? '' : 'collapse')
              }
            >
              <li
                className="edit"
                onClick={(event) => {
                  event.stopPropagation()
                  onToggleOptions()
                  props.editOnClick()
                }}
              >
                Edit
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-3  col-lg-3  col-md-3 col-sm-3 assessmentCardWithOption__img">
          <img src={Icon} alt="icon" />
        </div>
        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 assessmentCardWithOption__body">
          <h5>{props.title}</h5>
          <h5>Batch : {details?.batch_title}</h5>
          <p className="subjectCard__subjectCode">
            Assessment Id: {props.assessmentId} <br />
            Subject code : {details?.subject_code} <br />
            Subject Name : {details?.subject_title} <br />
            Starts on : {moment(props.startDate).format(
              'DD-MM-YYYY hh:mm A'
            )}{' '}
            <br />
            Ends on : {moment(props.dueDate).format('DD-MM-YYYY hh:mm A')}
            <br />
            Question Added : {props?.questionStatus}
          </p>
          {props.show ? (
            <div>
              <button onClick={props.onAddClick} className={props.buttonClass}>
                {props.button}
              </button>
              <div className="clear"></div>
            </div>
          ) : null}
          <div>
            <button onClick={props.onViewClick} className={props.buttonClass}>
              {props.viewButton}
            </button>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
        <div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>

      <div className="clear"></div>
    </div>
  )
}

export default AssessmentCard
