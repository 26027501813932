import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'underscore'

//assets
import LeftRightLayout from '../assets/layout/leftrightlayout'
import Col100 from '../assets/layout/leftrightlayout/column100'
import NoSelfAssessment from '../../assets/images/noSelfAssessment.png'

//actions
import { fetchBatchSubjectDataById } from '../../redux/action/subject'

//reducer
import { getJwt, getUsername, getEntityId } from '../../redux/reducer/account'
import {
  getBatchSubjectId,
  getAssessmentTitle,
  getAssessmentId,
} from '../../redux/reducer/ui'
import {
  fetchAssessmentSubmissionByAIdAndStuId,
  fetchAssessment,
} from '../../redux/action/assessment'
import {
  getAssessmentSubmissions,
  getIsAssessmentSubmissionLoading,
} from '../../redux/reducer/assessment'

import Loader from '../assets/molecules/loader'

const AssessmentResult = () => {
  const dispatch = useDispatch()

  const jwt = useSelector(getJwt)
  const batchSubjectId = useSelector(getBatchSubjectId)
  const assessmentTitle = useSelector(getAssessmentTitle)
  const assessmentId = useSelector(getAssessmentId)
  const isAssessmentSubmissionLoading = useSelector(
    getIsAssessmentSubmissionLoading
  )
  const assessmentSubmissions = useSelector(getAssessmentSubmissions)
  const entityId = useSelector(getEntityId)
  const username = useSelector(getUsername)

  const [selectedStudent, setSelectedStudent] = useState({
    id: entityId,
    name: username,
  })
  const [formData, setFormData] = useState({})
  const [errorMsg, setErrorMsg] = useState('')
  const [marks, setMarks] = useState({})

  useEffect(() => {
    const id = batchSubjectId
    dispatch(fetchBatchSubjectDataById({ jwt, id }))
    dispatch(fetchAssessment({ jwt, id: assessmentId }))
  }, [assessmentId, batchSubjectId, dispatch, jwt])

  useEffect(() => {
    let obj = {}
    assessmentSubmissions.forEach((submission) => {
      obj = {
        ...obj,
        [submission.question_id]: submission.scored_point || 0,
      }
    })
    setMarks(obj)
  }, [assessmentSubmissions])

  useEffect(() => {
    setFormData({})
    setErrorMsg('')
    dispatch(
      fetchAssessmentSubmissionByAIdAndStuId({
        jwt,
        assessmentId,
        studentId: selectedStudent.id,
      })
    )
  }, [selectedStudent]) // eslint-disable-line react-hooks/exhaustive-deps

  const onchangeHandler = (e, submittedId) => {
    const name = e.target.name.split('-')[0]
    let value = e.target.value
    value = value === 'true' || value === true || false
    setFormData({
      ...formData,
      [submittedId]: { ...formData[submittedId], [name]: value },
    })
    errorMsg && setErrorMsg('')
  }

  const onChangeMark = (e, question_id, weight) => {
    if (
      e.target.value === '' ||
      parseFloat(e.target.value) <= parseFloat(weight)
    ) {
      setMarks({ ...marks, [question_id]: e.target.value })
    }
  }

  const [popClassName, setPopClassName] = useState('hidePop')
  const openPopup = (e) => {
    e.preventDefault()
    setPopClassName('showPop')
  }
  const closePopup = (e) => {
    e.preventDefault()
    setPopClassName('hidePop')
  }

  const createQuestion = (submission, i) => {
    switch (submission.question_type) {
      case 'SINGLE_CHOICE':
      case 'MULTIPLE_CHOICE':
      case 'BOOLEAN':
        return (
          <div className="question__wrapper">
            <div className="question__wrap">
              <p
                className="assessment__question"
                dangerouslySetInnerHTML={{ __html: submission.question_title }}
              ></p>
              <div className="assessment__marks">
                <span className="full">&bull;</span>
                {/* <p>{`${submission.scored_point} out ${submission.weightage} obtained`}</p> */}
              </div>
              <div className="clear"></div>
            </div>
            <div className="answer__wrap">
              <div class="form-row">
                <div class="col-md-2">
                  {/* <input
                    type="number"
                    name={`scored_point-${i + 1}`}
                    value={submission.scored_point}
                    class="form-control"
                    placeholder="Marks"
                  /> */}
                </div>
              </div>
              <div className="clear"></div>
            </div>
            <div className="clear"></div>
          </div>
        )
      case 'TEXT':
        let radioCorrectProps = {}
        let radioWrongProps = {}
        if (submission.assessed) {
          radioCorrectProps = {
            ...radioCorrectProps,
            checked: submission.correct,
          }
          radioWrongProps = {
            ...radioWrongProps,
            checked: !submission.correct,
          }
        }
        return (
          <div className="question__wrapper">
            <div className="question__wrap">
              <p
                className="assessment__question"
                dangerouslySetInnerHTML={{ __html: submission.question_title }}
              ></p>
              <div className="assessment__marks">
                {submission.assessed ? (
                  <span className="full">&bull;</span>
                ) : null}
                {submission.assessed ? (
                  <p></p>
                ) : (
                  // <p>{`${submission.scored_point} out ${submission.weightage} obtained`}</p>
                  <p>{`Marks - ${submission.weightage}`}</p>
                )}
              </div>
              <div className="clear"></div>
            </div>
            <div className="answer__wrap">
              <h6>{submission.submitted_answer_text}</h6>
            </div>
            <div className="answer__wrap">
              <div class="form-row">
                <div class="col-md-10">
                  <ul className="radio__wrapper">
                    <li>
                      <input
                        type="radio"
                        className="radio--btn"
                        name={`correct-${i + 1}`}
                        id={submission._id + 1}
                        value={true}
                        onChange={(e) =>
                          submission?.assessed
                            ? null
                            : onchangeHandler(e, submission._id)
                        }
                        {...radioCorrectProps}
                      />
                      <label
                        htmlFor={submission._id + 1}
                        className="radio--label"
                      >
                        Correct
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        className="radio--btn"
                        name={`correct-${i + 1}`}
                        id={submission._id + 2}
                        value={false}
                        onChange={(e) =>
                          submission?.assessed
                            ? null
                            : onchangeHandler(e, submission._id)
                        }
                        {...radioWrongProps}
                      />
                      <label
                        htmlFor={submission._id + 2}
                        className="radio--label"
                      >
                        Wrong
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="col-md-2">
                  {/* <input
                    type="text"
                    name={`scored_point-${i + 1}`}
                    value={marks[submission.question_id]}
                    className="form-control"
                    placeholder="Marks"
                    onChange={(e) =>
                      submission?.assessed
                        ? null
                        : onChangeMark(
                            e,
                            submission.question_id,
                            submission.weightage
                          )
                    }
                  /> */}
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        )
      case 'UPLOAD':
        let radioCorrectPropsUpload = {}
        let radioWrongPropsUpload = {}
        if (submission.assessed) {
          radioCorrectPropsUpload = {
            ...radioCorrectPropsUpload,
            checked: submission.correct,
          }
          radioWrongPropsUpload = {
            ...radioWrongPropsUpload,
            checked: !submission.correct,
          }
        }
        return (
          <div className="question__wrapper">
            <div className="question__wrap">
              <p
                className="assessment__question"
                dangerouslySetInnerHTML={{ __html: submission.question_title }}
              ></p>
              <div className="assessment__marks">
                {submission.assessed ? (
                  <span className="full">&bull;</span>
                ) : null}
                {submission.assessed ? (
                  <p>{`${submission.scored_point} out ${submission.weightage} obtained`}</p>
                ) : (
                  <p>{`Marks - ${submission.weightage}`}</p>
                )}
              </div>
              <div className="clear"></div>
            </div>
            <div className="answer__wrap">
              <h6>{submission.submitted_answer_text}</h6>
            </div>
            <div className="answer__wrap">
              <div class="form-row">
                <div class="col-md-10">
                  <ul className="radio__wrapper">
                    <li>
                      <input
                        type="radio"
                        className="radio--btn"
                        name={`correct-${i + 1}`}
                        id={submission._id + 1}
                        value={true}
                        onChange={(e) =>
                          submission?.assessed
                            ? null
                            : onchangeHandler(e, submission._id)
                        }
                        {...radioCorrectPropsUpload}
                      />
                      <label
                        htmlFor={submission._id + 1}
                        className="radio--label"
                      >
                        Correct
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        className="radio--btn"
                        name={`correct-${i + 1}`}
                        id={submission._id + 2}
                        value={false}
                        onChange={(e) =>
                          submission?.assessed
                            ? null
                            : onchangeHandler(e, submission._id)
                        }
                        {...radioWrongPropsUpload}
                      />
                      <label
                        htmlFor={submission._id + 2}
                        className="radio--label"
                      >
                        Wrong
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="col-md-2">
                  <input
                    type="text"
                    name={`scored_point-${i + 1}`}
                    value={marks[submission.question_id]}
                    className="form-control"
                    placeholder="Marks"
                    onChange={(e) =>
                      submission?.assessed
                        ? null
                        : onChangeMark(
                            e,
                            submission.question_id,
                            submission.weightage
                          )
                    }
                  />
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        )
      default:
        return <div />
    }
  }

  const noExaminationAvailable = () => {
    return (
      <div className="noPending">
        <div>
          <img
            src={NoSelfAssessment}
            alt="This student did not submit assessment"
          />
          <h2 className="text-center">Not Yet Submitted.</h2>
          <p>This student did not submit assessment. </p>
        </div>
      </div>
    )
  }

  return (
    <div className="body__container">
      <section>
        <h1>Evaluation</h1>
        <div className="row">
          <div className="col-md-8 padding--0">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/me/assessments">Evaluation</Link>
                </li>
                <li className="breadcrumb-item active">{assessmentTitle}</li>
              </ol>
            </nav>
          </div>
          <div className="clear"></div>
        </div>
      </section>
      <section>
        <LeftRightLayout>
          <Col100>
            <h2>{selectedStudent?.name || '---'}</h2>
            {isAssessmentSubmissionLoading ? (
              <Loader />
            ) : (
              <form className="assessment__form assessment__form--evaluation">
                {assessmentSubmissions.length
                  ? assessmentSubmissions.map((submission, index) => {
                      return createQuestion(submission, index)
                    })
                  : noExaminationAvailable()}
                <div className="scoreBoard">
                  <div className="row">
                    <div className="col">
                      <h3>Questions evaluated</h3>
                      <h3>{`${
                        assessmentSubmissions?.filter(
                          (submission) => submission?.assessed
                        ).length
                      } / ${assessmentSubmissions?.length}`}</h3>
                    </div>
                    <div className="col">
                      <h3 className="text-right">Total Score</h3>
                      {/* <h3 className="text-right">{`${assessmentSubmissions?.reduce(
                        (total, submission) => total + submission?.scored_point,
                        0
                      )} / ${assessmentSubmissions?.reduce(
                        (total, submission) => total + submission?.weightage,
                        0
                      )}`}</h3> */}
                    </div>
                  </div>
                  <div className="clear"></div>
                </div>
              </form>
            )}
          </Col100>
        </LeftRightLayout>
      </section>
    </div>
  )
}

export default AssessmentResult
