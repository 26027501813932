import {
  GET_ANNOUNCEMENTS_START,
  GET_ANNOUNCEMENTS_SUCCESS,
  GET_ANNOUNCEMENTS_FAILURE,
  APP_LOGOUT,
} from '../actions'

let initialState = {
  announcements: [],
  isLoading: false,
  isError: false,
}
export const announcement = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANNOUNCEMENTS_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case GET_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        announcements: action.data,
      }
    case GET_ANNOUNCEMENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

export const getAnnouncements = (state) => state.announcement.announcements
export const getIsLoading = (state) => state.announcement.isLoading
export const getIsError = (state) => state.announcement.isError
