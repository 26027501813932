import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import _ from 'underscore'

//assets
import OnlineSessionCard from '../assets/molecules/onlineSessionCard'
import Loader from '../assets/molecules/loader'
import LeftRightLayout from '../assets/layout/leftrightlayout'
import Col100 from '../assets/layout/leftrightlayout/column100'
import Select from '../assets/molecules/select'

//action
import { fetchOnlineSessions } from '../../redux/action/onlineSession'

import {
  getIsLoading,
  getOnlineSessionsGroupBySubject,
} from '../../redux/reducer/onlineSession'
import { getBatchSubjects } from '../../redux/reducer/subject'
import { getJwt } from '../../redux/reducer/account'

const OnlineSession = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  //selectors
  //account
  const jwt = useSelector((state) => getJwt(state))
  const subjects = useSelector(getBatchSubjects)
  // const error = useSelector(getIsError)
  const isLoading = useSelector(getIsLoading)
  const sessionsGroupBy = useSelector(getOnlineSessionsGroupBySubject)

  const [batchSubjectId, setBatchSubjectId] = useState(subjects[0]?._id)
  const [subjectsByID] = useState(_.groupBy(subjects, (x) => x._id))
  console.log('sessionsGroupBy: ', sessionsGroupBy)
  useEffect(() => {
    dispatch(fetchOnlineSessions({ jwt }))
  }, [dispatch, jwt])

  const getSubjectNameByBatchSubjectId = (id) => {
    const filteredSubject = subjects.filter((subject) => subject._id === id)
    const currentSubject = filteredSubject.length > 0 ? filteredSubject[0] : {}
    return currentSubject.subject_title
  }

  const onClickedAttend = (e, session) => {
    e.preventDefault()
    history.push({
      pathname: '/me/session/detail',
      state: { session },
    })
  }

  const onSubjectSelect = (e) => {
    setBatchSubjectId(e.target.value)
  }

  return (
    <div className="tabs__container tabs--student">
      <section>
        <h1>Sessions</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Online Sessions</li>
          </ol>
        </nav>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col100>
            <Select onChange={(e) => onSubjectSelect(e)}>
              <option>{getSubjectNameByBatchSubjectId(batchSubjectId)}</option>
              {subjects?.map((subject, index) => {
                return (
                  batchSubjectId !== subject._id && (
                    <option value={subject._id} key={index}>
                      {subject.subject_title}
                    </option>
                  )
                )
              })}
            </Select>
            {isLoading ? (
              <Loader />
            ) : sessionsGroupBy?.[batchSubjectId]?.length ? (
              <div>
                {sessionsGroupBy[batchSubjectId]
                  ?.filter((session) => {
                    return new Date() < new Date(session.end_time)
                  })
                  ?.sort(
                    (a, b) => new Date(a?.start_time) - new Date(b?.start_time)
                  )
                  .map((session, index) => {
                    return (
                      <OnlineSessionCard
                        key={index}
                        onlinecardStudents="true"
                        img="https://cwxdata.s3.ap-south-1.amazonaws.com/phy.jpg"
                        onClick={(e) =>
                          !(
                            new Date() > new Date(session?.end_time) ||
                            moment().isBefore(
                              moment(session?.start_time).subtract(5, 'minutes')
                            )
                          )
                            ? onClickedAttend(e, session)
                            : {}
                        }
                        heading={session.title}
                        description={session?.title}
                        subject={subjectsByID[batchSubjectId][0]?.subject_title}
                        chapter="Chapter"
                        date={moment(session.start_time).format('Do MMMM YYYY')}
                        timing={
                          moment(session.start_time).format('hh:mm A') +
                          ' - ' +
                          moment(session.end_time).format('hh:mm A')
                        }
                        button="Attend"
                        buttonClass={
                          !(
                            new Date() > new Date(session?.end_time) ||
                            moment().isBefore(
                              moment(session?.start_time).subtract(5, 'minutes')
                            )
                          )
                            ? 'button--blue'
                            : 'button--disable'
                        }
                        moreDetails={'Teacher: ' + session.author_name}
                      />
                    )
                  })}
                {sessionsGroupBy[batchSubjectId]
                  ?.filter((session) => {
                    return new Date() > new Date(session.end_time)
                  })
                  ?.sort(
                    (a, b) => new Date(a?.start_time) - new Date(b?.start_time)
                  )
                  .map((session, index) => {
                    return (
                      <OnlineSessionCard
                        key={index}
                        onlinecardStudents="true"
                        img="https://cwxdata.s3.ap-south-1.amazonaws.com/phy.jpg"
                        onClick={(e) =>
                          new Date() < new Date(session.end_time)
                            ? onClickedAttend(e, session)
                            : {}
                        }
                        heading={session.title}
                        description={session?.title}
                        subject={subjectsByID[batchSubjectId][0]?.subject_title}
                        chapter="Chapter"
                        date={moment(session.start_time).format('Do MMMM YYYY')}
                        timing={
                          moment(session.start_time).format('hh:mm A') +
                          ' - ' +
                          moment(session.end_time).format('hh:mm A')
                        }
                        button="Attend"
                        buttonClass={
                          new Date() < new Date(session.end_time)
                            ? 'button--blue'
                            : 'button--disable'
                        }
                        moreDetails={'Teacher: ' + session.author_name}
                      />
                    )
                  })}
              </div>
            ) : (
              <div>No Sessions available</div>
            )}
          </Col100>
        </LeftRightLayout>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default OnlineSession
