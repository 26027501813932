import {
  GET_CHAPTERS_START,
  GET_CHAPTERS_SUCCESS,
  GET_CHAPTERS_FAILURE,
  CREATE_CHAPTER_START,
  CREATE_CHAPTER_SUCCESS,
  CREATE_CHAPTER_FAILURE,
  EDIT_CHAPTER_START,
  EDIT_CHAPTER_SUCCESS,
  EDIT_CHAPTER_FAILURE,
  DELETE_CHAPTER_START,
  DELETE_CHAPTER_SUCCESS,
  DELETE_CHAPTER_FAILURE,
  CLEAR_CHAPTER,
} from '../actions'

import { checkStatus } from '../helpers.js'

export const clearChapters = () => {
  return {
    type: CLEAR_CHAPTER,
  }
}

const getChaptersStart = () => {
  return {
    type: GET_CHAPTERS_START,
  }
}

const getChaptersSuccess = ({ chapters }) => {
  return {
    type: GET_CHAPTERS_SUCCESS,
    chapters,
  }
}

const getChaptersFailure = () => {
  return {
    type: GET_CHAPTERS_FAILURE,
  }
}

const apiCallTogetChaptersBySubjectId = ({ jwt, subjectId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/chapters/subject-id/${subjectId}`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}

export const fetchChaptersBySubjectId = (data) => {
  return async (dispatch) => {
    dispatch(getChaptersStart())
    try {
      const receivedData = await apiCallTogetChaptersBySubjectId(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      let chapters = response.data.chapters
      dispatch(getChaptersSuccess({ chapters }))
    } catch (err) {
      dispatch(getChaptersFailure())
    }
  }
}

const createChapterStart = () => {
  return {
    type: CREATE_CHAPTER_START,
  }
}

const createChapterSuccess = () => {
  return {
    type: CREATE_CHAPTER_SUCCESS,
  }
}

const createChapterFailure = () => {
  return {
    type: CREATE_CHAPTER_FAILURE,
  }
}

const createChapter = ({ payload, jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/chapters`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postCreateChapter = ({ formData, subjectId, jwt }) => {
  return async (dispatch) => {
    dispatch(createChapterStart())
    try {
      const payload = {
        title: formData.title,
        description: formData.description,
        subject_id: subjectId,
      }
      const receivedData = await createChapter({ payload, jwt })
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(fetchChaptersBySubjectId({ jwt, subjectId }))
      dispatch(createChapterSuccess())
    } catch (err) {
      dispatch(createChapterFailure())
    }
  }
}

const editChapterStart = () => {
  return {
    type: EDIT_CHAPTER_START,
  }
}

const editChapterSuccess = () => {
  return {
    type: EDIT_CHAPTER_SUCCESS,
  }
}

const editChapterFailure = () => {
  return {
    type: EDIT_CHAPTER_FAILURE,
  }
}

const editChapterApiCall = ({ payload, jwt, chapterId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/chapters/${chapterId}`
  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const editChapter = ({ formData, subjectId, chapterId, jwt }) => {
  return async (dispatch) => {
    dispatch(editChapterStart())
    try {
      const payload = {
        title: formData.title,
        description: formData.description,
        subject_id: formData.subjectId ? formData.subjectId : subjectId,
      }
      const receivedData = await editChapterApiCall({
        payload,
        jwt,
        chapterId,
      })
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(fetchChaptersBySubjectId({ jwt, subjectId }))
      dispatch(editChapterSuccess())
    } catch (err) {
      dispatch(editChapterFailure())
    }
  }
}

const deleteChapterStart = () => {
  return {
    type: DELETE_CHAPTER_START,
  }
}

const deleteChapterSuccess = (chapters) => {
  return {
    type: DELETE_CHAPTER_SUCCESS,
    chapters,
  }
}

const deleteChapterFailure = () => {
  return {
    type: DELETE_CHAPTER_FAILURE,
  }
}

const deleteChapterApiCall = ({ jwt, chapterId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/chapters/${chapterId}`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const deleteChapter = (data) => {
  return async (dispatch, getState) => {
    dispatch(deleteChapterStart())
    try {
      const receivedData = await deleteChapterApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      const { chapter } = getState()
      const { chapters } = chapter
      const updatedChapters = chapters?.filter(
        (chapter) => chapter._id !== data?.chapterId
      )
      dispatch(deleteChapterSuccess(updatedChapters))
    } catch (err) {
      dispatch(deleteChapterFailure())
    }
  }
}
