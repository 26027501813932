import React, { useEffect, useState, useRef, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'

import Loader from '../assets/molecules/loader'
import PopupCustom from '../assets/layout/popupCustom'
import PopupConfirm from '../assets/layout/popupConfirm'
import SelfAssessmentEnd from '../../assets/images/selfAssessmentEnd.png'
import { AlertSuccess } from '../assets/molecules/alerts'

//action
import {
  fetchAssessmentQuestions,
  postAssessmentSubmission,
  fetchAssessment,
} from '../../redux/action/assessment'
import { startFileUpload } from '../../redux/action/upload'
import { postImpressions } from '../../redux/action/impressions'
import { setAssessment } from '../../redux/action/ui'
import { startGetProfileInfo } from '../../redux/action/profile'

//reducer
import { getJwt, getEntityType } from '../../redux/reducer/account'
import {
  getIsAssessmentsLoading,
  getAssessmentQuestions,
  getIsError,
  getIsAssessmentSubmissionLoading,
  getIsAssessmentLoading,
  getAssessment,
} from '../../redux/reducer/assessment'
import {
  getIsError as getFileUploadError,
  getFile,
  getIsLoadingUploadFile,
} from '../../redux/reducer/upload'
import {
  getAssessmentId,
  getAssessmentTitle,
  getBatchSubjectId,
  getAssessment as getAssessmentUI,
} from '../../redux/reducer/ui'
import { getProfileInfo, profile } from '../../redux/reducer/profile'

import { usePrevious, useInterval } from '../helpers'

const MyAssessment = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const elRefs = useRef([])

  let [formData, setFormData] = useState({})
  let [errorMessage, setErrorMessage] = useState('')
  let [uploadFiles, setUploadedFiles] = useState({})
  let [selectedUploadQuestionId, setSelectedUploadQuestionId] = useState('')
  const [currentTime, setCurrentTime] = useState()
  const [lastStatus, setLastStatus] = useState(false)
  const [autoStop, setAutoStop] = useState(false)

  //selector
  const jwt = useSelector(getJwt)
  const isLoading = useSelector(getIsAssessmentsLoading)
  const isAssessmentSubmissionLoading = useSelector(
    getIsAssessmentSubmissionLoading
  )
  const assessmentId = useSelector(getAssessmentId)
  const assessmentTitle = useSelector(getAssessmentTitle)
  const selectedAssessment = useSelector(getAssessmentUI)
  const assessmentQuestions = useSelector(getAssessmentQuestions)
  const error = useSelector(getIsError)
  const isLoadingPrev = usePrevious(isAssessmentSubmissionLoading)
  const batchSubjectId = useSelector(getBatchSubjectId)
  const fileUploadError = useSelector(getFileUploadError)
  const uploadedFile = useSelector(getFile)
  const isLoadingUploadFile = useSelector(getIsLoadingUploadFile)
  const isLoadingUploadFilePrev = usePrevious(isLoadingUploadFile)
  const entityType = useSelector(getEntityType)
  const isAssessmentLoading = useSelector(getIsAssessmentLoading)
  const isAssessmentLoadingPrev = usePrevious(isAssessmentLoading)
  const assessment = useSelector(getAssessment)
  const profileInfo = useSelector(getProfileInfo)

  const [allowed, setAllowed] = useState('')

  useEffect(() => {
    dispatch(startGetProfileInfo({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    const id = assessmentId
    if (selectedAssessment && !selectedAssessment?.has_taken) {
      if (entityType === 'students') {
        const body = {
          content_id: selectedAssessment?._id,
          content_type: 'assessment',
          time_spent: '.1',
          batch_subject_id: selectedAssessment?.batch_subject_id,
        }
        dispatch(postImpressions({ jwt, body }))
      }
      dispatch(fetchAssessmentQuestions({ jwt, id }))
      dispatch(fetchAssessment({ jwt, id: selectedAssessment?._id }))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isAssessmentLoading &&
      isAssessmentLoading !== isAssessmentLoadingPrev &&
      isAssessmentLoadingPrev !== undefined
    ) {
      dispatch(
        setAssessment(
          assessment?.assessment?._id,
          assessment?.assessment?.title,
          assessment?.assessment
        )
      )
    }
  }, [isAssessmentLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useInterval(() => {
    setCurrentTime(moment())
  }, 1000)

  const getimer = () => {
    // start time and end time
    var startTime = currentTime
    var endTime = moment(selectedAssessment?.due_date)

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime))

    // duration in hours
    var hours = parseInt(duration.asHours())

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60

    // duration in minutes
    var seconds = parseInt(duration.asSeconds()) % 60

    if (hours < 0 || minutes < 0 || seconds < 0) {
      history.push('/me/assessments')
    } else {
      if (minutes === 10 && !lastStatus && hours === 0) {
        setLastStatus(true)
      }
      if (hours === 0 && minutes === 0 && seconds === 0 && !autoStop) {
        setAutoStop(true)
        onAnswersSubmit()
      }
      return hours + ':' + minutes + ':' + seconds
    }
  }

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isAssessmentSubmissionLoading &&
      !isAssessmentSubmissionLoading &&
      !error
    ) {
      if (autoStop) {
        history.push('/me/assessments')
      } else {
        openPopupCustom()
      }
    } else if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isAssessmentSubmissionLoading &&
      !isAssessmentSubmissionLoading &&
      error
    ) {
      setErrorMessage('Already Submitted!')
    }
  }, [error, isAssessmentSubmissionLoading, isLoadingPrev]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      isLoadingUploadFilePrev !== undefined &&
      isLoadingUploadFilePrev !== isLoadingUploadFile &&
      !isLoadingUploadFile &&
      !fileUploadError
    ) {
      let oldQuestionFiles = uploadFiles[selectedUploadQuestionId]
      if (oldQuestionFiles && oldQuestionFiles?.length) {
        oldQuestionFiles.push(uploadedFile?.publicUrl)
        const obj = {
          ...uploadFiles,
          [selectedUploadQuestionId]: oldQuestionFiles,
        }
        setUploadedFiles(obj)
      } else {
        const obj = {
          ...uploadFiles,
          [selectedUploadQuestionId]: [uploadedFile?.publicUrl],
        }
        setUploadedFiles(obj)
      }
    }
  }, [fileUploadError, isLoadingUploadFile, isLoadingUploadFilePrev]) // eslint-disable-line react-hooks/exhaustive-deps

  const onchangeHandler = (e, question, answer) => {
    if (!Array.isArray(formData[question?._id])) {
      setFormData({ ...formData, [question._id]: [e.target.value] })
    } else {
      if (question?.type === 'MULTIPLE_CHOICE') {
        if (formData[question?._id].includes(e.target.value)) {
          const removed = formData[question?._id].filter(
            (id) => id !== e.target.value
          )
          setFormData({ ...formData, [question?._id]: removed })
        } else {
          setFormData({
            ...formData,
            [question?._id]: [...formData[question?._id], e.target.value],
          })
        }
      } else {
        setFormData({ ...formData, [question._id]: [e.target.value] })
      }
    }
    errorMessage && setErrorMessage('')
  }

  const onChangeFile = (e, id) => {
    setErrorMessage('')
    setSelectedUploadQuestionId(id)
    const imagedata = e.target.files[0]
    const data = new FormData()
    data.append('file', imagedata)
    dispatch(startFileUpload(data))
  }

  const onUploadAnswerSheet = (e, i) => {
    e.preventDefault()
    setAllowed('')
    if (profileInfo && profileInfo.uploadAssessmentNotAllowed)
      setAllowed('notAllowed')
    else {
      elRefs.current[i].current.click()
    }
  }

  const onDeleteFile = (id, index) => {
    const obj = {
      ...uploadFiles,
      [id]: uploadFiles[id]?.filter((val, i) => i !== index),
    }
    setUploadedFiles(obj)
  }

  const createQuestion = (question, i) => {
    switch (question.type) {
      case 'SINGLE_CHOICE':
      case 'MULTIPLE_CHOICE':
      case 'BOOLEAN':
        return (
          <div key={i} className="question__wrapper">
            <div className="question__wrap">
              <p className="assessment__question">{`${i + 1})`}</p>

              <p
                className="assessment__question"
                dangerouslySetInnerHTML={{ __html: question.title }}
              ></p>
              <p className="assessment__marks">{question.weightage} mark(s)</p>
              <div className="clear"></div>
            </div>
            <ul className="radio__wrapper">
              {question?.answers?.map((answer, index) => (
                <li key={index}>
                  <input
                    type={
                      question.type === 'MULTIPLE_CHOICE' ? 'checkbox' : 'radio'
                    }
                    className="radio--btn"
                    name={question._id}
                    id={answer._id}
                    value={answer._id}
                    onChange={(e) => onchangeHandler(e, question, answer)}
                  />
                  <label
                    htmlFor={answer._id}
                    className="radio--label"
                    dangerouslySetInnerHTML={{ __html: answer.title }}
                  ></label>
                </li>
              ))}
            </ul>
            <div className="clear"></div>
          </div>
        )
      case 'TEXT':
        return (
          <div key={i} className="question__wrapper">
            <div className="question__wrap">
              <p className="assessment__question">{`${i + 1})`}</p>
              <p
                className="assessment__question"
                dangerouslySetInnerHTML={{ __html: question.title }}
              ></p>
              <p className="assessment__marks">{question.weightage} mark(s)</p>
              <div className="clear"></div>
            </div>
            <textarea
              placeholder="Type your answer"
              name={question?._id}
              value={formData?.question?._id}
              rows="10"
              onChange={(e) => onchangeHandler(e, question, '')}
            />
          </div>
        )
      case 'UPLOAD':
        let questiontitle = ''
        if (
          question?.title?.match(/\.[0-9a-z]+$/i)?.[0] &&
          question?.title?.match(/\.[0-9a-z]+$/i)?.[0] === '.pdf'
        ) {
          questiontitle = (
            <iframe height="1200" width="90%" src={question?.title} />
          )
        } else {
          questiontitle = (
            <p
              className="assessment__question"
              dangerouslySetInnerHTML={{ __html: question.title }}
            ></p>
          )
        }
        return isLoadingUploadFile &&
          selectedUploadQuestionId === question?._id ? (
          <Loader />
        ) : (
          <div key={i} className="question__wrapper">
            <div className="question__wrap">
              <p className="assessment__question">{`${i + 1})`}</p>
              {questiontitle}
              <p className="assessment__marks">{question.weightage} mark(s)</p>
              <div className="clear"></div>
            </div>
            <div className="question__wrap">
              <ul style={{ paddingLeft: 25 }}>
                {uploadFiles[question?._id] &&
                uploadFiles[question?._id]?.length
                  ? uploadFiles[question?._id]?.map((file, index) => {
                      return (
                        <li key={index}>
                          <a href={file}>Sheet {index + 1} Uploaded</a>
                          <span
                            style={{
                              cursor: 'pointer',
                              fontSize: 15,
                              padding: 20,
                              color: '#fb6161',
                            }}
                            onClick={() => onDeleteFile(question?._id, index)}
                          >
                            {' '}
                            DELETE{' '}
                          </span>
                        </li>
                      )
                    })
                  : null}
              </ul>
              <input
                type="file"
                id={question?._id}
                name={question?._id}
                ref={elRefs.current[i]}
                style={{ display: 'none' }}
                onChange={(e) => onChangeFile(e, question?._id)}
              />
              <button
                onClick={(e) => onUploadAnswerSheet(e, i)}
                // onClick={(e) => {
                //   e.preventDefault()
                //   elRefs.current[i].current.click()
                // }}
                className="button--blue"
              >
                {uploadFiles[question?._id] &&
                uploadFiles[question?._id]?.length
                  ? 'Add More Sheets'
                  : 'Upload Answer Sheet'}
              </button>
            </div>
          </div>
        )
      default:
        return <div key={i} />
    }
  }

  const onAnswersSubmit = () => {
    const formDataValues = Object.values(formData)
    const uploadFileValues = Object.values(uploadFiles)
    const isFormFilled = formDataValues.every(
      (formDataValue) =>
        formDataValue !== '' &&
        formDataValue !== null &&
        formDataValue !== undefined &&
        (formDataValue?.length ? formDataValue[0] : false)
    )
    const isFilesFilled = uploadFileValues.every(
      (uploadFileValue) =>
        uploadFileValue !== '' &&
        uploadFileValue !== null &&
        uploadFileValue !== undefined &&
        uploadFileValue?.length
    )
    const payload = {
      assessment_id: selectedAssessment?._id,
      batch_subject_id: selectedAssessment?.batch_subject_id,
      submissions: [],
    }
    if (
      Object.keys(formData)?.length + Object.keys(uploadFiles)?.length ===
        assessmentQuestions?.length &&
      (Object.keys(formData)?.length
        ? isFormFilled
        : Object.keys(uploadFiles)?.length
        ? isFilesFilled
        : true)
    ) {
      assessmentQuestions.forEach((question) => {
        switch (question.type) {
          case 'SINGLE_CHOICE':
          case 'MULTIPLE_CHOICE':
          case 'BOOLEAN':
            payload.submissions.push({
              question_id: question._id,
              question_title: question.title,
              question_type: question.type,
              submitted_answer_ids: formData[question._id],
              submitted_answer_text: 0,
            })
            break
          case 'TEXT':
            payload.submissions.push({
              question_id: question._id,
              question_title: question.title,
              question_type: question.type,
              submitted_answer_ids: [],
              submitted_answer_text: formData[question._id][0],
            })
            break
          case 'UPLOAD':
            payload.submissions.push({
              question_id: question._id,
              question_title: question.title,
              question_type: question.type,
              submitted_answer_ids: uploadFiles[question._id]
                ? uploadFiles[question._id]
                : [],
              submitted_answer_text: 0,
            })
            break
          default:
        }
      })
      dispatch(postAssessmentSubmission({ jwt, payload }))
    } else {
      setErrorMessage('Please answer all the questions')
    }
  }

  const [popClassName, setPopClassName] = useState('hidePop')
  const openPopup = () => {
    setPopClassName('showPop')
  }
  const closePopup = () => {
    setPopClassName('hidePop')
  }

  const [popCustomClassName, setPopCustomClassName] = useState('hidePop')
  const openPopupCustom = () => {
    setPopCustomClassName('showPop')
  }
  const closePopupCustom = () => {
    setPopCustomClassName('hidePop')
    history.push({
      pathname: '/dashboard',
      state: { status: 'success' },
    })
  }

  const arrLength = assessmentQuestions?.length

  if (elRefs.current.length !== arrLength) {
    // add or remove refs
    elRefs.current = Array(arrLength)
      .fill()
      .map((_, i) => elRefs.current[i] || createRef())
  }

  let uploadFilesLength = 0

  Object.keys(uploadFiles).forEach((key) => {
    uploadFiles[key].forEach((item) => {
      uploadFilesLength = uploadFilesLength + 1
    })
  })

  return (
    <div className="tabs__container">
      <section>
        <h1>{assessmentTitle}</h1>
        {selectedAssessment?.url && selectedAssessment?.url !== 'noFiles' ? (
          <div>
            <h1
              style={{ fontSize: 50, color: lastStatus ? '#fb6161' : '#000' }}
            >
              {getimer()}
            </h1>
            {lastStatus ? (
              <h1 style={{ fontSize: 30, color: '#fb6161' }}>
                Time to upload the sheets
              </h1>
            ) : null}
            <a
              href={selectedAssessment?.url}
              className={'button--blue button--blue--download'}
              download
            >
              DOWNLOAD QUESTION PAPER
            </a>
          </div>
        ) : null}
        <form className="assessment__form">
          <br />
          {isLoading
            ? selectedAssessment?.has_taken
              ? 'The Examination has been submitted.'
              : 'Loading...'
            : selectedAssessment?.has_taken
            ? 'The Examination has been submitted.'
            : assessmentQuestions?.length
            ? assessmentQuestions?.map((question, index) => {
                return createQuestion(question, index)
              })
            : selectedAssessment?.url && selectedAssessment?.url !== 'noFiles'
            ? 'Download Question paper'
            : 'The Examination has not yet started, please check your local device time settings and revisit to attend!'}
        </form>
        {assessmentQuestions?.length ||
        (selectedAssessment?.url && selectedAssessment?.url !== 'noFiles') ? (
          profileInfo && !profileInfo.uploadAssessmentNotAllowed ? (
            <div className="text-center">
              <button
                onClick={() => openPopup()}
                className="button--blue"
                type="submit"
              >
                SUBMIT ASSESMENT
              </button>
            </div>
          ) : (
            <div className="text-center">
              <button
                onClick={() => history.push('/me/assessments')}
                className="button--blue"
                type="submit"
              >
                GO BACK
              </button>
            </div>
          )
        ) : (
          <div className="text-center">
            <button
              onClick={() => history.push('/me/assessments')}
              className="button--blue"
              type="submit"
            >
              GO BACK
            </button>
          </div>
        )}
      </section>
      <PopupConfirm
        className={popClassName}
        close={() => closePopup()}
        onClick={onAnswersSubmit}
        buttonText="YES"
        popupHead="SUBMIT ASSESMENT"
      >
        {isAssessmentSubmissionLoading ? (
          <Loader />
        ) : (
          <div>
            <h2>Are you sure you want to submit?</h2>
            {selectedAssessment?.url &&
            selectedAssessment?.url !== 'noFiles' ? (
              uploadFilesLength ? (
                uploadFilesLength === 1 ? (
                  <h1>{uploadFilesLength} Sheet uploaded</h1>
                ) : (
                  <h1>{uploadFilesLength} Sheets uploaded</h1>
                )
              ) : (
                <h1 style={{ color: '#fb6161' }}>
                  {uploadFilesLength} files uploaded
                </h1>
              )
            ) : null}
            {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}
          </div>
        )}
      </PopupConfirm>
      <PopupCustom
        className={popCustomClassName}
        close={() => closePopupCustom()}
        popupHead="Thanks"
      >
        <div className="self__assessment--popup">
          <img
            className="self__assessmentIcon"
            src={SelfAssessmentEnd}
            alt="END ASSESMENT"
          />
          <h2>Thank You for your submission.</h2>
          <h4 className="assessment__details">
            Your results will be shared with you soon
          </h4>
          {/* <div className="custom__popup--buttonWrapper">
            <Link to="/assessments/results">
              <button className="button--transeperent">SEE RESULTS</button>
            </Link>
            <Link to="/assessments/questions">
              <button className="button--blue">RETAKE ASSESMENT</button>
            </Link>
            <div className="clear"></div>
          </div> */}
          <button
            onClick={() => {
              history.push({
                pathname: '/dashboard',
                state: { status: 'success' },
              })
            }}
            className="button--blue"
          >
            EXIT NOW
          </button>
        </div>
      </PopupCustom>

      <div className="clear"></div>
      {allowed === 'notAllowed' && (
        <AlertSuccess>
          Please submit the answer sheet in the school.
        </AlertSuccess>
      )}
    </div>
  )
}

export default MyAssessment
