import React, { useState, useEffect } from 'react'

import PopUp from '../layout/popupCustom'

export const AlertSuccess = (props) => {
  const [styleClass, setStyleClass] = useState('alert alert-success')

  useEffect(() => {
    const interval = setInterval(() => {
      if (props.onIntervalDone) {
        props.onIntervalDone()
      }
      setStyleClass('d-none')
    }, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [props])

  return (
    <PopUp className={styleClass} close={() => setStyleClass('d-none')}>
      <div className={'alert alert-success'} role="alert">
        {props.children}
      </div>
    </PopUp>
  )
}

export const AlertError = (props) => {
  const [styleClass, setStyleClass] = useState('alert alert-error')

  useEffect(() => {
    const interval = setInterval(() => {
      setStyleClass('d-none')
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <PopUp className={styleClass} close={() => setStyleClass('d-none')}>
      <div className={'alert alert-danger'} role="alert">
        {props.children}
      </div>
    </PopUp>
  )
}
