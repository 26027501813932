import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
//assets
import Loader from '../../assets/molecules/loader'
import Select from '../../assets/molecules/select'
import { AlertSuccess } from '../../assets/molecules/alerts'
import ChaptersCard from '../../assets/molecules/chaptersCardWithOutProgress'
import Popup from '../../assets/layout/popupConfirm'
import PopupForm from '../../assets/layout/popupForm'
import AddItems from '../../assets/molecules/addItems'
import NoPending from '../../../assets/images/no-pending.png'
import AddChapter from '../../../assets/images/addChapter.png'

// import Select from '../assets/molecules/select'
//action
//subject(chapter actions also implemented in subject)
import {
  fetchSubjects,
  clearSubjectSelected,
} from '../../../redux/action/subject'
import {
  clearChapters,
  fetchChaptersBySubjectId,
  postCreateChapter,
  editChapter,
  deleteChapter,
} from '../../../redux/action/chapter'

//ui
import {
  setSubject,
  setChapter,
  setBatch,
  setBatchSubjectId,
} from '../../../redux/action/ui'

//reducer
//subject(chapters also implemented in subject)
import {
  groupByBatchSubject,
  getSubjects,
} from '../../../redux/reducer/subject'
import {
  getChapters,
  getIsChaptersLoading,
  getIsError,
} from '../../../redux/reducer/chapter'

//account
import { getJwt } from '../../../redux/reducer/account'

import {
  getSubjectId,
  //getSubjectTitle,
  getBatch,
  //getBatchTitle,
} from '../../../redux/reducer/ui'

import { getBatches } from '../../../redux/reducer/batches'
import {
  // subjectIcons,
  subjectPics,
  usePrevious,
} from '../../helpers'

const Chapter = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  //selectors
  //account
  const jwt = useSelector((state) => getJwt(state))
  //ui
  const subjectId = useSelector((state) => getSubjectId(state))
  //subject(chapters)
  const subjects = useSelector((state) => groupByBatchSubject(state))
  const subjectsAll = useSelector((state) => getSubjects(state))
  const chapters = useSelector((state) => getChapters(state))
  const isLoading = useSelector((state) => getIsChaptersLoading(state))
  const isLoadingPrev = usePrevious(isLoading)
  const batches = useSelector((state) => getBatches(state))
  const error = useSelector(getIsError)

  const [popClassName, setPopClassName] = useState('hidePop')
  const [deleteChapterId, setDeleteChapterId] = useState('')
  const [selectedSubject, setSelectedSubject] = useState('')

  const subjectPicKeys = Object.keys(subjectPics)

  const [isEdit, setIsEdit] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showEditSuccess, setShowEditSuccess] = useState(false)
  const [pressStatus, setPressStatus] = useState('')
  const [popup, setPopup] = useState('hidePop')
  const [errMsg, setErrMsg] = useState('')
  const [formData, setFormData] = useState()
  const [editChapterPopClassName, setEditChapterPopClassName] = useState(
    'hidePop'
  )
  const [selectedChapter, setSelectedChapter] = useState('')
  const showPopup = () => {
    setPopup('showPop')
    setShowSuccess(false)
    setShowEditSuccess(false)
    setPressStatus('')
  }

  let location = useLocation()

  //ui for batch selected
  let batch = useSelector((state) => getBatch(state))

  useEffect(() => {
    subjectId && dispatch(fetchChaptersBySubjectId({ jwt, subjectId }))
    if (subjectsAll.length === 0) fetchSubjects({ jwt })
  }, [dispatch, jwt, subjectId, subjectsAll, batches, batch])

  useEffect(() => {
    selectedSubject &&
      dispatch(fetchChaptersBySubjectId({ jwt, subjectId: selectedSubject }))
  }, [dispatch, jwt, selectedSubject])

  useEffect(() => {
    if (isLoadingPrev !== undefined && !isLoading && !error) {
      setShowSuccess(true)
      setShowEditSuccess(true)
    }
  }, [error, isLoading, isLoadingPrev])

  const onChangeSubject = (e) => {
    e.preventDefault()
    dispatch(setSubject(e.target.value))
  }

  const onChangeBatch = (e) => {
    e.preventDefault()
    dispatch(clearChapters())
    dispatch(clearSubjectSelected())
    dispatch(setBatch(e.target.value))
    setSelectedSubject('')
  }

  const onChapterSelect = (chapter) => {
    dispatch(setChapter(chapter._id, chapter.title))
    dispatch(setBatchSubjectId(currentSubject?._id))
    history.push('/batch/subjects/chapters/topics')
  }

  const onChapterEdit = () => {
    dispatch(
      editChapter({
        jwt,
        chapterId: selectedChapter._id,
        formData,
        subjectId: selectedChapter.subject_id,
      })
    )
    closeEditChapterPopup()
    setIsEdit(false)
    setFormData('')
    setPressStatus('edit')
  }
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const closeEditChapterPopup = () => {
    setIsEdit(false)
    setFormData('')
    setEditChapterPopClassName('hidePop')
  }
  const onSubmit = () => {
    const { title, description } = formData
    if (title !== '' && description !== '') {
      dispatch(postCreateChapter({ jwt, subjectId, formData }))
      setPopup('hidePop')
    } else {
      setErrMsg('Please fill all form fields')
    }
    setFormData()
    setPressStatus('create')
  }

  const openEditChapterPopup = (chapter) => {
    setIsEdit(true)
    setFormData({
      title: chapter.title,
      description: chapter.description,
      subjectId: chapter.subject_id,
    })
    setEditChapterPopClassName('showPop')
    setSelectedChapter(chapter)
    setShowEditSuccess(false)
    setPressStatus('')
  }

  const deletePopup = (chapterId) => {
    setPopClassName('showPop')
    setDeleteChapterId(chapterId)
  }
  const closePopup = () => {
    setPopClassName('hidePop')
    setPopup('hidePop')
  }
  const ondeleteChapter = (e, chapterId) => {
    e.preventDefault()
    dispatch(deleteChapter({ jwt, chapterId, subjectId }))
    setPopClassName('hidePop')
  }

  const filteredList = subjects[batch]?.filter(
    (subject) => subject.subject_id === subjectId
  )
  const currentSubject = filteredList?.length > 0 ? filteredList[0] : {}

  const filteredListBatches = batches.filter((bat) => bat._id === batch)
  const currentBatch =
    filteredListBatches.length > 0 ? filteredListBatches[0] : {}

  const getSubjectNameById = (id) => {
    const filteredSubject = subjectsAll?.filter(
      (subject) => subject.subject_id === id
    )
    return filteredSubject.length ? filteredSubject[0].subject_title : ''
  }

  const notAvailable = (type) => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">There is no chapters available</h2>
          <p>Add chapters or go to subjects </p>
          <div className="noPending__button--Wrapper">
            <Link to="/batch/subjects">
              <button className="button--blue">GO TO SUBJECTS</button>
            </Link>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    )
  }

  const notAvailableSubject = (type) => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">Please Select A SUbject</h2>
          <p>Select a Subject in the subject dropdown </p>
        </div>
      </div>
    )
  }

  return (
    <div className="tabs__container">
      {location?.state?.status === 'success' ? (
        <AlertSuccess>You Have successfully Add a chapter</AlertSuccess>
      ) : null}
      <section>
        <div className="row">
          <div className="col-md-6 padding--0">
            <h1>Chapters</h1>
          </div>
          <div className="col-md-6 padding--0">
            {/* <Link
              to={{
                pathname: `/batch/${batch}/subjects/${subjectId}/create-chapter`,
              }}
            > */}
            {subjectId && (
              <button
                onClick={() => {
                  showPopup()
                }}
                className="button--blue float-right"
              >
                + Add Chapter
              </button>
            )}
            {/* </Link> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 padding--0">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/dashboard">{currentBatch.title}</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link to="/batch/subjects">
                    {currentSubject.subject_title}
                  </Link>
                </li>
                <li className="breadcrumb-item active">Chapters</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 padding--0">
            <div className="customTab__dropdowns">
              <Select
                className="float-left"
                onChange={(e) => onChangeBatch(e)}
                defaultValue={currentBatch._id}
              >
                {batches?.length ? (
                  batches?.map((batch, index) => {
                    return (
                      <option
                        value={batch._id}
                        selected={batch._id === currentBatch._id}
                        key={index}
                      >
                        {batch.title}
                      </option>
                    )
                  })
                ) : (
                  <option>No batches available</option>
                )}
              </Select>
              <Select
                className="float-center"
                onChange={(e) => onChangeSubject(e)}
                defaultValue={currentSubject.subject_id}
              >
                <option>Select a Subject</option>
                {subjects[batch]?.length ? (
                  subjects[batch]?.map((subject, index) => {
                    return (
                      <option
                        value={subject.subject_id}
                        selected={subject.subject_id === subjectId}
                        key={index}
                      >
                        {subject.subject_title}
                      </option>
                    )
                  })
                ) : (
                  <option>No subjects available</option>
                )}
              </Select>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </section>
      {isLoading ? (
        <Loader />
      ) : subjectId ? (
        <section className="chaptersCard--withEdit">
          {chapters?.length
            ? chapters?.map((chapter, index) => {
                const subjectName = getSubjectNameById(
                  chapter.subject_id
                ).toLowerCase()
                const filteredKeys = subjectPicKeys.filter((key) =>
                  subjectName.includes(key)
                )
                const pic = subjectPics[filteredKeys[0]]
                return (
                  <ChaptersCard
                    key={index}
                    img={pic}
                    chapterName={chapter.title}
                    description={chapter.description}
                    noOfTopics={chapter.topic_count}
                    onClick={() => onChapterSelect(chapter)}
                    editOnClick={() => openEditChapterPopup(chapter)}
                    deleteOnClick={(e) => deletePopup(chapter._id)}
                  />
                )
              })
            : notAvailable()}
          <div className="clear"></div>
        </section>
      ) : (
        notAvailableSubject()
      )}
      {subjectId && (
        <AddItems
          imageURL={AddChapter}
          text="+ ADD CHAPTER"
          onClick={() => showPopup()}
        />
      )}

      <Popup
        className={popClassName}
        close={() => closePopup()}
        onClick={(e) => ondeleteChapter(e, deleteChapterId)}
        popupHead="Delete"
        buttonText="proceed"
      >
        <h2>Are you sure want to delete?</h2>
      </Popup>
      <PopupForm
        className={!isEdit ? popup : editChapterPopClassName}
        close={() => {
          closePopup()
          closeEditChapterPopup()
        }}
        onClickButton={!isEdit ? onSubmit : onChapterEdit}
        buttonText={isEdit ? 'EDIT CHAPTER' : 'CREATE CHAPTER'}
        popupHead={isEdit ? 'Edit Chapter' : 'Create Chapter'}
      >
        <form>
          <ol>
            <li>
              <h2>What is this chapter about?</h2>
              <p>
                This information will help the students understand what this
                chapter is all about.
              </p>
              <div className="form-group sessionTitle">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter chapter title"
                  name="title"
                  value={formData?.title || ''}
                  onChange={(e) => {
                    onChange(e)
                  }}
                />
              </div>
              <div className="form-group sessionDescription">
                <textarea
                  name="description"
                  value={formData?.description || ''}
                  onChange={onChange}
                  placeholder="Chapter Description"
                ></textarea>
              </div>
            </li>
          </ol>
        </form>
      </PopupForm>
      {showSuccess && pressStatus === 'create' ? (
        <AlertSuccess>You have successfully added a Chapter</AlertSuccess>
      ) : null}
      {showEditSuccess && pressStatus === 'edit' ? (
        <AlertSuccess>You have successfully edited a Chapter</AlertSuccess>
      ) : null}
    </div>
  )
}

export default Chapter
