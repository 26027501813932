import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Tab, Tabs } from 'react-bootstrap'
import ReactPlayer from 'react-player'

//assets
import Loader from '../../assets/molecules/loader'
import LeftRightLayout from '../../assets/layout/leftrightlayout'
import Col100 from '../../assets/layout/leftrightlayout/column100'

//action
import { fetchTopic } from '../../../redux/action/topic'
import { fetchDoubtsByTopicId } from '../../../redux/action/doubt'

//reducer
import { getJwt } from '../../../redux/reducer/account'
import {
  getTopicId,
  getSubjectId,
  getChapterId,
  getBatch,
} from '../../../redux/reducer/ui'

import { getBatches } from '../../../redux/reducer/batches'

import { groupByBatchSubject } from '../../../redux/reducer/subject'

import {
  getTopic,
  getIsTopicsLoading,
  getTopics,
} from '../../../redux/reducer/topic'
import { getChapters } from '../../../redux/reducer/chapter'

const createMarkup = (data) => {
  return { __html: data }
}

const TopicDetail = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const jwt = useSelector(getJwt)
  const topicId = useSelector(getTopicId)
  const topic = useSelector(getTopic)
  const isTopicsLoading = useSelector(getIsTopicsLoading)
  const chapters = useSelector(getChapters)
  const topics = useSelector(getTopics)
  const subjects = useSelector(groupByBatchSubject)
  let batches = useSelector(getBatches)

  const subjectId = useSelector(getSubjectId)
  const chapterId = useSelector(getChapterId)
  let batch = useSelector(getBatch)

  const [videoPlaying, setVideoPlaying] = useState(false)

  useEffect(() => {
    dispatch(fetchTopic({ jwt, topicId }))
    dispatch(fetchDoubtsByTopicId({ jwt, topicId }))
  }, [dispatch, topicId, jwt])

  useEffect(() => {
    const interval = setInterval(() => {}, 1000)
    return () => clearInterval(interval)
  }, [])

  const filteredList = subjects[batch]?.filter(
    (subject) => subject.subject_id === subjectId
  )
  const currentSubject = filteredList?.length > 0 ? filteredList[0] : {}

  const filteredListBatches = batches.filter((bat) => bat._id === batch)
  const currentBatch =
    filteredListBatches.length > 0 ? filteredListBatches[0] : {}

  const filteredListTopics = topics.filter((topic) => topic._id === topicId)
  const currentTopic =
    filteredListTopics.length > 0 ? filteredListTopics[0] : {}

  const filteredListChapters = chapters.filter(
    (chapter) => chapter._id === chapterId
  )
  const currentChapter =
    filteredListChapters.length > 0 ? filteredListChapters[0] : {}

  const videoPlayingFunction = (value) => {
    setVideoPlaying(value)
  }

  const regexmatchurl = (url) => {
    // eslint-disable-next-line
    // const code = url.match(/(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/) // prettier-ignore

    // console.log(url.includes("player"));
    // console.log(url);
    // console.log(code);
    if (url.includes('player')) {
      return (
        <iframe
          src={url}
          width="100%"
          height="500px"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          controls={true}
        ></iframe>
      )
    } else {
      return (
        <ReactPlayer
          url={url}
          onPlay={() => videoPlayingFunction(true)}
          onPause={() => videoPlayingFunction(false)}
          controls={true}
          height="500px"
          width="100%"
        />
      )
    }
  }

  // console.log('topic.content_uri', regexmatchurl(topic.content_uri))
  // console.log("https://vimeo.com/534807166/1d93cc02e0","https://player.vimeo.com/video/463446577");

  return (
    <div className="tabs__container">
      <section>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/dashboard">{currentBatch.title}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/batch/subjects">{currentSubject.subject_title}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/batch/subjects/chapters">{currentChapter.title}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/batch/subjects/chapters/topics">
                {currentTopic.title}
              </Link>
            </li>
            <li className="breadcrumb-item active">Detail</li>
          </ol>
        </nav>
        <button
          className={'button--blue'}
          style={{ marginLeft: 0 }}
          onClick={(e) => {
            history.push({
              pathname: '/topic/reports',
              state: {
                topicId: topicId,
                currentTopic,
                currentBatch,
                currentSubject,
              },
            })
          }}
        >
          VIEW REPORT
        </button>
      </section>
      <section>
        <LeftRightLayout>
          <Col100>
            {isTopicsLoading ? (
              <Loader />
            ) : (
              <div className="topic__detailImage">
                {topic.type === 'image' && (
                  <img src={topic.content_uri} alt={topic.title} />
                )}
                {topic.type === 'audio' && (
                  <iframe
                    title={topic.title}
                    src={topic.content_uri}
                    height="500"
                    width="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  ></iframe>
                )}
                {topic.type === 'video' &&
                  regexmatchurl(
                    topic.content_uri
                  )
                  // <iframe
                  //   src={regexmatchurl(topic.content_uri)}
                  //   width="100%"
                  //   height="500px"
                  //   frameborder="0"
                  //   webkitallowfullscreen
                  //   mozallowfullscreen
                  //   allowfullscreen
                  //   controls={true}
                  // ></iframe>
                  // <ReactPlayer
                  //   url={regexmatchurl(topic.content_uri)}
                  //   onPlay={() => videoPlayingFunction(true)}
                  //   onPause={() => videoPlayingFunction(false)}
                  //   controls={true}
                  //   height="500px"
                  //   width="100%"
                  // />
                }
                <div className="clear"></div>
              </div>
            )}
            <Tabs>
              <Tab eventKey="description" title="Description">
                <div style={{ paddingLeft: '20px' }}>
                  <div
                    dangerouslySetInnerHTML={createMarkup(topic.wysiwyg_state)}
                  />
                </div>
              </Tab>
              {topic.notes && topic.notes.length > 0 && (
                <Tab eventKey="notes" title="Notes">
                  <div
                    className="noPending__button--Wrapper"
                    style={{ paddingLeft: '20px' }}
                  >
                    {topic.notes.map((file, index) => {
                      return (
                        <button
                          className={'button--blue'}
                          style={{ marginLeft: 0 }}
                          onClick={() => window.open(file, '_blank')}
                        >
                          Note {index + 1}
                        </button>
                      )
                    })}
                  </div>
                </Tab>
              )}
            </Tabs>
          </Col100>
        </LeftRightLayout>
        <div className="clear"></div>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default TopicDetail
