import React from 'react'
import { useSelector } from 'react-redux'
import Header from './header'
import LeftNavigation from './leftNavigation'

import { Route, Redirect } from 'react-router-dom'
import {
  isLoggedIn as isLoggedInFun,
  getUsersInstitutionTitle,
  getUsersInstitutionNavLogo,
} from '../../redux/reducer/account.js'

export const PrivateLayout = ({ children, ...rest }) => {
  const isLoggedIn = useSelector(isLoggedInFun)
  //const username = useSelector(getUsername)
  const institutionTitle = useSelector(getUsersInstitutionTitle)
  const InstitutionNavLogo = useSelector(getUsersInstitutionNavLogo)
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isLoggedIn ? (
          <div className="dashboard">
            <LeftNavigation />
            <div className="header__container">
              <Header
                institutionTitle={institutionTitle}
                InstitutionNavLogo={InstitutionNavLogo}
              />
              <div className="clear"></div>
            </div>
            <div className="main__container">
              {children}
              <div className="clear"></div>
            </div>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }}
    />
  )
}
