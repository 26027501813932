import React from 'react'

export const ProfileCard = (props) => {
  return (
    <div className="profileCard__container">
      <div className="profileCard__header">
        <h3>{props.title}</h3>
      </div>
      <div className="profileCard__content">{props.children}</div>
    </div>
  )
}

export const ProfileItem = (props) => {
  return (
    <div className="profileItem__container">
      <p>{props.title}</p>
      <div className="profileItem__value__container">
        <img src={props.img} alt="CWX" />
        <h5>{props.value}</h5>
      </div>
    </div>
  )
}

export const ProfileImg = (props) => {
  return (
    <div className="profileImg">
      <div className="profileImg__container">
        <img src={props.img} alt="CWX" />
      </div>
      <h3>{props.name}</h3>
      <img className="institutionImg" src={props.institutionImg} alt="CWX" />
      <p>{props.institutionName}</p>
    </div>
  )
}
