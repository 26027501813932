import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getEntityType } from '../../../redux/reducer/account'

const DoubtsCard = (props) => {
  const [optionState, setoptionState] = useState(true)

  const entityType = useSelector(getEntityType)

  const onToggleOptions = () => {
    setoptionState(!optionState)
  }
  return (
    <div className="doubtsCard">
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 doubtsCard__profile">
          <img src={props.picture} alt="profile" />
          <p className="doubtsCard__PersonName">{props.name}</p>
        </div>
        {!props?.responses && entityType !== 'faculties' ? (
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 dotsContainer">
            <ul
              onClick={() => onToggleOptions()}
              className="doubtsCard__dotsContainer"
            >
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <ul
              className={
                'options__container ' + (optionState ? '' : 'collapse')
              }
            >
              <li
                className="edit"
                onClick={() => {
                  onToggleOptions()
                  props.editOnClick()
                }}
              >
                Edit
              </li>
              <li
                className="delete"
                onClick={() => {
                  onToggleOptions()
                  props.deleteOnClick()
                }}
              >
                Delete
              </li>
            </ul>
          </div>
        ) : null}
        <div className="clear"></div>
      </div>
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
          <p className="doubtsCard__subject">
            {props.subject}
            <span>{props.chapter}</span>
          </p>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">
          <p className="doubtsCard__time">{props.time}</p>
        </div>
        <div className="clear"></div>
      </div>

      <div className="row">
        <div className="col-sm-12 doubtsCard__content">
          <p>{props.content}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 doubtsCard__seeReply">
          <button onClick={props.replyButton} className="button--white">
            {props.buttonText}
          </button>
        </div>
      </div>
      <div className="clear"></div>
    </div>
  )
}

export default DoubtsCard
