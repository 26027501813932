import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { Link } from 'react-router-dom'
// import _ from 'underscore'

//assests
import Loader from '../assets/molecules/loader'
import Button from '../assets/molecules/button'
import LeftRightLayout from '../assets/layout/leftrightlayout'
import Col75Right from '../assets/layout/leftrightlayout/column75Right'
import Col25Left from '../assets/layout/leftrightlayout/column25Left'
import {
  ProfileItem,
  ProfileCard,
  ProfileImg,
} from '../assets/molecules/profile'
import Popup from '../assets/layout/popupConfirm'

//images
import Password from '../../assets/images/password.png'
import UserIcon from '../../assets/images/profile/userid.svg'
import EmailIcon from '../../assets/images/profile/email.svg'
import ContactNumberIcon from '../../assets/images/profile/contactnumber.svg'
import AgeIcon from '../../assets/images/profile/age.svg'
import RollNumberIcon from '../../assets/images/profile/rollnumber.svg'

// import DobIcon from '../../assets/images/profile/dob.svg'
// import FatherIcon from '../../assets/images/profile/father.svg'
// import MotherIcon from '../../assets/images/profile/mother.svg'
// import ClassesIcon from '../../assets/images/profile/classes.svg'
// import AdmissionNumberIcon from '../../assets/images/profile/admissionnumber.svg'
// import AddressIcon from '../../assets/images/profile/address.svg'

//action
import { fetchStudent } from '../../redux/action/students'
import { startGetProfileInfo } from '../../redux/action/profile'
import { postChangePassword } from '../../redux/action/account'
import {
  getProfileInfo,
  getIsLoadingProfile,
} from '../../redux/reducer/profile'

//reducer
import {
  getJwt,
  getEntityId,
  getUsersInstitutionTitle,
  getUsersInstitutionNavLogo,
  getUsername,
  getMessage,
  isLoading as getisLoadingAccount,
} from '../../redux/reducer/account'

//helpers
import { usePrevious } from '../helpers'

const MyProfile = () => {
  const dispatch = useDispatch()

  //state
  const [popClassName, setPopClassName] = useState('hidePop')
  const [oldPassword, setoldPassword] = useState('')
  const [newPassword, setnewPassword] = useState('')
  const [reEnterPassword, setreEnterPassword] = useState('')
  const [error, setError] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  //selector
  const jwt = useSelector(getJwt)
  const entityId = useSelector(getEntityId)
  const intitutionTitle = useSelector(getUsersInstitutionTitle)
  const usersInstitutionNavLogo = useSelector(getUsersInstitutionNavLogo)
  const username = useSelector(getUsername)
  const message = useSelector(getMessage)
  const isLoadingAccount = useSelector(getisLoadingAccount)
  const isLoadingAccountPrev = usePrevious(isLoadingAccount)
  const isLoading = useSelector(getIsLoadingProfile)
  const profileInfo = useSelector(getProfileInfo)

  useEffect(() => {
    dispatch(startGetProfileInfo({ jwt }))
    dispatch(fetchStudent({ jwt, studentId: entityId }))
  }, [dispatch, entityId, jwt])

  useEffect(() => {
    if (!isLoadingAccount && isLoadingAccountPrev !== undefined && message) {
      setError('Please check the Old Password.')
    }
  }, [isLoadingAccount, isLoadingAccountPrev, message])

  const openPopup = (note) => {
    setPopClassName('showPop')
  }

  const closePopup = () => {
    setoldPassword('')
    setnewPassword('')
    setreEnterPassword('')
    setError('')
    setShowPassword(false)
    setPopClassName('hidePop')
  }

  const onsubmit = () => {
    if (oldPassword && newPassword && reEnterPassword) {
      if (newPassword === reEnterPassword) {
        const payload = {
          old_password: oldPassword,
          new_password: newPassword,
        }
        dispatch(postChangePassword({ jwt, payload }))
      } else {
        setError('The new passwords do not match.')
      }
    } else {
      setError('Enter Required fields.')
    }
  }

  return isLoading ? (
    <Loader />
  ) : (
    <div className="tabs__container tabs--student">
      <section>
        <h1>Profile</h1>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col25Left className="padding--0">
            <h2 className="subjects--head">Personal Information</h2>
            <ProfileImg
              img={profileInfo?.avatar || UserIcon}
              name={profileInfo?.name}
              institutionImg={usersInstitutionNavLogo}
              institutionName={intitutionTitle}
            />
            <ProfileItem title="User ID" value={username} img={UserIcon} />
            <ProfileItem
              title="Gender"
              value={profileInfo?.gender}
              img={UserIcon}
            />
            <ProfileItem
              title="PRN"
              value={profileInfo?.prn}
              img={RollNumberIcon}
            />
            <ProfileItem
              title="Admission Year"
              value={profileInfo?.admission_year}
              img={AgeIcon}
            />
            {/* <ProfileItem
              title="Date of Birth"
              value="02 October 2006"
              img={DobIcon}
            />
            <ProfileItem
              title="Fathers Name"
              value="Rakesh Rohra"
              img={FatherIcon}
            />
            <ProfileItem
              title="Mothers Name"
              value="Sakina Rohra"
              img={MotherIcon}
            /> */}
            <Button
              color="link"
              click={() => {
                openPopup()
              }}
              text="CHANGE PASSWORD"
              className="changePasswordButton"
            />
          </Col25Left>
          <Col75Right>
            <h2>Other Information</h2>
            {/* <ProfileCard title="Class information"> */}
            {/* <ProfileItem
                title="Current Class"
                value="Second A"
                img={ClassesIcon}
              />
              <ProfileItem
                title="Roll Number"
                value="33"
                img={RollNumberIcon}
              />
              <ProfileItem
                title="Admission number"
                value="148890"
                img={AdmissionNumberIcon}
              /> */}
            {/* <ProfileItem title="User ID" value={username} img={UserIcon} />
            </ProfileCard> */}
            <ProfileCard title="Contact Details">
              <ProfileItem
                title="Email Address"
                value={
                  profileInfo?.email?.length
                    ? profileInfo?.email?.join(', ')
                    : '-'
                }
                img={EmailIcon}
              />
              <ProfileItem
                title="Contact Number"
                value={
                  profileInfo?.mobile?.length
                    ? profileInfo?.mobile?.join(', ')
                    : '-'
                }
                img={ContactNumberIcon}
              />
              {/* <ProfileItem
                  title="Address"
                  value="JN-2/70/B-8/Gulmohar Aptms Sector-9/Vashi/Navi Mumbai  400703 Maharashtra, India"
                  img={AddressIcon}
                /> */}
            </ProfileCard>
          </Col75Right>
        </LeftRightLayout>
        <Popup
          className={popClassName}
          close={() => closePopup()}
          onClick={() => onsubmit()}
          buttonText="submit"
          popupHead="Change Password"
        >
          <div className="changePassword__wrap">
            <img src={Password} alt="password" />
            <input
              placeholder="Old Password"
              type={showPassword ? 'text' : 'password'}
              name="oldpassword"
              value={oldPassword}
              onChange={(e) => setoldPassword(e.target.value)}
            />
            <div className="clear"></div>
          </div>
          <div className="changePassword__wrap">
            <img src={Password} alt="password" />
            <input
              placeholder="New Password"
              type={showPassword ? 'text' : 'password'}
              name="newpassword"
              value={newPassword}
              onChange={(e) => setnewPassword(e.target.value)}
            />
            <div className="clear"></div>
          </div>
          <div className="changePassword__wrap">
            <img src={Password} alt="password" />
            <input
              placeholder="Re-Enter New Password"
              type={showPassword ? 'text' : 'password'}
              name="reenterpassword"
              value={reEnterPassword}
              onChange={(e) => setreEnterPassword(e.target.value)}
            />
            <div className="clear"></div>
          </div>
          <button onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? 'Hide Password' : 'Show Password'}
          </button>
          <div className={error ? 'alert alert-danger' : ''}>{error}</div>
        </Popup>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default MyProfile
