import {
  APP_LOGOUT,
  GET_ASSESSMENT_START,
  GET_ASSESSMENT_SUCCESS,
  GET_ASSESSMENT_FAILURE,
  GET_ASSESSMENTS_START,
  GET_ASSESSMENTS_SUCCESS,
  GET_ASSESSMENTS_LONG_POLLING_SUCCESS,
  GET_ASSESSMENTS_FAILURE,
  GET_ASSESSMENT_QUESTIONS_BY_ID_START,
  GET_ASSESSMENT_QUESTIONS_BY_ID_SUCCESS,
  GET_ASSESSMENT_QUESTIONS_BY_ID_FAILURE,
  POST_ASSESSMENT_SUBMISSION_START,
  POST_ASSESSMENT_SUBMISSION_SUCCESS,
  POST_ASSESSMENT_SUBMISSION_FAILURE,
  GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_START,
  GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_SUCCESS,
  GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_FAILURE,
  POST_ASSESSMENT_EVALUATE_START,
  POST_ASSESSMENT_EVALUATE_SUCCESS,
  POST_ASSESSMENT_EVALUATE_FAILURE,
  GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_START,
  GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_SUCCESS,
  GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_FAILURE,
  POST_SELF_ASSESSMENT_SUBMISSION_START,
  POST_SELF_ASSESSMENT_SUBMISSION_SUCCESS,
  POST_SELF_ASSESSMENT_SUBMISSION_FAILURE,
  GET_SELF_ASSESSMENTS_BY_USER_START,
  GET_SELF_ASSESSMENTS_BY_USER_SUCCESS,
  GET_SELF_ASSESSMENTS_BY_USER_FAILURE,
  UNSET_ASSESSMENT_SUBMISSIONS,
  POST_ASSESSMENT_START,
  POST_ASSESSMENT_SUCCESS,
  POST_ASSESSMENT_FAILURE,
  POST_ASSESSMENT_CSV_URL_START,
  POST_ASSESSMENT_CSV_URL_SUCCESS,
  POST_ASSESSMENT_CSV_URL_FAILURE,
  PATCH_ASSESSMENT_START,
  PATCH_ASSESSMENT_SUCCESS,
  PATCH_ASSESSMENT_FAILURE,
  GET_ASSESSMENT_BY_ID_MARKS_START,
  GET_ASSESSMENT_BY_ID_MARKS_SUCCESS,
  GET_ASSESSMENT_BY_ID_MARKS_FAILURE,
  GET_ASSESSMENT_QUESTIONS_START,
  GET_ASSESSMENT_QUESTIONS_SUCCESS,
  GET_ASSESSMENT_QUESTIONS_FAILURE,
  PATCH_ASSESSMENT_STATUS_START,
  PATCH_ASSESSMENT_STATUS_SUCCESS,
  PATCH_ASSESSMENT_STATUS_FAILURE,
} from '../actions'

import _ from 'underscore'

let initialState = {
  error: false,
  assessment: {},
  assessments: [],
  assessmentQuestions: [],
  assessmentMarks: [],
  isAssessmentLoading: false,
  isAssessmentsLoading: false,
  isAssessmentSubmissionLoading: false,
  isAssessmentEvaluateLoading: false,
  isAssessmentMarksLoading: false,
  assessmentSubmissions: [],
  selfAssessmentSubmittedData: '',
  selfAssessments: [],
}

export const assessment = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_ASSESSMENT_START:
      return {
        ...state,
        isAssessmentLoading: true,
        error: false,
      }
    case GET_ASSESSMENT_SUCCESS:
      return {
        ...state,
        isAssessmentLoading: false,
        assessment: action.assessment,
        error: false,
      }
    case GET_ASSESSMENT_FAILURE:
      return {
        ...state,
        isAssessmentLoading: false,
        error: true,
      }
    case GET_ASSESSMENTS_START:
      return {
        ...state,
        error: false,
        isAssessmentsLoading: true,
      }
    case GET_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: false,
        assessments: action.assessments,
      }
    case GET_ASSESSMENTS_LONG_POLLING_SUCCESS:
      return {
        ...state,
        error: false,
        assessments: action.assessments,
      }
    case GET_ASSESSMENTS_FAILURE:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: true,
      }
    case GET_ASSESSMENT_QUESTIONS_BY_ID_START:
      return {
        ...state,
        error: false,
        isAssessmentsLoading: true,
      }
    case GET_ASSESSMENT_QUESTIONS_BY_ID_SUCCESS:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: false,
        assessmentQuestions: action.assessmentQuestions,
      }
    case GET_ASSESSMENT_QUESTIONS_BY_ID_FAILURE:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: true,
        assessmentQuestions: [],
      }
    case POST_ASSESSMENT_SUBMISSION_START:
      return {
        ...state,
        error: false,
        isAssessmentSubmissionLoading: true,
      }
    case POST_ASSESSMENT_SUBMISSION_SUCCESS:
      return {
        ...state,
        isAssessmentSubmissionLoading: false,
        error: false,
      }
    case POST_ASSESSMENT_SUBMISSION_FAILURE:
      return {
        ...state,
        isAssessmentSubmissionLoading: false,
        error: true,
      }
    case GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_START:
      return {
        ...state,
        error: false,
        isAssessmentSubmissionLoading: true,
        assessmentSubmissions: [],
      }
    case GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_SUCCESS:
      return {
        ...state,
        isAssessmentSubmissionLoading: false,
        error: false,
        assessmentSubmissions: action.assessment_submissions,
      }
    case GET_ASSESSMENT_SUBMISSION_BY_A_ID_AND_STU_ID_FAILURE:
      return {
        ...state,
        isAssessmentSubmissionLoading: false,
        error: true,
      }
    case UNSET_ASSESSMENT_SUBMISSIONS:
      return {
        ...state,
        assessmentSubmissions: [],
      }
    case POST_ASSESSMENT_EVALUATE_START:
      return {
        ...state,
        error: false,
        isAssessmentEvaluateLoading: true,
      }
    case POST_ASSESSMENT_EVALUATE_SUCCESS:
      return {
        ...state,
        isAssessmentEvaluateLoading: false,
        error: false,
      }
    case POST_ASSESSMENT_EVALUATE_FAILURE:
      return {
        ...state,
        isAssessmentEvaluateLoading: false,
      }
    case GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_START:
      return {
        ...state,
        error: false,
        isAssessmentsLoading: true,
        selfAssessmentSubmittedData: '',
      }
    case GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_SUCCESS:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: false,
        assessmentQuestions: action.assessmentQuestions,
      }
    case GET_ASSESSMENT_QUESTIONS_BY_TOPIC_ID_FAILURE:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: true,
        assessmentQuestions: [],
      }
    case POST_SELF_ASSESSMENT_SUBMISSION_START:
      return {
        ...state,
        error: false,
        isAssessmentSubmissionLoading: true,
      }
    case POST_SELF_ASSESSMENT_SUBMISSION_SUCCESS:
      return {
        ...state,
        isAssessmentSubmissionLoading: false,
        error: false,
        selfAssessmentSubmittedData: action.self_assessment,
      }
    case POST_SELF_ASSESSMENT_SUBMISSION_FAILURE:
      return {
        ...state,
        isAssessmentSubmissionLoading: false,
        error: true,
      }
    // case GET_SELF_ASSESSMENTS_BY_USER_START:
    //   return {
    //     ...state,
    //     error: false,
    //     isAssessmentSubmissionLoading: true,
    //   }
    case GET_SELF_ASSESSMENTS_BY_USER_SUCCESS:
      return {
        ...state,
        // isAssessmentSubmissionLoading: false,
        error: false,
        selfAssessments: action.selfAssessments,
      }
    // case GET_SELF_ASSESSMENTS_BY_USER_FAILURE:
    //   return {
    //     ...state,
    //     isAssessmentSubmissionLoading: false,
    //     error: true,
    //   }
    case POST_ASSESSMENT_START:
      return {
        ...state,
        isAssessmentsLoading: true,
        error: false,
      }
    case POST_ASSESSMENT_SUCCESS:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: false,
      }
    case POST_ASSESSMENT_FAILURE:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: true,
      }
    case POST_ASSESSMENT_CSV_URL_START:
      return {
        ...state,
        isAssessmentsLoading: true,
        error: false,
      }
    case POST_ASSESSMENT_CSV_URL_SUCCESS:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: false,
      }
    case POST_ASSESSMENT_CSV_URL_FAILURE:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: true,
      }
    case PATCH_ASSESSMENT_START:
      return {
        ...state,
        isAssessmentsLoading: true,
        error: false,
      }
    case PATCH_ASSESSMENT_SUCCESS:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: false,
      }
    case PATCH_ASSESSMENT_FAILURE:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: true,
      }
    case GET_ASSESSMENT_BY_ID_MARKS_START:
      return {
        ...state,
        error: false,
        isAssessmentMarksLoading: true,
      }
    case GET_ASSESSMENT_BY_ID_MARKS_SUCCESS:
      return {
        ...state,
        isAssessmentMarksLoading: false,
        error: false,
        assessmentMarks: action.assessment_marks,
      }
    case GET_ASSESSMENT_BY_ID_MARKS_FAILURE:
      return {
        ...state,
        isAssessmentMarksLoading: false,
        error: true,
      }
    case GET_ASSESSMENT_QUESTIONS_START:
      return {
        ...state,
        error: false,
        isAssessmentsLoading: true,
      }
    case GET_ASSESSMENT_QUESTIONS_SUCCESS:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: false,
        assessmentQuestions: action.assessmentQuestions,
      }
    case GET_ASSESSMENT_QUESTIONS_FAILURE:
      return {
        ...state,
        isAssessmentsLoading: false,
        error: true,
        assessmentQuestions: [],
      }
    case PATCH_ASSESSMENT_STATUS_START:
      return {
        ...state,
        isAssessmentLoading: true,
        error: false,
      }
    case PATCH_ASSESSMENT_STATUS_SUCCESS:
      return {
        ...state,
        isAssessmentLoading: false,
        error: false,
      }
    case PATCH_ASSESSMENT_STATUS_FAILURE:
      return {
        ...state,
        isAssessmentLoading: false,
        error: true,
      }
    default:
      return state
  }
}

export const getAssessment = (state) => state.assessment.assessment
export const getAssessments = (state) => state.assessment.assessments
export const getAssessmentsGroupByBatchSubjectId = (state) =>
  _.groupBy(state.assessment.assessments, 'batch_subject_id')
export const getIsAssessmentsLoading = (state) =>
  state.assessment.isAssessmentsLoading
export const getIsAssessmentLoading = (state) =>
  state.assessment.isAssessmentLoading
export const getAssessmentQuestions = (state) => {
  return state.assessment?.assessmentQuestions
}
export const getIsAssessmentSubmissionLoading = (state) =>
  state.assessment.isAssessmentSubmissionLoading

export const getIsAssessmentEvaluateLoading = (state) =>
  state.assessment.isAssessmentEvaluateLoading
export const getAssessmentSubmissions = (state) =>
  state.assessment.assessmentSubmissions

export const getSelfAssessmentSubmittedData = (state) =>
  state.assessment.selfAssessmentSubmittedData

export const getIsError = (state) => state.assessment.error

export const getSelfAssessments = (state) => state.assessment.selfAssessments
export const getISAssessmentMarksLoading = (state) =>
  state.assessment.isAssessmentMarksLoading
export const getAssessmentMarks = (state) => state.assessment.assessmentMarks
