import React from 'react'

import Loader from '../../assets/molecules/loader'

const PopupConfirm = (props) => {
  return (
    <div className={'popup__container ' + props.className}>
      <div className="popup__box">
        <div className="popup__wrap">
          <div className="popup__head">
            <h3>{props.popupHead}</h3>

            <span onClick={props.close} className="close__popup">
              x
            </span>
            <div className="clear"></div>
          </div>
          <div className="popup__content">
            {props.children}
            <div className="popup__buttonWrapper">
              <button className="button--transparent" onClick={props.close}>
                {props?.cancelButtonText ? props?.cancelButtonText : 'CANCEL'}
              </button>
              {props.buttonText &&
                (props?.isloading ? (
                  <Loader />
                ) : (
                  <button className="button--blue" onClick={props.onClick}>
                    {props.buttonText}
                  </button>
                ))}
            </div>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  )
}

export default PopupConfirm
