import React from 'react'
import Select from './select'

const CustomTab = (props) => {
  return (
    <div className="customTab">
      <div className="custonmTab__tabItems">
        {props?.tabItems &&
          Object.keys(props?.tabItems)?.map((key, index) => {
            return (
              <div key={index} onClick={() => props?.onClickTab(key)}>
                <p
                  className={
                    props?.selectedTab === key ? 'tabItem--active' : 'tabItem'
                  }
                >
                  {key + ' (' + props?.tabItems[key] + ')'}
                </p>
              </div>
            )
          })}
      </div>
      <div className="customTab__dropdowns">
        <Select
          onChange={(e) => {
            const selected = props?.subjects?.filter(
              (item) => item?._id === e.target.value
            )
            return props?.onChangeSubjects(selected[0])
          }}
          value={props?.selectedSubject}
        >
          {props?.subjects?.map((subject, index) => {
            return (
              <option value={subject._id} key={index}>
                {subject.subject_title}
              </option>
            )
          })}
        </Select>
        <Select
          onChange={(e) => {
            const selected = props?.batches?.filter(
              (item) => item?._id === e.target.value
            )
            return props?.onChangeBatches(selected[0])
          }}
          value={props?.selectedBatch}
        >
          {props?.batches?.map((batche, index) => {
            return (
              <option value={batche._id} key={index}>
                {batche.title}
              </option>
            )
          })}
        </Select>
      </div>
    </div>
  )
}

export default CustomTab
