import React from 'react'

const Col25Left = (props) => {
  return (
    <div className={'col-lg-4 leftLayoutSmall ' + props.className}>
      {props.children}
      <div className="clear"></div>
    </div>
  )
}

export default Col25Left
