import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { usePrevious } from '../helpers'

//assets
import Card from '../assets/molecules/card'
import Button from '../assets/molecules/button'

//actions
import { postFaculty } from '../../redux/action/faculty'

//reducer
//account
import { getJwt } from '../../redux/reducer/account'
import { getIsFacultiesLoading, getIsError } from '../../redux/reducer/faculty'

import Loader from '../assets/molecules/loader'

const Create = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  //selector
  const jwt = useSelector(getJwt)
  const error = useSelector(getIsError)
  const isLoading = useSelector(getIsFacultiesLoading)
  const isLoadingPrev = usePrevious(isLoading)

  let [formData, setFormData] = useState({})
  let [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (error) {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }, [error])

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isLoading &&
      !isLoading &&
      !error
    ) {
      history.push({
        pathname: '/assign/faculty',
        state: { status: 'success' },
      })
    }
  }, [error, history, isLoading, isLoadingPrev])

  const onChange = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (
      formData?.name &&
      formData?.gender &&
      formData?.email &&
      formData?.mobile &&
      formData?.prn &&
      formData?.designation &&
      formData?.avatar
    ) {
      const payload = {
        name: formData.name,
        gender: formData?.gender,
        email: [formData.email],
        mobile: [formData.mobile],
        prn: formData.prn,
        designation: formData.designation,
        avatar: formData.avatar,
      }
      dispatch(postFaculty({ payload, jwt }))
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  return (
    <div className="tabs__container">
      <section>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Add Faculty</li>
          </ol>
        </nav>
      </section>

      <section>
        <h4>Add Faculty</h4>
        <Card columnNo="1" title="Create Faculty" titleColor={1}>
          <input
            name="name"
            value={formData.name}
            type="text"
            placeholder="Name"
            onChange={onChange}
          />
          <select name="gender" onChange={onChange}>
            <option>Please select gender</option>
            <option value="male">male</option>
            <option value="female">female</option>
          </select>
          <input
            name="email"
            value={formData.email}
            type="email"
            placeholder="Email"
            onChange={onChange}
          />
          <input
            name="mobile"
            value={formData.mobile}
            placeholder="Mobile Number"
            onChange={onChange}
          />
          <input
            name="prn"
            value={formData.prn}
            type="text"
            placeholder="PRN"
            onChange={onChange}
          />
          <input
            name="designation"
            value={formData.designation}
            type="text"
            placeholder="Designation"
            onChange={onChange}
          />
          <input
            name="avatar"
            value={formData.avatar}
            type="text"
            placeholder="Avatar"
            onChange={onChange}
          />
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              className="button--small"
              color="blue"
              text="Add"
              click={(e) => onSubmit(e)}
            />
          )}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </Card>
      </section>
    </div>
  )
}

export default Create
