import {
  APP_LOGOUT,
  ADD_NOTES_START,
  ADD_NOTES_SUCCESS,
  ADD_NOTES_FAILURE,
  GET_NOTES_BY_TOPIC_ID_START,
  GET_NOTES_BY_TOPIC_ID_SUCCESS,
  GET_NOTES_BY_TOPIC_ID_FAILURE,
  EDIT_NOTES_START,
  EDIT_NOTES_SUCCESS,
  EDIT_NOTES_FAILURE,
  DELETE_NOTES_START,
  DELETE_NOTES_SUCCESS,
  DELETE_NOTES_FAILURE,
  GET_NOTES_BY_BATCH_SUBJECT_ID_START,
  GET_NOTES_BY_BATCH_SUBJECT_ID_SUCCESS,
  GET_NOTES_BY_BATCH_SUBJECT_ID_FAILURE,
  DELETE_NOTE_START,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAILURE,
  GET_NOTES_BY_SESSION_ID_START,
  GET_NOTES_BY_SESSION_ID_SUCCESS,
  // GET_NOTES_BY_SESSION_ID_FAILURE,
} from '../actions'

let initialState = {
  error: false,
  isNotesLoading: false,
  isNotesDeleting: false,
  isEditNotesLoading: false,
  notes: [],
  note: '',
  noteId: '',
}

export const note = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_NOTES_BY_TOPIC_ID_START:
      return {
        ...state,
        error: false,
        isNotesLoading: true,
      }
    case GET_NOTES_BY_TOPIC_ID_SUCCESS:
      return {
        ...state,
        isNotesLoading: false,
        error: false,
        notes: action.notes,
      }
    case GET_NOTES_BY_TOPIC_ID_FAILURE:
      return {
        ...state,
        isNotesLoading: false,
        error: true,
      }
    case ADD_NOTES_START:
      return {
        ...state,
        error: false,
        isNotesLoading: true,
      }
    case ADD_NOTES_SUCCESS:
      return {
        ...state,
        isNotesLoading: false,
        error: false,
        note: action?.note,
        noteId: action?.noteId,
      }
    case ADD_NOTES_FAILURE:
      return {
        ...state,
        isNotesLoading: false,
        error: true,
      }
    case EDIT_NOTES_START:
      return {
        ...state,
        error: false,
        isEditNotesLoading: true,
      }
    case EDIT_NOTES_SUCCESS:
      return {
        ...state,
        isEditNotesLoading: false,
        error: false,
        notes: action.notes,
      }
    case EDIT_NOTES_FAILURE:
      return {
        ...state,
        isEditNotesLoading: false,
        error: true,
      }
    case DELETE_NOTES_START:
      return {
        ...state,
        error: false,
        isNotesDeleting: true,
      }
    case DELETE_NOTES_SUCCESS:
      return {
        ...state,
        isNotesDeleting: false,
        error: false,
      }
    case DELETE_NOTES_FAILURE:
      return {
        ...state,
        isNotesDeleting: false,
        error: true,
      }
    case GET_NOTES_BY_BATCH_SUBJECT_ID_START:
      return {
        ...state,
        error: false,
        isNotesLoading: true,
      }
    case GET_NOTES_BY_BATCH_SUBJECT_ID_SUCCESS:
      return {
        ...state,
        isNotesLoading: false,
        error: false,
        notes: action.notes,
      }
    case GET_NOTES_BY_BATCH_SUBJECT_ID_FAILURE:
      return {
        ...state,
        isNotesLoading: false,
        error: true,
      }
    case DELETE_NOTE_START:
      return {
        ...state,
        error: false,
        isNotesLoading: true,
      }
    case DELETE_NOTE_SUCCESS:
      return {
        ...state,
        isNotesLoading: false,
        error: false,
        notes: action.notes,
      }
    case DELETE_NOTE_FAILURE:
      return {
        ...state,
        isNotesLoading: false,
        error: true,
      }
    case GET_NOTES_BY_SESSION_ID_START:
      return {
        ...state,
        error: false,
        isNotesLoading: true,
      }
    case GET_NOTES_BY_SESSION_ID_SUCCESS:
      return {
        ...state,
        isNotesLoading: false,
        error: false,
        notes: action.notes,
      }
    default:
      return state
  }
}

export const getNoteByTopicId = (state) => state.note.note
export const getNotes = (state) => state.note.notes
export const getIsNotesLoading = (state) => state.note.isNotesLoading
export const getIsNotesDeleting = (state) => state.note.isNotesDeleting
export const getIsEditNotesLoading = (state) => state.note.isEditNotesLoading

export const getIsError = (state) => state.note.error
