import React, { useEffect, useState, useRef, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import _ from 'underscore'
import moment from 'moment'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

//assets
import LeftRightLayout from '../../assets/layout/leftrightlayout'
import Col100 from '../../assets/layout/leftrightlayout/column100'
import Loader from '../../assets/molecules/loader'
import Select from '../../assets/molecules/select'
import { AlertSuccess } from '../../assets/molecules/alerts'
import TopicsCard from '../../assets/molecules/topicsCardWithOptions'
import Popup from '../../assets/layout/popupConfirm'
// import AddItems from "../../assets/molecules/addItems";
import NoPending from '../../../assets/images/no-pending.png'
// import AddTopics from "../../../assets/images/addChapter.png";
import PopupForm from '../../assets/layout/popupForm'

import { subjectPics, usePrevious } from '../../helpers'

//action
//topic
import {
  fetchAddTopic,
  fetchEditTopic,
  fetchTopicsByChapterId,
  fetchDeleteTopic,
} from '../../../redux/action/topic'
import { getFaculties } from '../../../redux/action/faculty'
import { fetchStudentStudyPlanByChapterBatchSubject } from '../../../redux/action/studyplan'
import { startVideoUpload } from '../../../redux/action/vimeoUpload'
import { startFileUpload } from '../../../redux/action/upload'
//ui
import { setChapter, setTopic } from '../../../redux/action/ui'
//reducer
//subject(chapters also implemented in subject)
import { getSubjects } from '../../../redux/reducer/subject'

import {
  getIsTopicsLoading,
  getTopics,
  getIsError,
  getStatus,
} from '../../../redux/reducer/topic'

import { getChapters } from '../../../redux/reducer/chapter'
import { getAllFaculties } from '../../../redux/reducer/faculty'
import {
  getVimeoLink,
  getIsLoadingVimeoFile,
  getIsError as getIsVimeoError,
} from '../../../redux/reducer/vimeoUpload'
//account
import { getJwt, getUserId, getUsername } from '../../../redux/reducer/account'

import {
  getSubjectId,
  getBatch,
  getChapterId,
  getBatchSubjectId,
} from '../../../redux/reducer/ui'

import { getBatches } from '../../../redux/reducer/batches'
import {
  getStudyPlanByChapterIdBatchSubId,
  getIsLoadingStudyPlanByChapterIdBatchSubId,
} from '../../../redux/reducer/studyplan'
import {
  getIsError as getFileUploadError,
  getFile,
  getIsLoadingUploadFile,
} from '../../../redux/reducer/upload'

const Topic = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const notesRef = useRef([])
  const videoRef = useRef()
  const arrLength = 1
  //selectors
  //account
  const jwt = useSelector((state) => getJwt(state))
  //ui
  const subjectId = useSelector((state) => getSubjectId(state))
  const chapterId = useSelector((state) => getChapterId(state))
  let batch = useSelector((state) => getBatch(state))

  //subject(topics)
  const subjects = useSelector((state) => getSubjects(state))
  const chapters = useSelector((state) => getChapters(state))
  const topics = useSelector((state) => getTopics(state))
  const topicStatus = useSelector((state) => getStatus(state))
  const isLoading = useSelector((state) => getIsTopicsLoading(state))
  const isLoadingPrev = usePrevious(isLoading)
  let batches = useSelector((state) => getBatches(state))
  const allFaculties = useSelector(getAllFaculties)
  const error = useSelector(getIsError)
  const vimeoLink = useSelector(getVimeoLink)
  const isLoadingVimeo = useSelector((state) => getIsLoadingVimeoFile(state))
  const isLoadingVimeoPrev = usePrevious(isLoadingVimeo)
  const vimeoError = useSelector(getIsVimeoError)
  const selectedBatchSubjectId = useSelector(getBatchSubjectId)
  const isLoadingStudyPlanByChapterIdBatchSubId = useSelector(
    getIsLoadingStudyPlanByChapterIdBatchSubId
  )
  const isLoadingStudyPlanByChapterIdBatchSubIdPrev = usePrevious(
    isLoadingStudyPlanByChapterIdBatchSubId
  )
  const studyPlanByChapterIdBatchSubId = useSelector(
    getStudyPlanByChapterIdBatchSubId
  )
  const uploadedFile = useSelector(getFile)
  const isLoadingUploadFile = useSelector(getIsLoadingUploadFile)
  const isLoadingUploadFilePrev = usePrevious(isLoadingUploadFile)
  const fileUploadError = useSelector(getFileUploadError)

  const [popClassName, setPopClassName] = useState('hidePop')
  const [deleteTopicId, setDeletetopicId] = useState('')

  const [formData, setFormData] = useState({})
  const [editTopicPopClassName, setEditTopicPopClassName] = useState('hidePop')
  const [selectedTopic, setSelectedTopic] = useState('')
  const [selectedFaculty, setSelectedFaculty] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [popup, setPopup] = useState('hidePop')
  const [showSuccess, setShowSuccess] = useState(false)
  const [showEditSuccess, setShowEditSuccess] = useState(false)
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
  const [pressStatus, setPressStatus] = useState('')
  const [uploadedVideo, setUploadedVideo] = useState({})
  const [editVideo, setEditVideo] = useState(true)
  const [contentVideo, setContentVideo] = useState(false)
  const [studyPlanTopicIds, setStudyPlanTopicIds] = useState([])
  const [description, setDescription] = useState(EditorState.createEmpty())
  const [notesUrl, setNotesUrl] = useState([])
  const [videoImageUrl, setVideoImageUrl] = useState('')
  const [imageUploaded, setImageUploaded] = useState(false)
  const [noteUploaded, setNoteUploaded] = useState(false)

  const userid = useSelector(getUserId)
  const username = useSelector(getUsername)

  let [errorMessage, setErrorMessage] = useState('')
  const subjectPicKeys = Object.keys(subjectPics)

  if (notesRef.current.length !== arrLength) {
    notesRef.current = Array(arrLength)
      .fill()
      .map((_, i) => notesRef.current[i] || createRef())
  }

  useEffect(() => {
    dispatch(fetchTopicsByChapterId({ jwt, chapterId }))
    dispatch(
      fetchStudentStudyPlanByChapterBatchSubject({
        jwt,
        chapterId,
        batchSubjectId: selectedBatchSubjectId,
      })
    )
    dispatch(getFaculties({ jwt }))
  }, [dispatch, jwt, chapterId, selectedBatchSubjectId])

  useEffect(() => {
    if (isLoadingPrev !== undefined && !isLoading && !error) {
      setShowSuccess(true)
      setShowEditSuccess(true)
      setShowDeleteSuccess(true)
    }
  }, [isLoading, isLoadingPrev]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }, [error])

  useEffect(() => {
    if (notesUrl === '') {
      notesRef.current.value = ''
    }
    if (videoImageUrl === '') {
      videoRef.current.value = ''
    }
  }, [notesUrl, videoImageUrl])

  useEffect(() => {
    if (
      isLoadingStudyPlanByChapterIdBatchSubIdPrev !== undefined &&
      !isLoadingStudyPlanByChapterIdBatchSubId &&
      isLoadingStudyPlanByChapterIdBatchSubId !==
        isLoadingStudyPlanByChapterIdBatchSubIdPrev
    ) {
      setStudyPlanTopicIds(
        _.uniq(studyPlanByChapterIdBatchSubId?.map((item) => item?.topic_id))
      )
    }
  }, [
    isLoadingStudyPlanByChapterIdBatchSubId,
    isLoadingStudyPlanByChapterIdBatchSubIdPrev,
    studyPlanByChapterIdBatchSubId,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      isLoadingUploadFilePrev !== undefined &&
      isLoadingUploadFilePrev !== isLoadingUploadFile &&
      !isLoadingUploadFile &&
      !fileUploadError
    ) {
      if (imageUploaded) {
        setFormData({ ...formData, contentUrl: uploadedFile.publicUrl })
        setVideoImageUrl(uploadedFile.publicUrl)
        setImageUploaded(false)
      }
      if (noteUploaded) {
        const urls = [...notesUrl, uploadedFile?.publicUrl]
        setNotesUrl(urls)
        setNoteUploaded(false)
      }
    }
  }, [
    fileUploadError,
    formData,
    imageUploaded,
    isLoadingUploadFile,
    isLoadingUploadFilePrev,
    noteUploaded,
    notesUrl,
    uploadedFile,
    uploadedFile.publicUrl,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      isLoadingVimeoPrev !== undefined &&
      !isLoadingVimeo &&
      !vimeoError &&
      isLoadingVimeo != isLoadingVimeoPrev
    ) {
      if (!isEdit) {
        const data = {
          title: formData.title,
          type: formData.type,
          content_uri: vimeoLink,
          wysiwyg_state: draftToHtml(
            convertToRaw(description.getCurrentContent())
          ).toString(),
          chapter_id: chapterId,
          author_id: selectedFaculty.author_id,
          author_name: selectedFaculty.author_name,
          notes: notesUrl && notesUrl.length > 0 ? notesUrl : [],
        }
        dispatch(fetchAddTopic({ jwt, data }))
      } else {
        const data = {
          title: formData.title,
          type: formData.type,
          content_uri: vimeoLink,
          wysiwyg_state: draftToHtml(
            convertToRaw(description.getCurrentContent())
          ).toString(),
          chapter_id: chapterId,
          author_id: selectedFaculty.author_id,
          author_name: selectedFaculty.author_name,
          notes: notesUrl && notesUrl.length > 0 ? notesUrl : [],
        }
        dispatch(
          fetchEditTopic({
            jwt,
            topicId: selectedTopic._id,
            data,
          })
        )
      }
    }
  }, [isLoadingVimeo, isLoadingVimeoPrev]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoadingPrev !== undefined && !isLoading && !error) {
      if (topicStatus === 'added') {
        closeTopicPopup()
        setPressStatus('create')
        setFormData({})
        setSelectedFaculty({})
        setUploadedVideo({})
        setContentVideo(false)
        setErrorMessage('')
        setDescription(EditorState.createEmpty())
      } else if (topicStatus === 'edited') {
        closeTopicPopup()
        setIsEdit(false)
        setFormData({})
        setSelectedFaculty({})
        setPressStatus('edit')
        setEditVideo(true)
        setContentVideo(false)
        setErrorMessage('')
        setDescription(EditorState.createEmpty())
      } else if (topicStatus === 'deleted') {
        setPressStatus('delete')
      }
    }
  }, [error, isLoading, isLoadingPrev, topicStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeChapter = (e) => {
    e.preventDefault()
    dispatch(setChapter(e.target.value))
  }

  // const editTopic = (e, topicId) => {
  //   history.push(
  //     `/batch/${batch}/subjects/${subjectId}/chapters/${chapterId}/topics/${topicId}/edit-topic`
  //   );
  // };
  const contentTypes = ['image', 'audio', 'video']
  const showPopup = () => {
    setPopup('showPop')
    setShowSuccess(false)
    setShowEditSuccess(false)
    setPressStatus('')
    setFormData({})
    setSelectedFaculty({})
    setDescription(EditorState.createEmpty())
    setErrorMessage('')
    setNotesUrl([])
    setVideoImageUrl('')
  }

  const deletePopup = (topicId) => {
    setShowDeleteSuccess(false)
    setPopClassName('showPop')
    setPressStatus('')
    setDeletetopicId(topicId)
  }
  const closePopup = () => {
    setEditTopicPopClassName('hidePop')
    setPopClassName('hidePop')
    setPopup('hidePop')
    setErrorMessage('')
  }
  const deleteTopic = (e, topicId) => {
    e.preventDefault()
    dispatch(fetchDeleteTopic({ jwt, topicId }))
    setPopClassName('hidePop')
  }

  const closeTopicPopup = () => {
    setEditTopicPopClassName('hidePop')
    setPopup('hidePop')
    setErrorMessage('')
  }

  const onTopicSelect = (topicId, title) => {
    dispatch(setTopic(topicId, title))
    history.push('/batch/subjects/chapters/topics/topicDetail')
  }

  const openEditTopicPopup = (topic) => {
    setIsEdit(true)
    setFormData({
      title: topic.title,
      type: topic.type,
      contentUrl: topic.content_uri,
    })
    setSelectedFaculty({
      author_name: topic.author_name,
      author_id: topic.author_id,
    })
    setNotesUrl(topic?.notes)
    setEditTopicPopClassName('showPop')
    setSelectedTopic(topic)
    setShowEditSuccess(false)
    setPressStatus('')

    const blocksFromHtml = htmlToDraft(topic.wysiwyg_state)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    const editorState = EditorState.createWithContent(contentState)
    setDescription(editorState)
  }
  const closeEditTopicPopup = () => {
    setIsEdit(false)
    setPopup('hidePop')
    setEditTopicPopClassName('hidePop')
  }

  const onChange = (e) => {
    if (e.target.name === 'author_name') {
      let id = e.target.value
      let details = allFaculties.find((faculty) => {
        if (id === faculty._id) {
          return faculty
        }
      })
      setFormData({
        ...formData,
        author_id: e.target.value,
        author_name: details.name,
      })
    } else if (e.target.name === 'type') {
      videoRef.current.value = ''
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        contentUrl: '',
      })
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    errorMessage && setErrorMessage('')
  }

  const setFaculty = (e) => {
    let id = e.target.value
    let details = allFaculties.find((faculty) => {
      if (id === faculty._id) {
        return faculty
      }
    })
    setSelectedFaculty({
      ...selectedFaculty,
      author_id: e.target.value,
      author_name: details.name,
    })
  }

  const onVideoImageUpload = (e, type) => {
    if (type === 'video') {
      setEditVideo(false)
      setContentVideo(true)
      setFormData({ ...formData, contentUrl: e.target.files })
      setUploadedVideo({ ...uploadedVideo, files: e.target.files })
    }
    if (type === 'image') {
      setErrorMessage('')
      const fileData = e.target.files[0]
      const data = new FormData()
      data.append('file', fileData)
      setImageUploaded(true)
      dispatch(startFileUpload(data))
    }
  }

  const onChangeFile = (e) => {
    const fileData = e.target.files[0]
    const data = new FormData()
    data.append('file', fileData)
    setNoteUploaded(true)
    dispatch(startFileUpload(data))
  }

  const onUploadNotes = (e, i) => {
    e.preventDefault()
    notesRef.current[0].current.click()
  }

  const onDeleteFile = (index) => {
    const values = [...notesUrl]
    values.splice(index, 1)
    setNotesUrl(values)
  }

  const onSubmit = () => {
    if (
      formData?.title &&
      formData?.type &&
      formData?.contentUrl &&
      draftToHtml(convertToRaw(description.getCurrentContent())).toString() &&
      selectedFaculty?.author_name
    ) {
      if (formData?.type === 'video') {
        const payload = {
          file: uploadedVideo.files,
          name: formData.title,
        }
        dispatch(startVideoUpload(payload))
        closeTopicPopup()
      } else {
        const data = {
          title: formData.title,
          type: formData.type,
          content_uri: formData.contentUrl,
          wysiwyg_state: draftToHtml(
            convertToRaw(description.getCurrentContent())
          ).toString(),
          chapter_id: chapterId,
          author_id: selectedFaculty.author_id,
          author_name: selectedFaculty.author_name,
          notes: notesUrl && notesUrl.length > 0 ? notesUrl : [],
        }
        dispatch(fetchAddTopic({ jwt, data }))
      }
    } else {
      setErrorMessage('Please fill the form')
    }
  }
  const getSubjectNameById = (id) => {
    const filteredSubject = subjects?.filter(
      (subject) => subject.subject_id === id
    )
    return filteredSubject.length ? filteredSubject[0].subject_title : ''
  }
  const onTopicEdit = () => {
    if (
      formData?.title &&
      formData?.type &&
      formData?.contentUrl &&
      draftToHtml(convertToRaw(description.getCurrentContent())).toString() &&
      selectedFaculty?.author_name
    ) {
      if (contentVideo) {
        const payload = {
          file: uploadedVideo.files,
          name: formData.title,
        }
        dispatch(startVideoUpload(payload))
        closeTopicPopup()
      } else {
        const data = {
          title: formData.title,
          type: formData.type,
          content_uri: formData.contentUrl,
          wysiwyg_state: draftToHtml(
            convertToRaw(description.getCurrentContent())
          ).toString(),
          chapter_id: chapterId,
          author_id: selectedFaculty.author_id,
          author_name: selectedFaculty.author_name,
          notes: notesUrl && notesUrl.length > 0 ? notesUrl : [],
        }
        dispatch(
          fetchEditTopic({
            jwt,
            topicId: selectedTopic._id,
            data,
          })
        )
      }
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  const filteredList = subjects.filter(
    (subject) => subject.subject_id === subjectId
  )
  const currentSubject = filteredList.length > 0 ? filteredList[0] : {}

  const filteredListBatches = batches.filter((bat) => bat._id === batch)
  const currentBatch =
    filteredListBatches.length > 0 ? filteredListBatches[0] : {}

  const filteredListChapters = chapters.filter(
    (chapter) => chapter._id === chapterId
  )
  const currentChapter =
    filteredListChapters.length > 0 ? filteredListChapters[0] : {}

  const notAvailable = (type) => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">There is no topics available</h2>
          <p>Add topics or go to chapters </p>
          <div className="noPending__button--Wrapper">
            <Link to="/batch/subjects/chapters">
              <button className="button--blue">GO TO CHAPTERS</button>
            </Link>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="tabs__container">
      {location?.state?.status === 'success' ? (
        <AlertSuccess>You Have successfully Add a Topic</AlertSuccess>
      ) : null}

      <section>
        <div className="row">
          <div className="col-md-6 padding--0">
            <h1>Topics</h1>
          </div>
          <div className="col-md-6 padding--0">
            {/* <Link
              to={{
                pathname: `/batch/${batch}/subjects/${subjectId}/chapters/${chapterId}/create-topic`,
              }}
            > */}
            <button
              onClick={() => {
                showPopup()
              }}
              className="button--blue float-right"
            >
              + Add topics
            </button>
            {/* </Link> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 padding--0">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/dashboard">{currentBatch.title}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/batch/subjects">
                    {currentSubject.subject_title}
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/batch/subjects/chapters">
                    {currentChapter.title}
                  </Link>
                </li>

                <li className="breadcrumb-item active">topics</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 padding--0">
            <div className="customTab__dropdowns">
              <Select
                onChange={(e) => onChangeChapter(e)}
                defaultValue={chapterId}
              >
                {chapters?.map((chapter, index) => {
                  return (
                    <option
                      value={chapter._id}
                      selected={chapter._id === chapterId}
                      key={index}
                    >
                      {chapter.title}
                    </option>
                  )
                })}
              </Select>
            </div>
            <div className="clear"></div>
          </div>
        </div>
        <div className="clear"></div>
      </section>
      <LeftRightLayout>
        <Col100>
          {isLoading || isLoadingVimeo ? (
            <Loader />
          ) : chapterId ? (
            <section>
              {topics?.length
                ? topics?.map((topic, index) => {
                    const subjectName = getSubjectNameById(
                      topic.subject_id
                    ).toLowerCase()
                    const filteredKeys = subjectPicKeys.filter((key) =>
                      subjectName.includes(key)
                    )
                    const pic = subjectPics[filteredKeys[0]]
                    const filteredStudyPlan =
                      studyPlanByChapterIdBatchSubId &&
                      studyPlanByChapterIdBatchSubId?.length
                        ? studyPlanByChapterIdBatchSubId?.filter(
                            (item) => item?.topic_id === topic?._id
                          )
                        : []
                    return (
                      <TopicsCard
                        key={index}
                        img={pic}
                        heading={topic.title}
                        description={topic.wysiwyg_state}
                        button="view"
                        buttonClass="button--blue"
                        extraContent={
                          filteredStudyPlan && filteredStudyPlan?.length
                            ? `Study Plan Date : ${moment(
                                filteredStudyPlan[0]?.date
                              ).format('DD-MM-YYYY')}`
                            : ''
                        }
                        studyPlan={studyPlanTopicIds.includes(topic?._id)}
                        onClick={() => onTopicSelect(topic._id, topic.title)}
                        editOnClick={() => {
                          openEditTopicPopup(topic)
                        }}
                        deleteOnClick={(e) => deletePopup(topic._id)}
                      />
                    )
                  })
                : notAvailable()}
              <div className="clear"></div>
            </section>
          ) : (
            <div> Please Select a Chapters </div>
          )}
        </Col100>
      </LeftRightLayout>
      <Popup
        popupHead="Delete Topic"
        className={popClassName}
        close={() => closePopup()}
        onClick={(e) => deleteTopic(e, deleteTopicId)}
        buttonText="Yes"
      >
        <h2>Are you sure want to delete?</h2>
      </Popup>
      <PopupForm
        className={!isEdit ? popup : editTopicPopClassName}
        close={() => {
          closePopup()
          closeEditTopicPopup()
        }}
        onClickButton={!isEdit ? onSubmit : onTopicEdit}
        buttonText={isEdit ? 'EDIT TOPIC' : 'CREATE TOPIC'}
        popupHead={isEdit ? 'Edit Topic' : 'Create Topic'}
      >
        <form>
          <ol>
            <li>
              <h2>What is this topic about?</h2>
              <p>
                This information will help the students understand what this
                topic is all about.
              </p>
              <div className="form-group sessionTitle">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter topic title"
                  name="title"
                  value={formData?.title || ''}
                  onChange={(e) => {
                    onChange(e)
                  }}
                />
              </div>
            </li>
            <li>
              <h2>What will be the content type for this topic?</h2>
              <p>
                Content type can be anything from video, audio and pictures.
                This content will appear along with the description of the
                topic.
              </p>
              <div className="form-row">
                <div className="form-group col sessionTopic">
                  <select
                    id="inputState"
                    className="form-control"
                    name="type"
                    onChange={(e) => {
                      onChange(e)
                    }}
                  >
                    <option value="" selected>
                      Select content type
                    </option>
                    {contentTypes?.map((type, index) => {
                      return (
                        <option
                          key={index}
                          value={type}
                          selected={type === formData?.type ? true : false}
                        >
                          {type}
                        </option>
                      )
                    })}
                  </select>
                </div>
                {formData?.type === 'video' || formData?.type === 'image' ? (
                  <div className="form-group col sessionTitle">
                    <input
                      type="file"
                      name="uploadVideoImage"
                      ref={videoRef}
                      onChange={(e) => onVideoImageUpload(e, formData?.type)}
                    />
                    {formData?.contentUrl &&
                    formData?.type === 'video' &&
                    isEdit &&
                    editVideo ? (
                      <p>File uploaded : {formData?.contentUrl}</p>
                    ) : null}
                  </div>
                ) : (
                  <div className="form-group col sessionTitle">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter content url"
                      name="contentUrl"
                      value={formData?.contentUrl || ''}
                      ref={videoRef}
                      onChange={(e) => {
                        onChange(e)
                      }}
                    />
                  </div>
                )}
                {formData?.contentUrl && formData?.type === 'image' && (
                  <p>File uploaded : {formData?.contentUrl}</p>
                )}
              </div>
            </li>
            <li>
              <h2>Describe topic content here</h2>
              <p>Student’s will read this content under this topic.</p>
              <div className="form-group sessionDescription">
                <Editor
                  editorState={description}
                  onEditorStateChange={setDescription}
                  placeholder={'Enter topic description'}
                />
              </div>
            </li>
            <li>
              <h2>Notes</h2>
              <p>Add Notes to the topic.</p>
              {notesUrl && notesUrl?.length
                ? notesUrl?.map((file, index) => {
                    return (
                      <div>
                        <a href={file}>Note {index + 1} Uploaded</a>
                        <span
                          style={{
                            cursor: 'pointer',
                            fontSize: 15,
                            padding: 20,
                            color: '#fb6161',
                          }}
                          onClick={() => onDeleteFile(index)}
                        >
                          {' '}
                          DELETE{' '}
                        </span>
                      </div>
                    )
                  })
                : null}
              {isLoadingUploadFile && noteUploaded ? (
                <Loader />
              ) : (
                <div className="form-group sessionTopic">
                  <input
                    type="file"
                    name={'notesUrl'}
                    ref={notesRef.current[0]}
                    style={{ display: 'none' }}
                    onChange={onChangeFile}
                  />
                  <button
                    onClick={(e) => onUploadNotes(e, 0)}
                    className="button--blue"
                  >
                    {notesUrl && notesUrl?.length
                      ? 'Add More Notes'
                      : 'Upload Notes'}
                  </button>
                </div>
              )}
            </li>
            <li>
              <h2>Author</h2>
              <p>Select faculty name.</p>
              <div className="form-group col sessionTopic">
                <select
                  id="inputFaculty"
                  className="form-control"
                  name="author_name"
                  onChange={(e) => {
                    setFaculty(e)
                  }}
                >
                  <option value="">
                    {isEdit ? selectedFaculty.author_name : 'Select a Faculty'}
                  </option>
                  {allFaculties?.map((faculty, index) => {
                    return (
                      <option
                        key={index}
                        value={faculty._id || ''}
                        selected={selectedFaculty.author_id === faculty._id}
                      >
                        {faculty.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </li>
          </ol>
          <div className="clear"></div>
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </form>
      </PopupForm>

      {showSuccess && pressStatus === 'create' ? (
        <AlertSuccess>You have successfully added a Topic</AlertSuccess>
      ) : null}
      {showEditSuccess && pressStatus === 'edit' ? (
        <AlertSuccess>You have successfully edited a Topic</AlertSuccess>
      ) : null}
      {showDeleteSuccess && pressStatus === 'delete' ? (
        <AlertSuccess>You have successfully deleted a Topic</AlertSuccess>
      ) : null}
    </div>
  )
}

export default Topic
