import React from 'react'
import { Provider } from 'react-redux'
import configureStore from '../redux/configureStore'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/style.css'
import { PersistGate } from 'redux-persist/integration/react'
//component

import Router from './router.js'

let { store, persistor } = configureStore()
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router />
      </PersistGate>
    </Provider>
  )
}

export default App
