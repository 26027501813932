import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import Col from '../assets/molecules/column'
import Card from '../assets/molecules/card'
import Loader from '../assets/molecules/loader'
import Button from '../assets/molecules/button'
// import Popup from '../assets/layout/popup'
import PopupCustom from '../assets/layout/popupCustom'
import SelfAssessmentStart from '../../assets/images/selfAssessmentStart.png'

//action
import {
  fetchAssessmentQuestionsByTopicId,
  postAssessmentSubmissionForTopic,
} from '../../redux/action/assessment'

//reducer
import { getJwt } from '../../redux/reducer/account'
import {
  // getIsAssessmentsLoading,
  getAssessmentQuestions,
  // getIsError,
  getIsAssessmentSubmissionLoading,
  getSelfAssessmentSubmittedData,
} from '../../redux/reducer/assessment'
import {
  getTopicTitle,
  getTopicId,
  getSubjectTitle,
  getChapterTitle,
  getBatchSubjectId,
} from '../../redux/reducer/ui'
import { getTopic } from '../../redux/reducer/topic'

// import { usePrevious } from '../helpers'

const TopicAssessment = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  //selector
  const jwt = useSelector(getJwt)
  // const isLoading = useSelector(getIsAssessmentsLoading)
  const isLoading = false
  const isAssessmentSubmissionLoading = useSelector(
    getIsAssessmentSubmissionLoading
  )
  const assessmentQuestions = useSelector(getAssessmentQuestions)
  // const error = useSelector(getIsError)
  // const isLoadingPrev = usePrevious(isAssessmentSubmissionLoading)
  const selfAssessmentSubmittedData = useSelector(
    getSelfAssessmentSubmittedData
  )

  const topic = useSelector(getTopic)
  const topicId = useSelector(getTopicId)
  const batchSubjectId = useSelector(getBatchSubjectId)

  const [popClassName, setPopClassName] = useState('hidePop')
  let [formData, setFormData] = useState({})
  let [errorMessage, setErrorMessage] = useState('')
  let [correctAnswers, setCorrectAnswers] = useState([])
  let [isRetake, setIsRetake] = useState(false)

  useEffect(() => {
    setCorrectAnswers([])
    const id = topicId
    dispatch(fetchAssessmentQuestionsByTopicId({ jwt, id }))
  }, [topicId, dispatch, jwt])

  useEffect(() => {
    if (
      selfAssessmentSubmittedData !== '' &&
      selfAssessmentSubmittedData !== undefined &&
      Object.keys(selfAssessmentSubmittedData).length
    ) {
      // setPopClassName('showPop')
      selfAssessmentSubmittedData.questions.forEach((question, i) => {
        let answersForQues = []
        question.answers.forEach((answer) => {
          if (answer.type === 'CORRECT') {
            answersForQues.push(answer.title)
          }
        })
        correctAnswers.push(answersForQues)
        setCorrectAnswers(correctAnswers)
      })
    } else {
      setPopClassName('hidePop')
    }
  }, [correctAnswers, selfAssessmentSubmittedData])

  const closePopup = () => {
    setPopClassName('hidePop')
  }

  const onchangeHandler = (e, question, answer) => {
    if (!Array.isArray(formData[question?._id])) {
      setFormData({ ...formData, [question._id]: [e.target.value] })
    } else {
      if (question?.type === 'MULTIPLE_CHOICE') {
        if (formData[question?._id].includes(e.target.value)) {
          const removed = formData[question?._id].filter(
            (id) => id !== e.target.value
          )
          setFormData({ ...formData, [question?._id]: removed })
        } else {
          setFormData({
            ...formData,
            [question?._id]: [...formData[question?._id], e.target.value],
          })
        }
      } else {
        setFormData({ ...formData, [question._id]: [e.target.value] })
      }
    }
    errorMessage && setErrorMessage('')
  }

  const createQuestion = (question, i) => {
    switch (question.type) {
      case 'SINGLE_CHOICE':
      case 'BOOLEAN':
      case 'MULTIPLE_CHOICE':
        return (
          <div className="question__wrapper">
            <div className="question__wrap">
              <p className="assessment__question">{`${i + 1}) ${
                question.title
              }`}</p>
              <p className="assessment__marks">{question.weightage} mark(s)</p>
              <div className="clear"></div>
            </div>
            <ul className="radio__wrapper">
              {question?.answers?.map((answer, index) => (
                <li>
                  <input
                    type={
                      question.type === 'MULTIPLE_CHOICE' ? 'checkbox' : 'radio'
                    }
                    className="radio--btn"
                    name={question._id}
                    id={answer._id}
                    value={answer._id}
                    onChange={(e) => onchangeHandler(e, question, answer)}
                  />
                  <label for={answer._id} className="radio--label">
                    {answer.title}
                  </label>
                </li>
              ))}
            </ul>
            <div className="clear"></div>
          </div>
        )
      default:
        return <div />
    }
  }

  const payload = {
    topic_id: topicId,
    batch_subject_id: batchSubjectId,
    answers: [],
  }

  const onAnswersSubmit = (e) => {
    e.preventDefault()
    const formDataValues = Object.values(formData)
    const isFormFilled = formDataValues.every(
      (formDataValue) =>
        formDataValue !== '' &&
        formDataValue !== null &&
        formDataValue !== undefined
    )
    if (
      Object.keys(formData).length === assessmentQuestions.length &&
      isFormFilled
    ) {
      assessmentQuestions.forEach((question) => {
        switch (question.type) {
          case 'SINGLE_CHOICE':
          case 'MULTIPLE_CHOICE':
          case 'BOOLEAN':
            payload.answers.push({
              qid: question._id,
              submitted_answer_ids: formData[question._id],
            })
            break
          default:
        }
      })
      dispatch(postAssessmentSubmissionForTopic({ jwt, payload }))
      setPopClassName('showPop')
      setIsRetake(false)
    } else {
      setErrorMessage('Please answer all the questions')
    }
  }

  return (
    <div className="tabs__container">
      {/* <section>
        <Col no="2">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/me/subjects">{subjectTitle}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/me/subjects/chapters">{chapterTitle}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/me/subjects/chapters/topics">{topicTitle}</Link>
              </li>
              <li className="breadcrumb-item active">Assessment</li>
            </ol>
          </nav>
        </Col>
      </section> */}
      <section className="margin--top100">
        <h1>Self Assessment - {topic.title}</h1>
        {isLoading ? (
          <Loader />
        ) : assessmentQuestions?.length ? (
          <div>
            <form className="assessment__form">
              {assessmentQuestions.map((question, index) => {
                return (
                  <div className="question__wrapper">
                    {createQuestion(question, index)}
                    {Object.keys(selfAssessmentSubmittedData).length !== 0 &&
                      !isRetake && (
                        <p className="correct__answer">{`Correct Answer: ${correctAnswers[
                          index
                        ]?.join(', ')}`}</p>
                      )}
                  </div>
                )
              })}
              <div className="text-center">
                {Object.keys(selfAssessmentSubmittedData).length !== 0 &&
                !isRetake ? (
                  <Button
                    click={() =>
                      history.push('/me/subjects/chapters/topics/detail')
                    }
                    color="blue"
                    text="Go Back"
                  />
                ) : isAssessmentSubmissionLoading ? (
                  <Loader />
                ) : (
                  <Button
                    click={(e) => onAnswersSubmit(e)}
                    color="blue"
                    text="Submit"
                  />
                )}
              </div>
              {errorMessage && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}
            </form>
          </div>
        ) : (
          <p>No quetions on this assessment</p>
        )}
        <div className="clear"></div>
      </section>
      <PopupCustom
        className={popClassName}
        close={() => closePopup()}
        popupHead="Start Examination"
      >
        <div className="self__assessment--popup">
          <img
            className="self__assessmentIcon"
            src={SelfAssessmentStart}
            alt="START SELF ASSESMENT"
          />
          <h2>Thank You for your submission.</h2>
          <p className="assessment__teacherName">
            Your score is{' '}
            {`${selfAssessmentSubmittedData.scored_point}/${selfAssessmentSubmittedData.total_point}`}
          </p>
          <button
            onClick={(e) => {
              setIsRetake(true)
              closePopup()
              // setCorrectAnswers([])
              // setFormData({})
            }}
            className="button--blue"
          >
            Retake Assessment
          </button>
          <br />
          <br />
          {/* <button
            onClick={() => closePopup()}
            className="button--transparent margin--0"
          >
            LATER
                          </button> */}
        </div>
      </PopupCustom>
      {/* <Popup className={popClassName} close={() => closePopup()}>
        <h2>
          You have scored!!{' '}
          {`${selfAssessmentSubmittedData.scored_point}/${selfAssessmentSubmittedData.total_point}`}
        </h2>
      </Popup> */}
      <div className="clear"></div>
    </div>
  )
}

export default TopicAssessment
