import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

//assets
import Card from '../../assets/molecules/card'
import Button from '../../assets/molecules/button'
import Loader from '../../assets/molecules/loader'

import { usePrevious } from '../../helpers'

//actions
//subject
import { fetchAddTopic } from '../../../redux/action/topic'

//reducer
//account
import { getJwt, getUsername, getUserId } from '../../../redux/reducer/account'
import { getIsTopicsLoading, getIsError } from '../../../redux/reducer/topic'
import {
  getChapterId,
  getSubjectTitle,
  getChapterTitle,
} from '../../../redux/reducer/ui'

const AddTopics = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  //selector
  const jwt = useSelector((state) => getJwt(state))
  const error = useSelector((state) => getIsError(state))
  const isLoading = useSelector((state) => getIsTopicsLoading(state))
  const chapterId = useSelector(getChapterId)
  const subjectTitle = useSelector(getSubjectTitle)
  const chapterTitle = useSelector(getChapterTitle)
  const userid = useSelector(getUserId)
  const username = useSelector(getUsername)

  const isLoadingPrev = usePrevious(isLoading)

  let [formData, setFormData] = useState({ faculty: `${userid}-${username}` })
  let [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (error) {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }, [error])

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isLoading &&
      !isLoading &&
      !error
    ) {
      history.push({
        pathname: '/me/faculty/subjects/chapters/topics',
        state: { status: 'success' },
      })
    }
  }, [error, history, isLoading, isLoadingPrev])
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (
      formData?.title &&
      formData?.type &&
      formData?.contentUrl &&
      formData?.description
    ) {
      const data = {
        title: formData.title,
        type: formData.type,
        content_uri: formData.contentUrl,
        wysiwyg_state: formData.description,
        chapter_id: chapterId,
        author_id: userid,
        author_name: username,
      }
      dispatch(fetchAddTopic({ jwt, data }))
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  const contentTypes = ['image', 'audio', 'video']

  return (
    <div className="tabs__container">
      <section>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/dashboard">{subjectTitle}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/me/faculty/subjects/chapters">{chapterTitle}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/me/faculty/subjects/chapters/topics">Topics</Link>
            </li>
            <li className="breadcrumb-item active">Add Topic</li>
          </ol>
        </nav>
      </section>

      <section>
        <h2>Add Topic</h2>
        <Card columnNo="1" title="Add Topic" titleColor={1}>
          <input
            name="title"
            value={formData.title}
            type="text"
            placeholder="Title"
            onChange={onChange}
          />
          <select name="faculty" onChange={(e) => onChange(e)}>
            <option value={userid} selected={true}>
              {username}
            </option>
          </select>
          <select name="type" onChange={(e) => onChange(e)}>
            <option value="">Please select content type</option>
            {contentTypes.map((contentType, index) => {
              return (
                <option value={contentType} key={index}>
                  {contentType}
                </option>
              )
            })}
          </select>
          {formData.type && (
            <input
              name="contentUrl"
              value={formData.contentUrl}
              type="text"
              placeholder={`Please upload ${formData.type}`}
              onChange={onChange}
            />
          )}

          <textarea
            rows="20"
            name="description"
            value={formData.description}
            placeholder="Description"
            onChange={onChange}
          ></textarea>
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              className="button--small"
              color="blue"
              text="Add"
              click={(e) => onSubmit(e)}
            />
          )}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </Card>
      </section>
    </div>
  )
}

export default AddTopics
