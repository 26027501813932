import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { usePrevious, subjectPics } from '../../helpers'

import Loader from '../../assets/molecules/loader'
import Select from '../../assets/molecules/select'
import SubjectCard from '../../assets/molecules/subjectCard'
import NoPending from '../../../assets/images/no-pending.png'
import PopupForm from '../../assets/layout/popupForm'
import { AlertSuccess, AlertError } from '../../assets/molecules/alerts'

//actions
import { fetchSubjects } from '../../../redux/action/subject'
import { setBatch, setSubject } from '../../../redux/action/ui'
import { postSubject } from '../../../redux/action/subject'

//reducers
import { getJwt } from '../../../redux/reducer/account'
import {
  groupByBatchSubject,
  isSubjectsloading,
  getIsError,
} from '../../../redux/reducer/subject'

import { getBatch } from '../../../redux/reducer/ui'
import { getBatches } from '../../../redux/reducer/batches'

import { subjectIcons } from '../../helpers'

const Subject = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  //get data from redux store
  //account
  let jwt = useSelector((state) => getJwt(state))
  const error = useSelector(getIsError)
  const isLoading = useSelector(isSubjectsloading)
  const isLoadingPrev = usePrevious(isLoading)

  //subject
  let subjects = useSelector((state) => groupByBatchSubject(state))
  let isloading = useSelector((state) => isSubjectsloading(state))
  let batches = useSelector((state) => getBatches(state))

  //ui for batch selected
  let batch = useSelector((state) => getBatch(state))
  let batchId = useSelector(getBatch)

  let [formData, setFormData] = useState({})
  let [logoValue, setLogoValue] = useState({})
  let [errorMessage, setErrorMessage] = useState('')
  const [formPopClassName, setFormPopClassName] = useState('hidePop')
  const [showSuccess, setShowSuccess] = useState(false)
  const [pressStatus, setPressStatus] = useState('')
  const subjectPicKeys = Object.keys(subjectPics)

  // const openEditDoubtPopup = (doubt) => {
  //   setEditDoubtPopClassName('showPop')
  //   setSelectedDoubt(doubt)
  //   setEditText(doubt.doubt_text)
  // }
  const showPopup = () => {
    setFormPopClassName('showPop')
    setShowSuccess(false)
    setPressStatus('')
  }
  const closePopup = () => {
    setFormPopClassName('hidePop')
    setFormData({})
    setLogoValue({})
  }

  useEffect(() => {
    dispatch(fetchSubjects({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (error) {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }, [error])

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isLoading &&
      !isLoading &&
      !error
    ) {
      history.push({
        pathname: '/batch/subjects',
        state: { status: 'success' },
      })
    }
  }, [error, history, isLoading, isLoadingPrev])

  useEffect(() => {
    if (isLoadingPrev !== undefined && !isLoading && !error) {
      setShowSuccess(true)
    }
  }, [error, isLoading, isLoadingPrev])

  const onChange = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onLogoChange = (e) => {
    let logo_pic = subjectPics[e.target.value]
    setLogoValue({ ...logoValue, logo_name: e.target.value, logo: logo_pic })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (
      formData?.title &&
      formData?.description &&
      logoValue?.logo &&
      formData?.code
    ) {
      const payload = {
        title: formData.title,
        subject_logo: logoValue.logo,
        description: formData.description,
        code: formData.code,
      }
      dispatch(postSubject({ payload, jwt, batchId }))
      setFormPopClassName('hidePop')
    } else {
      setErrorMessage('Please fill the form')
    }
    setFormData({})
    setLogoValue({})
    setPressStatus('create')
  }

  //const filteredListBatches = batches.filter((bat) => bat._id === batchId);
  //const currentBatch = filteredListBatches.length > 0 ? filteredListBatches[0] : {}

  const onChangeBatch = (e) => {
    e.preventDefault()
    dispatch(setBatch(e.target.value))
  }

  const onSubjectSelect = (subject) => {
    dispatch(setSubject(subject.subject_id, subject.subject_title))
    history.push('/batch/subjects/chapters')
  }

  const filteredList = batches.filter((bat) => bat._id === batch)
  const currentBatch = filteredList.length > 0 ? filteredList[0] : {}
  const noSubjectsAvailable = (type) => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">No subjects available.</h2>
          <button onClick={() => showPopup()} className="button--blue">
            + Add Subjects
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="tabs__container">
      <section>
        {isloading ? (
          <Loader />
        ) : batch ? (
          <React.Fragment>
            <div className="row">
              <div className="col-md-6 padding--0">
                <h1>Subjects</h1>
                {/* <h1>Subjects of {currentBatch.title}</h1> */}
              </div>
              <div className="col-md-6 padding--0">
                {/* <Link
                  to={{
                    pathname: `/batch/subjects/create`,
                  }}
                > */}
                <button
                  onClick={() => showPopup()}
                  className="button--blue float-right"
                >
                  + Add Subject
                </button>
                {/* </Link> */}
              </div>
              <div className="clear"></div>
            </div>

            <div className="row">
              <div className="col-md-6 padding--0">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">{currentBatch.title}</Link>
                    </li>
                    <li className="breadcrumb-item active">Subjects</li>
                  </ol>
                </nav>
              </div>
              <div className="col-md-6 padding--0">
                <div className="customTab__dropdowns">
                  <Select
                    onChange={(e) => onChangeBatch(e)}
                    defaultValue={currentBatch._id}
                  >
                    <option value="" selected>
                      Select an Option
                    </option>
                    {batches?.map((batch, index) => {
                      return (
                        <option value={batch._id} key={index}>
                          {batch.title}
                        </option>
                      )
                    })}
                  </Select>
                </div>
              </div>
              <div className="clear"></div>
            </div>

            {subjects[batch]?.length > 0
              ? subjects[batch]?.map((subject, index) => {
                  return (
                    <SubjectCard
                      key={index}
                      className="subjectCard__moderator"
                      icon={
                        subjectIcons[subject.subject_title.toLowerCase()]
                          ? subjectIcons[subject.subject_title.toLowerCase()]
                          : subjectIcons['others']
                      }
                      subjectName={subject.subject_title}
                      subjectCode={subject.subject_code}
                      noOfStudents={subject.students.length}
                      noOfTeachers={subject.faculties.length}
                      profile="true"
                      moderator="true"
                      onClick={() => onSubjectSelect(subject)}
                    />
                  )
                })
              : noSubjectsAvailable()}
          </React.Fragment>
        ) : (
          <div> Please select a batch </div>
        )}
      </section>
      <PopupForm
        className={formPopClassName}
        close={() => {
          closePopup()
        }}
        onClickButton={(e) => onSubmit(e)}
        buttonText="Submit"
        popupHead="Add subject"
      >
        <form>
          <ol>
            <li>
              <h2>Which subject is this?</h2>
              <p>
                This information will help the students understand the subject
                name.
              </p>
              <div className="form-group sessionTitle">
                <input
                  name="title"
                  value={formData.title || ''}
                  type="text"
                  placeholder="Title"
                  onChange={onChange}
                />
              </div>
            </li>
            <li>
              <h2>What are the subject code and logo for the subject?</h2>
              <p>It will help user to identify subject easily </p>
              <div className="form-row">
                <div className="form-group col sessionTopic">
                  <input
                    name="code"
                    value={formData.code || ''}
                    type="text"
                    placeholder="Subject Code"
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col sessionTitle">
                  <select
                    name="logo"
                    onChange={(e) => {
                      onLogoChange(e)
                    }}
                  >
                    <option value="" selected>
                      Select a logo
                    </option>
                    {subjectPicKeys?.map((key, index) => {
                      return (
                        <option
                          key={index}
                          value={key || ''}
                          selected={logoValue.logo_name === key}
                        >
                          {key}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>

              {/* <div className="form-row">
                <div className="form-group col sessionUpload">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="upload"
                    name="url"
                  />
                </div>
              </div> */}
            </li>
            <li>
              <h2>Describe about subject here</h2>
              <p>It will help to get an idea about subject</p>
              <div className="form-group sessionDescription">
                <textarea
                  name="description"
                  value={formData.description || ''}
                  placeholder="Description"
                  onChange={onChange}
                ></textarea>
              </div>
            </li>
          </ol>

          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </form>
      </PopupForm>
      {showSuccess && pressStatus === 'create' ? (
        <AlertSuccess>You have successfully added a Subject</AlertSuccess>
      ) : null}
    </div>
  )
}

export default Subject
