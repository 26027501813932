import {
  APP_LOGOUT,
  UPLOAD_FILE_START,
  UPLOAD_FILE,
  UPLOAD_FILE_FAIL,
} from '../actions.js'

const initialState = {
  error: false,
  file: {},
  isLoadingUploadFile: false,
}

export const uploadFile = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE_START:
      return Object.assign({}, state, {
        isLoadingUploadFile: true,
        error: false,
      })
    case UPLOAD_FILE:
      return Object.assign({}, initialState, {
        file: action.data,
        isLoadingUploadFile: false,
        error: false,
      })
    case UPLOAD_FILE_FAIL:
      return Object.assign({}, state, {
        error: true,
        isLoadingUploadFile: false,
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

export const getIsError = (state) => state.uploadFile.error
export const getFile = (state) => state.uploadFile.file
export const getIsLoadingUploadFile = (state) =>
  state.uploadFile.isLoadingUploadFile
