import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Tab, Tabs } from 'react-bootstrap'
import moment from 'moment'

//assets
import Col25 from '../assets/layout/leftrightlayout/column25'
import Col75 from '../assets/layout/leftrightlayout/column75'
import NotesCard from '../assets/molecules/notesCard'
import AddNotes from '../assets/molecules/addNotes'
import LeftRightLayout from '../assets/layout/leftrightlayout'
import Popup from '../../components/assets/layout/popupConfirm'

import {
  fetchNotesBySessionId,
  postSessionNote,
  deleteNote,
  fetchEditnotes,
} from '../../redux/action/note'
import {
  fetchOnlineSessionById,
  editOnlineSessionById,
  editRealoadSuccess,
} from '../../redux/action/onlineSession'
import { postImpressions } from '../../redux/action/impressions'

import { getJwt, getUsername, getEntityType } from '../../redux/reducer/account'
import { getOnlineSession, getReaload } from '../../redux/reducer/onlineSession'
import {
  getNotes,
  getIsNotesLoading,
  getIsError,
} from '../../redux/reducer/note'

import { useInterval } from '../helpers'

const Detail = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const [noteText, setNoteText] = useState()

  const currentSession = location?.state?.session

  const jwt = useSelector(getJwt)
  const isLoading = useSelector(getIsNotesLoading)
  const isError = useSelector(getIsError)
  const notes = useSelector(getNotes)
  const username = useSelector(getUsername)
  const currentOnlineSession = useSelector(getOnlineSession)
  const entityType = useSelector(getEntityType)
  const reaload = useSelector(getReaload)

  const startConference = () => {
    try {
      const domain = 'video.coursewarex.com'
      const options = {
        parentNode: document.getElementById('jitsi-container'),
        roomName: currentSession?._id,
        displayName: username,
        configOverwrite: {
          startWithAudioMuted: true,
          startWithVideoMuted: true,
        },
        interfaceConfigOverwrite: {
          MOBILE_APP_PROMO: false,
        },
      }
      if (
        (currentSession?._id && currentOnlineSession?.author_logged_in) ||
        entityType === 'faculties'
      ) {
        const api = new window.JitsiMeetExternalAPI(domain, options)
        api.addEventListener('videoConferenceJoined', () => {
          console.log('Local User Joined')
          api.executeCommand('displayName', username)
        })
      }
    } catch (error) {
      console.error('Failed to load Jitsi API', error)
    }
  }

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..

    if (window.JitsiMeetExternalAPI) startConference()
    else alert('Jitsi Meet API script not loaded')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (entityType === 'students' && currentSession) {
      const body = {
        content_id: currentSession?._id,
        content_type: 'online',
        time_spent: '.1',
        batch_subject_id: currentSession?.batch_subject_id
          ? currentSession.batch_subject_id
          : currentSession.subject_id,
      }
      dispatch(postImpressions({ jwt, body }))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(
      fetchNotesBySessionId({
        jwt,
        id: currentSession?._id,
      })
    )
    if (entityType === 'faculties') {
      dispatch(
        editOnlineSessionById({
          jwt,
          id: currentSession?._id,
          payload: {
            author_logged_in: true,
          },
        })
      )
    }
    if (entityType !== 'faculties') {
      dispatch(
        fetchOnlineSessionById({
          jwt,
          id: currentSession?._id,
        })
      )
    }
    return () => {
      if (entityType === 'faculties') {
        dispatch(
          editOnlineSessionById({
            jwt,
            id: currentSession?._id,
            payload: {
              author_logged_in: false,
            },
          })
        )
        dispatch(
          editRealoadSuccess({
            reaload: true,
          })
        )
      }
    }
  }, [currentSession, dispatch, entityType, jwt])

  useInterval(() => {
    if (entityType !== 'faculties') {
      dispatch(
        fetchOnlineSessionById({
          jwt,
          id: currentSession?._id,
        })
      )
    }
  }, 5000)

  useEffect(() => {
    !isLoading && !isError && setNoteText('')
  }, [isLoading, isError])

  useEffect(() => {
    if (entityType === 'students') {
      if (currentOnlineSession?.author_logged_in && !reaload) {
        dispatch(
          editRealoadSuccess({
            reaload: true,
          })
        )
        window.location.reload(false)
      }

      if (!currentOnlineSession?.author_logged_in) {
        dispatch(
          editRealoadSuccess({
            reaload: false,
          })
        )
      }
    }
  }, [currentOnlineSession, dispatch, entityType, reaload])

  const [seletedNote, setSelectedNote] = useState('')
  const [popClassName, setPopClassName] = useState('hidePop')
  const [deletePopClassName, setDeletePopClassName] = useState('hidePop')

  const openPopup = (note) => {
    setSelectedNote(note)
    setNoteText(note?.note_text)
    setPopClassName('showPop')
  }

  const closePopup = () => {
    setNoteText('')
    setPopClassName('hidePop')
  }

  const openDeletePopup = (note) => {
    setSelectedNote(note)
    setDeletePopClassName('showPop')
  }

  const closeDeletePopup = () => {
    setDeletePopClassName('hidePop')
  }

  const onEditNote = (e) => {
    dispatch(
      fetchEditnotes({ jwt, noteId: seletedNote._id, note_text: noteText })
    )
    closePopup()
  }

  const onDelete = () => {
    dispatch(deleteNote({ jwt, id: seletedNote._id }))
    closeDeletePopup()
  }

  return (
    <div className="tabs__container">
      <section>
        <h1>{currentSession?.title}</h1>
        <div className="row">
          <div className="col-md-8 padding--0">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    to={
                      entityType === 'students'
                        ? '/me/sessions'
                        : entityType === 'faculties'
                        ? '/me/faculty/sessions'
                        : '/onlinesessions/reports'
                    }
                  >
                    Sessions
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  {currentSession?.title}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col75>
            {
              <div className="topic__detailImage online__sessionContainer">
                {currentOnlineSession?.author_logged_in ||
                entityType === 'faculties' ? (
                  <div>
                    <div id="jitsi-container" style={{ height: 500 }}></div>
                    {entityType === 'faculties' ? (
                      <button
                        className="button--red button--red--endCall"
                        onClick={() => history.push('/me/faculty/sessions')}
                      >
                        END CALL
                      </button>
                    ) : null}
                  </div>
                ) : moment().isBefore(moment(currentSession?.start_time)) ? (
                  <h1>Live Session yet to start ...</h1>
                ) : moment().isAfter(moment(currentSession?.end_time)) ? (
                  <h1>Live Session Ended.</h1>
                ) : (
                  <h1>Waiting for the Teacher to join ...</h1>
                )}
                <div className="clear"></div>
              </div>
            }
            <Popup
              className={popClassName}
              close={() => closePopup()}
              onClick={() => onEditNote()}
              buttonText="Save Note"
              popupHead="Edit Note"
            >
              <textarea
                rows="5"
                value={noteText || ''}
                onChange={(e) => setNoteText(e.target.value)}
              ></textarea>
            </Popup>
            <Popup
              className={deletePopClassName}
              close={() => closeDeletePopup()}
              onClick={() => onDelete()}
              buttonText="yes"
              popupHead="Delete Note"
            >
              <h2>Are you sure want to delete?</h2>
            </Popup>
          </Col75>
          {entityType === 'students' ? (
            <Col25>
              <Tabs>
                <Tab eventKey="notes" title={`Notes (${notes?.length})`}>
                  {notes?.map((note, index) => {
                    return (
                      <NotesCard
                        key={index}
                        content={note.note_text}
                        editOnClick={(e) => {
                          openPopup(note)
                        }}
                        deleteOnClick={(e) => openDeletePopup(note)}
                      />
                    )
                  })}
                  <AddNotes
                    value={noteText || ''}
                    onChange={(e) => {
                      setNoteText(e.target.value)
                    }}
                    onClick={(e) => {
                      const payload = {
                        session_id: currentSession?._id,
                        note_text: noteText,
                      }
                      dispatch(postSessionNote({ jwt, payload }))
                    }}
                  />
                </Tab>
              </Tabs>
            </Col25>
          ) : null}
        </LeftRightLayout>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default Detail
