import React from 'react'

const HomeCard = (props) => {
  return (
    <div className="homeCard row">
      <div className="col-xl-3  col-lg-3  col-md-12 col-sm-12 homeCard__img">
        <img src={props.img} alt="CWX" />
        <div className="clear"></div>
      </div>
      <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 homeCard_content">
        <div>
          <h3>{props.heading}</h3>

          {props?.onlinecardStudents ? (
            <div className="homeCard__details--online">
              <h5 className="homeCard__subject">{props.subject}</h5>
              <div className="clear"></div>
            </div>
          ) : (
            <div className="homeCard__details--online">
              <h5 className="homeCard__class">
                <span>Class: </span>
                {props.class}
              </h5>
              <h5 className="homeCard__subject">{props.subject}</h5>
              <h5 className="homeCard__chapter">{props.chapter}</h5>
              <div className="clear"></div>
            </div>
          )}
          <p className="homeCard__para">{props.description}</p>
          <div className="homeCard__details">
            <h5 className="homeCard__date">{props.date}</h5>
            <h5 className="homeCard__timing">{props.timing}</h5>
            {/* <h5 className="homeCard__timing--online">{props.startTiming}</h5>
            <h5 className="homeCard__timing--online">{props.endTiming}</h5> */}
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="col-xl-2 col-lg-3 col-md-12 col-sm-12 display__flex homeCard__wrap3">
        <div>
          <button className={props.buttonClass} onClick={props.onClick}>
            {props.button}
          </button>
          <p className="homeCard__teacher">{props.moreDetails}</p>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  )
}

export default HomeCard
