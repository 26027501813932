import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactPlayer from 'react-player'

// import _ from "underscore";
import { Tab, Tabs } from 'react-bootstrap'
import moment from 'moment'
//import ProfilePic from '../../assets/images/old/profile-pic.png'

//assets
import Loader from '../../assets/molecules/loader'
import LeftRightLayout from '../../assets/layout/leftrightlayout'
import Col75 from '../../assets/layout/leftrightlayout/column75'
import Col25 from '../../assets/layout/leftrightlayout/column25'
import DoubtsCard from '../../assets/molecules/doubtsCard'
// import SharedFiles from '../assets/molecules/sharedFiles'
import ProfilePic from '../../../assets/images/profile.png'
// import Documents from '../../assets/images/document.svg'
// import Notes from '../../assets/images/notes.svg'
// import PDF from '../../assets/images/pdf.svg'
// import Excel from '../../assets/images/excel.svg'
import Popup from '../../assets/layout/popupConfirm'
import InputSend from '../../assets/molecules/inputSend'
import PopupDoubt from '../../assets/layout/popupRight'
import ReplyCard from '../../assets/molecules/replyCard'
//import ProfileCard from '../assets/molecules/profileCard'

//action
import { fetchTopic } from '../../../redux/action/topic'
import {
  fetchDoubtsByTopicId,
  // fetchDoubtsResponse,
  deleteStudentDoubtsById,
  editStudentDoubtsById,
  postReplyDoubt,
} from '../../../redux/action/doubt'

import { postImpressions } from '../../../redux/action/impressions'
// import { setDoubtId } from "../../redux/action/ui";

//reducer
import { getJwt } from '../../../redux/reducer/account'
import {
  getTopicTitle,
  getTopicId,
  getSubjectTitle,
  getChapterTitle,
  getBatchSubjectId,
} from '../../../redux/reducer/ui'
import { getTopic, getIsTopicsLoading } from '../../../redux/reducer/topic'
import {
  getDoubtState,
  getResponsesGroupByDoubtId,
} from '../../../redux/reducer/doubt'
import { getSubjects } from '../../../redux/reducer/subject'

//helpers
import { setSubject, setChapter } from '../../../redux/action/ui'
import { fetchChaptersBySubjectId } from '../../../redux/action/chapter'
import { getChapters } from '../../../redux/reducer/chapter'

const TopicDetail = () => {
  const dispatch = useDispatch()

  const jwt = useSelector(getJwt)
  const topicId = useSelector(getTopicId)
  const topic = useSelector(getTopic)
  const { doubts } = useSelector(getDoubtState)
  const isTopicsLoading = useSelector(getIsTopicsLoading)
  const subjectTitle = useSelector(getSubjectTitle)
  const chapterTitle = useSelector(getChapterTitle)
  const topicTitle = useSelector(getTopicTitle)
  const batch_subject_id = useSelector(getBatchSubjectId)
  const subjects = useSelector(getSubjects)
  const chapters = useSelector(getChapters)
  const responsesGroupByDoubtId = useSelector(getResponsesGroupByDoubtId)

  useEffect(() => {
    dispatch(fetchTopic({ jwt, topicId }))
    dispatch(fetchDoubtsByTopicId({ jwt, topicId }))
  }, [dispatch, jwt, topicId])

  // const getSubjectNameByBatchSubId = (id) => {
  //   const filtered = subjects.filter((subject) => subject._id === id)
  //   return filtered.length ? filtered[0].subject_title : ''
  // }

  // const getSubjectNameById = (id) => {
  //   const filtered = subjects.filter((subject) => subject.subject_id === id)
  //   return filtered.length ? filtered[0].subject_title : ''
  // }

  // useEffect(() => {
  //   if (Object.keys(topic).length && subjects.length) {
  //     const subjectTitle = getSubjectNameById(topic.subject_id)
  //     dispatch(setSubject(topic.subject_id, subjectTitle))
  //     dispatch(fetchChaptersBySubjectId({ jwt, subjectId: topic.subject_id }))
  //   }
  // }, [topic, subjects, dispatch, jwt, getSubjectNameById])

  // const getChapterNameById = (id) => {
  //   const filtered = chapters.filter((chapter) => chapter._id === id)
  //   return filtered.length ? filtered[0].title : ''
  // }

  // useEffect(() => {
  //   if (chapters.length) {
  //     const chapterTitle = getChapterNameById(topic.chapter_id)
  //     dispatch(setChapter(topic.chapter_id, chapterTitle))
  //   }
  // }, [chapters, dispatch, getChapterNameById, topic.chapter_id])

  const createMarkup = (data) => {
    return { __html: data }
  }
  // const [doubt, setDoubt] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");
  // const [isRepliedClicked, setIsRepliedClicked] = useState({});
  const [editText, setEditText] = useState()
  const [selectedDoubt, setSelectedDoubt] = useState('')
  const [editDoubtPopClassName, setEditDoubtPopClassName] = useState('hidePop')
  const [deleteDoubtPopClassName, setDeleteDoubtPopClassName] = useState(
    'hidePop'
  )

  const openEditDoubtPopup = (doubt) => {
    setEditDoubtPopClassName('showPop')
    setSelectedDoubt(doubt)
    setEditText(doubt.doubt_text)
  }

  const openDeleteDoubtPopup = (doubt) => {
    setDeleteDoubtPopClassName('showPop')
    setSelectedDoubt(doubt)
  }

  const closeEditDoubtPopup = () => {
    setEditText('')
    setEditDoubtPopClassName('hidePop')
  }

  const closeDeleteDoubtPopup = () => {
    setDeleteDoubtPopClassName('hidePop')
  }

  const onDoubtEdit = () => {
    dispatch(
      editStudentDoubtsById({
        jwt,
        doubtId: selectedDoubt._id,
        payload: {
          doubt_text: editText,
        },
      })
    )
    setEditText('')
    closeEditDoubtPopup()
  }

  const onDoubtDelete = () => {
    dispatch(
      deleteStudentDoubtsById({
        doubtId: selectedDoubt._id,
        jwt,
        isFromTopicDetail: true,
        topicId,
      })
    )
    setSelectedDoubt('')
    closeDeleteDoubtPopup()
  }

  const [doubtClicked, setDoubtClicked] = useState()
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [popupDoubtRepliesClassName, setPopupDoubtRepliesClassName] = useState(
    'hidePop'
  )
  const openPopupDoubtReplies = (doubt) => {
    setDoubtClicked(doubt)
    setPopupDoubtRepliesClassName('showPop')
  }
  const closePopupDoubtReplies = () => {
    setPopupDoubtRepliesClassName('hidePop')
    setDoubtClicked()
  }

  const videoPlayingFunction = (value) => {
    setVideoPlaying(value)
  }

  return (
    <div className="tabs__container">
      <section>
        <h1>{topic.title}</h1>
        <div className="row">
          <div className="col-md-8 padding--0">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/me/faculty/subjects">{subjectTitle}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/me/faculty/subjects">{chapterTitle}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/me/faculty/subjects/chapters/topics">
                    {topicTitle}
                  </Link>
                </li>
                <li className="breadcrumb-item active">Detail</li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col75>
            {isTopicsLoading ? (
              <Loader />
            ) : (
              <div className="topic__detailImage">
                {topic.type === 'image' && (
                  <img src={topic.content_uri} alt={topic.title} />
                )}
                {topic.type === 'audio' && (
                  <iframe
                    title={topic.title}
                    src={topic.content_uri}
                    height="500"
                    width="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  ></iframe>
                )}
                {topic.type === 'video' && (
                  <ReactPlayer
                    url={topic.content_uri}
                    onPlay={() => videoPlayingFunction(true)}
                    onPause={() => videoPlayingFunction(false)}
                    controls={true}
                    height="500px"
                    width="100%"
                  />
                )}
                <div className="clear"></div>
              </div>
            )}

            <Tabs>
              <Tab eventKey="description" title="Description">
                <div style={{ paddingLeft: '20px' }}>
                  <div
                    dangerouslySetInnerHTML={createMarkup(topic.wysiwyg_state)}
                  />
                </div>
              </Tab>
              {topic.notes && topic.notes.length > 0 && (
                <Tab eventKey="notes" title="Notes">
                  <div
                    className="noPending__button--Wrapper"
                    style={{ paddingLeft: '20px' }}
                  >
                    {topic.notes.map((file, index) => {
                      return (
                        <button
                          className={'button--blue'}
                          style={{ marginLeft: 0 }}
                          onClick={() => window.open(file, '_blank')}
                        >
                          Note {index + 1}
                        </button>
                      )
                    })}
                  </div>
                </Tab>
              )}
            </Tabs>
            <Popup
              className={editDoubtPopClassName}
              close={() => closeEditDoubtPopup()}
              onClick={() => onDoubtEdit()}
              buttonText="Save Doubt"
              popupHead="Edit Doubt"
            >
              <textarea
                rows="5"
                value={editText || ''}
                onChange={(e) => setEditText(e.target.value)}
              ></textarea>
            </Popup>
            <Popup
              className={deleteDoubtPopClassName}
              close={() => closeDeleteDoubtPopup()}
              onClick={() => onDoubtDelete()}
              buttonText="yes"
              popupHead="Delete Doubts"
            >
              <h2>Are you sure want to delete?</h2>
            </Popup>
          </Col75>
          <Col25>
            <Tabs>
              <Tab
                className="doubtsTab"
                eventKey="doubts"
                title={`Doubts (${doubts?.length})`}
              >
                {doubts?.length > 0 ? (
                  doubts
                    ?.sort(
                      (a, b) =>
                        new Date(b?.created_at) - new Date(a?.created_at)
                    )
                    ?.map((doubt, index) => {
                      return (
                        <DoubtsCard
                          key={index}
                          picture={ProfilePic}
                          name={doubt.author_name}
                          // subject={getSubjectNameByBatchSubId(
                          //   doubt.batch_subject_id
                          // )}
                          chapter={doubt?.topic_title}
                          // responses={doubt?.response_count}
                          time={moment(doubt.created_at).fromNow()}
                          content={doubt.doubt_text}
                          editOnClick={() => {
                            openEditDoubtPopup(doubt)
                          }}
                          deleteOnClick={() => {
                            openDeleteDoubtPopup(doubt)
                          }}
                          responses={
                            responsesGroupByDoubtId?.[doubt._id]?.length || 0
                          }
                          buttonText={`SEE REPLIES (${
                            responsesGroupByDoubtId?.[doubt._id]?.length || 0
                          })`}
                          replyButton={() => openPopupDoubtReplies(doubt)}
                        />
                      )
                    })
                ) : (
                  <p>No doubts available</p>
                )}
              </Tab>
            </Tabs>
            <PopupDoubt
              className={popupDoubtRepliesClassName}
              close={() => closePopupDoubtReplies()}
              onClick=""
              buttonText="Save Doubt"
              popupHead="Doubt Replies"
            >
              <DoubtsCard
                picture={ProfilePic}
                name={doubtClicked?.author_name}
                // subject={getSubjectNameByBatchSubId(
                //   doubtClicked?.batch_subject_id
                // )}
                chapter={doubtClicked?.topic_title}
                responses={doubtClicked?.response_count}
                time={moment(doubtClicked?.created_at).fromNow()}
                content={doubtClicked?.doubt_text}
                editOnClick={() => {
                  openEditDoubtPopup(doubtClicked)
                }}
                deleteOnClick={() => {
                  openDeleteDoubtPopup(doubtClicked)
                }}
              />
              {responsesGroupByDoubtId?.[doubtClicked?._id]?.map((response) => {
                return (
                  <ReplyCard
                    picture={ProfilePic}
                    name={response.author_name}
                    time={moment(response.created_at).fromNow()}
                    content={response.response_text}
                  />
                )
              })}
              <InputSend
                placeholder="Reply your comment"
                onClick={(respondMsg) => {
                  const payload = {
                    response_text: respondMsg,
                    doubt_id: doubtClicked._id,
                    subject_id: doubtClicked.batch_subject_id,
                  }
                  dispatch(postReplyDoubt({ jwt, payload, topicId }))
                }}
              />
            </PopupDoubt>
          </Col25>
        </LeftRightLayout>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default TopicDetail
