export const checkStatus = response => {
    if (response.status >= 200 && response.status < 300) return response

    if (401 === response.status && response.statusText !== '') {
        let error = {message : "" , auth : true}
        error.message = response.statusText
        throw error
    } else {
        let error = { message: "", auth: false}
        error.message = response.statusText
        throw error
    }
}