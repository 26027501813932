import {
  GET_STUDENT_START,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_FAILURE,
  PATCH_STUDENT_START,
  PATCH_STUDENT_SUCCESS,
  PATCH_STUDENT_FAILURE,
  REMOVE_STUDENT_START,
  REMOVE_STUDENT_SUCCESS,
  REMOVE_STUDENT_FAILURE,
  UNARCHIVE_STUDENT_START,
  UNARCHIVE_STUDENT_SUCCESS,
  UNARCHIVE_STUDENT_FAILURE,
  POST_STUDENT_START,
  POST_STUDENT_SUCCESS,
  POST_STUDENT_FAILURE,
} from '../actions'

import { checkStatus } from '../helpers.js'

const getStudentStart = () => {
  return {
    type: GET_STUDENT_START,
  }
}

const getStudentSuccess = (student) => {
  return {
    type: GET_STUDENT_SUCCESS,
    student,
  }
}
const getStudentFailure = () => {
  return {
    type: GET_STUDENT_FAILURE,
  }
}

const fetchDataForStudent = ({ jwt, studentId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/students/${studentId}`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}
export const fetchStudent = (data) => {
  return async (dispatch) => {
    dispatch(getStudentStart())
    try {
      const receivedData = await fetchDataForStudent(data)
      const response = await receivedData.json()
      let student = response.data.student
      dispatch(getStudentSuccess(student))
    } catch (err) {
      dispatch(getStudentFailure())
    }
  }
}

const getEditStudentStart = () => {
  return {
    type: PATCH_STUDENT_START,
  }
}

const getEditStudentSuccess = (student) => {
  return {
    type: PATCH_STUDENT_SUCCESS,
    student,
  }
}
const getEditStudentFailure = () => {
  return {
    type: PATCH_STUDENT_FAILURE,
  }
}

const editDataForStudent = ({ jwt, studentId, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/students/${studentId}`
  const options = {
    method: 'PATCH',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const editStudent = (data) => {
  return async (dispatch) => {
    dispatch(getEditStudentStart())
    try {
      const receivedData = await editDataForStudent(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      let student = response.student
      dispatch(getEditStudentSuccess(student))
    } catch (err) {
      dispatch(getEditStudentFailure())
    }
  }
}

const removeStudentStart = () => {
  return {
    type: REMOVE_STUDENT_START,
  }
}

const removeStudentSuccess = (student) => {
  return {
    type: REMOVE_STUDENT_SUCCESS,
  }
}
const removeStudentFailure = () => {
  return {
    type: REMOVE_STUDENT_FAILURE,
  }
}

const deleteStudentApiCall = ({ jwt, payload }) => {
  console.log(payload.student_id, 'payload.student_id')
  const url = `${process.env.REACT_APP_SERVER}/api/students`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const deleteStudent = (data) => {
  return async (dispatch) => {
    dispatch(removeStudentStart())
    try {
      const receivedData = await deleteStudentApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(removeStudentSuccess())
    } catch (err) {
      dispatch(removeStudentFailure())
    }
  }
}

const unarchiveStudentStart = () => {
  return {
    type: UNARCHIVE_STUDENT_START,
  }
}

const unarchiveStudentSuccess = (student) => {
  return {
    type: UNARCHIVE_STUDENT_SUCCESS,
    student,
  }
}
const unarchiveStudentFailure = () => {
  return {
    type: UNARCHIVE_STUDENT_FAILURE,
  }
}

const unarchiveStudentApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/students/dearc/student/${payload.student_id}`
  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const unarchiveStudent = (data) => {
  return async (dispatch) => {
    dispatch(unarchiveStudentStart())
    try {
      const receivedData = await unarchiveStudentApiCall(data)
      const response = await receivedData.json()
      let student = response.data.student
      dispatch(unarchiveStudentSuccess(student))
    } catch (err) {
      dispatch(unarchiveStudentFailure())
    }
  }
}

//Add Student
const postStudentStart = () => ({
  type: POST_STUDENT_START,
})

const postStudentFail = (errMsg) => ({
  type: POST_STUDENT_FAILURE,
  errMsg,
})

const postStudentSuccess = (data) => ({
  type: POST_STUDENT_SUCCESS,
  data,
})

export const startAddStudent = (data) => {
  return async (dispatch) => {
    dispatch(postStudentStart())
    try {
      const receivedData = await addStudent(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(postStudentSuccess(response.data))
    } catch (err) {
      dispatch(postStudentFail())
    }
  }
}

const addStudent = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/students`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}
