import {
  GET_INSTITUTION_START,
  GET_INSTITUTION_SUCCESS,
  GET_INSTITUTION_FAILURE,
  POST_INSTITUTION_START,
  POST_INSTITUTION_SUCCESS,
  POST_INSTITUTION_FAILURE,
} from '../actions'
import { checkStatus } from '../helpers.js'

const getInstitutionStart = () => {
  return {
    type: GET_INSTITUTION_START,
  }
}

const getInstitutionSuccess = ({ institution }) => {
  return {
    type: GET_INSTITUTION_SUCCESS,
    institution,
  }
}

const getInstitutionFailed = () => {
  return {
    type: GET_INSTITUTION_FAILURE,
  }
}

const getInstitutionById = ({ institutionId, jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/institutions/${institutionId}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

//get institution by id
export const getInstitution = (data) => {
  return async (dispatch) => {
    dispatch(getInstitutionStart())
    try {
      const receivedData = await getInstitutionById(data)
      const response = await receivedData.json()
      dispatch(getInstitutionSuccess(response.data))
    } catch (err) {
      dispatch(getInstitutionFailed())
    }
  }
}

const postInstitutionStart = () => {
  return {
    type: POST_INSTITUTION_START,
  }
}

const postInstitutionSuccess = () => {
  return {
    type: POST_INSTITUTION_SUCCESS,
  }
}

const postInstitutionFailed = () => {
  return {
    type: POST_INSTITUTION_FAILURE,
  }
}

const postInstitutionApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/institutions`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

//post institution
export const postInstitution = (data) => {
  return async (dispatch) => {
    dispatch(postInstitutionStart())
    try {
      const receivedData = await postInstitutionApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(postInstitutionSuccess(response))
    } catch (err) {
      dispatch(postInstitutionFailed())
    }
  }
}
