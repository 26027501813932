import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
// import { Link } from "react-router-dom";
// import { getPermission } from "../../redux/reducer/account";
import { emitLogout } from '../../redux/action/account.js'
import { useDispatch } from 'react-redux'
import Profile from '../../assets/images/profile.png'
import Notification from '../../assets/images/notification.png'
import Welcome from '../../assets/images/welcome.png'
import { useHistory } from 'react-router-dom'

import { fetchAssessmentsLongPolling } from '../../redux/action/assessment'
import { fetchOnlineSessionsLongPolling } from '../../redux/action/onlineSession'
import { fetchAnnouncements } from '../../redux/action/announcements'

import { getAssessments } from '../../redux/reducer/assessment'
import { getAnnouncements } from '../../redux/reducer/announcements'
import { getOnlineSessions } from '../../redux/reducer/onlineSession'
import {
  getJwt,
  getEntityType,
  getUsersInstitutionId,
} from '../../redux/reducer/account'
import { getBatchSubjects } from '../../redux/reducer/subject'
import Popup from '../assets/layout/popupVideo'

// import { useInterval } from '../helpers'
import { setAssessment, setBatchSubjectId } from '../../redux/action/ui'

const Header = (props) => {
  const history = useHistory()

  const [navState, setnavState] = useState(true)
  const [profileState, setProfileState] = useState(true)
  const [localAssessments, setLocalAssessments] = useState([])
  const [localSessions, setLocalSessions] = useState([])
  const [localAnnouncements, setlocalAnnouncements] = useState([])
  const [popClassName, setPopClassName] = useState('hidePop')

  const showPopup = (e) => {
    e.preventDefault()
    setPopClassName('showPop')
  }
  const closePopup = () => {
    setPopClassName('hidePop')
  }

  const onToggleNotification = () => {
    setnavState(!navState)
    setProfileState(true)
  }
  const onToggleProfile = () => {
    setProfileState(!profileState)
    setnavState(true)
  }

  const dispatch = useDispatch()

  let jwt = useSelector(getJwt)
  let assessments = useSelector(getAssessments)
  let onlineSessions = useSelector(getOnlineSessions)
  let batchSubjects = useSelector(getBatchSubjects)
  let announcements = useSelector(getAnnouncements)
  let entityType = useSelector(getEntityType)
  let institutionId = useSelector(getUsersInstitutionId)

  useEffect(() => {
    if (entityType === 'faculties' || entityType === 'students') {
      dispatch(fetchAssessmentsLongPolling({ jwt }))
      dispatch(fetchOnlineSessionsLongPolling({ jwt }))
      dispatch(fetchAnnouncements({ jwt }))
    }
  }, [dispatch, entityType, jwt])

  const getSubjectNameByBatchSubjectId = (id) => {
    const filtered = batchSubjects.filter((subject) => subject._id === id)
    return filtered.length ? filtered[0].subject_title : ''
  }

  useEffect(() => {
    if (assessments?.length) {
      setLocalAssessments(
        assessments?.filter(
          (assessment) =>
            assessment.has_taken !== true &&
            new Date() <= new Date(assessment?.due_date)
        )
      )
    }
    if (onlineSessions?.length) {
      setLocalSessions(
        onlineSessions?.filter((session) => {
          const filtered = batchSubjects.filter(
            (subject) =>
              subject._id === (session?.batch_subject_id || session?.subject_id)
          )
          return (
            new Date() < new Date(session?.end_time) &&
            (filtered.length ? filtered[0].subject_title : '')
          )
        })
      )
    }
    if (announcements?.length) {
      setlocalAnnouncements(
        announcements?.filter(
          (announcement) =>
            parseInt(moment().diff(announcement?.updated_at, 'days')) < 3
        )
      )
    }
  }, [assessments, onlineSessions, announcements, batchSubjects])

  // const entityType = useSelector(getPermission);
  const onClick = (e) => {
    e.preventDefault()
    dispatch(emitLogout())
  }

  const nav = () => {
    setnavState(true)
  }
  const profile = () => {
    setProfileState(true)
  }

  window.addEventListener('click', function (e) {
    if (!document.getElementById('notification')?.contains(e.target)) {
      nav()
    }
    if (!document.getElementById('profile')?.contains(e.target)) {
      profile()
    }
  })

  const onClickProfile = () => {
    onToggleProfile()
    if (entityType === 'students') {
      history.push('/me/profile')
    } else if (entityType === 'faculties') {
      history.push('/faculty/profile')
    }
  }

  const onClickTakeAssessment = (id, title, batchSubjectId, assessment) => {
    dispatch(setAssessment(id, title, assessment))
    dispatch(setBatchSubjectId(batchSubjectId))
    history.push(`/assessments/questions`)
  }

  const onClickedAttend = (e, session) => {
    e.preventDefault()
    history.push({
      pathname: '/me/session/detail',
      state: { session },
    })
  }

  return (
    <header>
      <nav id="menu" className="navbar navbar-expand-lg">
        <div className="navbar-brand client__logo">
          <img src={props.InstitutionNavLogo} alt="Logo" />
          {/* <p>{props.institutionTitle}</p> */}
        </div>
        <div className="top__navigation">
          <ul className="header__nav navbar-nav nav ml-auto float-right">
            <li id="welcome" className="welcome">
              <img onClick={(e) => showPopup(e)} src={Welcome} alt="Welcome" />
              <p className="img__description">Click here for Demo</p>
            </li>
            <li id="notification" className="notification">
              <img
                onClick={() => onToggleNotification()}
                src={Notification}
                alt="Notification"
              />
              <span className="notification__count">
                {(entityType === 'students' ? localAssessments.length : 0) +
                  localSessions.length +
                  localAnnouncements.length}
              </span>
              <ul
                className={
                  'notification__wrapper ' + (navState ? '' : 'collapse')
                }
              >
                <li className="notification__header">
                  <span>
                    All Notifications (
                    {(entityType === 'students' ? localAssessments.length : 0) +
                      localSessions.length +
                      localAnnouncements.length}
                    )
                  </span>
                  {/* <span>Unread Notifications (0)</span> */}
                </li>
                {localSessions
                  ?.sort(
                    (a, b) => new Date(a?.start_time) - new Date(b?.start_time)
                  )
                  ?.map((session, index) => {
                    const disableStatus =
                      new Date() > new Date(session?.end_time) ||
                      moment().isBefore(
                        moment(session?.start_time).subtract(5, 'minutes')
                      )
                    return (
                      <li
                        className="notification__list"
                        key={index}
                        onClick={(e) =>
                          !disableStatus ? onClickedAttend(e, session) : null
                        }
                      >
                        <h5>{session.title} | Session</h5>
                        <p className="notification__details">
                          {session.title}{' '}
                          <span>
                            {getSubjectNameByBatchSubjectId(
                              session.batch_subject_id
                            )}
                          </span>
                        </p>
                        <p className="notification__time">
                          {'Start Time : ' +
                            moment(session?.start_time).format(
                              'Do MMM hh:mm A'
                            )}
                        </p>
                        {/* <p className="notification__symbol--true"></p> */}
                        <div className="clear"></div>
                      </li>
                    )
                  })}
                {entityType === 'students'
                  ? localAssessments
                      ?.sort(
                        (a, b) => new Date(a?.due_date) - new Date(b?.due_date)
                      )
                      ?.map((assessment, index) => {
                        return (
                          <li
                            className="notification__list"
                            key={index}
                            onClick={() =>
                              moment().isSameOrAfter(
                                moment(assessment?.start_date)
                              )
                                ? onClickTakeAssessment(
                                    assessment._id,
                                    assessment.title,
                                    assessment.batch_subject_id,
                                    assessment
                                  )
                                : null
                            }
                          >
                            <h5>{assessment.title} Pending</h5>
                            <p className="notification__details">
                              {assessment.description}{' '}
                              <span>
                                {getSubjectNameByBatchSubjectId(
                                  assessment.batch_subject_id
                                )}
                              </span>
                            </p>
                            <p className="notification__time">
                              {'Submit On : ' +
                                moment(assessment?.start_date).format(
                                  'DD-MM-YYYY | hh:mm A'
                                )}
                            </p>
                            {/* <p className="notification__symbol--true"></p> */}
                            <div className="clear"></div>
                          </li>
                        )
                      })
                  : null}
                {localAnnouncements
                  ?.sort(
                    (a, b) => new Date(a?.updated_at) - new Date(b?.updated_at)
                  )
                  ?.map((announcement, index) => {
                    return (
                      <li className="notification__list" key={index}>
                        <h5>{announcement?.title}</h5>
                        <p className="notification__details">
                          {announcement?.description}
                        </p>
                        <p className="notification__time">
                          {moment(announcement?.updated_at).format(
                            'DD-MM-YYYY'
                          )}
                        </p>
                        {/* <p className="notification__symbol--true"></p> */}
                        <div className="clear"></div>
                      </li>
                    )
                  })}
              </ul>
            </li>
            <li id="profile" className="profile">
              <img
                onClick={() => onToggleProfile()}
                src={Profile}
                alt="Profile"
              />
              <ul
                className={
                  'profile__wrapper ' + (profileState ? '' : 'collapse')
                }
              >
                <li className="profile__header">Profile</li>
                <li onClick={onClickProfile}>My Profile</li>
                <li onClick={onClick}>Logout</li>
              </ul>
            </li>
          </ul>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </nav>
      <Popup
        className={popClassName}
        close={() => {
          closePopup()
        }}
      >
        {entityType === 'students' ? (
          institutionId === '5f0adcb30798ba0cb3ff0255' ? (
            <iframe
              src="https://player.vimeo.com/video/589774575"
              width="100%"
              height="550"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          ) : (
            <iframe
              src="https://player.vimeo.com/video/467831291"
              width="100%"
              height="550"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            ></iframe>
          )
        ) : (
          <iframe
            src="https://player.vimeo.com/video/467831510"
            width="100%"
            height="550"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          ></iframe>
        )}
      </Popup>
      <div className="clear"></div>
    </header>
  )
}

export default Header
