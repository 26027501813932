import {
  SET_BATCH,
  SET_SUBJECT,
  SET_CHAPTER,
  SET_TOPIC,
  SET_ASSESSMENT,
  SET_BATCH_SUBJECT_ID,
  SET_DOUBT_ID,
} from '../actions'

export const setBatch = (batchId, batchTitle) => {
  return {
    type: SET_BATCH,
    batchId,
    batchTitle,
  }
}

export const setSubject = (subjectId, subjectTitle) => {
  return {
    type: SET_SUBJECT,
    subjectId,
    subjectTitle,
  }
}

export const setChapter = (chapterId, chapterTitle) => {
  return {
    type: SET_CHAPTER,
    chapterId,
    chapterTitle,
  }
}

export const setTopic = (topicId, topicTitle) => {
  return {
    type: SET_TOPIC,
    topicId,
    topicTitle,
  }
}

export const setAssessment = (assessmentId, assessmentTitle, assessment) => {
  return {
    type: SET_ASSESSMENT,
    assessmentId,
    assessmentTitle,
    assessment,
  }
}

export const setBatchSubjectId = (batchSubjectId) => {
  return {
    type: SET_BATCH_SUBJECT_ID,
    batchSubjectId,
  }
}

export const setDoubtId = (doubtId) => {
  return {
    type: SET_DOUBT_ID,
    doubtId,
  }
}
