import {
  GET_STUDENT_START,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_FAILURE,
  REMOVE_STUDENT_START,
  REMOVE_STUDENT_SUCCESS,
  REMOVE_STUDENT_FAILURE,
  UNARCHIVE_STUDENT_START,
  UNARCHIVE_STUDENT_SUCCESS,
  UNARCHIVE_STUDENT_FAILURE,
  PATCH_STUDENT_START,
  PATCH_STUDENT_SUCCESS,
  PATCH_STUDENT_FAILURE,
  POST_STUDENT_START,
  POST_STUDENT_SUCCESS,
  POST_STUDENT_FAILURE,
  APP_LOGOUT,
} from '../actions'

let initialState = {
  students: [],
  isLoading: false,
  isError: false,
  studentListRemove: [],
  isLoadingRemoveStudent: false,
  isLoadingEdit: false,
  isErrorRemove: false,
  addedStudent: {},
}
export const students = (state = initialState, action) => {
  switch (action.type) {
    case POST_STUDENT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case POST_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addedStudent: action.data,
        isError: false,
      }
    case POST_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case GET_STUDENT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case GET_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        students: action.student,
      }
    case GET_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case PATCH_STUDENT_START:
      return {
        ...state,
        isLoadingEdit: true,
        isError: false,
      }
    case PATCH_STUDENT_SUCCESS:
      return {
        ...state,
        isLoadingEdit: false,
        isError: false,
        students: action.student,
      }
    case PATCH_STUDENT_FAILURE:
      return {
        ...state,
        isLoadingEdit: false,
        isError: true,
      }
    case REMOVE_STUDENT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case REMOVE_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      }
    case REMOVE_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case UNARCHIVE_STUDENT_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case UNARCHIVE_STUDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        students: action.student,
      }
    case UNARCHIVE_STUDENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

export const getStudents = (state) => state.students.students
export const getIsLoading = (state) => state.students.isLoading
export const getIsError = (state) => state.students.isError
export const getRemoveStudents = (state) => state.students.students
export const getRemoveIsLoading = (state) => state.students.isLoading
export const getRemoveIsError = (state) => state.students.isError
export const getUnarchiveStudents = (state) => state.students.students
export const getUnarchiveIsLoading = (state) => state.students.isLoading
export const getUnarchiveIsError = (state) => state.students.isError
export const getIsLoadingEdit = (state) => state.students.isLoadingEdit
export const getAddedStudent = (state) => state.students.addedStudent
