import {
  GET_STUDENT_STUDY_PLAN_START,
  GET_STUDENT_STUDY_PLAN_SUCCESS,
  GET_STUDENT_STUDY_PLAN_FAILURE,
  GET_STUDENT_STUDY_BY_TOPICID_START,
  GET_STUDENT_STUDY_BY_TOPICID_SUCCESS,
  GET_STUDENT_STUDY_BY_TOPICID_FAILURE,
  POST_STUDENT_STUDY_PLAN_START,
  POST_STUDENT_STUDY_PLAN_SUCCESS,
  POST_STUDENT_STUDY_PLAN_FAILURE,
  GET_STUDENT_WEEKLY_PLAN_START,
  GET_STUDENT_WEEKLY_PLAN_SUCCESS,
  GET_STUDENT_WEEKLY_PLAN_FAILURE,
  GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_START,
  GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_SUCCESS,
  GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_FAILURE,
  DELETE_STUDY_PLAN_START,
  DELETE_STUDY_PLAN_SUCCESS,
  DELETE_STUDY_PLAN_FAILURE,
} from '../actions'

import { checkStatus } from '../helpers.js'
// import { getById } from '../../helper/utils'
import _ from 'underscore'

const studentStudyPlanStart = () => {
  return {
    type: GET_STUDENT_STUDY_PLAN_START,
  }
}
const studentStudyPlanSuccess = (data) => {
  const { studyplan } = data

  return {
    type: GET_STUDENT_STUDY_PLAN_SUCCESS,
    studyplan,
  }
}

const studyPlanSuccess = (data) => {
  const { studyplans } = data

  const studyplanByBatch = _.groupBy(studyplans, 'batch_id')

  return {
    type: GET_STUDENT_STUDY_PLAN_SUCCESS,
    studyplan: studyplans,
    studyplanByBatch,
  }
}

const studentStudyPlanFailure = () => {
  return {
    type: GET_STUDENT_STUDY_PLAN_FAILURE,
  }
}

const studentStudyPlan = ({ jwt, date }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/me/studyplans/daily/${date}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchStudentStudyPlan = (data) => {
  return async (dispatch) => {
    dispatch(studentStudyPlanStart())
    try {
      const receivedData = await studentStudyPlan(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(studentStudyPlanSuccess(response?.data))
    } catch (err) {
      dispatch(studentStudyPlanFailure())
    }
  }
}

const studyPlanDaily = ({ jwt, date }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/study-plans/daily/${date}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchStudyPlanDaily = (data) => {
  return async (dispatch) => {
    dispatch(studentStudyPlanStart())
    try {
      const receivedData = await studyPlanDaily(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(studyPlanSuccess(response?.data))
    } catch (err) {
      dispatch(studentStudyPlanFailure())
    }
  }
}

const studentWeeklyPlanStart = () => {
  return {
    type: GET_STUDENT_WEEKLY_PLAN_START,
  }
}
const studentWeeklyPlanSuccess = (data) => {
  const { studyplan } = data
  return {
    type: GET_STUDENT_WEEKLY_PLAN_SUCCESS,
    studyplan,
  }
}

const studentWeeklyPlanFailure = () => {
  return {
    type: GET_STUDENT_WEEKLY_PLAN_FAILURE,
  }
}

const studentStudyWeeklyPlan = ({ jwt, week }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/study-plans/weekly/${week}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchStudentStudyWeeklyPlan = (data) => {
  return async (dispatch) => {
    dispatch(studentWeeklyPlanStart())
    try {
      const receivedData = await studentStudyWeeklyPlan(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(studentWeeklyPlanSuccess(response?.data))
    } catch (err) {
      dispatch(studentWeeklyPlanFailure())
    }
  }
}

const postStudyPlanStart = () => {
  return {
    type: POST_STUDENT_STUDY_PLAN_START,
  }
}
const postStudyPlanSuccess = (data) => {
  const { studyplan } = data
  return {
    type: POST_STUDENT_STUDY_PLAN_SUCCESS,
    studyplan,
  }
}

const postStudyPlanFailure = () => {
  return {
    type: POST_STUDENT_STUDY_PLAN_FAILURE,
  }
}

const postStudyPlanApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/study-plans`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postStudyPlan = (data) => {
  return async (dispatch) => {
    dispatch(postStudyPlanStart())
    try {
      const receivedData = await postStudyPlanApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(fetchStudyPlanDaily({ date: data.payload.date, jwt: data.jwt }))
      dispatch(postStudyPlanSuccess(response?.data))
    } catch (err) {
      dispatch(postStudyPlanFailure())
    }
  }
}

const deleteStudyPlanStart = () => {
  return {
    type: DELETE_STUDY_PLAN_START,
  }
}
const deleteStudyPlanSuccess = (studyplans) => {
  const studyplanByBatch = _.groupBy(studyplans, 'batch_id')
  return {
    type: DELETE_STUDY_PLAN_SUCCESS,
    studyplans,
    studyplanByBatch,
  }
}

const deleteStudyPlanFailure = () => {
  return {
    type: DELETE_STUDY_PLAN_FAILURE,
  }
}

const deleteStudyPlanApiCall = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/study-plans/${id}`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const deleteStudyPlan = (data) => {
  return async (dispatch, getState) => {
    dispatch(deleteStudyPlanStart())
    try {
      const receivedData = await deleteStudyPlanApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      const { studyplan } = getState()
      const { studentStudyPlan } = studyplan
      const filtered = studentStudyPlan.filter((plan) => plan._id !== data?.id)
      dispatch(deleteStudyPlanSuccess(filtered))
    } catch (err) {
      dispatch(deleteStudyPlanFailure())
    }
  }
}

//fetch study plan by topic id
const studyPlanByTopicIdStart = () => {
  return {
    type: GET_STUDENT_STUDY_BY_TOPICID_START,
  }
}
const studyPlanByTopicIdSuccess = (response) => {
  return {
    type: GET_STUDENT_STUDY_BY_TOPICID_SUCCESS,
    data: response?.data?.studyplan,
  }
}

const studyPlanByTopicIdFailure = () => {
  return {
    type: GET_STUDENT_STUDY_BY_TOPICID_FAILURE,
  }
}

const studyPlanByTopicIdApiCall = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/study-plans/topic/${id}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchStudyPlanByTopicId = (data) => {
  return async (dispatch) => {
    dispatch(studyPlanByTopicIdStart())
    try {
      const receivedData = await studyPlanByTopicIdApiCall(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(studyPlanByTopicIdSuccess(response))
    } catch (err) {
      dispatch(studyPlanByTopicIdFailure())
    }
  }
}

//fetch study plan by chapter and batch sub id
const studentPlanByChapterBatchSubjectStart = () => {
  return {
    type: GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_START,
  }
}
const studentPlanByChapterBatchSubjectSuccess = (data) => {
  const { studyplans } = data
  return {
    type: GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_SUCCESS,
    studyplans,
  }
}

const studentPlanByChapterBatchSubjectFailure = () => {
  return {
    type: GET_STUDENT_STUDY_PLAN_BY_CHAPTER_BACTCH_SUB_FAILURE,
  }
}

const studentStudyPlanByChapterBatchSubject = ({
  jwt,
  chapterId,
  batchSubjectId,
}) => {
  const url = `${process.env.REACT_APP_SERVER}/api/study-plans/chapter-id/${chapterId}/batch-subject-id/${batchSubjectId}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchStudentStudyPlanByChapterBatchSubject = (data) => {
  return async (dispatch) => {
    dispatch(studentPlanByChapterBatchSubjectStart())
    try {
      const receivedData = await studentStudyPlanByChapterBatchSubject(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(studentPlanByChapterBatchSubjectSuccess(response?.data))
    } catch (err) {
      dispatch(studentPlanByChapterBatchSubjectFailure())
    }
  }
}
