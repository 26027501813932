import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Tab, Tabs } from 'react-bootstrap'
import moment from 'moment'
//assets
import Loader from '../assets/molecules/loader'
import LeftRightLayout from '../assets/layout/leftrightlayout'
import Col75 from '../assets/layout/leftrightlayout/column75'
import Col25 from '../assets/layout/leftrightlayout/column25'
import TopicsCard from '../assets/molecules/topicsCard'
import Accordion from '../assets/layout/accordion'
import TeachersCard from '../assets/molecules/teachersCard'
import DoubtsCard from '../assets/molecules/doubtsCard'
import TeachersSubjectCard from '../assets/molecules/teachersSubjectCard'
import ExamsCard from '../assets/molecules/examsCard'
import TeachersIcon from '../../assets/images/profile.png'
import ProfilePic from '../../assets/images/profile.png'
import Popup from '../assets/layout/popupRight'
import ReplyCard from '../assets/molecules/replyCard'
import InputSend from '../assets/molecules/inputSend'
import PopupConfirm from '../../components/assets/layout/popupConfirm'

//action
//topic
import {
  fetchTopicsByChapterId,
  fetchTopicsByTeachers,
} from '../../redux/action/topic'
import { fetchChaptersBySubjectId } from '../../redux/action/chapter'
import { fetchAssessments } from '../../redux/action/assessment'
//ui
import {
  setTopic,
  setAssessment,
  setBatchSubjectId,
} from '../../redux/action/ui'
import {
  postReplyDoubt,
  fetchDoubtsResponsesByIds,
  editStudentDoubtsById,
  deleteStudentDoubtsById,
} from '../../redux/action/doubt'

//reducer
//subject(chapters also implemented in subject)
//import { getSubjects } from '../../redux/reducer/subject'
import { getChapters } from '../../redux/reducer/chapter'
import {
  getIsTopicsLoading,
  getTopics,
  getIsLoadingTopicsByTeachers,
  getIsErrorTopicsByTeachers,
  getTopicsGroupByFaculty,
} from '../../redux/reducer/topic'
//account
import { getJwt, getUsername } from '../../redux/reducer/account'

import {
  getSubjectId,
  getChapterId,
  getSubjectTitle,
  getChapterTitle,
  getBatchSubjectId,
} from '../../redux/reducer/ui'
import {
  getIsFacultiesLoading,
  getFaculties,
} from '../../redux/reducer/faculty'
import { getAssessments } from '../../redux/reducer/assessment'
import {
  getDoubts,
  getResponsesGroupByDoubtId,
  // getIsDoubtsLoading,
  // getIsError,
} from '../../redux/reducer/doubt'

const Topic = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { state } = useLocation()
  const chapterData = state?.chapterData
  //selectors
  //account
  const jwt = useSelector(getJwt)
  //ui
  const subjectId = useSelector(getSubjectId)
  const chapterId = useSelector(getChapterId)
  const subjectTitle = useSelector(getSubjectTitle)
  const chapterTitle = useSelector(getChapterTitle)
  const batchSubjectId = useSelector(getBatchSubjectId)
  //const subjects = useSelector(getSubjects)
  const doubts = useSelector(getDoubts)
  const isFacultiesLoading = useSelector(getIsFacultiesLoading)
  const isLoadingTopicsByFaculty = useSelector(getIsLoadingTopicsByTeachers)
  const isErrorTopicsByFaculty = useSelector(getIsErrorTopicsByTeachers)
  const topicsGroupByFaculty = useSelector(getTopicsGroupByFaculty)
  const responsesGroupByDoubtId = useSelector(getResponsesGroupByDoubtId)
  const username = useSelector(getUsername)
  // const isDoubtsLoading = useSelector(getIsDoubtsLoading);
  // const isDoubtError = useSelector(getIsError);

  //subject(topics)
  //const subjects = useSelector(getSubjects)
  const chapters = useSelector(getChapters)
  const topics = useSelector(getTopics)
  const isLoading = useSelector(getIsTopicsLoading)
  const faculties = useSelector(getFaculties)
  let assessments = useSelector(getAssessments)

  useEffect(() => {
    dispatch(fetchTopicsByChapterId({ jwt, chapterId }))
    dispatch(fetchAssessments({ jwt }))
    chapters.length === 0 &&
      dispatch(fetchChaptersBySubjectId({ jwt, subjectId }))
  }, [dispatch, jwt, chapterId, chapters.length, subjectId])

  useEffect(() => {
    if (doubts.length) {
      const doubtIds = doubts?.map((doubt) => doubt._id)
      dispatch(fetchDoubtsResponsesByIds({ jwt, doubtIds }))
    }
  }, [dispatch, doubts, jwt])

  const [localFaculties, setLocalFaculties] = useState([])
  const [localDoubts, setLocalDoubts] = useState([])
  const [localAssessments, setLocalAssessments] = useState([])
  const [doubtClicked, setDoubtClicked] = useState()

  useEffect(() => {
    setLocalFaculties(
      faculties.filter((faculty) =>
        faculty.subjects.some((subject) => subjectId === subject.id)
      )
    )
    setLocalDoubts(
      doubts.filter((doubt) => doubt.batch_subject_id === batchSubjectId)
    )
    setLocalAssessments(
      assessments.filter(
        (assessment) => assessment.batch_subject_id === batchSubjectId
      )
    )
  }, [faculties, doubts, assessments, subjectId, batchSubjectId])

  useEffect(() => {
    if (faculties.length) {
      const facultyIds = faculties?.map((faculty) => faculty._id)
      dispatch(fetchTopicsByTeachers({ jwt, facultyIds }))
    }
  }, [dispatch, faculties, jwt])

  // const onChangeChapter = (e) => {
  //   e.preventDefault();
  //   const chapterTitle =
  //     e.target.options[e.target.options.selectedIndex].innerText;
  //   dispatch(setChapter(e.target.value, chapterTitle));
  // };

  const onTopicSelect = (topicId, topicTitle) => {
    dispatch(setTopic(topicId, topicTitle))
    history.push('/me/subjects/chapters/topics/detail')
  }

  // const getFacultiesBySubjectId = (id) => {
  //   const filtered = subjects?.filter((subject) => subject.subject_id === id)
  //   return filtered[0].faculties?.map((faculty) => faculty.name).join(', ')
  // }

  const onAssessmentSelect = (id, title, batchSubjectId, taken, status) => {
    dispatch(setAssessment(id, title))
    dispatch(setBatchSubjectId(batchSubjectId))
    if (status === 'CLOSED') {
      history.push(`/assessments/results`)
    } else {
      if (!taken) {
        history.push(`/assessments/questions`)
      }
    }
  }

  const [popClassName, setPopClassName] = useState('hidePop')
  const openPopup = (doubt) => {
    setDoubtClicked(doubt)
    setPopClassName('showPop')
  }
  const closePopup = () => {
    setPopClassName('hidePop')
    setDoubtClicked()
  }

  const [editText, setEditText] = useState()
  const [selectedDoubt, setSelectedDoubt] = useState('')
  const [editDoubtPopClassName, setEditDoubtPopClassName] = useState('hidePop')
  const [deleteDoubtPopClassName, setDeleteDoubtPopClassName] = useState(
    'hidePop'
  )

  const openEditDoubtPopup = (doubt) => {
    setEditDoubtPopClassName('showPop')
    setSelectedDoubt(doubt)
    setEditText(doubt.doubt_text)
  }

  const openDeleteDoubtPopup = (doubt) => {
    setDeleteDoubtPopClassName('showPop')
    setSelectedDoubt(doubt)
  }

  const closeEditDoubtPopup = () => {
    setEditText('')
    setEditDoubtPopClassName('hidePop')
  }

  const closeDeleteDoubtPopup = () => {
    setDeleteDoubtPopClassName('hidePop')
  }

  const onEditDoubt = () => {
    dispatch(
      editStudentDoubtsById({
        jwt,
        doubtId: selectedDoubt._id,
        payload: {
          doubt_text: editText,
        },
      })
    )
    setEditText('')
    closeEditDoubtPopup()
  }

  const onDeleteDoubt = () => {
    dispatch(deleteStudentDoubtsById({ doubtId: selectedDoubt._id, jwt }))
    setSelectedDoubt('')
    closeDeleteDoubtPopup()
  }

  return (
    <div className="tabs__container">
      <section>
        <h1>{chapterTitle}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/me/subjects">{subjectTitle}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/me/subjects/chapters">{chapterTitle}</Link>
            </li>
            <li className="breadcrumb-item active">Topics</li>
          </ol>
        </nav>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col75>
            {/* <Select
              onChange={(e) => onChangeChapter(e)}
              defaultValue={chapterId}
            >
              {chapters?.map((chapter, index) => {
                return (
                  <option
                    value={chapter._id}
                    key={index}
                    selected={chapter._id === chapterId}
                  >
                    {chapter.title}
                  </option>
                )
              })}
            </Select> */}

            {isLoading ? (
              <Loader />
            ) : subjectId ? (
              <section>
                <Link className="backLink" to="/me/subjects/chapters">
                  {' '}
                  <p className="back">{subjectTitle}</p>
                </Link>
                <div className="chaptersCard chaptersCard--topicListing row">
                  <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 chaptersCard__description ">
                    <h5>{chapterData?.title}</h5>
                    <p className="chaptersCard__availableTopics">
                      Available topics:{' '}
                      <span>{chapterData?.visible_topic_count}</span>
                    </p>
                    <p className="chaptersCard__noOfTopics">
                      Completed: <span>{chapterData?.read_topic_count}</span>
                    </p>
                    <p className="chaptersCard__total">
                      Total: <span>{chapterData?.topic_count}</span>
                    </p>
                    <div className="clear"></div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <div
                      className="progress"
                      data-percentage={Math.round(
                        (chapterData?.read_topic_count /
                          chapterData?.topic_count) *
                          100
                      )}
                    >
                      <span className="progress-left">
                        <span className="progress-bar"></span>
                      </span>
                      <span className="progress-right">
                        <span className="progress-bar"></span>
                      </span>
                      <div className="progress-value">
                        <div>
                          {Math.round(
                            (chapterData?.read_topic_count /
                              chapterData?.topic_count) *
                              100
                          )}
                          %
                        </div>
                      </div>
                    </div>
                    <div className="clear"></div>
                  </div>
                </div>
                {topics?.filter((topic) => topic?.visibility)?.length ? (
                  topics
                    ?.filter((topic) => topic?.visibility)
                    ?.map((topic, index) => {
                      return (
                        <div className="topics__wrapper">
                          <TopicsCard
                            key={index}
                            img="https://cwxdata.s3.ap-south-1.amazonaws.com/phy.jpg"
                            heading={topic.title}
                            description={topic.wysiwyg_state}
                            button="Read"
                            buttonClass="button--blue"
                            onClick={() =>
                              onTopicSelect(topic._id, topic.title)
                            }
                          />
                          <div className="clear"></div>
                        </div>
                      )
                    })
                ) : (
                  <div>No Topics</div>
                )}
                <div className="clear"></div>
              </section>
            ) : (
              <div> Please Select a Subject </div>
            )}
            <PopupConfirm
              className={editDoubtPopClassName}
              close={() => closeEditDoubtPopup()}
              onClick={() => onEditDoubt()}
              buttonText="Save Doubt"
              popupHead="Edit Doubt"
            >
              <textarea
                rows="5"
                value={editText || ''}
                onChange={(e) => setEditText(e.target.value)}
              ></textarea>
            </PopupConfirm>
            <PopupConfirm
              className={deleteDoubtPopClassName}
              close={() => closeDeleteDoubtPopup()}
              onClick={() => onDeleteDoubt()}
              buttonText="yes"
              popupHead="Delete Doubts"
            >
              <h2>Are you sure want to delete?</h2>
            </PopupConfirm>
          </Col75>
          <Col25>
            <Tabs>
              <Tab
                eventKey="teachers"
                title={`Teachers (${localFaculties.length})`}
              >
                <Accordion>
                  {isFacultiesLoading ? (
                    <Loader />
                  ) : (
                    localFaculties?.map((faculty, i) => {
                      const facultyTopicsBelongsToChapter = topicsGroupByFaculty?.[
                        faculty._id
                      ]?.filter((topic) => topic.chapter_id === chapterId)
                      return (
                        <TeachersCard
                          key={i}
                          itemNo={i + 1}
                          icon={TeachersIcon}
                          teachersName={faculty.name}
                          designation={faculty.designation}
                        >
                          <h5>List of topics</h5>
                          {facultyTopicsBelongsToChapter?.length ? (
                            facultyTopicsBelongsToChapter.map(
                              (topic, index) => {
                                return (
                                  <TeachersSubjectCard
                                    onClick={() =>
                                      onTopicSelect(topic._id, topic.title)
                                    }
                                    topic={topic.title}
                                  />
                                )
                              }
                            )
                          ) : (
                            <p>No topics available</p>
                          )}
                        </TeachersCard>
                      )
                    })
                  )}
                </Accordion>
              </Tab>
              <Tab
                className="doubtsTab"
                eventKey="doubts"
                title={`Doubts (${localDoubts?.length})`}
              >
                {localDoubts?.length > 0 ? (
                  localDoubts
                    ?.sort(
                      (a, b) =>
                        new Date(b?.created_at) - new Date(a?.created_at)
                    )
                    ?.map((doubt, index) => {
                      return (
                        <DoubtsCard
                          picture={ProfilePic}
                          name={
                            doubt?.author_name
                              ? doubt?.author_name === username
                                ? 'You'
                                : 'Anonymous'
                              : ''
                          }
                          subject={subjectTitle}
                          chapter={doubt.topic_title}
                          time={moment(doubt.created_at).fromNow()}
                          content={doubt.doubt_text}
                          responses={
                            responsesGroupByDoubtId?.[doubt._id]?.length || 0
                          }
                          buttonText={`SEE TEACHER REPLIES (${
                            responsesGroupByDoubtId?.[doubt._id]?.length || 0
                          })`}
                          replyButton={() => openPopup(doubt)}
                          editOnClick={() => {
                            setPopClassName('hidePop')
                            openEditDoubtPopup(doubt)
                          }}
                          deleteOnClick={() => {
                            setPopClassName('hidePop')
                            openDeleteDoubtPopup(doubt)
                          }}
                        />
                      )
                    })
                ) : (
                  <p>No doubts available</p>
                )}
              </Tab>
              {/* <Tab
                className="examsCard__wrapper"
                eventKey="exams"
                title={`Exams (${localAssessments?.length})`}
              >
                {localAssessments?.length > 0 ? (
                  localAssessments?.map((assessment, index) => {
                    return (
                      <ExamsCard
                        onClick={() => {
                          onAssessmentSelect(
                            assessment._id,
                            assessment.title,
                            assessment.batch_subject_id,
                            assessment?.has_taken,
                            assessment?.status
                          )
                        }}
                        subjectName={assessment.title}
                        subject={subjectTitle}
                        submit={
                          assessment?.status !== 'CLOSED'
                            ? assessment?.has_taken
                              ? `Submited on ${moment(
                                  assessment?.updated_at
                                ).format('DD-MM-YYYY')}`
                              : 'Not Submitted'
                            : 'View Published Results'
                        }
                      />
                    )
                  })
                ) : (
                  <p>No assessment available</p>
                )}
              </Tab> */}
            </Tabs>
            <Popup
              className={popClassName}
              close={() => closePopup()}
              onClick=""
              buttonText="Save Doubt"
              popupHead="Edit Doubt"
            >
              <DoubtsCard
                picture={ProfilePic}
                name={
                  doubtClicked?.author_name
                    ? doubtClicked?.author_name === username
                      ? 'You'
                      : 'Anonymous'
                    : ''
                }
                subject={subjectTitle}
                chapter={doubtClicked?.topic_title}
                time={moment(doubtClicked?.created_at).fromNow()}
                content={doubtClicked?.doubt_text}
                responses={doubtClicked?.response_count}
                editOnClick={() => {
                  openEditDoubtPopup(doubtClicked)
                }}
                deleteOnClick={() => {
                  openDeleteDoubtPopup(doubtClicked)
                }}
              />
              {responsesGroupByDoubtId?.[doubtClicked?._id]?.map((response) => {
                return (
                  <ReplyCard
                    picture={ProfilePic}
                    name={response.author_name}
                    time={moment(response.created_at).fromNow()}
                    content={response.response_text}
                  />
                )
              })}
              <InputSend
                placeholder="Reply your comment"
                onClick={(respondMsg) => {
                  const payload = {
                    response_text: respondMsg,
                    doubt_id: doubtClicked._id,
                    subject_id: doubtClicked.batch_subject_id,
                  }
                  dispatch(postReplyDoubt({ jwt, payload }))
                }}
              />
            </Popup>
          </Col25>
        </LeftRightLayout>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default Topic
