import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { Tab, Tabs } from 'react-bootstrap'
//import _ from 'underscore'
import moment from 'moment'

//assests
import Loader from '../assets/molecules/loader'
import LeftRightLayout from '../assets/layout/leftrightlayout'
import Col75 from '../assets/layout/leftrightlayout/column75'
import Col25 from '../assets/layout/leftrightlayout/column25'
import SubjectCard from '../assets/molecules/subjectCard'
import Accordion from '../assets/layout/accordion'
import TeachersCard from '../assets/molecules/teachersCard'
import DoubtsCard from '../assets/molecules/doubtsCard'
import ReplyCard from '../assets/molecules/replyCard'
import InputSend from '../assets/molecules/inputSend'
import TeachersSubjectCard from '../assets/molecules/teachersSubjectCard'
import Popup from '../assets/layout/popupRight'
import ExamsCard from '../assets/molecules/examsCard'
import TeachersIcon from '../../assets/images/profile.png'
import ProfilePic from '../../assets/images/profile.png'
import PopupConfirm from '../../components/assets/layout/popupConfirm'

//action
import { fetchUserSubjects } from '../../redux/action/subject'
import { fetchAssessments } from '../../redux/action/assessment'
import {
  setSubject,
  setChapter,
  setBatchSubjectId,
  setAssessment,
  setTopic,
} from '../../redux/action/ui'
import { fetchFacultiesOfCurrentUser } from '../../redux/action/faculty'
import {
  fetchDoubts,
  fetchDoubtsResponsesByIds,
  postReplyDoubt,
  deleteStudentDoubtsById,
  editStudentDoubtsById,
} from '../../redux/action/doubt'
import { fetchTopicsByTeachers } from '../../redux/action/topic'

//reducers
import { getJwt, getUsername } from '../../redux/reducer/account'
import { isSubjectsloading, getSubjects } from '../../redux/reducer/subject'
import {
  getIsFacultiesLoading,
  getFaculties,
} from '../../redux/reducer/faculty'
import {
  getDoubts,
  getResponsesGroupByDoubtId,
} from '../../redux/reducer/doubt'
import { getAssessments } from '../../redux/reducer/assessment'
import { getTopicsGroupByFaculty } from '../../redux/reducer/topic'

import { subjectIcons, usePrevious } from '../helpers'

const Subjects = () => {
  const dispatch = useDispatch()

  const history = useHistory()

  const jwt = useSelector(getJwt)
  const isLoading = useSelector(isSubjectsloading)
  const subjects = useSelector(getSubjects)
  const isFacultiesLoading = useSelector(getIsFacultiesLoading)
  const faculties = useSelector(getFaculties)
  const doubts = useSelector(getDoubts)
  const assessments = useSelector(getAssessments)
  const topicsGroupByFaculty = useSelector(getTopicsGroupByFaculty)
  const responsesGroupByDoubtId = useSelector(getResponsesGroupByDoubtId)
  const username = useSelector(getUsername)

  useEffect(() => {
    dispatch(fetchUserSubjects({ jwt }))
    dispatch(fetchFacultiesOfCurrentUser({ jwt }))
    dispatch(fetchDoubts({ jwt }))
    dispatch(fetchAssessments({ jwt }))
  }, [dispatch, jwt])

  const [localAssessments, setLocalAssessments] = useState([])
  const [doubtClicked, setDoubtClicked] = useState()
  const [searchText, setSearchText] = useState('')
  const [localSubjects, setLocalSubjects] = useState([])
  const [localDoubts, setLocalDoubts] = useState([])

  useEffect(() => {
    assessments &&
      setLocalAssessments(assessments?.filter((assessment) => true))
  }, [assessments])

  useEffect(() => {
    if (faculties.length) {
      const facultyIds = faculties?.map((faculty) => faculty._id)
      dispatch(fetchTopicsByTeachers({ jwt, facultyIds }))
    }
  }, [dispatch, faculties, jwt])

  useEffect(() => {
    if (!isLoading && isLoading !== undefined) {
      if (searchText) {
        setLocalSubjects(
          subjects.filter((subject) => {
            const subjectName = subject?.subject_title?.toLowerCase()
            return subjectName.includes(searchText)
          })
        )
      } else {
        setLocalSubjects(subjects)
      }
    }
  }, [searchText, isLoading, subjects])

  useEffect(() => {
    if (localSubjects && doubts) {
      const batchSubIds = localSubjects.map((sub) => sub?._id)
      setLocalDoubts(
        doubts?.filter((doubt) => {
          return batchSubIds.includes(doubt?.batch_subject_id)
        })
      )
    } else {
      setLocalDoubts([])
    }
  }, [localSubjects, doubts])

  useEffect(() => {
    if (localDoubts.length) {
      const doubtIds = localDoubts?.map((doubt) => doubt._id)
      dispatch(fetchDoubtsResponsesByIds({ jwt, doubtIds }))
    }
  }, [dispatch, jwt, localDoubts])

  const onSubjectSelect = (subjectId, subject_title, batchSubjectId) => {
    dispatch(setSubject(subjectId, subject_title))
    dispatch(setBatchSubjectId(batchSubjectId))
    history.push('/me/subjects/chapters')
  }

  const getSubjectNameByBatchSubjectId = (id) => {
    const filtered = subjects?.filter((subject) => subject._id === id)
    return filtered.length ? filtered[0].subject_title : ''
  }

  const getSubjectNameById = (id) => {
    const filtered = subjects?.filter((subject) => subject.subject_id === id)
    return filtered.length ? filtered[0].subject_title : ''
  }

  const onAssessmentSelect = (id, title, batchSubjectId, taken, status) => {
    dispatch(setAssessment(id, title))
    dispatch(setBatchSubjectId(batchSubjectId))
    if (status === 'CLOSED') {
      history.push(`/assessments/results`)
    } else {
      if (!taken) {
        history.push(`/assessments/questions`)
      }
    }
  }

  const onTopicSelect = (topic) => {
    dispatch(setSubject(topic.subject_id, getSubjectNameById(topic.subject_id)))
    dispatch(setChapter(topic.chapter_id, ''))
    dispatch(setTopic(topic._id, topic.title))
    history.push('/me/subjects/chapters/topics/detail')
  }

  const [popClassName, setPopClassName] = useState('hidePop')
  const openPopup = (doubt) => {
    setDoubtClicked(doubt)
    setPopClassName('showPop')
  }
  const closePopup = () => {
    setPopClassName('hidePop')
    setDoubtClicked()
  }

  const [editText, setEditText] = useState()
  const [selectedDoubt, setSelectedDoubt] = useState('')
  const [editDoubtPopClassName, setEditDoubtPopClassName] = useState('hidePop')
  const [deleteDoubtPopClassName, setDeleteDoubtPopClassName] = useState(
    'hidePop'
  )

  const openEditDoubtPopup = (doubt) => {
    setEditDoubtPopClassName('showPop')
    setSelectedDoubt(doubt)
    setEditText(doubt.doubt_text)
  }

  const openDeleteDoubtPopup = (doubt) => {
    setDeleteDoubtPopClassName('showPop')
    setSelectedDoubt(doubt)
  }

  const closeEditDoubtPopup = () => {
    setEditText('')
    setEditDoubtPopClassName('hidePop')
  }

  const closeDeleteDoubtPopup = () => {
    setDeleteDoubtPopClassName('hidePop')
  }

  const onEditDoubt = () => {
    dispatch(
      editStudentDoubtsById({
        jwt,
        doubtId: selectedDoubt._id,
        payload: {
          doubt_text: editText,
        },
      })
    )
    setEditText('')
    closeEditDoubtPopup()
  }

  const onDeleteDoubt = () => {
    dispatch(deleteStudentDoubtsById({ doubtId: selectedDoubt._id, jwt }))
    setSelectedDoubt('')
    closeDeleteDoubtPopup()
  }

  return (
    <div className="tabs__container">
      <section>
        <h1>Subjects</h1>
        <div className="row">
          <div className="col-md-9 padding--0">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Subjects</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-3 padding--0">
            <div className="search__wrapper">
              <input
                type="text"
                className="search__box"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <button
                className="close--icon"
                type="reset"
                onClick={() => setSearchText('')}
              ></button>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col75>
            <h2>List of subjects</h2>

            {isLoading ? (
              <Loader />
            ) : localSubjects?.length ? (
              localSubjects?.map((subject, index) => {
                return (
                  <SubjectCard
                    key={index}
                    icon={
                      subjectIcons[subject.subject_title.toLowerCase()]
                        ? subjectIcons[subject.subject_title.toLowerCase()]
                        : subjectIcons['others']
                    }
                    subjectName={subject.subject_title}
                    subjectCode={subject.subject_code}
                    noOfTopics={subject?.chapter_count}
                    onClick={() =>
                      onSubjectSelect(
                        subject.subject_id,
                        subject.subject_title,
                        subject._id
                      )
                    }
                  />
                )
              })
            ) : (
              <p>No subjects avilable</p>
            )}
            <PopupConfirm
              className={editDoubtPopClassName}
              close={() => closeEditDoubtPopup()}
              onClick={() => onEditDoubt()}
              buttonText="Save Doubt"
              popupHead="Edit Doubt"
            >
              <textarea
                rows="5"
                value={editText || ''}
                onChange={(e) => setEditText(e.target.value)}
              ></textarea>
            </PopupConfirm>
            <PopupConfirm
              className={deleteDoubtPopClassName}
              close={() => closeDeleteDoubtPopup()}
              onClick={() => onDeleteDoubt()}
              buttonText="yes"
              popupHead="Delete Doubts"
            >
              <h2>Are you sure want to delete?</h2>
            </PopupConfirm>
          </Col75>
          <Col25>
            <Tabs>
              <Tab
                eventKey="teachers"
                title={`Teachers (${faculties?.length})`}
              >
                <Accordion>
                  {isFacultiesLoading ? (
                    <Loader />
                  ) : (
                    faculties?.map((faculty, i) => {
                      return (
                        <TeachersCard
                          key={i}
                          itemNo={i + 1}
                          icon={TeachersIcon}
                          teachersName={faculty.name}
                          designation={faculty.designation}
                        >
                          <h5>List of topics</h5>
                          {topicsGroupByFaculty?.[faculty._id] !== undefined &&
                          topicsGroupByFaculty?.[faculty._id].length ? (
                            topicsGroupByFaculty?.[faculty._id]?.map(
                              (topic, index) => {
                                return (
                                  <TeachersSubjectCard
                                    key={index}
                                    onClick={() => onTopicSelect(topic)}
                                    topic={topic.title}
                                  />
                                )
                              }
                            )
                          ) : (
                            <p>No topics available</p>
                          )}
                        </TeachersCard>
                      )
                    })
                  )}
                </Accordion>
              </Tab>
              <Tab
                className="doubtsTab"
                eventKey="doubtsTab"
                title={`Doubts (${localDoubts?.length})`}
              >
                {localDoubts?.length > 0 ? (
                  localDoubts
                    ?.sort(
                      (a, b) =>
                        new Date(b?.created_at) - new Date(a?.created_at)
                    )
                    ?.map((doubt, index) => {
                      return (
                        <DoubtsCard
                          key={index}
                          picture={ProfilePic}
                          name={
                            doubt?.author_name
                              ? doubt?.author_name === username
                                ? 'You'
                                : 'Anonymous'
                              : ''
                          }
                          subject={getSubjectNameById(doubt.batch_subject_id)}
                          chapter={doubt.topic_title}
                          time={moment(doubt.created_at).fromNow()}
                          content={doubt.doubt_text}
                          responses={
                            responsesGroupByDoubtId?.[doubt._id]?.length || 0
                          }
                          buttonText={`SEE TEACHER REPLIES (${
                            responsesGroupByDoubtId?.[doubt._id]?.length || 0
                          })`}
                          replyButton={() => openPopup(doubt)}
                          editOnClick={() => {
                            openEditDoubtPopup(doubt)
                          }}
                          deleteOnClick={() => {
                            openDeleteDoubtPopup(doubt)
                          }}
                        />
                      )
                    })
                ) : (
                  <p>No doubts available</p>
                )}
              </Tab>
              {/* <Tab
                className="examsCard__wrapper"
                eventKey="exams"
                title={`Exams (${localAssessments?.length})`}
              >
                {localAssessments?.length > 0 ? (
                  localAssessments
                    ?.sort(
                      (a, b) =>
                        new Date(b?.created_at) - new Date(a?.created_at)
                    )
                    ?.map((assessment, index) => {
                      return (
                        <ExamsCard
                          key={index}
                          onClick={() => {
                            onAssessmentSelect(
                              assessment?._id,
                              assessment?.title,
                              assessment?.batch_subject_id,
                              assessment?.has_taken,
                              assessment?.status
                            )
                          }}
                          subjectName={assessment.title}
                          subject={getSubjectNameByBatchSubjectId(
                            assessment.batch_subject_id
                          )}
                          submit={
                            assessment?.status !== 'CLOSED'
                              ? assessment?.has_taken
                                ? `Submited on ${moment(
                                    assessment?.updated_at
                                  ).format('DD-MM-YYYY')}`
                                : 'Not Submitted'
                              : 'View Published Results'
                          }
                        />
                      )
                    })
                ) : (
                  <p>No assessment available</p>
                )}
              </Tab> */}
            </Tabs>
            <Popup
              className={popClassName}
              close={() => closePopup()}
              onClick=""
              buttonText="Save Doubt"
              popupHead="Replies"
            >
              <DoubtsCard
                picture={ProfilePic}
                name={
                  doubtClicked?.author_name
                    ? doubtClicked?.author_name === username
                      ? 'You'
                      : 'Anonymous'
                    : ''
                }
                subject={getSubjectNameById(doubtClicked?.batch_subject_id)}
                chapter={doubtClicked?.topic_title}
                responses={doubtClicked?.response_count}
                time={moment(doubtClicked?.created_at).fromNow()}
                content={doubtClicked?.doubt_text}
                editOnClick={() => {
                  setPopClassName('hidePop')
                  openEditDoubtPopup(doubtClicked)
                }}
                deleteOnClick={() => {
                  setPopClassName('hidePop')
                  openDeleteDoubtPopup(doubtClicked)
                }}
              />
              {responsesGroupByDoubtId?.[doubtClicked?._id]?.map((response) => {
                return (
                  <ReplyCard
                    picture={ProfilePic}
                    name={response.author_name}
                    time={moment(response.created_at).fromNow()}
                    content={response.response_text}
                  />
                )
              })}
              <InputSend
                placeholder="Reply your comment"
                onClick={(respondMsg) => {
                  const payload = {
                    response_text: respondMsg,
                    doubt_id: doubtClicked._id,
                    subject_id: doubtClicked.batch_subject_id,
                  }
                  dispatch(postReplyDoubt({ jwt, payload }))
                }}
              />
            </Popup>
          </Col25>
        </LeftRightLayout>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default Subjects
