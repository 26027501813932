import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getPermission } from '../../redux/reducer/account'
import { navList } from '../helpers'

const LeftNav = (props) => {
  const [navState, setnavState] = useState(true)
  const [activeState, setActiveState] = useState({ home: true })

  const activeFunction = (name) => {
    setnavState(true)
    setActiveState({ [name]: true })
  }

  const onToggleNav = () => {
    setnavState(!navState)
  }

  const leftNav = () => {
    setnavState(true)
  }

  window.addEventListener('click', function (e) {
    if (!document.getElementById('leftNav')?.contains(e.target)) {
      leftNav()
    }
  })

  const entityType = useSelector(getPermission)
  return (
    <div
      id="leftNav"
      className={'left__navigation ' + (navState ? '' : 'collapse')}
    >
      <div
        onClick={() => onToggleNav()}
        className={'navbar__toggle ' + (navState ? '' : 'navClose')}
      >
        <input type="checkbox" />
        <span></span>
        <div className="clear"></div>
      </div>

      <div className={'menu__wrapper ' + (navState ? '' : 'collapse')}>
        <ul className="menu">
          {navList[entityType]?.map((list, index) => {
            return (
              <li
                key={index}
                className={
                  'item item__' +
                  list.name +
                  ' ' +
                  (activeState[list.name] ? 'active' : '')
                }
              >
                <Link onClick={() => activeFunction(list.name)} to={list.to}>
                  {list.name}
                </Link>
              </li>
            )
          })}
        </ul>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  )
}
export default LeftNav
