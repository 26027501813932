import React from 'react'
import AddButton from '../../../assets/images/add-comment.svg'
const AddNotes = (props) => {
  return (
    <div className="addNotes">
      <textarea
        value={props.value}
        onChange={props.onChange}
        placeholder="Take a note"
        onBlur={() => (props.onBlur ? props.onBlur() : {})}
      ></textarea>
      <img
        onClick={props.onClick}
        className="addNotes__addButton"
        src={AddButton}
        alt="add"
      />
      <div className="clear"></div>
    </div>
  )
}

export default AddNotes
