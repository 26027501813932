import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import _ from 'underscore'

//assets
import Loader from '../../../assets/molecules/loader'
import Select from '../../../assets/molecules/select'
import { downloadReport } from '../../../helpers'
import Popup from '../../../assets/layout/popupConfirm'
import { AlertSuccess } from '../../../assets/molecules/alerts'

//action
import { fetchBatches } from '../../../../redux/action/batches'
import { fetchSubjects } from '../../../../redux/action/subject'
import { getFaculties } from '../../../../redux/action/faculty'
import {
  getOnlineSessionByBatchId,
  deleteOnlineSession,
} from '../../../../redux/action/onlineSession'

//reducer
import {
  getIsFacultiesLoading,
  getIsError as getFacultiesError,
  getAllFaculties,
} from '../../../../redux/reducer/faculty'
import {
  getBatches,
  getIsLoading,
  getIsError,
} from '../../../../redux/reducer/batches'
import {
  getSubjects,
  isSubjectsloading as getIsSubjectsloading,
  getIsError as getSubjectError,
} from '../../../../redux/reducer/subject'
import { getJwt } from '../../../../redux/reducer/account'
import {
  getIsLoadingGetByBatchId,
  getBatchOnlineSessions,
  getIsError as getSessiosnsError,
  getIsLoading as getIsLoadingDelete,
} from '../../../../redux/reducer/onlineSession'

//helpers
import { usePrevious } from '../../../helpers'

const Reports = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedBatch, setSelectedBatch] = useState('')
  const [selectedsubject, setSelectedSubject] = useState('')
  const [reportData, setReportData] = useState({})
  const [deletePopClassName, setDeletePopClassName] = useState('hidePop')
  const [selectedReportId, setSelectedReportId] = useState('')
  const [successMsg, setSuccessMsg] = useState(false)

  const openDeletePopup = (e, reportId) => {
    setSelectedReportId(reportId)
    setDeletePopClassName('showPop')
  }

  const closeDeletePopup = () => {
    setDeletePopClassName('hidePop')
  }

  const jwt = useSelector(getJwt)
  const batches = useSelector(getBatches)
  const isLoadingBatches = useSelector(getIsLoading)
  const isLoadingBatchesPrev = usePrevious(isLoadingBatches)
  const isErrorBatches = useSelector(getIsError)
  const subjects = useSelector(getSubjects)
  const isSubjectsloading = useSelector(getIsSubjectsloading)
  const isSubjectsloadingPrev = usePrevious(isSubjectsloading)
  const subjectError = useSelector(getSubjectError)
  const isLoadingGetByBatchId = useSelector(getIsLoadingGetByBatchId)
  const isLoadingGetByBatchIdPrev = usePrevious(isLoadingGetByBatchId)
  const sessiosnsError = useSelector(getSessiosnsError)
  const batchOnlineSessions = useSelector(getBatchOnlineSessions)
  const isFacultiesLoading = useSelector(getIsFacultiesLoading)
  const isFacultiesLoadingPrev = usePrevious(isFacultiesLoading)
  const facultiesError = useSelector(getFacultiesError)
  const allFaculties = useSelector(getAllFaculties)
  const isLoadingDelete = useSelector(getIsLoadingDelete)
  const isLoadingDeletePrev = usePrevious(isLoadingDelete)

  useEffect(() => {
    dispatch(fetchBatches({ jwt }))
    dispatch(fetchSubjects({ jwt }))
    dispatch(getFaculties({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (
      !isFacultiesLoading &&
      isFacultiesLoadingPrev !== undefined &&
      !isLoadingBatches &&
      isLoadingBatchesPrev !== undefined &&
      !isSubjectsloading &&
      isSubjectsloadingPrev !== undefined &&
      !isErrorBatches &&
      !subjectError &&
      !facultiesError &&
      batches &&
      batches?.length > 0 &&
      subjects &&
      subjects?.length > 0
    ) {
      setSelectedBatch(batches[0]?._id)
    }
  }, [isSubjectsloading, isLoadingBatches, isFacultiesLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      selectedBatch &&
      !isLoadingDelete &&
      isLoadingDeletePrev !== undefined
    ) {
      closeDeletePopup()
      dispatch(getOnlineSessionByBatchId({ jwt, id: selectedBatch }))
      setSelectedSubject('')
    }
  }, [selectedBatch, isLoadingDelete]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !sessiosnsError &&
      !isLoadingDelete &&
      isLoadingDeletePrev !== undefined &&
      isLoadingDelete !== isLoadingDeletePrev
    ) {
      setSuccessMsg(true)
    }
  }, [isLoadingDelete]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isLoadingGetByBatchId &&
      isLoadingGetByBatchIdPrev !== undefined &&
      !sessiosnsError &&
      selectedBatch
    ) {
      const header = {
        Sl_No: 'Sl No',
        Batch: 'Batch',
        Subject: 'Subject',
        Title: 'Title',
        Start_Time: 'Start Time',
        End_Time: 'End Time',
        Created_By: 'Created By',
      }
      const batch = batches.filter((item) => item?._id === selectedBatch)[0]
      const data = batchOnlineSessions
        ?.sort((a, b) => new Date(b?.start_time) - new Date(a?.start_time))
        ?.filter((item) =>
          selectedsubject ? item?.batch_subject_id === selectedsubject : true
        )
        .map((session, index) => {
          const subject = subjects.filter(
            (item) => item?._id === session?.batch_subject_id
          )[0]

          return {
            id: session?._id,
            Sl_No: index + 1,
            Batch: batch?.title,
            BatchId: batch?._id,
            Subject: subject?.subject_title,
            Title: session?.title,
            'End Time Check': session?.end_time,
            Start_Time: moment(new Date(session?.start_time)).format(
              'DD-MM-YYYY | hh:mm A'
            ),
            End_Time: moment(new Date(session?.end_time)).format(
              'DD-MM-YYYY | hh:mm A'
            ),
            Created_By: _.findWhere(allFaculties, {
              _id: session?.author_entity_id,
            })?.name,
          }
        })
      setReportData({ header, data })
    }
  }, [isLoadingGetByBatchId, selectedsubject]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeBatch = (e) => {
    setSelectedBatch(e.target.value)
  }

  const onChangeSubject = (e) => {
    setSelectedSubject(e.target.value)
  }

  const downloadPdf = () => {
    const data = {
      reportData: reportData?.data,
    }
    downloadReport({
      nameOfTemplate: 'cwxLiveClassReport',
      dataForTemplate: data,
      downloadedFileName: `liveClassReport`,
    })
  }

  const onAttendSession = (e, id) => {
    e.preventDefault()
    const session = batchOnlineSessions.filter((item) => item?._id === id)[0]
    if (session && new Date() < new Date(session.end_time)) {
      history.push({
        pathname: '/me/session/detail',
        state: { session },
      })
    }
  }

  const onDelete = () => {
    dispatch(deleteOnlineSession({ jwt, sessionId: selectedReportId }))
  }

  const viewReport = (e, report) => {
    e.preventDefault()
    history.push({
      pathname: '/onlinesession/report',
      state: { report },
    })
  }

  return isLoadingBatches ||
    isSubjectsloading ||
    isLoadingGetByBatchId ||
    isFacultiesLoading ? (
    <Loader />
  ) : (
    <div className="tabs__container">
      <section>
        <nav className="padding--leftRight">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Live Session Reports</li>
          </ol>
        </nav>
        <div className="clear"></div>
      </section>
      <section>
        <button className="button--blue" onClick={() => downloadPdf()}>
          Pdf
        </button>
        <Select
          className="float-right"
          onChange={(e) => onChangeSubject(e)}
          value={selectedsubject}
        >
          <option value={''}>All Subjects</option>
          {subjects
            ?.filter((sub) => sub?.batch_id === selectedBatch)
            ?.map((sub, index) => {
              return (
                <option key={index} value={sub?._id}>
                  {sub?.subject_title}
                </option>
              )
            })}
        </Select>
        <Select
          className="float-right"
          onChange={(e) => onChangeBatch(e)}
          value={selectedBatch}
        >
          {batches?.map((batch, index) => {
            return (
              <option key={index} value={batch?._id}>
                {batch?.title}
              </option>
            )
          })}
        </Select>
        <div className="clear"></div>
      </section>
      {reportData?.header && reportData?.data ? (
        <section style={{ paddingTop: 20 }}>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {Object.keys(reportData?.header)?.map((key, index) => {
                    return <th key={index}>{reportData?.header[key]}</th>
                  })}
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {reportData?.data?.map((report, index) => {
                  return (
                    <tr key={index}>
                      {Object.keys(reportData?.header)?.map((key, index) => {
                        return <td key={index}>{report[key]}</td>
                      })}
                      <td>
                        <button
                          className={
                            new Date() < new Date(report['End Time Check'])
                              ? 'button--blue'
                              : 'button--disable'
                          }
                          onClick={(e) => onAttendSession(e, report?.id)}
                        >
                          ATTEND
                        </button>
                      </td>
                      <td>
                        <button
                          className={
                            new Date() < new Date(report['End Time Check'])
                              ? 'button--red'
                              : 'button--disable'
                          }
                          onClick={
                            new Date() < new Date(report['End Time Check'])
                              ? (e) => openDeletePopup(e, report?.id)
                              : null
                          }
                        >
                          DELETE
                        </button>
                      </td>
                      <td>
                        <button
                          className="button--blue"
                          onClick={(e) => viewReport(e, report)}
                        >
                          Report
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="clear"></div>
          </div>
        </section>
      ) : null}
      <div className="clear"></div>
      <Popup
        className={deletePopClassName}
        close={() => closeDeletePopup()}
        onClick={() => onDelete()}
        buttonText="yes"
        popupHead="Delete Note"
      >
        {isLoadingDelete ? <Loader /> : <h2>Are you sure want to delete?</h2>}
      </Popup>
      {successMsg ? (
        <AlertSuccess>You have successfully deleted a session.</AlertSuccess>
      ) : null}
    </div>
  )
}

export default Reports
