import React from 'react'

const LeftRightLayout = (props) => {
  return (
    <div className={'row row-flex leftright__layout ' + props?.className}>
      {props.children}
      <div className="clear"></div>
    </div>
  )
}

export default LeftRightLayout
