import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'underscore'
import moment from 'moment'
import { Tab, Tabs, Carousel } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

import Accordion from '../../assets/layout/accordion'
import CustomTab from '../../assets/molecules/customTab'
import ExaminationTeacherCard from '../../assets/molecules/examinationTeacherCard'
import Button from '../../assets/molecules/button'
import NoDoubts from '../../../assets/images/noDoubts.svg'
import ProfilePic from '../../../assets/images/profile.png'
import LeftRightLayout from '../../assets/layout/leftrightlayout'
import Col75 from '../../assets/layout/leftrightlayout/column75'
import Col25 from '../../assets/layout/leftrightlayout/column25'
import StudentsCard from '../../assets/molecules/studentsCard'
import TeachersIcon from '../../../assets/images/profile.png'
import Loader from '../../assets/molecules/loader'
import DoubtsMainCard from '../../assets/molecules/doubtsMainCard'
import InputSend from '../../assets/molecules/inputSend'
import DoubtsCard from '../../assets/molecules/doubtsCard'
import ReplyCard from '../../assets/molecules/replyCard'
import NoPending from '../../../assets/images/no-pending.png'
import NoEvaluations from '../../../assets/images/noEvaluations.svg'
import Homework from '../../../assets/images/homework.png'

//action
import {
  fetchBatchSubjects,
  fetchUserSubjects,
  fetchBatchSubjectDataById,
} from '../../../redux/action/subject'
import { fetchBatches } from '../../../redux/action/batches'
import { fetchAssessments } from '../../../redux/action/assessment'
import {
  fetchDoubtsBySubjectId,
  fetchDoubtsResponseByIds,
  postReplyDoubt,
} from '../../../redux/action/doubt'
// import {
//   setTopic,
//   setSubject,
//   setBatchSubjectId,
//   setChapter,
// } from '../../../redux/action/ui'
import { fetchOnlineSessions } from '../../../redux/action/onlineSession'

//reducer
import { getJwt, getUserId } from '../../../redux/reducer/account'
import {
  getDoubts,
  getIsDoubtsLoading,
  getIsDoubtsResponseLoading,
  getResponse,
  getIsDoubtReplying,
} from '../../../redux/reducer/doubt'
import {
  isSubjectsloading,
  getSubjects,
  getBatchSubject,
} from '../../../redux/reducer/subject'
import { getBatches, getIsLoading } from '../../../redux/reducer/batches'
import { getOnlineSessions } from '../../../redux/reducer/onlineSession'
import {
  getAssessmentsGroupByBatchSubjectId,
  getIsAssessmentsLoading,
} from '../../../redux/reducer/assessment'

//helpers
import { usePrevious } from '../../helpers'
import {
  setTopic,
  setSubject,
  setChapter,
  setAssessment,
  setBatchSubjectId,
} from '../../../redux/action/ui'

const TeacherDashbaord = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  //state
  const [selectedSubject, setSelectedSubject] = useState('')
  const [selectedBatch, setSelectedBatch] = useState('')
  const [doubtsByTopics, setDoubtsByTopics] = useState({})
  const [responsesByDoubtId, setResponsesByDoubtId] = useState({})
  const [checkedIndex, setCheckedIndex] = useState(0)
  const [newDoubtsCount, setnewDoubtsCount] = useState(0)
  const [repliedDoubtsCount, setRepliedDoubtsCount] = useState(0)
  const [loadingDoubts, setLoadingDoubts] = useState(true)
  const [loadingAssessments, setLoadingAssessments] = useState(true)
  const [doubtKey, setDoubtKey] = useState('')
  const [selectedTab, setSelectedTab] = useState('Student’s Doubts')
  const [teacherBatches, setTeacherBatches] = useState([])
  const [teacherSubjects, setTeacherSubjects] = useState([])
  const [localSessions, setLocalSessions] = useState([])
  const [pendingAsseeements, setpendingAsseeements] = useState([])
  const [evaluatedAsseeements, setevaluatedAsseeements] = useState([])

  //selector
  const jwt = useSelector(getJwt)
  const userId = useSelector(getUserId)
  const doubts = useSelector(getDoubts)
  const isLoadingDoubts = useSelector(getIsDoubtsLoading)
  const isLoadingDoubtsPrev = usePrevious(isLoadingDoubts)
  const isLoadingSubjects = useSelector(isSubjectsloading)
  const isLoadingSubjectsPrev = usePrevious(isLoadingSubjects)
  const subjects = useSelector(getSubjects)
  const subject = useSelector(getBatchSubject)
  const isDoubtsResponseLoading = useSelector(getIsDoubtsResponseLoading)
  const isDoubtsResponseLoadingPrev = usePrevious(isDoubtsResponseLoading)
  const isDoubtReplying = useSelector(getIsDoubtReplying)
  const isDoubtReplyingPrev = usePrevious(isDoubtReplying)
  const responses = useSelector(getResponse)
  const batches = useSelector(getBatches)
  const isLoadingBatches = useSelector(getIsLoading)
  const isLoadingBatchesPrev = usePrevious(isLoadingBatches)
  const onlineSessions = useSelector(getOnlineSessions)
  const isAssessmentsLoading = useSelector(getIsAssessmentsLoading)
  const isAssessmentsLoadingPrev = usePrevious(isAssessmentsLoading)
  const assessmentsGroupByBatchSubjectId = useSelector(
    getAssessmentsGroupByBatchSubjectId
  )

  useEffect(() => {
    dispatch(fetchBatchSubjects({ jwt }))
    dispatch(fetchUserSubjects({ jwt }))
    dispatch(fetchOnlineSessions({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (!isLoadingSubjects && isLoadingSubjectsPrev !== undefined) {
      dispatch(fetchBatches({ jwt }))
    }
  }, [isLoadingSubjects]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onlineSessions?.length) {
      setLocalSessions(
        onlineSessions?.filter(
          (session) =>
            new Date() < new Date(session?.end_time) &&
            session.author_id === userId
        )
      )
    }
  }, [onlineSessions]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoadingBatches && isLoadingBatchesPrev !== undefined) {
      dispatch(fetchAssessments({ jwt }))
    }
  }, [isLoadingBatches]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isAssessmentsLoading && isAssessmentsLoadingPrev !== undefined) {
      const batchIds = subjects?.map((sub) => sub?.batch_id)
      const filteredBatches = batches?.filter((batch) =>
        batchIds?.includes(batch?._id)
      )
      setTeacherBatches(filteredBatches)
      setSelectedBatch(filteredBatches[0])
    }
  }, [isAssessmentsLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedBatch) {
      const filteredSubjects = subjects?.filter(
        (sub) => sub?.batch_id === selectedBatch?._id
      )
      setTeacherSubjects(filteredSubjects)
      setSelectedSubject(filteredSubjects[0])
    }
  }, [selectedBatch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedSubject) {
      setpendingAsseeements(
        assessmentsGroupByBatchSubjectId[selectedSubject?._id]?.filter(
          (item) =>
            item?.status !== 'EVOLUTED' &&
            item?.status !== 'CANCELED' &&
            item?.status !== 'LIVE' &&
            (item?.status === 'CLOSED' || item?.status === 'DRAFTED')
        )
      )
      setevaluatedAsseeements(
        assessmentsGroupByBatchSubjectId[selectedSubject?._id]?.filter(
          (item) => item?.status === 'EVOLUTED'
        )
      )
      dispatch(fetchDoubtsBySubjectId({ jwt, subjectId: selectedSubject?._id }))
    }
  }, [selectedSubject]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoadingDoubts && isLoadingDoubtsPrev !== undefined) {
      if (doubts && doubts?.length > 0) {
        dispatch(
          fetchDoubtsResponseByIds({
            jwt,
            doubtIds: doubts.map((doubt) => doubt?._id),
          })
        )
      } else {
        setDoubtKey('newDoubt')
      }
    }
  }, [isLoadingDoubts]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isDoubtsResponseLoading && isDoubtsResponseLoadingPrev !== undefined) {
      setResponsesByDoubtId(_.groupBy(responses, (x) => x?.doubt_id))
      setDoubtKey('newDoubt')
    }
  }, [isDoubtsResponseLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (doubtKey) {
      const newDoubts = doubts
        ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
        ?.filter((item) => !item?.response_count)
      const repliedDoubts = doubts
        ?.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
        ?.filter((item) => item?.response_count)
      setnewDoubtsCount(newDoubts?.length)
      setRepliedDoubtsCount(repliedDoubts?.length)
      setDoubtsByTopics(
        doubtKey === 'newDoubt'
          ? _.groupBy(newDoubts, (x) => x?.topic_id)
          : _.groupBy(repliedDoubts, (x) => x?.topic_id)
      )
      setLoadingDoubts(false)
    }
  }, [doubtKey, responsesByDoubtId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isDoubtReplying && isDoubtReplyingPrev !== undefined) {
      dispatch(fetchDoubtsBySubjectId({ jwt, subjectId: selectedSubject?._id }))
    }
  }, [isDoubtReplying]) // eslint-disable-line react-hooks/exhaustive-deps

  const noDoubts = () => {
    return (
      <div className="noPending">
        <div>
          <img src={NoDoubts} alt="No new doubts right now" />
          <h2 className="text-center">
            {doubtKey === 'newDoubt'
              ? 'No new doubts right now'
              : 'No replied doubts right now'}
          </h2>
          <p>
            {doubtKey === 'newDoubt'
              ? 'Till then, you can help students clear up their old doubts.'
              : 'Replied doubts will be stored here.'}
          </p>
        </div>
      </div>
    )
  }

  const noEval = (evalTab) => {
    return (
      <div className="noPending">
        <div>
          <img src={NoEvaluations} alt="" />
          <h2 className="text-center">
            {evalTab === 'pending'
              ? 'No pending examinations currently.'
              : 'No evaluated examinations currently.'}
          </h2>
        </div>
      </div>
    )
  }

  const noStudents = () => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">No Students assigned.</h2>
        </div>
      </div>
    )
  }

  const onTopicSelect = (doubt) => {
    // const { topic_id, topic_title } = doubt
    // dispatch(setTopic(topic_id, topic_title))
    dispatch(
      setSubject(selectedSubject?.subject_id, selectedSubject?.subject_title)
    )
    dispatch(setBatchSubjectId(doubt?.batch_subject_id))
    dispatch(setChapter(doubt?.chapter_id, doubt?.chapter_name))
    dispatch(setTopic(doubt?.topic_id, doubt?.topic_title))
  }

  const onReply = (text, doubt) => {
    const payload = {
      response_text: text,
      doubt_id: doubt?._id,
      subject_id: doubt?.batch_subject_id,
    }
    dispatch(postReplyDoubt({ jwt, payload }))
  }
  const onClickedAttend = (e, session) => {
    e.preventDefault()
    history.push({
      pathname: '/me/session/detail',
      state: { session },
    })
  }

  const onAssessmentSelect = (assessment) => {
    dispatch(setAssessment(assessment._id, assessment.title))
    dispatch(setBatchSubjectId(assessment.batch_subject_id))
    history.push(`/faculty/assessments/submissions`)
  }

  return (
    <div className="body__container">
      <section>
        <h1>Home</h1>
        {localSessions && localSessions?.length ? (
          <Carousel>
            {localSessions?.map((session, index) => {
              const filteredBatch = subjects.filter(
                (sub) =>
                  sub._id === (session?.batch_subject_id || session?.subject_id)
              )
              const currentBatch =
                filteredBatch.length > 0 ? filteredBatch[0] : {}
              return (
                <Carousel.Item key={index}>
                  <div className="carousel__img">
                    <img src={Homework} alt="cwx" />
                    <div className="clear"></div>
                  </div>
                  <div className="carousel__text">
                    <h2>
                      {session.title} |{' '}
                      {moment(session?.start_time).format(
                        'DD-MM-YYYY | hh:mm A'
                      )}{' '}
                      | Session
                    </h2>
                    <h2>Class - {currentBatch.batch_title}</h2>
                    <button
                      onClick={(e) => onClickedAttend(e, session)}
                      className="button--white"
                    >
                      TAKE SESSION
                    </button>
                    <div className="clear"></div>
                  </div>
                </Carousel.Item>
              )
            })}
          </Carousel>
        ) : null}
      </section>
      <section className="tabs__wrapper">
        <CustomTab
          selectedTab={selectedTab}
          tabItems={{
            'Student’s Doubts': newDoubtsCount + repliedDoubtsCount,
            'Exam Evaluations':
              pendingAsseeements && evaluatedAsseeements
                ? pendingAsseeements?.length + evaluatedAsseeements?.length
                : 0,
          }}
          onClickTab={(item) => setSelectedTab(item)}
          subjects={teacherSubjects}
          selectedSubject={selectedSubject?._id}
          onChangeSubjects={(selected) => {
            setDoubtKey('')
            setSelectedSubject(selected)
          }}
          selectedBatch={selectedBatch?._id}
          batches={teacherBatches}
          onChangeBatches={(selected) => {
            setDoubtKey('')
            setSelectedBatch(selected)
          }}
        />
        <div className="clear"></div>
        <LeftRightLayout className="leftright__layout--transparent">
          <Col75 className="leftLayout--teacherDashBoard">
            {selectedTab === 'Student’s Doubts' ? (
              loadingDoubts ? (
                <Loader />
              ) : (
                <div>
                  <Tabs
                    activeKey={doubtKey}
                    onSelect={(k) => {
                      setCheckedIndex(0)
                      setDoubtKey(k)
                    }}
                    className="square__tabs square__tabs--twoItems"
                  >
                    <Tab
                      eventKey="newDoubt"
                      title={'New (' + newDoubtsCount + ')'}
                    ></Tab>
                    <Tab
                      eventKey="repliedDoubt"
                      title={'Replied (' + repliedDoubtsCount + ')'}
                    ></Tab>
                  </Tabs>
                  <Accordion>
                    {doubtsByTopics && Object.keys(doubtsByTopics).length > 0
                      ? Object.keys(doubtsByTopics).map((key, index) => {
                          return (
                            <DoubtsMainCard
                              key={index}
                              itemNo={index}
                              title={doubtsByTopics[key][0]?.topic_title}
                              noOfRepies={doubtsByTopics[key]?.length}
                              link="/me/faculty/subjects/chapters/topics/detail"
                              onClickLink={() => {
                                onTopicSelect(doubtsByTopics[key][0])
                              }}
                              checked={checkedIndex === index}
                              setCheckedIndex={(item) => {
                                setCheckedIndex(item)
                              }}
                            >
                              {doubtsByTopics[key] &&
                                doubtsByTopics[key]?.map((doubt, index) => {
                                  return (
                                    <div key={index}>
                                      <DoubtsCard
                                        picture={ProfilePic}
                                        name={doubt?.author_name}
                                        subject=""
                                        chapter={doubt?.topic_title}
                                        time={
                                          moment().diff(
                                            doubt?.created_at,
                                            'days'
                                          ) + ' days ago'
                                        }
                                        content={doubt?.doubt_text}
                                        responses={1}
                                        editOnClick={() => {}}
                                        deleteOnClick={() => {}}
                                      />
                                      {responsesByDoubtId &&
                                        responsesByDoubtId[doubt?._id] &&
                                        responsesByDoubtId[doubt?._id]
                                          ?.sort(
                                            (a, b) =>
                                              new Date(b?.created_at) -
                                              new Date(a?.created_at)
                                          )
                                          ?.map((res, index) => {
                                            return (
                                              <ReplyCard
                                                key={index}
                                                picture={ProfilePic}
                                                name={
                                                  res?.author_name
                                                    ? res?.author_name
                                                    : ''
                                                }
                                                time={
                                                  moment().diff(
                                                    res?.created_at,
                                                    'days'
                                                  ) + ' days ago'
                                                }
                                                content={res?.response_text}
                                              />
                                            )
                                          })}
                                      <InputSend
                                        placeholder="Add your response"
                                        onClick={(text) => onReply(text, doubt)}
                                      />
                                    </div>
                                  )
                                })}
                            </DoubtsMainCard>
                          )
                        })
                      : noDoubts()}
                  </Accordion>
                </div>
              )
            ) : (
              <Tabs className="square__tabs square__tabs--twoItems">
                <Tab
                  eventKey="pending"
                  title={
                    'Pending (' +
                    (pendingAsseeements ? pendingAsseeements?.length : 0) +
                    ')'
                  }
                >
                  {pendingAsseeements && pendingAsseeements?.length
                    ? pendingAsseeements?.map((item, index) => {
                        return (
                          <ExaminationTeacherCard
                            key={index}
                            img="https://cwxdata.s3.ap-south-1.amazonaws.com/phy.jpg"
                            onClick={() => onAssessmentSelect(item)}
                            heading={item?.title}
                            subject={selectedSubject?.subject_title}
                            class={selectedBatch?.title}
                            evalutedResponse={`${item.submissions_evoluted}/${item.submissions_received}`}
                            currentResponse={`${item.submissions_received}/${
                              selectedBatch?.students?.length || 0
                            }`}
                            buttonColor={'blue'}
                            button={'EVALUATE NOW'}
                          />
                        )
                      })
                    : noEval('pending')}
                </Tab>
                <Tab
                  eventKey="evaluted"
                  title={
                    'Evaluated (' +
                    (evaluatedAsseeements ? evaluatedAsseeements?.length : 0) +
                    ')'
                  }
                >
                  {evaluatedAsseeements && evaluatedAsseeements?.length
                    ? evaluatedAsseeements?.map((item, index) => {
                        return (
                          <ExaminationTeacherCard
                            key={index}
                            img="https://cwxdata.s3.ap-south-1.amazonaws.com/phy.jpg"
                            onClick={() => onAssessmentSelect(item)}
                            heading={item?.title}
                            subject={selectedSubject?.subject_title}
                            class={selectedBatch?.title}
                            evalutedResponse={`${item.submissions_evoluted}/${item.submissions_received}`}
                            currentResponse={`${item.submissions_received}/${
                              selectedBatch?.students?.length || 0
                            }`}
                            buttonColor={'blue'}
                            button={'VIEW EVALUATION'}
                          />
                        )
                      })
                    : noEval('evaluated')}
                </Tab>
              </Tabs>
            )}
          </Col75>
          <Col25 className={'rightLayout--teacherDashboard'}>
            <div className={'rightLayout--white'}>
              {isLoadingBatches ? (
                <Loader />
              ) : (
                <div>
                  <h1>Students</h1>
                  {selectedBatch?.students?.length > 0
                    ? selectedBatch?.students?.map((student, index) => (
                        <StudentsCard
                          key={index}
                          itemNo={1}
                          icon={TeachersIcon}
                          teachersName={student?.name}
                          designation={selectedBatch?.title}
                        />
                      ))
                    : noStudents()}
                  <div className="buttonLinearBg">
                    <Button
                      color="link"
                      click={() => {}}
                      text="GO TO ALL STUDENTS"
                      className="changePasswordButton"
                    />
                  </div>
                </div>
              )}
            </div>
          </Col25>
        </LeftRightLayout>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default TeacherDashbaord
