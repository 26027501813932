import {
  GET_INSTITUTION_START,
  GET_INSTITUTION_SUCCESS,
  GET_INSTITUTION_FAILURE,
  POST_INSTITUTION_START,
  POST_INSTITUTION_SUCCESS,
  POST_INSTITUTION_FAILURE,
} from '../actions'

let initialState = {
  title: '',
  error: false,
  isInstitutionGetting: false,
  isInstitutionGot: false,
  isLoading: false,
}
export const institution = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTITUTION_START:
      return {
        ...state,
        isInstitutionGetting: true,
        error: false,
      }
    case GET_INSTITUTION_SUCCESS:
      return {
        ...state,
        isInstitutionGot: true,
        isInstitutionGetting: false,
        error: false,
        title: action.title,
      }
    case GET_INSTITUTION_FAILURE:
      return {
        ...state,
        error: true,
        isInstitutionGetting: false,
        isInstitutionGot: false,
      }

    case POST_INSTITUTION_START:
      return {
        ...state,
        isLoading: true,
        error: false,
      }
    case POST_INSTITUTION_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
      }
    case POST_INSTITUTION_FAILURE:
      return {
        ...state,
        error: true,
        isLoading: false,
      }
    default:
      return state
  }
}

export const getIsLoading = (state) => state.institution.isLoading
export const getIsError = (state) => state.institution.error

export const getInstitutionName = (state) => state.institution.title
