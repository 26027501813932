import {
  POST_IMPRESSIONS_START,
  POST_IMPRESSIONS_SUCCESS,
  POST_IMPRESSIONS_FAILURE,
  GET_LAST_VIEWED_START,
  GET_LAST_VIEWED_SUCCESS,
  GET_LAST_VIEWED_FAILURE,
  GET_PENDING_ITEMS_START,
  GET_PENDING_ITEMS_SUCCESS,
  GET_PENDING_ITEMS_FAILURE,
} from '../actions'

import { checkStatus } from '../helpers.js'

const postImpressionsStart = () => {
  return {
    type: POST_IMPRESSIONS_START,
  }
}

const postImpressionsSuccess = ({ impressions }) => {
  return {
    type: POST_IMPRESSIONS_SUCCESS,
    impressions,
  }
}

const postImpressionsFailure = () => {
  return {
    type: POST_IMPRESSIONS_FAILURE,
  }
}

const apiCallToImpressions = ({ jwt, body }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/impressions`
  const options = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(body),
  }
  return fetch(url, options)
}

export const postImpressions = (data) => {
  return async (dispatch) => {
    dispatch(postImpressionsStart())
    try {
      const receivedData = await apiCallToImpressions(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      let subjects = response.data.batch_subjects
      dispatch(postImpressionsSuccess({ subjects }))
    } catch (err) {
      dispatch(postImpressionsFailure())
    }
  }
}

const fetchLastViewdStart = () => {
  return {
    type: GET_LAST_VIEWED_START,
  }
}

const fetchLastViewdSuccess = (last_visited_topics) => {
  return {
    type: GET_LAST_VIEWED_SUCCESS,
    last_visited_topics,
  }
}

const fetchLastViewdFailure = () => {
  return {
    type: GET_LAST_VIEWED_FAILURE,
  }
}

const apiCallTofetchLastViewd = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/me/last-visited`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}

export const fetchLastViewd = (data) => {
  return async (dispatch) => {
    dispatch(fetchLastViewdStart())
    try {
      const receivedData = await apiCallTofetchLastViewd(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      let last_visited_topics = response.data.last_visited_topics
      dispatch(fetchLastViewdSuccess(last_visited_topics))
    } catch (err) {
      dispatch(fetchLastViewdFailure())
    }
  }
}

const fetchPendingItemsStart = () => {
  return {
    type: GET_PENDING_ITEMS_START,
  }
}

const fetchPendingItemsSuccess = (pending_items) => {
  return {
    type: GET_PENDING_ITEMS_SUCCESS,
    pending_items,
  }
}

const fetchPendingItemsFailure = () => {
  return {
    type: GET_PENDING_ITEMS_FAILURE,
  }
}

const apiCallTofetchPendingItems = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/me/pending-items`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}

export const fetchPendingItems = (data) => {
  return async (dispatch) => {
    dispatch(fetchPendingItemsStart())
    try {
      const receivedData = await apiCallTofetchPendingItems(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      let pending_items = response.data.studyplan
      dispatch(fetchPendingItemsSuccess(pending_items))
    } catch (err) {
      dispatch(fetchPendingItemsFailure())
    }
  }
}
