import React from 'react'

const SubjectDoubtCard = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={'subjectDoubtsCard row ' + props?.className}
    >
      <div className="col-xl-4  col-lg-4  col-md-4 col-sm-4 subjectCard__img">
        <img src={props.icon} alt="CWX" />
        <div className="clear"></div>
      </div>
      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
        <h5>{props.subjectName}</h5>
        {props.batchName && (
          <p className="subjectCard__subjectCode">{props.batchName}</p>
        )}
        <p className="subjectCard__subjectCode">
          Subject code : {props.subjectCode}
        </p>
        {!props?.notes ? (
          <p className="subjectCard__noOfTopics">{props.noOfTopics} Doubts</p>
        ) : null}
        <div className="clear"></div>
      </div>
    </div>
  )
}

export default SubjectDoubtCard
