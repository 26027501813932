import {
  APP_LOGIN_START,
  APP_LOGIN,
  APP_LOGIN_FAIL,
  APP_LOGOUT,
  // APP_AUTH_FAILED,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  VERIFY_OTP_START,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  STUDENT_RESET_PASSWORD_START,
  STUDENT_RESET_PASSWORD_SUCCESS,
  STUDENT_RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  TEACHER_RESET_PASSWORD_START,
  TEACHER_RESET_PASSWORD_SUCCESS,
  TEACHER_RESET_PASSWORD_FAILURE,
  // APP_AUTH_FAILED
} from '../actions.js'

import { checkStatus } from '../helpers.js'

const emitLoginStart = () => ({
  type: APP_LOGIN_START,
})

const emitLoggedIn = (data) => {
  const {
    jwt,
    user_id,
    username,
    entity_id,
    entity_type,
    permission,
    institution_id,
    institution_title,
    institution_nav_logo,
    academic_year,
  } = data
  return {
    type: APP_LOGIN,
    username,
    entity_id,
    entity_type,
    institution_id,
    institution_title,
    institution_nav_logo,
    permission,
    jwt,
    user_id,
    academic_year,
  }
}

const emitLoginFail = () => ({
  type: APP_LOGIN_FAIL,
})

export const emitLogout = () => ({
  type: APP_LOGOUT,
})

// const emitAuthFailed = () => ({
//     type: APP_AUTH_FAILED
// })

const login = ({ username, password }) => {
  let url = process.env.REACT_APP_SERVER + '/api/auth/login'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  })
}

export const emitLogin = (data) => {
  return async (dispatch) => {
    try {
      dispatch(emitLoginStart())
      const recivedData = await login(data)
      const validatedData = checkStatus(recivedData)
      const json = await validatedData.json()
      dispatch(emitLoggedIn(json.data))
    } catch (err) {
      console.error(err, 'err')
      dispatch(emitLoginFail())
    }
  }
}

const forgotPasswordStart = () => ({
  type: FORGOT_PASSWORD_START,
})

export const forgotPasswordSuccess = (data) => ({
  type: FORGOT_PASSWORD_SUCCESS,
})

const forgotPasswordFailure = () => ({
  type: FORGOT_PASSWORD_FAILURE,
})

const forgotPassword = ({ username }) => {
  let url = process.env.REACT_APP_SERVER + '/api/auth/forgot-password'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ username }),
  })
}

export const postForgotPassword = (data) => {
  return async (dispatch) => {
    try {
      dispatch(forgotPasswordStart())
      const recivedData = await forgotPassword(data)
      const validatedData = checkStatus(recivedData)
      const json = await validatedData.json()
      dispatch(forgotPasswordSuccess(json.data))
    } catch (err) {
      console.error(err, 'err')
      dispatch(forgotPasswordFailure())
    }
  }
}

//change password
const changePasswordStart = () => ({
  type: CHANGE_PASSWORD_START,
})

export const changePasswordSuccess = (data) => ({
  type: CHANGE_PASSWORD_SUCCESS,
})

const changePasswordFailure = () => ({
  type: CHANGE_PASSWORD_FAILURE,
})

const changePassword = ({ jwt, payload }) => {
  let url = process.env.REACT_APP_SERVER + '/api/auth/change-password'
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export const postChangePassword = (data) => {
  return async (dispatch) => {
    try {
      dispatch(changePasswordStart())
      const recivedData = await changePassword(data)
      const validatedData = checkStatus(recivedData)
      const json = await validatedData.json()
      dispatch(changePasswordSuccess(json.data))
    } catch (err) {
      console.error(err, 'err')
      dispatch(changePasswordFailure())
    }
  }
}

const verifyOTPStart = () => ({
  type: VERIFY_OTP_START,
})

const verifyOTPSuccess = (data) => {
  const { temp_token } = data
  return {
    type: VERIFY_OTP_SUCCESS,
    temp_token,
  }
}

const verifyOTPFailure = () => ({
  type: VERIFY_OTP_FAILURE,
})

const verifyOTP = ({ otp }) => {
  let url = process.env.REACT_APP_SERVER + '/api/auth/verify-otp'
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ otp }),
  })
}

export const postVerifyOTP = (data) => {
  return async (dispatch) => {
    try {
      dispatch(verifyOTPStart())
      const recivedData = await verifyOTP(data)
      const validatedData = checkStatus(recivedData)
      const json = await validatedData.json()
      dispatch(verifyOTPSuccess(json.data))
    } catch (err) {
      console.error(err, 'err')
      dispatch(verifyOTPFailure())
    }
  }
}

const resetPasswordStart = () => ({
  type: RESET_PASSWORD_START,
})

const resetPasswordSuccess = (data) => ({
  type: RESET_PASSWORD_SUCCESS,
})

const resetPasswordFailure = () => ({
  type: RESET_PASSWORD_FAILURE,
})

const resetPassword = ({ token, password }) => {
  let url = process.env.REACT_APP_SERVER + '/api/auth/reset-password'
  const payload = {
    temp_token: token,
    new_password: password,
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
}

export const postResetPassword = (data) => {
  return async (dispatch) => {
    try {
      dispatch(resetPasswordStart())
      const recivedData = await resetPassword(data)
      const validatedData = checkStatus(recivedData)
      const json = await validatedData.json()
      dispatch(resetPasswordSuccess(json.data))
    } catch (err) {
      console.error(err, 'err')
      dispatch(resetPasswordFailure())
    }
  }
}

const postStudentResetStart = () => {
  return {
    type: STUDENT_RESET_PASSWORD_START,
  }
}

const postStudentResetSuccess = () => {
  return {
    type: STUDENT_RESET_PASSWORD_SUCCESS,
  }
}

const postStudentResetFailure = () => {
  return {
    type: STUDENT_RESET_PASSWORD_FAILURE,
  }
}

const postStudentResetapiCall = ({ payload, jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/students/reset-password`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postStudentReset = (data) => {
  return async (dispatch) => {
    dispatch(postStudentResetStart())
    try {
      const receivedData = await postStudentResetapiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(postStudentResetSuccess())
    } catch (err) {
      dispatch(postStudentResetFailure())
    }
  }
}

//reset password for teacher
const postTeacherResetStart = () => {
  return {
    type: TEACHER_RESET_PASSWORD_START,
  }
}

const postTeacherResetSuccess = () => {
  return {
    type: TEACHER_RESET_PASSWORD_SUCCESS,
  }
}

const postTeacherResetFailure = () => {
  return {
    type: TEACHER_RESET_PASSWORD_FAILURE,
  }
}

const postTeacherResetapiCall = ({ payload, jwt }) => {
  console.log(payload, 'payload')
  const url = `${process.env.REACT_APP_SERVER}/api/faculties/reset-password`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postTeacherReset = (data) => {
  return async (dispatch) => {
    dispatch(postTeacherResetStart())
    try {
      const receivedData = await postTeacherResetapiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(postTeacherResetSuccess())
    } catch (err) {
      dispatch(postTeacherResetFailure())
    }
  }
}
