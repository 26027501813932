import React from 'react'

const ExaminationTeacherCard = (props) => {
  return (
    <div className="examinationCard">
      <h2>{props.heading}</h2>
      <div className="row">
        <div className="col-xl-3  col-lg-3  col-md-12 col-sm-12">
          <img className="examinationCard__img" src={props.img} alt="CWX" />
          <div className="clear"></div>
        </div>
        <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 examinationCard__content">
          <div className="examinationCard__details examinationCard__details--teacher">
            <div className="examinationCard__details--sec1--teacher">
              <h5>{props.subject}</h5>
              <p>
                Class: <span>{props.class}</span>
              </p>
              <p>
                Evaluated reponses: <span>{props.evalutedResponse}</span>
              </p>
              <p>
                Current responses: <span>{props.currentResponse}</span>
              </p>
              <div className="clear"></div>
            </div>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 display__flex examinationCard__wrap3">
          <button
            onClick={props.onClick}
            className={'button--' + props.buttonColor}
          >
            {props.button}
          </button>
          <div className="clear"></div>
        </div>
      </div>
    </div>
  )
}

export default ExaminationTeacherCard
