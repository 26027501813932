import React from 'react'

const Button = (props) => {
  return (
    <button
      className={'button button--' + props.color + ' ' + props.className}
      type="submit"
      onClick={props.click}
    >
      {props.text}
    </button>
  )
}
export default Button
