import React from 'react'

const ExamCard = (props) => {
  return (
    <div onClick={props.onClick} className="examCard">
      <h2>{props.heading + ' ' + props?.time}</h2>

      <div className="examCard__wrapper">
        <div className="examCard__teacherWrap">
          <div className="examCard__teacherImg">
            <img src={props.teacherImg} alt={props.teacher} />
          </div>

          <div className="examCard__examDetails">
            {/* <p>
              Teacher: <span>{props.teacher}</span>
            </p> */}
            <div className="clear"></div>
            <p>
              Number of questions: <span>{props.noOfQuestions} </span>
              <span
                className={
                  'examCard__beforeExam examCard__beforeExam--' +
                  props.scoreDisplay
                }
              >
                {/* <span className="pipe">|</span>{' '}
                <span className="examCard__idealTime">
                  Ideal Time to Complete:{' '}
                </span>
                <span> {props.timeToComplete}</span> */}
              </span>
              <span
                className={
                  'examCard__afterExam examCard__afterExam--' +
                  props.scoreDisplay
                }
              >
                SCORE: {props.score}
              </span>
            </p>
          </div>
          <div className="clear"></div>
        </div>

        <div className={'examCard__disclaimer--' + props.disclaimer}>
          <span>Note: </span> {props.message}
          <div className="clear"></div>
        </div>

        <div className="exam__submitWrapper">
          <p>
            Start Date: <span>{props.startDate}</span>
          </p>
          <p>
            End Date: <span>{props.submitDate}</span>
          </p>
          <button
            onClick={props.onClick}
            className={'button--' + props.buttonColor}
          >
            {props.button}
          </button>
          <div className="clear"></div>
        </div>

        <div className="clear"></div>
      </div>
    </div>
  )
}

export default ExamCard
