import React from 'react'

const Popup = (props) => {
  return (
    <div className={'popup__container ' + props.className}>
      <div className="popup__box">
        <div className="popup__wrap">
          {props.children}
          <button className="button button--transparent" onClick={props.close}>
            close
          </button>
          {props.buttonText && (
            <button className="button button--blue" onClick={props.onClick}>
              {props.buttonText}
            </button>
          )}
          <span onClick={props.close} className="close__popup">
            x
          </span>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  )
}

export default Popup
