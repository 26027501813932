import React from 'react'
import RightArrow from '../../../assets/images/rightArrow.png'

const SubjectCard = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={'subjectCard row ' + props?.className}
    >
      <div className="col-xl-4  col-lg-4  col-md-4 col-sm-4 subjectCard__img">
        <img src={props.icon} alt="CWX" />
        <div className="clear"></div>
      </div>
      <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8">
        <h5>{props.subjectName}</h5>
        <p className="subjectCard__subjectCode">
          Subject code : {props.subjectCode}
        </p>
        {props?.moderator ? (
          <React.Fragment>
            <p className="subjectCard__count">Students: {props.noOfStudents}</p>
            <p className="subjectCard__count">Teachers: {props.noOfTeachers}</p>
          </React.Fragment>
        ) : null}

        {props?.profile ? (
          <p className="subjectCard__noOfTopics">{props.noOfTopics}</p>
        ) : (
          <p className="subjectCard__noOfTopics">{props.noOfTopics} Chapters</p>
        )}
        <div className="clear"></div>
      </div>
      {props?.profile ? null : (
        <img className="subjectCard__arrow" src={RightArrow} alt="cwx" />
      )}
    </div>
  )
}

export default SubjectCard
