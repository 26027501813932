import React, { useEffect, useState, useRef, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

//assets
import Col100 from '../../assets/layout/leftrightlayout/column100'
import Loader from '../../assets/molecules/loader'
import LeftRightLayout from '../../assets/layout/leftrightlayout'
import TopicsCard from '../../assets/molecules/topicsCardWithOptions'
import PopupCreateLiveSession from '../../../components/assets/layout/popupForm'
import { subjectPics, usePrevious } from '../../helpers'
import PopupConfirm from '../../../components/assets/layout/popupConfirm'
import { AlertSuccess } from '../../assets/molecules/alerts'

//action
//topic
import {
  fetchTopicsByChapterId,
  fetchAddTopic,
  fetchDeleteTopic,
  fetchEditTopic,
} from '../../../redux/action/topic'
import { startVideoUpload } from '../../../redux/action/vimeoUpload'
import { startFileUpload } from '../../../redux/action/upload'
//ui
import { setTopic } from '../../../redux/action/ui'

//reducer

import {
  getIsTopicsLoading,
  getTopics,
  getIsError,
  getStatus,
} from '../../../redux/reducer/topic'
import { getSubjects } from '../../../redux/reducer/subject'
import { getChapters } from '../../../redux/reducer/chapter'
import {
  getVimeoLink,
  getIsLoadingVimeoFile,
  getIsError as getIsVimeoError,
} from '../../../redux/reducer/vimeoUpload'
//account
import { getJwt, getUserId, getUsername } from '../../../redux/reducer/account'

import {
  getSubjectTitle,
  getSubjectId,
  getChapterId,
  getChapterTitle,
} from '../../../redux/reducer/ui'
import {
  getIsError as getFileUploadError,
  getFile,
  getIsLoadingUploadFile,
} from '../../../redux/reducer/upload'

const Topic = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const notesRef = useRef([])
  const videoRef = useRef()
  const arrLength = 1
  //selectors
  //account
  const jwt = useSelector((state) => getJwt(state))
  //ui
  const subjectTitle = useSelector(getSubjectTitle)
  const chapterTitle = useSelector(getChapterTitle)
  const subjectId = useSelector(getSubjectId)
  const chapterId = useSelector(getChapterId)
  //subject(topics)
  const chapters = useSelector((state) => getChapters(state))
  const subjects = useSelector(getSubjects)
  const topics = useSelector((state) => getTopics(state))
  const topicStatus = useSelector((state) => getStatus(state))
  const isLoading = useSelector((state) => getIsTopicsLoading(state))
  const isTopicError = useSelector(getIsError)
  const isLoadingPrev = usePrevious(isLoading)
  const userid = useSelector(getUserId)
  const username = useSelector(getUsername)
  const vimeoLink = useSelector(getVimeoLink)
  const isLoadingVimeo = useSelector((state) => getIsLoadingVimeoFile(state))
  const isLoadingVimeoPrev = usePrevious(isLoadingVimeo)
  const vimeoError = useSelector(getIsVimeoError)
  const uploadedFile = useSelector(getFile)
  const isLoadingUploadFile = useSelector(getIsLoadingUploadFile)
  const isLoadingUploadFilePrev = usePrevious(isLoadingUploadFile)
  const fileUploadError = useSelector(getFileUploadError)

  const [currentSubject, setCurrentSubject] = useState('')
  const [currentChapter, setCurrentChapter] = useState('')
  const [formData, setFormData] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const [popup, setPopup] = useState('hidePop')
  const [showSuccess, setShowSuccess] = useState(false)
  const [showEditSuccess, setShowEditSuccess] = useState(false)
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false)
  const [pressStatus, setPressStatus] = useState('')
  const [uploadedVideo, setUploadedVideo] = useState({})
  const [editVideo, setEditVideo] = useState(true)
  const [contentVideo, setContentVideo] = useState(false)
  const [description, setDescription] = useState(EditorState.createEmpty())
  const [notesUrl, setNotesUrl] = useState([])
  const [videoImageUrl, setVideoImageUrl] = useState('')
  const [imageUploaded, setImageUploaded] = useState(false)
  const [noteUploaded, setNoteUploaded] = useState(false)

  const subjectPicKeys = Object.keys(subjectPics)
  if (notesRef.current.length !== arrLength) {
    notesRef.current = Array(arrLength)
      .fill()
      .map((_, i) => notesRef.current[i] || createRef())
  }

  useEffect(() => {
    dispatch(fetchTopicsByChapterId({ jwt, chapterId }))
  }, [dispatch, jwt, chapterId, subjectId])

  useEffect(() => {
    if (chapterId && subjectId) {
      const filterdSubject = subjects.filter(
        (subject) => subject.subject_id === subjectId
      )
      setCurrentSubject(filterdSubject.length ? filterdSubject[0] : '')
      const filterdchapter = chapters.filter(
        (chapter) => chapter._id === chapterId
      )
      setCurrentChapter(filterdchapter.length ? filterdchapter[0] : '')
    }
  }, [chapterId, chapters, subjectId, subjects])

  useEffect(() => {
    if (isLoadingPrev !== undefined && !isLoading && !isTopicError) {
      setShowSuccess(true)
      setShowEditSuccess(true)
      setShowDeleteSuccess(true)
    }
  }, [isLoading, isLoadingPrev, isTopicError])

  useEffect(() => {
    if (isTopicError) {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }, [isTopicError])

  useEffect(() => {
    if (notesUrl === '') {
      notesRef.current.value = ''
    }
    if (videoImageUrl === '') {
      videoRef.current.value = ''
    }
  }, [notesUrl, videoImageUrl])

  useEffect(() => {
    if (
      isLoadingUploadFilePrev !== undefined &&
      isLoadingUploadFilePrev !== isLoadingUploadFile &&
      !isLoadingUploadFile &&
      !fileUploadError
    ) {
      if (imageUploaded) {
        setFormData({ ...formData, contentUrl: uploadedFile.publicUrl })
        setVideoImageUrl(uploadedFile.publicUrl)
        setImageUploaded(false)
      }
      if (noteUploaded) {
        const urls = [...notesUrl, uploadedFile?.publicUrl]
        setNotesUrl(urls)
        setNoteUploaded(false)
      }
    }
  }, [
    fileUploadError,
    formData,
    imageUploaded,
    isLoadingUploadFile,
    isLoadingUploadFilePrev,
    noteUploaded,
    notesUrl,
    uploadedFile,
    uploadedFile.publicUrl,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      isLoadingVimeoPrev !== undefined &&
      !isLoadingVimeo &&
      !vimeoError &&
      isLoadingVimeo != isLoadingVimeoPrev
    ) {
      if (!isEdit) {
        const data = {
          title: formData.title,
          type: formData.type,
          content_uri: vimeoLink,
          wysiwyg_state: draftToHtml(
            convertToRaw(description.getCurrentContent())
          ).toString(),
          chapter_id: chapterId,
          author_id: userid,
          author_name: username,
          notes: notesUrl && notesUrl.length > 0 ? notesUrl : [],
        }
        dispatch(fetchAddTopic({ jwt, data }))
      } else {
        const data = {
          title: formData.title,
          type: formData.type,
          content_uri: vimeoLink,
          wysiwyg_state: draftToHtml(
            convertToRaw(description.getCurrentContent())
          ).toString(),
          chapter_id: chapterId,
          author_id: userid,
          author_name: username,
          notes: notesUrl && notesUrl.length > 0 ? notesUrl : [],
        }
        dispatch(
          fetchEditTopic({
            jwt,
            topicId: selectedTopic._id,
            data,
          })
        )
      }
    }
  }, [isLoadingVimeo, isLoadingVimeoPrev]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isLoadingPrev !== undefined && !isLoading && !isTopicError) {
      if (topicStatus === 'added') {
        closeTopicPopup()
        setPressStatus('create')
        setFormData({})
        setUploadedVideo({})
        setContentVideo(false)
        setErrorMessage('')
        setDescription(EditorState.createEmpty())
      } else if (topicStatus === 'edited') {
        closeTopicPopup()
        setIsEdit(false)
        setFormData({})
        setPressStatus('edit')
        setEditVideo(true)
        setContentVideo(false)
        setErrorMessage('')
        setDescription(EditorState.createEmpty())
      } else if (topicStatus === 'deleted') {
        setPressStatus('delete')
      }
    }
  }, [isTopicError, isLoading, isLoadingPrev, topicStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  // const onChangeChapter = (e) => {
  //   e.preventDefault()
  //   dispatch(setChapter(e.target.value))
  // }

  // const editTopic = (e, topicId, topicTitle) => {
  //   dispatch(setTopic(topicId, topicTitle))
  //   history.push(`/me/faculty/subjects/chapters/topics/edit`)
  // }
  // const deletePopup = (topicId) => {
  //   setPopClassName('showPop')
  //   setDeletetopicId(topicId)
  // }
  // const closePopup = () => {
  //   setPopClassName('hidePop')
  // }
  // const deleteTopic = (e, topicId) => {
  //   e.preventDefault()
  //   dispatch(fetchDeleteTopic({ jwt, topicId }))
  //   setPopClassName('hidePop')
  // }

  const onTopicSelect = (topicId, title) => {
    dispatch(setTopic(topicId, title))
    history.push('/me/faculty/subjects/chapters/topics/detail')
  }

  const onChange = (e) => {
    if (e.target.name === 'type') {
      videoRef.current.value = ''
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        contentUrl: '',
      })
      errorMessage && setErrorMessage('')
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
      errorMessage && setErrorMessage('')
    }
  }

  const onSubmit = () => {
    if (
      formData?.title &&
      formData?.type &&
      formData?.contentUrl &&
      draftToHtml(convertToRaw(description.getCurrentContent())).toString()
    ) {
      if (formData?.type === 'video') {
        const payload = {
          file: uploadedVideo.files,
          name: formData.title,
        }
        dispatch(startVideoUpload(payload))
        closeTopicPopup()
      } else {
        const data = {
          title: formData.title,
          type: formData.type,
          content_uri: formData.contentUrl,
          wysiwyg_state: draftToHtml(
            convertToRaw(description.getCurrentContent())
          ).toString(),
          chapter_id: chapterId,
          author_id: userid,
          author_name: username,
          notes: notesUrl && notesUrl.length > 0 ? notesUrl : [],
        }
        dispatch(fetchAddTopic({ jwt, data }))
      }
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  const showPopup = () => {
    setPopup('showPop')
    setFormData({})
    setShowSuccess(false)
    setShowEditSuccess(false)
    setDescription(EditorState.createEmpty())
    setPressStatus('')
    setErrorMessage('')
    setNotesUrl([])
    setVideoImageUrl('')
  }

  const closePopup = () => {
    setPopup('hidePop')
  }

  const contentTypes = ['image', 'audio', 'video']

  const getSubjectNameById = (id) => {
    const filteredSubject = subjects?.filter(
      (subject) => subject.subject_id === id
    )
    return filteredSubject.length ? filteredSubject[0].subject_title : ''
  }

  const [editTopicPopClassName, setEditTopicPopClassName] = useState('hidePop')
  const [deleteTopicPopClassName, setDeleteTopicPopClassName] = useState(
    'hidePop'
  )
  const [selectedTopic, setSelectedTopic] = useState('')
  const [isEdit, setIsEdit] = useState(false)

  const openEditTopicPopup = (topic) => {
    setIsEdit(true)
    setFormData({
      title: topic.title,
      type: topic.type,
      contentUrl: topic.content_uri,
    })
    setNotesUrl(topic?.notes)
    setEditTopicPopClassName('showPop')
    setSelectedTopic(topic)
    setShowEditSuccess(false)
    setPressStatus('')
    const blocksFromHtml = htmlToDraft(topic.wysiwyg_state)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    const editorState = EditorState.createWithContent(contentState)
    setDescription(editorState)
  }

  const openDeleteTopicPopup = (topic) => {
    setDeleteTopicPopClassName('showPop')
    setSelectedTopic(topic)
    setShowDeleteSuccess(false)
    setPressStatus('')
  }

  const closeEditTopicPopup = () => {
    setIsEdit(false)
    setEditTopicPopClassName('hidePop')
    setErrorMessage('')
  }

  const closeDeleteTopicPopup = () => {
    setDeleteTopicPopClassName('hidePop')
  }

  const closeTopicPopup = () => {
    setEditTopicPopClassName('hidePop')
    setPopup('hidePop')
    setErrorMessage('')
  }

  const onTopicEdit = () => {
    if (
      formData?.title &&
      formData?.type &&
      formData?.contentUrl &&
      draftToHtml(convertToRaw(description.getCurrentContent())).toString()
    ) {
      if (contentVideo) {
        const payload = {
          file: uploadedVideo.files,
          name: formData.title,
        }
        dispatch(startVideoUpload(payload))
        closeTopicPopup()
      } else {
        const data = {
          title: formData.title,
          type: formData.type,
          content_uri: formData.contentUrl,
          wysiwyg_state: draftToHtml(
            convertToRaw(description.getCurrentContent())
          ).toString(),
          chapter_id: chapterId,
          author_id: userid,
          author_name: username,
          notes: notesUrl && notesUrl.length > 0 ? notesUrl : [],
        }
        dispatch(
          fetchEditTopic({
            jwt,
            topicId: selectedTopic._id,
            data,
          })
        )
      }
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  const onTopicDelete = () => {
    dispatch(
      fetchDeleteTopic({
        topicId: selectedTopic._id,
        jwt,
      })
    )
    setSelectedTopic('')
    closeDeleteTopicPopup()
  }

  const onVideoImageUpload = (e, type) => {
    if (type === 'video') {
      setEditVideo(false)
      setContentVideo(true)
      setFormData({ ...formData, contentUrl: e.target.files })
      setUploadedVideo({ ...uploadedVideo, files: e.target.files })
    }
    if (type === 'image') {
      setErrorMessage('')
      const fileData = e.target.files[0]
      const data = new FormData()
      data.append('file', fileData)
      setImageUploaded(true)
      dispatch(startFileUpload(data))
    }
  }

  // const onNotesUpload = (e) => {
  //   const fileData = e.target.files[0]
  //   const data = new FormData()
  //   data.append('file', fileData)
  //   setNoteUploaded(true)
  //   dispatch(startFileUpload(data))
  // }

  const onChangeFile = (e) => {
    const fileData = e.target.files[0]
    const data = new FormData()
    data.append('file', fileData)
    setNoteUploaded(true)
    dispatch(startFileUpload(data))
  }

  const onUploadNotes = (e, i) => {
    e.preventDefault()
    notesRef.current[0].current.click()
  }

  const onDeleteFile = (index) => {
    const values = [...notesUrl]
    values.splice(index, 1)
    setNotesUrl(values)
  }

  return (
    <div className="tabs__container">
      <section>
        <div className="row">
          <div className="col-md-8 padding--0">
            <h1>Chapters</h1>
          </div>
          <div className="col-md-4 breadcrumb__rightSide padding--0">
            <button
              onClick={() => {
                showPopup()
              }}
              className="button--blue"
            >
              ADD TOPIC
            </button>
          </div>
        </div>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/me/faculty/subjects">{subjectTitle}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/me/faculty/subjects">{chapterTitle}</Link>
            </li>
            <li className="breadcrumb-item active">Topics</li>
          </ol>
        </nav>
        <div className="clear"></div>
        <PopupCreateLiveSession
          className={!isEdit ? popup : editTopicPopClassName}
          close={() => {
            closePopup()
            closeEditTopicPopup()
          }}
          onClickButton={!isEdit ? onSubmit : onTopicEdit}
          buttonText={isEdit ? 'EDIT TOPIC' : 'CREATE TOPIC'}
          popupHead={isEdit ? 'Edit Topic' : 'Create Topic'}
        >
          <form>
            <ol>
              <li>
                <h2>What is this topic about?</h2>
                <p>
                  This information will help the students understand what this
                  topic is all about.
                </p>
                <div className="form-group sessionTitle">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter topic title"
                    name="title"
                    value={formData?.title || ''}
                    onChange={(e) => {
                      onChange(e)
                    }}
                  />
                </div>
                {/* <div className="form-row">
                  <div className="form-group col sessionTopic">
                    <select
                      id="inputState"
                      className="form-control"
                      name="chapterId"
                      onChange={(e) => {
                        onChange(e)
                      }}
                    >
                      <option value="" selected>
                        Select Chapter
                      </option>
                      {chapters?.map((chapter) => {
                        return (
                          <option value={chapter._id}>
                            {chapter.title}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div> */}
              </li>
              <li>
                <h2>What will be the content type for this topic?</h2>
                <p>
                  Content type can be anything from video, audio and pictures.
                  This content will appear along with the description of the
                  topc.
                </p>
                <div className="form-row">
                  <div className="form-group col sessionTopic">
                    <select
                      id="inputState"
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        onChange(e)
                      }}
                    >
                      <option value="" selected>
                        Select content type
                      </option>
                      {contentTypes?.map((type, index) => {
                        return (
                          <option
                            key={index}
                            value={type}
                            selected={type === formData?.type ? true : false}
                          >
                            {type}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  {formData?.type === 'video' || formData?.type === 'image' ? (
                    <div className="form-group col sessionTitle">
                      <input
                        type="file"
                        name="uploadVideoImage"
                        ref={videoRef}
                        onChange={(e) => onVideoImageUpload(e, formData?.type)}
                      />
                      {formData?.contentUrl &&
                      formData?.type === 'video' &&
                      isEdit &&
                      editVideo ? (
                        <p>File uploaded : {formData?.contentUrl}</p>
                      ) : null}
                    </div>
                  ) : (
                    <div className="form-group col sessionTitle">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter content url"
                        name="contentUrl"
                        ref={videoRef}
                        value={formData?.contentUrl || ''}
                        onChange={(e) => {
                          onChange(e)
                        }}
                      />
                    </div>
                  )}
                  {formData?.contentUrl && formData?.type === 'image' && (
                    <p>File uploaded : {formData?.contentUrl}</p>
                  )}
                </div>
              </li>
              <li>
                <h2>Describe topic content here</h2>
                <p>
                  Student’s will read this content as a topic in their
                  individual topic section
                </p>
                <div className="form-group sessionDescription">
                  <Editor
                    editorState={description}
                    onEditorStateChange={setDescription}
                    placeholder={'Enter topic description'}
                  />
                </div>
              </li>
              <li>
                <h2>Notes</h2>
                <p>Add Notes to the topic.</p>
                {notesUrl && notesUrl?.length
                  ? notesUrl?.map((file, index) => {
                      return (
                        <div>
                          <a href={file}>Note {index + 1} Uploaded</a>
                          <span
                            style={{
                              cursor: 'pointer',
                              fontSize: 15,
                              padding: 20,
                              color: '#fb6161',
                            }}
                            onClick={() => onDeleteFile(index)}
                          >
                            {' '}
                            DELETE{' '}
                          </span>
                        </div>
                      )
                    })
                  : null}
                {isLoadingUploadFile && noteUploaded ? (
                  <Loader />
                ) : (
                  <div className="form-group sessionTopic">
                    <input
                      type="file"
                      name={'notesUrl'}
                      ref={notesRef.current[0]}
                      style={{ display: 'none' }}
                      onChange={onChangeFile}
                    />
                    <button
                      onClick={(e) => onUploadNotes(e, 0)}
                      className="button--blue"
                    >
                      {notesUrl && notesUrl?.length
                        ? 'Add More Notes'
                        : 'Upload Notes'}
                    </button>
                  </div>
                )}
              </li>
            </ol>
            <div className="clear"></div>
            {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}
          </form>
        </PopupCreateLiveSession>
      </section>
      <section>
        <LeftRightLayout>
          <Col100>
            {isLoading || isLoadingVimeo ? (
              <Loader />
            ) : subjectTitle ? (
              <section>
                <Link className="backLink" to="/me/faculty/subjects">
                  {' '}
                  <p className="back">Chapters</p>
                </Link>
                <div className="chaptersCard chaptersCard--topicListing row">
                  <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 chaptersCard__description ">
                    <h5>
                      {chapterTitle} {currentSubject?.batch_title}
                    </h5>
                    <p className="chaptersCard__availableTopics">
                      {currentChapter?.description}
                    </p>
                    <div className="clear"></div>
                  </div>
                </div>
                {topics?.filter((topic) => topic?.visibility)?.length ? (
                  topics
                    ?.filter((topic) => topic?.visibility)
                    ?.map((topic, index) => {
                      const subjectName = getSubjectNameById(
                        topic.subject_id
                      ).toLowerCase()
                      const filteredKeys = subjectPicKeys.filter((key) =>
                        subjectName.includes(key)
                      )
                      const pic = subjectPics[filteredKeys[0]]
                      return (
                        <div key={index} className="topics__wrapper">
                          <TopicsCard
                            img={pic}
                            heading={topic.title}
                            description={topic.wysiwyg_state}
                            button="view"
                            buttonClass="button--blue"
                            // extraContent="2 new doubts"
                            onClick={() =>
                              onTopicSelect(topic._id, topic.title)
                            }
                            editOnClick={() => {
                              openEditTopicPopup(topic)
                            }}
                            deleteOnClick={() => {
                              openDeleteTopicPopup(topic)
                            }}
                          />
                          <div className="clear"></div>
                        </div>
                      )
                    })
                ) : (
                  <div>No Topics</div>
                )}
                <div className="clear"></div>
              </section>
            ) : (
              <div> Please Select a Subject </div>
            )}
            <PopupConfirm
              className={deleteTopicPopClassName}
              close={() => closeDeleteTopicPopup()}
              onClick={() => onTopicDelete()}
              buttonText="yes"
              popupHead="Delete Topic"
            >
              <h2>Are you sure want to delete?</h2>
            </PopupConfirm>
          </Col100>
        </LeftRightLayout>
      </section>
      {showSuccess && pressStatus === 'create' ? (
        <AlertSuccess>You have successfully added a Topic</AlertSuccess>
      ) : null}
      {showEditSuccess && pressStatus === 'edit' ? (
        <AlertSuccess>You have successfully edited a Topic</AlertSuccess>
      ) : null}
      {showDeleteSuccess && pressStatus === 'delete' ? (
        <AlertSuccess>You have successfully deleted a Topic</AlertSuccess>
      ) : null}
      <div className="clear"></div>
    </div>
  )
}

export default Topic
