import {
  GET_DAILY_REPORT_START,
  GET_DAILY_REPORT_SUCCESS,
  GET_DAILY_REPORT_FAILURE,
  GET_DAILY_ATTENDANCE_BY_BATCH_START,
  GET_DAILY_ATTENDANCE_BY_BATCH_SUCCESS,
  GET_DAILY_ATTENDANCE_BY_BATCH_FAILURE,
  GET_DAILY_ATTENDANCE_BY_SUBJECT_START,
  GET_DAILY_ATTENDANCE_BY_SUBJECT_SUCCESS,
  GET_DAILY_ATTENDANCE_BY_SUBJECT_FAILURE,
  GET_WEEKLY_ATTENDANCE_START,
  GET_WEEKLY_ATTENDANCE_SUCCESS,
  GET_WEEKLY_ATTENDANCE_FAILURE,
  GET_REPORT_BY_TOPICID_START,
  GET_REPORT_BY_TOPICID_SUCCESS,
  GET_REPORT_BY_TOPICID_FAILURE,
  GET_LIVE_CLASS_ATTENDANCE_START,
  GET_LIVE_CLASS_ATTENDANCE_SUCCESS,
  GET_LIVE_CLASS_ATTENDANCE_FAILURE,
} from '../actions'

import { checkStatus } from '../helpers.js'

const studentTopicByIdStart = () => {
  return {
    type: GET_REPORT_BY_TOPICID_START,
  }
}
const studentTopicByIdSuccess = (data) => {
  const { result } = data
  return {
    type: GET_REPORT_BY_TOPICID_SUCCESS,
    result,
  }
}

const studentTopicByIdFailure = () => {
  return {
    type: GET_REPORT_BY_TOPICID_FAILURE,
  }
}

const studentByTopicIdReport = ({ jwt, topicid }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/cwx/get-json-report/topic/${topicid}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchStudentByTopicIdReport = (data) => {
  return async (dispatch) => {
    dispatch(studentTopicByIdStart())
    try {
      const receivedData = await studentByTopicIdReport(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(studentTopicByIdSuccess(response.data))
    } catch (err) {
      dispatch(studentTopicByIdFailure())
    }
  }
}

const studentDailyReportStart = () => {
  return {
    type: GET_DAILY_REPORT_START,
  }
}
const studentDailyReportSuccess = (data) => {
  const { result } = data

  return {
    type: GET_DAILY_REPORT_SUCCESS,
    result,
  }
}

const studentDailyReportFailure = () => {
  return {
    type: GET_DAILY_REPORT_FAILURE,
  }
}

const studentDailyReport = ({ jwt, dateFormated, institutionId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/cwx/get-daily-json-report/institution/${institutionId}/date/${dateFormated}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchDailyReport = (data) => {
  return async (dispatch) => {
    dispatch(studentDailyReportStart())
    try {
      const receivedData = await studentDailyReport(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(studentDailyReportSuccess(response.data))
    } catch (err) {
      dispatch(studentDailyReportFailure())
    }
  }
}

const attendanceBySubjectStart = () => {
  return {
    type: GET_DAILY_ATTENDANCE_BY_SUBJECT_START,
  }
}
const attendanceBySubjectSuccess = (data) => {
  const { result } = data

  return {
    type: GET_DAILY_ATTENDANCE_BY_SUBJECT_SUCCESS,
    result,
  }
}

const attendanceBySubjectFailure = () => {
  return {
    type: GET_DAILY_ATTENDANCE_BY_SUBJECT_FAILURE,
  }
}

const attendanceBySubject = ({ jwt, dateFormated, institutionId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/cwx/get-daily-json-attendance-by-subject/institution/${institutionId}/date/${dateFormated}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchAttendanceBySubject = (data) => {
  return async (dispatch) => {
    dispatch(attendanceBySubjectStart())
    try {
      const receivedData = await attendanceBySubject(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(attendanceBySubjectSuccess(response.data))
    } catch (err) {
      dispatch(attendanceBySubjectFailure())
    }
  }
}

const attendanceByBatchStart = () => {
  return {
    type: GET_DAILY_ATTENDANCE_BY_BATCH_START,
  }
}
const attendanceByBatchSuccess = (data) => {
  const { result } = data

  return {
    type: GET_DAILY_ATTENDANCE_BY_BATCH_SUCCESS,
    result,
  }
}

const attendanceByBatchFailure = () => {
  return {
    type: GET_DAILY_ATTENDANCE_BY_BATCH_FAILURE,
  }
}

const attendanceByBatch = ({ jwt, dateFormated, institutionId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/cwx/get-daily-json-attendance-by-batch/institution/${institutionId}/date/${dateFormated}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchAttendanceByBatch = (data) => {
  return async (dispatch) => {
    dispatch(attendanceByBatchStart())
    try {
      const receivedData = await attendanceByBatch(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(attendanceByBatchSuccess(response.data))
    } catch (err) {
      dispatch(attendanceByBatchFailure())
    }
  }
}

const attendanceByWeekStart = () => {
  return {
    type: GET_WEEKLY_ATTENDANCE_START,
  }
}
const attendanceByWeekSuccess = (data) => {
  const { result } = data

  return {
    type: GET_WEEKLY_ATTENDANCE_SUCCESS,
    result,
  }
}
const attendanceByWeekFailure = () => {
  return {
    type: GET_WEEKLY_ATTENDANCE_FAILURE,
  }
}

const attendanceByWeek = ({ jwt, dateFormated, institutionId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/cwx/get-daily-json-attendance-by-batch/institution/${institutionId}/date/${dateFormated}/weekly`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchAttendanceWeekly = (data) => {
  return async (dispatch) => {
    dispatch(attendanceByWeekStart())
    try {
      const receivedData = await attendanceByWeek(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(attendanceByWeekSuccess(response.data))
    } catch (err) {
      dispatch(attendanceByWeekFailure())
    }
  }
}

const attendanceLiveClassStart = () => {
  return {
    type: GET_LIVE_CLASS_ATTENDANCE_START,
  }
}
const attendanceLiveClassSuccess = (data) => {
  const { result } = data

  return {
    type: GET_LIVE_CLASS_ATTENDANCE_SUCCESS,
    result,
  }
}
const attendanceLiveClassFailure = () => {
  return {
    type: GET_LIVE_CLASS_ATTENDANCE_FAILURE,
  }
}

const attendanceLiveClass = ({ jwt, dateFormated }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/cwx/get-daily-json-report-online/date/${dateFormated}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchAttendanceLiveClass = (data) => {
  return async (dispatch) => {
    dispatch(attendanceLiveClassStart())
    try {
      const receivedData = await attendanceLiveClass(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(attendanceLiveClassSuccess(response.data))
    } catch (err) {
      dispatch(attendanceLiveClassFailure())
    }
  }
}
