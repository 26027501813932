import {
  GET_SUBJECTS_START,
  GET_SUBJECTS_SUCCESS,
  GET_SUBJECTS_FAILURE,
  GET_SUBJECTS_DATA_START,
  GET_SUBJECTS_DATA_SUCCESS,
  GET_SUBJECTS_DATA_FAILURE,
  CLEAR_SUBJECT_SELECTED,
  GET_STUDENT_SUBJECTS_START,
  GET_STUDENT_SUBJECTS_SUCCESS,
  GET_STUDENT_SUBJECTS_FAILURE,
  GET_BATCH_SUBJETS_OF_STUDENT_START,
  GET_BATCH_SUBJETS_OF_STUDENT_SUCCESS,
  GET_BATCH_SUBJETS_OF_STUDENT_FAILURE,
  POST_SUBJECT_START,
  POST_SUBJECT_SUCCESS,
  POST_SUBJECT_FAILURE,
  POST_ASSIGN_FACULTY_BATCH_SUBJECT_START,
  POST_ASSIGN_FACULTY_BATCH_SUBJECT_SUCCESS,
  POST_ASSIGN_FACULTY_BATCH_SUBJECT_FAILURE,
  GET_BATCH_SUBJECT_DATA_BY_ID_START,
  GET_BATCH_SUBJECT_DATA_BY_ID_SUCCESS,
  GET_BATCH_SUBJECT_DATA_BY_ID_FAILURE,
} from '../actions'

import { checkStatus } from '../helpers.js'
import { getById } from '../../helper/utils'

const getSubjectsStart = () => {
  return {
    type: GET_SUBJECTS_START,
  }
}

const getSubjectsSuccess = ({ subjects }) => {
  return {
    type: GET_SUBJECTS_SUCCESS,
    subjects,
  }
}

const getSubjectsFailure = () => {
  return {
    type: GET_SUBJECTS_FAILURE,
  }
}

export const clearSubjectSelected = () => {
  return {
    type: CLEAR_SUBJECT_SELECTED,
  }
}

const apiCallTogetSubjects = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batch-subjects`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}

export const fetchSubjects = (data) => {
  return async (dispatch) => {
    dispatch(getSubjectsStart())
    try {
      const receivedData = await apiCallTogetSubjects(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      let subjects = response.data.batch_subjects
      dispatch(getSubjectsSuccess({ subjects }))
    } catch (err) {
      dispatch(getSubjectsFailure())
    }
  }
}

const getSubjectsDataStart = () => {
  return {
    type: GET_SUBJECTS_DATA_START,
  }
}

const getSubjectsDataSuccess = ({ subjects }) => {
  const subjectsById = getById({ data: subjects })
  return {
    type: GET_SUBJECTS_DATA_SUCCESS,
    subjects,
    subjectsById,
  }
}

const getSubjectsDataFailure = () => {
  return {
    type: GET_SUBJECTS_DATA_FAILURE,
  }
}

const apiCallTogetSubjectsData = ({ jwt, subject_ids }) => {
  let urlData = ''
  if (subject_ids && subject_ids.length > 0) {
    subject_ids.forEach((id, index) => {
      urlData = urlData.concat(index ? '&ids=' + id : 'ids=' + id)
    })
  }
  const url = `${process.env.REACT_APP_SERVER}/api/subjects?${urlData}`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}

export const fetchSubjectsData = (data) => {
  return async (dispatch) => {
    dispatch(getSubjectsDataStart())
    try {
      const receivedData = await apiCallTogetSubjectsData(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      let subjects = response.data.subjects
      dispatch(getSubjectsDataSuccess({ subjects }))
    } catch (err) {
      dispatch(getSubjectsDataFailure())
    }
  }
}

const userSubjectsStart = () => {
  return {
    type: GET_STUDENT_SUBJECTS_START,
  }
}

const userSubjectsSuccess = (data) => {
  const subjects = data?.batch_subjects
  return {
    type: GET_STUDENT_SUBJECTS_SUCCESS,
    subjects,
  }
}

const userSubjectsFailure = () => {
  return {
    type: GET_STUDENT_SUBJECTS_FAILURE,
  }
}

const userSubjects = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/me/batch-subjects`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchUserSubjects = (data) => {
  return async (dispatch) => {
    dispatch(userSubjectsStart())
    try {
      const receivedData = await userSubjects(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(userSubjectsSuccess(response?.data))
    } catch (err) {
      dispatch(userSubjectsFailure())
    }
  }
}

const getBatchSubjectsStart = () => {
  return {
    type: GET_BATCH_SUBJETS_OF_STUDENT_START,
  }
}

const getBatchSubjectsSuccess = (data) => {
  const { batch_subjects } = data
  return {
    type: GET_BATCH_SUBJETS_OF_STUDENT_SUCCESS,
    batchSubjects: batch_subjects,
  }
}

const getBatchSubjectsFailure = () => {
  return {
    type: GET_BATCH_SUBJETS_OF_STUDENT_FAILURE,
  }
}

const getBatchSubjects = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/me/batch-subjects`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchBatchSubjects = (data) => {
  return async (dispatch) => {
    dispatch(getBatchSubjectsStart())
    try {
      const receivedData = await getBatchSubjects(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getBatchSubjectsSuccess(response?.data))
    } catch (err) {
      dispatch(getBatchSubjectsFailure())
    }
  }
}

const postSubjectStart = () => {
  return {
    type: POST_SUBJECT_START,
  }
}

const postSubjectSuccess = () => {
  return {
    type: POST_SUBJECT_SUCCESS,
  }
}

const postSubjectFailure = () => {
  return {
    type: POST_SUBJECT_FAILURE,
  }
}

const postSubjectApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/subjects`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

const getBatchSubjectDataByIdStart = () => {
  return {
    type: GET_BATCH_SUBJECT_DATA_BY_ID_START,
  }
}

const getBatchSubjectDataByIdSuccess = (data) => {
  const { batch_subject } = data
  return {
    type: GET_BATCH_SUBJECT_DATA_BY_ID_SUCCESS,
    batch_subject,
  }
}

const getBatchSubjectDataByIdFailure = () => {
  return {
    type: GET_BATCH_SUBJECT_DATA_BY_ID_FAILURE,
  }
}
const getBatchSubjectDataById = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batch-subjects/${id}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchBatchSubjectDataById = (data) => {
  return async (dispatch) => {
    dispatch(getBatchSubjectDataByIdStart())
    try {
      const receivedData = await getBatchSubjectDataById(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getBatchSubjectDataByIdSuccess(response?.data))
    } catch (err) {
      dispatch(getBatchSubjectDataByIdFailure())
    }
  }
}

const postBatchSubjectApiCall = ({ jwt, data }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batch-subjects`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  return fetch(url, options)
}

export const postSubject = ({ jwt, payload, batchId }) => {
  return async (dispatch) => {
    dispatch(postSubjectStart())
    try {
      const receivedData = await postSubjectApiCall({ jwt, payload })
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      const data = {
        batch_id: batchId,
        subject_ids: [response?.data?.subject?._id],
      }
      const receivedData1 = await postBatchSubjectApiCall({ jwt, data })
      const validatedData1 = checkStatus(receivedData1)
      await validatedData1.json()
      dispatch(fetchSubjects({ jwt }))
      dispatch(postSubjectSuccess())
    } catch (err) {
      dispatch(postSubjectFailure())
    }
  }
}

const postFacultyToBatchSubjectStart = () => {
  return {
    type: POST_ASSIGN_FACULTY_BATCH_SUBJECT_START,
  }
}

const postFacultyToBatchSubjectSuccess = () => {
  return {
    type: POST_ASSIGN_FACULTY_BATCH_SUBJECT_SUCCESS,
  }
}

const postFacultyToBatchSubjectFailure = () => {
  return {
    type: POST_ASSIGN_FACULTY_BATCH_SUBJECT_FAILURE,
  }
}

const postFacultyToBatchSubjectApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batch-subjects/faculties`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postFacultyToBatchSubject = (data) => {
  return async (dispatch) => {
    dispatch(postFacultyToBatchSubjectStart())
    try {
      const receivedData = await postFacultyToBatchSubjectApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(postFacultyToBatchSubjectSuccess())
    } catch (err) {
      dispatch(postFacultyToBatchSubjectFailure())
    }
  }
}

//remove teacher from batch subject
const removeFacultyToBatchSubjectApiCall = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/batch-subjects/faculties`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const removeFacultyToBatchSubject = (data) => {
  return async (dispatch) => {
    dispatch(postFacultyToBatchSubjectStart())
    try {
      const receivedData = await removeFacultyToBatchSubjectApiCall(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      dispatch(postFacultyToBatchSubjectSuccess())
    } catch (err) {
      dispatch(postFacultyToBatchSubjectFailure())
    }
  }
}
