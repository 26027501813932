import {
  SET_BATCH,
  APP_LOGOUT,
  SET_SUBJECT,
  SET_CHAPTER,
  SET_TOPIC,
  SET_ASSESSMENT,
  CLEAR_SUBJECT_SELECTED,
  SET_BATCH_SUBJECT_ID,
  CLEAR_TOPICS,
  SET_DOUBT_ID,
} from '../actions'

let initialState = {
  batchId: '',
  subjectId: '',
  chapterId: '',
  topicId: '',
  doubtId: '',
  batchTitle: '',
  subjectTitle: '',
  chapterTitle: '',
  topicTitle: '',
  batchSubjectId: '',
  assessmentId: '',
  assessmentTitle: '',
  assessment: {},
}

export const ui = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case SET_BATCH:
      return {
        ...state,
        batchId: action.batchId,
        batchTitle: action.batchTitle,
      }
    case SET_SUBJECT:
      return {
        ...state,
        subjectId: action.subjectId,
        subjectTitle: action.subjectTitle,
      }
    case SET_CHAPTER:
      return {
        ...state,
        chapterId: action.chapterId,
        chapterTitle: action.chapterTitle,
      }
    case CLEAR_SUBJECT_SELECTED:
      return {
        ...state,
        subjectId: '',
        subjectTitle: '',
        subject: '',
      }
    case SET_TOPIC:
      return {
        ...state,
        topicId: action.topicId,
        topicTitle: action.topicTitle,
      }
    case SET_ASSESSMENT:
      return {
        ...state,
        assessmentId: action.assessmentId,
        assessmentTitle: action.assessmentTitle,
        assessment: action.assessment,
      }
    case SET_BATCH_SUBJECT_ID:
      return {
        ...state,
        batchSubjectId: action.batchSubjectId,
      }
    case CLEAR_TOPICS:
      return {
        ...state,
        topicId: '',
      }
    case SET_DOUBT_ID:
      return {
        ...state,
        doubtId: action.doubtId,
      }
    default:
      return state
  }
}

export const getBatch = (state) => state.ui.batchId
export const getSubjectId = (state) => state.ui.subjectId
export const getChapterId = (state) => state.ui.chapterId
export const getTopicId = (state) => state.ui.topicId
export const getDoubtId = (state) => state.ui.doubtId
export const getBatchTitle = (state) => state.ui.batchTitle
export const getSubjectTitle = (state) => state.ui.subjectTitle
export const getChapterTitle = (state) => state.ui.chapterTitle
export const getTopicTitle = (state) => state.ui.topicTitle
export const getAssessmentId = (state) => state.ui.assessmentId
export const getAssessmentTitle = (state) => state.ui.assessmentTitle
export const getBatchSubjectId = (state) => state.ui.batchSubjectId
export const getAssessment = (state) => state.ui.assessment
