import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

//assets
import Card from '../../assets/molecules/card'
import Button from '../../assets/molecules/button'
import Loader from '../../assets/molecules/loader'

import { usePrevious } from '../../helpers'

//actions
//subject
import { fetchAddTopic } from '../../../redux/action/topic'
import { getFaculties as fetchFaculties } from '../../../redux/action/faculty'

//reducer
//account
import { getJwt } from '../../../redux/reducer/account'
import { groupByBatchSubject } from '../../../redux/reducer/subject'
import { getIsTopicsLoading, getIsError } from '../../../redux/reducer/topic'
import { getChapters } from '../../../redux/reducer/chapter'
import { getBatch, getSubjectId } from '../../../redux/reducer/ui'
import { getBatches } from '../../../redux/reducer/batches'
import { getAllFaculties } from '../../../redux/reducer/faculty'

const AddTopics = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  //get param from url
  const { chapterId } = useParams()

  //selector
  const jwt = useSelector(getJwt)
  const error = useSelector(getIsError)
  const isLoading = useSelector(getIsTopicsLoading)
  let batchId = useSelector(getBatch)
  const subjectId = useSelector(getSubjectId)
  const chapters = useSelector(getChapters)
  const subjects = useSelector(groupByBatchSubject)
  const batches = useSelector(getBatches)
  const faculties = useSelector(getAllFaculties)

  const isLoadingPrev = usePrevious(isLoading)

  let [formData, setFormData] = useState({})
  let [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    dispatch(fetchFaculties({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isLoading &&
      !isLoading &&
      !error
    ) {
      history.push({
        pathname: '/batch/subjects/chapters/topics',
        state: { status: 'success' },
      })
    }
    error && setErrorMessage('Something went wrong. Please try again.')
  }, [error, history, isLoading, isLoadingPrev])
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (
      formData?.title &&
      formData?.type &&
      formData?.contentUrl &&
      formData?.description &&
      formData?.faculty
    ) {
      const [authorId, authorName] = formData?.faculty?.split('-')
      const data = {
        title: formData.title,
        type: formData.type,
        content_uri: formData.contentUrl,
        wysiwyg_state: formData.description,
        chapter_id: chapterId,
        author_id: authorId,
        author_name: authorName,
      }
      dispatch(fetchAddTopic({ jwt, data }))
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  const filteredListSubjects = subjects[batchId]?.filter(
    (subject) => subject.subject_id === subjectId
  )
  const currentSubject =
    filteredListSubjects?.length > 0 ? filteredListSubjects[0] : {}

  const filteredListBatches = batches.filter((bat) => bat._id === batchId)
  const currentBatch =
    filteredListBatches.length > 0 ? filteredListBatches[0] : {}

  const filteredListChapters = chapters.filter((chap) => chap._id === chapterId)

  const currentChapter =
    filteredListChapters.length > 0 ? filteredListChapters[0] : {}

  const contentTypes = ['image', 'audio', 'video']

  return (
    <div className="tabs__container">
      <section>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/dashboard">{currentBatch.title}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/batch/subjects">{currentSubject.subject_title}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/batch/subjects/chapters">{currentChapter.title}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/batch/subjects/chapters/topics">topics</Link>
            </li>
            <li className="breadcrumb-item active">Add Topic</li>
          </ol>
        </nav>
      </section>

      <section>
        <h2>Add Topic</h2>
        <Card columnNo="1" title="Add Topic" titleColor={1}>
          <input
            name="title"
            value={formData.title}
            type="text"
            placeholder="Title"
            onChange={onChange}
          />
          <select name="faculty" onChange={(e) => onChange(e)}>
            <option>Please select faculty</option>
            {faculties?.map((faculty, index) => {
              return (
                <option value={`${faculty._id}-${faculty.name}`} key={index}>
                  {`${faculty.name} - (${faculty.prn})`}
                </option>
              )
            })}
          </select>
          <select name="type" onChange={(e) => onChange(e)}>
            <option value="">Please select content type</option>
            {contentTypes?.map((contentType, index) => {
              return (
                <option value={contentType} key={index}>
                  {contentType}
                </option>
              )
            })}
          </select>
          {formData.type && (
            <input
              name="contentUrl"
              value={formData.contentUrl}
              type="text"
              placeholder={`Please upload ${formData.type}`}
              onChange={onChange}
            />
          )}

          <textarea
            rows="20"
            name="description"
            value={formData.description}
            placeholder="Description"
            onChange={onChange}
          ></textarea>
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              className="button--small"
              color="blue"
              text="Add"
              click={(e) => onSubmit(e)}
            />
          )}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </Card>
      </section>
    </div>
  )
}

export default AddTopics
