import React from 'react'

const TopicsCard = (props) => {
  const createMarkup = (data) => {
    return { __html: data }
  }
  return (
    <div onClick={props.onClick} className="topicsCard row">
      <div className="col-xl-3  col-lg-3  col-md-12 col-sm-12 topicsCard__img">
        <img src={props.img} alt="CWX" />
        <div className="clear"></div>
      </div>
      <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 topicsCard__content">
        <div>
          <h3>{props.heading}</h3>
          <p className="topicsCard__para">
            {' '}
            <div dangerouslySetInnerHTML={createMarkup(props.description)} />
          </p>
          <p className="topicsCard__para">{props.description1}</p>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="col-xl-2 col-lg-3 col-md-12 col-sm-12 display__flex topicsCard__wrap3">
        <div>
          <button onClick={props.onClick} className={props.buttonClass}>
            {props.button}
          </button>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  )
}

export default TopicsCard
