import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { version } from '../../../package.json'

import Loader from '../assets/molecules/loader'

import LoginBanner from '../../assets/images/login-banner.png'
import Logo from '../../assets/images/cwx-logo.png'
import UserId from '../../assets/images/user-id.png'
import Password from '../../assets/images/password.png'
// import Popup from '../assets/layout/popup'
import PopupConfirm from '../assets/layout/popupConfirm'
// import Button from "../assets/molecules/button";

// import { Helmet } from 'react-helmet'

//action
import {
  emitLogin,
  postForgotPassword,
  postVerifyOTP,
  postResetPassword,
} from '../../redux/action/account.js'

//selector
import {
  isLoggedIn as isLoggedInFun,
  isLoading,
  getIsError,
  getMessage,
  getIsForgotPassword,
  getIsOTPVerifed,
  getIsPasswordChanged,
  getTempToken,
} from '../../redux/reducer/account.js'

const Login = () => {
  // Declare a new state variable, which we'll call "count"
  const [loginFeilds, setloginFeilds] = useState({
    userName: '',
    password: '',
  })
  const [data, setData] = useState('')
  const [stage, setStage] = useState('')

  // const [forgotPasswordUsername, setForgotPasswordUsername] = useState('')
  // const [fpErrorMessage, setFpErrorMessage] = useState('')
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state) => isLoggedInFun(state), shallowEqual)
  const isloading = useSelector((state) => isLoading(state))
  const isError = useSelector((state) => getIsError(state))
  const message = useSelector((state) => getMessage(state))
  //forgot passwoed data
  const [errorMessage, setErrorMessage] = useState('')
  const isForgotPassword = useSelector(getIsForgotPassword)
  const isOTPVerifed = useSelector(getIsOTPVerifed)
  const isPasswordChanged = useSelector(getIsPasswordChanged)
  const token = useSelector(getTempToken)

  useEffect(() => {
    setStage({ forgotPassword: true })
    setErrorMessage(false)
  }, [dispatch])

  useEffect(() => {
    isForgotPassword && setStage({ verifyOTP: true })
    isOTPVerifed && setStage({ createPassword: true })
    if (isPasswordChanged) {
      closePopup()
      setErrorMessage('')
      // dispatch({ type: "APP_LOGOUT" });
      // history.push("/login");
    }
  }, [isForgotPassword, isOTPVerifed, isPasswordChanged, dispatch])

  const onChange = (e) => {
    e.preventDefault()
    loginFeilds[e.target.name] = e.target.value
    setloginFeilds(loginFeilds)
  }

  const onClick = (e) => {
    e.preventDefault()
    const { userName, password } = loginFeilds

    if (userName !== '' && password !== '') {
      dispatch(
        emitLogin({
          username: userName,
          password,
        })
      )
    }
  }

  const [popClassName, setPopClassName] = useState('hidePop')
  const openPopup = () => {
    setPopClassName('showPop')
  }
  const closePopup = () => {
    setData('')
    setStage({ forgotPassword: true, verifyOTP: false, createPassword: false })
    setPopClassName('hidePop')
  }

  const [popContactUsClassName, setPopContactUsClassName] = useState('hidePop')
  const openContactUsPopup = () => {
    setPopContactUsClassName('showPop')
  }
  const closeContactUsPopup = () => {
    setData('')
    setStage({ forgotPassword: true })
    setPopContactUsClassName('hidePop')
  }

  const onChangeFp = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
    errorMessage !== '' && setErrorMessage('')
  }

  const onSubmitUsername = (e) => {
    console.log('--- inside submit username ---', data)

    e.preventDefault()
    const { username } = data
    if (username !== '' && username !== undefined && username !== null) {
      dispatch(postForgotPassword({ username }))
    } else {
      setErrorMessage('Please enter username')
    }
  }

  const onSubmitOTP = (e) => {
    e.preventDefault()
    const { otp } = data
    if (otp !== '' && otp !== undefined && otp !== null) {
      dispatch(postVerifyOTP({ otp }))
    } else {
      setErrorMessage('Please enter OTP')
    }
  }

  const onSubmitPassword = (e) => {
    console.log(data)

    e.preventDefault()
    const { password, confirmPassword } = data
    if (password !== '' && confirmPassword !== '') {
      if (password === confirmPassword) {
        dispatch(postResetPassword({ password, token }))
      } else {
        setErrorMessage('Password mismatch')
      }
    } else {
      setErrorMessage('Please enter password')
    }
  }

  return isLoggedIn ? (
    <Redirect to={{ pathname: '/' }} />
  ) : (
    <div className="login">
      <div className="row">
        <div className="col-md-6 col-lg-4 login__leftBackground height--100">
          <div className="d-flex h-100 justify-content-center align-items-center">
            <div className="logo__container">
              <img src={Logo} alt="CWX logo" />
            </div>
            <div className="login__wrap">
              <form onSubmit={(e) => onClick(e)}>
                <h2>Login</h2>
                <div className="input__wrap input__wrap--userId">
                  <img src={UserId} alt="user id" />
                  <input
                    placeholder="User name"
                    type="text"
                    name="userName"
                    onChange={(e) => onChange(e)}
                  />
                  <div className="clear"></div>
                </div>
                <div className="input__wrap">
                  <img src={Password} alt="password" />
                  <input
                    placeholder="Password"
                    type="password"
                    name="password"
                    onChange={(e) => onChange(e)}
                  />
                  <div className="clear"></div>
                </div>
                <div className="login__popups">
                  <p
                    onClick={(e) => openContactUsPopup()}
                    className="login__forgotPassword"
                  >
                    Contact Us
                  </p>
                  <p
                    onClick={(e) => openPopup()}
                    className="login__forgotPassword"
                  >
                    Forgot Password ?
                  </p>
                </div>
                {isloading ? (
                  <Loader />
                ) : (
                  <button className="button--blue" onClick={(e) => onClick(e)}>
                    Submit
                  </button>
                )}
              </form>

              {isError && <div className="alert alert-danger">{message}</div>}
            </div>
            <div className="cwx__poweredby">
              <p>
                Powered by
                <br />
                <span>Nissi</span> v {version}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-8 login__background align-self-center height--100">
          <div className="d-flex h-100 justify-content-center align-items-center text-center">
            <div className="login__textContainer">
              <h1>CourseWareX - Your Personalized Learning Portal</h1>
              <p>
                CWX - An exclusive online platform that connects you to your
                institution. Providing personalised content curated by the
                institution for your enhanced learning.
              </p>
              <img src={LoginBanner} alt="Login" />
            </div>
          </div>
        </div>
      </div>
      <PopupConfirm
        className={popClassName}
        close={() => closePopup()}
        onClick={(e) => {
          ;(stage?.forgotPassword && onSubmitUsername(e)) ||
            (stage?.verifyOTP && onSubmitOTP(e)) ||
            (stage?.createPassword && onSubmitPassword(e))
        }}
        buttonText={
          (stage?.forgotPassword && 'Generate OTP') ||
          (stage?.verifyOTP && 'Verify') ||
          (stage?.createPassword && 'Change')
        }
        popupHead="Forgot Password"
        isloading={isloading}
      >
        {stage?.forgotPassword && (
          <div>
            <input
              onChange={(e) => onChangeFp(e)}
              type="text"
              name="username"
              value={data?.username}
              placeholder="Enter username"
            />
          </div>
        )}

        {stage?.verifyOTP && (
          <div>
            <input
              onChange={(e) => onChangeFp(e)}
              type="number"
              name="otp"
              value={data?.otp}
              placeholder="Enter OTP"
            />
          </div>
        )}

        {stage?.createPassword && (
          <div>
            <input
              onChange={(e) => onChangeFp(e)}
              type="password"
              name="password"
              value={data?.password}
              placeholder="Enter New Password"
            />
            <input
              onChange={(e) => onChangeFp(e)}
              type="password"
              name="confirmPassword"
              value={data?.confirmPassword}
              placeholder="Confirm Password"
            />
          </div>
        )}
        {errorMessage === 'Password mismatch' ? (
          <div
            className={
              errorMessage === 'Password mismatch' ? 'alert alert-danger' : ''
            }
          >
            {errorMessage}
          </div>
        ) : null}
      </PopupConfirm>
      <PopupConfirm
        className={popContactUsClassName}
        close={() => closeContactUsPopup()}
        onClick={(e) => {
          closeContactUsPopup()
        }}
        buttonText=""
        cancelButtonText="CLOSE"
        popupHead="CONTACT US"
      >
        <div className="help__text help__text--bottom">
          <h5>Tel : +91 8088100900</h5>
          <h5>Email : info@nissiagency.com</h5>
        </div>
      </PopupConfirm>
    </div>
  )
}

export default Login
