import React from 'react'

const StudentsCardWithMarks = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={
        'studentsCardWithMarks studentsCardWithMarks--' + props.className
      }
    >
      <div className="studentsCard__img">
        <img src={props.picture} alt="CWX" />
      </div>
      <div className="studentsCard__content">
        <div>
          <h5>
            {props.candidateName}{' '}
            <span
              className={
                'studentsCard__score studentsCard__score--' + props.evaluated
              }
            >
              {/* <span className="studentsCard__checkMark">&#10003;</span> */}
              {props.score}
            </span>
          </h5>
          <p>{props.details}</p>
        </div>
      </div>
    </div>
  )
}

export default StudentsCardWithMarks
