import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { usePrevious } from '../../helpers'
import { Tab, Tabs } from 'react-bootstrap'

//assets
import Loader from '../../assets/molecules/loader'
import Select from '../../assets/molecules/select'
import NoPending from '../../../assets/images/no-pending.png'
import PopupForm from '../../assets/layout/popupForm'
import AssessmentCard from '../../assets/molecules/assessmentCardWithOption'
import { AlertSuccess } from '../../assets/molecules/alerts'

//actions
import {
  fetchAssessmentList,
  editAssessment,
} from '../../../redux/action/assessment'
import { postAssessment } from '../../../redux/action/assessment'
import { startFileUpload } from '../../../redux/action/upload'
import { setBatch } from '../../../redux/action/ui'
import { postAssessmentCsvUrl } from '../../../redux/action/assessment'

//reducers
import { getJwt } from '../../../redux/reducer/account'
import {
  getIsError,
  getAssessments,
  getIsAssessmentsLoading,
} from '../../../redux/reducer/assessment'
import {
  getIsError as getFileUploadError,
  getFile,
  getIsLoadingUploadFile,
} from '../../../redux/reducer/upload'
import {
  groupByBatchSubject,
  getSubjects,
} from '../../../redux/reducer/subject'
import { getBatch } from '../../../redux/reducer/ui'
import { getBatches } from '../../../redux/reducer/batches'

const Assessment = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  let inputRef = useRef()

  let jwt = useSelector((state) => getJwt(state))
  const error = useSelector(getIsError)
  const uploadedFile = useSelector(getFile)
  const isLoadingUploadFile = useSelector(getIsLoadingUploadFile)
  const isLoadingUploadFilePrev = usePrevious(isLoadingUploadFile)
  const fileUploadError = useSelector(getFileUploadError)
  const subjects = useSelector((state) => groupByBatchSubject(state))
  const subjectsAll = useSelector((state) => getSubjects(state))
  let assessments = useSelector(getAssessments)
  let isLoading = useSelector((state) => getIsAssessmentsLoading(state))
  let isLoadingPrev = usePrevious(isLoading)
  let batches = useSelector((state) => getBatches(state))
  let batch = useSelector((state) => getBatch(state))

  let [batchSelected, setBatchSelected] = useState('All')
  let [formData, setFormData] = useState({})
  const [startDate, setStartDate] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  let [uploadFiles, setUploadFiles] = useState('')
  let [csvUrl, setCsvUrl] = useState({})
  let [csvError, setCsvError] = useState('')
  let [errorMessage, setErrorMessage] = useState('')
  const [selectedAssessment, setSelectedAssessment] = useState('')
  const [popup, setPopup] = useState('hidePop')
  const [formPopClassName, setFormPopClassName] = useState('hidePop')
  const [editAssessmentPopClassName, setEditAssessmentPopClassName] = useState(
    'hidePop'
  )
  const [showSuccess, setShowSuccess] = useState(false)
  const [showCsvSuccess, setShowCsvSuccess] = useState(false)
  const [showEditSuccess, setShowEditSuccess] = useState(false)
  const [pressStatus, setPressStatus] = useState('')

  const showAddPopup = () => {
    setPopup('showPop')
    setShowSuccess(false)
    setShowEditSuccess(false)
    setPressStatus('')
  }
  const closeAddPopup = () => {
    setPopup('hidePop')
    setFormData({})
    setUploadFiles('')
    setStartDate()
    setDueDate()
    setErrorMessage('')
  }

  const showCsvPopup = (e) => {
    setFormPopClassName('showPop')
    setShowCsvSuccess(false)
    setPressStatus('')
  }
  const closeCsvPopup = () => {
    setCsvUrl({})
    setCsvError('')
    setFormPopClassName('hidePop')
  }

  useEffect(() => {
    dispatch(fetchAssessmentList({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (
      isLoadingUploadFilePrev !== undefined &&
      isLoadingUploadFilePrev !== isLoadingUploadFile &&
      !isLoadingUploadFile &&
      !fileUploadError
    ) {
      setUploadFiles(uploadedFile.publicUrl)
    }
  }, [
    fileUploadError,
    isLoadingUploadFile,
    isLoadingUploadFilePrev,
    uploadedFile.publicUrl,
  ])

  useEffect(() => {
    if (uploadFiles === '') {
      inputRef.current.value = ''
    }
  }, [uploadFiles])

  useEffect(() => {
    if (isLoadingPrev !== undefined && !isLoading && !error) {
      setShowSuccess(true)
      setShowEditSuccess(true)
      setShowCsvSuccess(true)
      closeAddPopup()
    } else if (isLoadingPrev !== undefined && !isLoading && error) {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }, [error, isLoading, isLoadingPrev])

  const onChange = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const openEditAssessmentPopup = (assess) => {
    setIsEdit(true)
    setFormData({
      title: assess.title,
      description: assess.description,
    })
    setUploadFiles(assess.url && assess.url !== 'noFiles' ? assess.url : '')
    setStartDate(moment(assess.start_date).format('YYYY-MM-DD HH:mm:ss'))
    setDueDate(moment(assess.due_date).format('YYYY-MM-DD HH:mm:ss'))
    setEditAssessmentPopClassName('showPop')
    setSelectedAssessment(assess)
    setShowEditSuccess(false)
    setPressStatus('')
  }

  const onAssessmentEdit = () => {
    let payload = {
      title: formData.title,
      description: formData.description,
      start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      due_date: moment(dueDate).utc().format('YYYY-MM-DD HH:mm:ss'),
      url: uploadFiles && uploadFiles !== 'noFiles' ? uploadFiles : 'noFiles',
    }
    dispatch(
      editAssessment({
        jwt,
        assessmentId: selectedAssessment._id,
        payload,
      })
    )
    closeEditAssessmentPopup()
    setIsEdit(false)
    setFormData({})
    setStartDate()
    setUploadFiles('')
    setPressStatus('edit')
  }

  const closeEditAssessmentPopup = () => {
    setIsEdit(false)
    setFormData({})
    setStartDate()
    setDueDate()
    setUploadFiles('')
    setEditAssessmentPopClassName('hidePop')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (
      formData.title &&
      formData.description &&
      formData.subject &&
      startDate &&
      dueDate
      // uploadFiles
    ) {
      const payload = {
        title: formData.title,
        description: formData.description,
        batch_subject_id: formData.subject,
        start_date: moment(startDate).utc().format('YYYY-MM-DD HH:mm:ss '),
        due_date: moment(dueDate).utc().format('YYYY-MM-DD HH:mm:ss'),
        url: uploadFiles ? uploadFiles : 'noFiles',
      }
      dispatch(postAssessment({ payload, jwt }))
      setFormData({})
      setStartDate('')
      setDueDate('')
      setUploadFiles('')
      setPressStatus('create')
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  const onCsvSubmit = (e) => {
    e.preventDefault()
    if (csvUrl.csv) {
      const payload = {
        csv_url: csvUrl.csv,
      }
      dispatch(postAssessmentCsvUrl({ payload, jwt }))
      setFormPopClassName('hidePop')
      setCsvUrl({})
      setPressStatus('add')
    } else {
      setCsvError('Please fill the url')
    }
  }

  const onChangeBatch = (e) => {
    e.preventDefault()
    setBatchSelected(e.target.value)
    dispatch(setBatch(e.target.value))
  }

  const onChangeStartDate = (date) => {
    setStartDate(date)
  }

  const onChangeDueDate = (date) => {
    setDueDate(date)
  }

  const onChangeFile = (e) => {
    setErrorMessage('')
    const fileData = e.target.files[0]
    const data = new FormData()
    data.append('file', fileData)
    dispatch(startFileUpload(data))
  }

  const onChangeCsvUrl = (e) => {
    csvError && setCsvError('')
    setCsvUrl({ ...csvUrl, [e.target.name]: e.target.value })
  }

  const batchAssessmentLength = (status) => {
    let subjectByBatch = subjects[batchSelected]
    if (subjectByBatch === undefined) {
      return 0
    }
    let batchAssessment = []
    assessments.filter((assess) => {
      subjectByBatch.map((sub) => {
        if (sub._id === assess.batch_subject_id) {
          batchAssessment.push(assess)
        }
      })
    })
    return batchAssessment.filter((assess) =>
      status === 'previous'
        ? moment().isAfter(moment(assess?.due_date))
        : moment().isSameOrBefore(moment(assess?.due_date))
    ).length
  }

  const getAssessmentByBatch = (status) => {
    let subjectByBatch = subjects[batchSelected]
    if (subjectByBatch === undefined) {
      return ''
    }
    let batchAssessment = []
    assessments.filter((assess) => {
      subjectByBatch.map((sub) => {
        if (sub._id === assess.batch_subject_id) {
          batchAssessment.push(assess)
        }
      })
    })
    if (batchAssessment === []) {
      return <h4>No assessment available for the batch</h4>
    }
    return batchAssessment
      .filter((assess) =>
        status === 'previous'
          ? moment().isAfter(moment(assess?.due_date))
          : moment().isSameOrBefore(moment(assess?.due_date))
      )
      .map((assess, index) => {
        return (
          <AssessmentCard
            key={index}
            className="subjectCard__moderator"
            title={assess.title}
            assessmentId={assess._id}
            details={getAssessmentDetails(assess.batch_subject_id)}
            startDate={assess.start_date}
            dueDate={assess.due_date}
            editOnClick={() => openEditAssessmentPopup(assess)}
            show={status !== 'previous'}
            button="+  Add Question"
            buttonClass="button--blue button--margin"
            onAddClick={(e) => showCsvPopup(e)}
            onViewClick={(e) => viewQuestions(e, assess)}
            viewButton="View Questions"
          />
        )
      })
  }
  const getAssessmentDetails = (id) => {
    const dets = subjectsAll.find((sub) => {
      if (id === sub._id) {
        return sub
      }
    })
    return dets
  }

  const filteredList = batches.filter((bat) => bat._id === batch)
  const currentBatch = filteredList.length > 0 ? filteredList[0] : {}

  const noAssessmentAvailable = () => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">No assessment available.</h2>
          <button onClick={() => showAddPopup()} className="button--blue">
            + Add Assessment
          </button>
        </div>
      </div>
    )
  }

  const viewQuestions = (e, assess) => {
    e.preventDefault()
    history.push({
      pathname: `/assessment/questions/${assess._id}`,
      state: { assess },
    })
  }

  return (
    <div className="tabs__container">
      <section>
        {isLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className="row">
              <div className="col-md-6 padding--0">
                <h1>Assessments</h1>
              </div>
              <div className="col-md-6 padding--0">
                <button
                  onClick={() => showAddPopup()}
                  className="button--blue float-right"
                >
                  + Add Assessment
                </button>
              </div>
              <div className="clear"></div>
            </div>

            <div className="row">
              <div className="col-md-6 padding--0">
                <nav>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">Assessments</li>
                  </ol>
                </nav>
              </div>
              <div className="col-md-6 padding--0">
                <div className="customTab__dropdowns">
                  <Select
                    onChange={(e) => onChangeBatch(e)}
                    defaultValue={currentBatch._id}
                  >
                    <option value="All">All</option>
                    {batches.map((batch, index) => {
                      return (
                        <option value={batch._id} key={index}>
                          {batch.title}
                        </option>
                      )
                    })}
                  </Select>
                </div>
              </div>
              <div className="clear"></div>
            </div>
            <Tabs
              className="square__tabs square__tabs--twoItems assessment__tab"
              defaultActiveKey="upcoming"
            >
              <Tab
                eventKey="upcoming"
                title={
                  batchSelected === 'All'
                    ? `Upcoming Assessment (${
                        assessments.filter((assess) =>
                          moment().isSameOrBefore(moment(assess?.due_date))
                        ).length
                      }) `
                    : `Upcoming Assessment (${batchAssessmentLength(
                        'upcoming'
                      )}) `
                }
              >
                <p className="selfAssesment__info">
                  A formal test that you take to show your knowledge or ability
                  in a particular subject, or to obtain a qualification.
                </p>
                {assessments.length > 0
                  ? batchSelected === 'All'
                    ? assessments
                        .filter((assess) =>
                          moment().isSameOrBefore(moment(assess?.due_date))
                        )
                        .map((assess, index) => {
                          return (
                            <AssessmentCard
                              key={index}
                              className="subjectCard__moderator"
                              title={assess.title}
                              assessmentId={assess._id}
                              details={getAssessmentDetails(
                                assess.batch_subject_id
                              )}
                              startDate={assess.start_date}
                              dueDate={assess.due_date}
                              editOnClick={() =>
                                openEditAssessmentPopup(assess)
                              }
                              show={true}
                              button="+  Add Question"
                              onAddClick={(e) => showCsvPopup(e)}
                              onViewClick={(e) => viewQuestions(e, assess)}
                              viewButton="View Questions"
                              buttonClass="button--blue button--margin"
                              questionStatus={
                                assess.url && assess.url !== 'noFiles'
                                  ? 'Yes'
                                  : 'No'
                              }
                            />
                          )
                        })
                    : getAssessmentByBatch('upcoming')
                  : noAssessmentAvailable()}
              </Tab>
              <Tab
                eventKey="previous"
                title={
                  batchSelected === 'All'
                    ? `Previous Assessment (${
                        assessments.filter((assess) =>
                          moment().isAfter(moment(assess?.due_date))
                        ).length
                      }) `
                    : `Previous Assessment (${batchAssessmentLength(
                        'previous'
                      )}) `
                }
              >
                <p className="selfAssesment__info">
                  A formal test that you take to show your knowledge or ability
                  in a particular subject, or to obtain a qualification.
                </p>
                {assessments.length > 0
                  ? batchSelected === 'All'
                    ? assessments
                        .filter((assess) =>
                          moment().isAfter(moment(assess?.due_date))
                        )
                        .map((assess, index) => {
                          return (
                            <AssessmentCard
                              key={index}
                              className="subjectCard__moderator"
                              title={assess.title}
                              assessmentId={assess._id}
                              details={getAssessmentDetails(
                                assess.batch_subject_id
                              )}
                              startDate={assess.start_date}
                              dueDate={assess.due_date}
                              editOnClick={() =>
                                openEditAssessmentPopup(assess)
                              }
                              onViewClick={(e) => viewQuestions(e, assess)}
                              viewButton="View Questions"
                              buttonClass="button--blue button--margin"
                              questionStatus={
                                assess.url && assess.url !== 'noFiles'
                                  ? 'Yes'
                                  : 'No'
                              }
                            />
                          )
                        })
                    : getAssessmentByBatch('previous')
                  : noAssessmentAvailable()}
              </Tab>
            </Tabs>
          </React.Fragment>
        )}
      </section>

      <PopupForm
        className={!isEdit ? popup : editAssessmentPopClassName}
        close={() => {
          closeAddPopup()
          closeEditAssessmentPopup()
        }}
        onClickButton={!isEdit ? onSubmit : onAssessmentEdit}
        buttonText={isEdit ? 'EDIT ASSESSMENT' : 'CREATE ASSESSMENT'}
        popupHead={isEdit ? 'Edit Assessment' : 'Create Assessment'}
      >
        <form>
          <ol>
            <li>
              <h2>Title of Assessment</h2>
              <p>
                This information will help the students understand the
                assessment name.
              </p>
              <div className="form-group sessionTitle">
                <input
                  name="title"
                  value={formData.title || ''}
                  type="text"
                  placeholder="Title"
                  onChange={onChange}
                  className="form-control"
                />
              </div>
            </li>
            <li>
              <h2>Describe about assessment here</h2>
              <p>It will help to get an idea about assessment</p>
              <div className="form-group sessionDescription">
                <textarea
                  name="description"
                  value={formData.description || ''}
                  placeholder="Description"
                  onChange={onChange}
                  className="form-control"
                ></textarea>
              </div>
            </li>
            {!isEdit ? (
              <React.Fragment>
                <li>
                  <h2>For which batch the assessment is available?</h2>
                  <p>The assessment will be shown to a specific batch</p>
                  <div className="form-group sessionTopic">
                    <select
                      id="inputBatch"
                      className="form-control"
                      name="batch"
                      onChange={(e) => {
                        onChange(e)
                      }}
                      value={formData?.batch || ''}
                    >
                      <option value="">Select a Batch</option>
                      {batches.map((batch, index) => {
                        return (
                          <option key={index} value={batch._id}>
                            {batch.title}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </li>
                <li>
                  <h2>
                    For which subject of the batch the assessment is available?
                  </h2>
                  <p>The assessment will be shown to a specific subject</p>
                  <div className="form-group sessionTopic">
                    <select
                      id="inputSubject"
                      className="form-control"
                      name="subject"
                      onChange={(e) => {
                        onChange(e)
                      }}
                    >
                      <option value="">Select a Subject</option>
                      {subjects[formData.batch] ? (
                        subjects[formData.batch].map((sub, index) => {
                          return (
                            <option value={sub._id} key={index}>
                              {sub.subject_title}
                            </option>
                          )
                        })
                      ) : (
                        <option>No subjects available</option>
                      )}
                    </select>
                  </div>
                </li>
              </React.Fragment>
            ) : null}
            <li>
              <h2>Date on which assessment starts</h2>
              <p>It will help to get an idea when assessment starts</p>
              <div className="form-group sessionDate">
                <DatePicker
                  name="startDate"
                  onChange={(date) => {
                    onChangeStartDate(date)
                  }}
                  placeholderText="Select Start Date & Time"
                  selected={!isEdit ? startDate : new Date(startDate)}
                  dateFormat="dd/MM/yyyy HH:mm"
                  minDate={new Date()}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                />
              </div>
            </li>
            <div className="clear"></div>
            <li>
              <h2>Date on which assessment ends</h2>
              <p>It will help to get an idea when assessment ends</p>
              <div className="form-group sessionDate">
                <DatePicker
                  name="dueDate"
                  onChange={(date) => {
                    onChangeDueDate(date)
                  }}
                  selected={!isEdit ? dueDate : new Date(dueDate)}
                  placeholderText="Select Due Date & Time"
                  dateFormat="dd/MM/yyyy HH:mm"
                  disabled={!startDate}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="Time"
                  minDate={new Date()}
                />
                <div className="clear"></div>
              </div>
            </li>
            <li>
              <h2>Upload the file</h2>
              <p>It will contain the questions for assessment</p>
              {isLoadingUploadFile ? (
                <div>
                  <Loader />
                </div>
              ) : null}
              <div className="form-group sessionDescription">
                <input
                  type="file"
                  name="uploadFiles"
                  ref={inputRef}
                  onChange={onChangeFile}
                />
                {uploadFiles ? <p>File uploaded : {uploadFiles}</p> : null}
                <div className="clear"></div>
              </div>
              <div className="clear"></div>
            </li>
          </ol>
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
          <div className="clear"></div>
        </form>
      </PopupForm>
      <PopupForm
        className={formPopClassName}
        close={() => {
          closeCsvPopup()
        }}
        onClickButton={(e) => onCsvSubmit(e)}
        buttonText="Submit"
        popupHead="Add assessment question"
      >
        <form>
          <h2>Enter the Csv Url</h2>
          <p>It will contain the questions for assessment</p>
          <div className="form-group sessionDescription">
            <input
              name="csv"
              value={csvUrl.csv || ''}
              type="text"
              placeholder="Enter csv url"
              onChange={(e) => onChangeCsvUrl(e)}
              className="form-control"
            />
          </div>
          {csvError && <div className="alert alert-danger">{csvError}</div>}
          <div className="clear"></div>
        </form>
      </PopupForm>
      {showCsvSuccess && pressStatus === 'add' ? (
        <AlertSuccess>
          You have successfully added Assessment Questions
        </AlertSuccess>
      ) : null}
      {showSuccess && pressStatus === 'create' ? (
        <AlertSuccess>You have successfully added an Assessment</AlertSuccess>
      ) : null}
      {showEditSuccess && pressStatus === 'edit' ? (
        <AlertSuccess>You have successfully edited an Assessment</AlertSuccess>
      ) : null}
    </div>
  )
}

export default Assessment
