import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

//actions
import { fetchFacultyReport } from '../../../../redux/action/facultyReport'

//reducers
import { getJwt } from '../../../../redux/reducer/account'
import {
  getIsFacultyReportLoading,
  getFacultyReport,
} from '../../../../redux/reducer/facultyReport'

//assets
import Loader from '../../../assets/molecules/loader'
import { downloadReport } from '../../../helpers'

const FacultyReport = () => {
  const dispatch = useDispatch()
  const [reportData, setReportData] = useState({})
  const jwt = useSelector(getJwt)
  const isFacultyReportLoading = useSelector(getIsFacultyReportLoading)
  const facultyReport = useSelector(getFacultyReport)

  useEffect(() => {
    dispatch(fetchFacultyReport({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (!isFacultyReportLoading) {
      const header = {
        Sl_No: 'Sl No',
        Teacher_Name: 'Teacher Name',
        Topics_Created: 'Topics Created',
        Doubts_Asked: 'Doubts Asked',
        Doubts_Answered: 'Doubts Answered',
        Sessions_Held: 'Sessions Held',
      }
      const data = facultyReport.map((faculty, index) => {
        return {
          Sl_No: index + 1,
          Teacher_Name: faculty.name
            .split(' ')
            .map((name) => {
              return name.charAt(0).toUpperCase() + name.substr(1).toLowerCase()
            })
            .join(' '),
          Topics_Created: faculty.topics_count,
          Doubts_Asked: faculty.doubts_count,
          Doubts_Answered: faculty.doubts_answered,
          Sessions_Held: faculty.online_session_count,
        }
      })
      setReportData({ header, data })
    }
  }, [facultyReport, isFacultyReportLoading])

  const downloadPdf = () => {
    const data = {
      reportData: reportData.data,
    }
    downloadReport({
      nameOfTemplate: 'cwxTeacherAnalytics',
      dataForTemplate: data,
      downloadedFileName: `FacultyReport`,
    })
  }

  return isFacultyReportLoading ? (
    <Loader />
  ) : (
    <div className="tabs__container">
      <section>
        <nav className="padding--leftRight">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Faculty Report</li>
          </ol>
        </nav>
        <div className="clear"></div>
      </section>
      <button className="button--blue" onClick={() => downloadPdf()}>
        Pdf
      </button>
      {reportData.header && reportData.data ? (
        <section style={{ paddingTop: 20 }}>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {Object.keys(reportData.header).map((key, index) => {
                    return <th key={index}>{reportData.header[key]}</th>
                  })}
                </tr>
              </thead>
              <tbody>
                {reportData.data.map((report, index) => {
                  return (
                    <tr key={index}>
                      {Object.keys(reportData.header).map((key, index) => {
                        return <td key={index}>{report[key]}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="clear"></div>
          </div>
        </section>
      ) : null}
      <div className="clear"></div>
    </div>
  )
}

export default FacultyReport
