import React from 'react'

const Col25 = (props) => {
  return (
    <div className={'col-lg-4 rightLayout ' + props?.className}>
      {props.children}
      <div className="clear"></div>
    </div>
  )
}

export default Col25
