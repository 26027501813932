import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'underscore'
import { Tab, Tabs } from 'react-bootstrap'

//assests
import Loader from '../assets/molecules/loader'
import LeftRightLayout from '../assets/layout/leftrightlayout'
import Col75Right from '../assets/layout/leftrightlayout/column75Right'
import Col25Left from '../assets/layout/leftrightlayout/column25Left'
import SubjectDoubtCard from '../assets/molecules/subjectDoubtsCard'
import DoubtsMainCard from '../assets/molecules/doubtsMainCard'
import Accordion from '../assets/layout/accordion'
import NotesCard from '../assets/molecules/notesCard'
import Popup from '../assets/layout/popupConfirm'
import NoPending from '../../assets/images/no-pending.png'

//action
import {
  fetchNotesByBatchSubjectId,
  fetchDeletenotes,
  fetchEditnotes,
} from '../../redux/action/note'
import { fetchUserSubjects } from '../../redux/action/subject'
import {
  setTopic,
  setSubject,
  setBatchSubjectId,
  setChapter,
} from '../../redux/action/ui'

//reducer
import { getJwt } from '../../redux/reducer/account'
import {
  getIsNotesLoading,
  getNotes,
  getIsNotesDeleting,
  getIsEditNotesLoading,
} from '../../redux/reducer/note'
import { isSubjectsloading, getSubjects } from '../../redux/reducer/subject'

//helpers
import { usePrevious, subjectIcons } from '../helpers'

const MyNotes = () => {
  const dispatch = useDispatch()

  //state
  const [selectedSubject, setSelectedSubject] = useState('')
  const [notesByTopics, setNotesByTopics] = useState({})
  const [notesCount, setNotesCount] = useState([])
  const [selectedNote, setSelectedNote] = useState('')
  const [editedNoteText, setEditedNoteText] = useState('')
  const [checkedIndex, setCheckedIndex] = useState(0)
  const [popClassName, setPopClassName] = useState('hidePop')
  const [deletePopClassName, setDeletePopClassName] = useState('hidePop')

  const openPopup = (note) => {
    setSelectedNote(note)
    setEditedNoteText(note?.note_text)
    setPopClassName('showPop')
  }

  const closePopup = () => {
    setPopClassName('hidePop')
  }

  const openDeletePopup = (note) => {
    setSelectedNote(note)
    setDeletePopClassName('showPop')
  }

  const closeDeletePopup = () => {
    setDeletePopClassName('hidePop')
  }

  //selector
  const jwt = useSelector(getJwt)
  const isLoadingSubjects = useSelector(isSubjectsloading)
  const isLoadingSubjectsPrev = usePrevious(isLoadingSubjects)
  const isNotesLoading = useSelector(getIsNotesLoading)
  const isNotesLoadingPrev = usePrevious(isNotesLoading)
  const subjects = useSelector(getSubjects)
  const notes = useSelector(getNotes)
  const isNotesDeleting = useSelector(getIsNotesDeleting)
  const isNotesDeletingPrev = usePrevious(isNotesDeleting)
  const isEditNotesLoading = useSelector(getIsEditNotesLoading)
  const isEditNotesLoadingPrev = usePrevious(isEditNotesLoading)

  useEffect(() => {
    dispatch(fetchUserSubjects({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (!isLoadingSubjects && isLoadingSubjectsPrev !== undefined) {
      setSelectedSubject(subjects[0])
    }
  }, [isLoadingSubjects]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedSubject) {
      dispatch(
        fetchNotesByBatchSubjectId({
          jwt,
          batchSubjectId: selectedSubject?._id,
        })
      )
    }
  }, [dispatch, jwt, selectedSubject])

  useEffect(() => {
    if (!isNotesLoading && isNotesLoadingPrev !== undefined) {
      setNotesCount(notes.length)
      setNotesByTopics(
        _.groupBy(
          notes.sort(
            (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
          ),
          (x) => x?.topic_id
        )
      )
    }
  }, [isNotesLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isNotesDeleting && isNotesDeletingPrev !== undefined) {
      dispatch(
        fetchNotesByBatchSubjectId({
          jwt,
          batchSubjectId: selectedSubject?._id,
        })
      )
      closeDeletePopup()
    }
  }, [isNotesDeleting]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEditNotesLoading && isEditNotesLoadingPrev !== undefined) {
      dispatch(
        fetchNotesByBatchSubjectId({
          jwt,
          batchSubjectId: selectedSubject?._id,
        })
      )
      closePopup()
    }
  }, [isEditNotesLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const noNotes = () => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">Take Notes in your Subject topics</h2>
          <p>Revise your notes before your exams for better performance</p>
        </div>
      </div>
    )
  }

  const onTopicSelect = (note) => {
    dispatch(
      setSubject(selectedSubject?.subject_id, selectedSubject?.subject_title)
    )
    dispatch(setBatchSubjectId(note?.batch_subject_id))
    dispatch(setChapter(note?.chapter_id, note?.chapter_title))
    dispatch(setTopic(note?.topic_id, note?.topic_title))
  }

  const onEdit = () => {
    dispatch(
      fetchEditnotes({
        jwt,
        noteId: selectedNote?._id,
        note_text: editedNoteText,
      })
    )
  }
  const onChangeSubjectInMob = (event) => {
    const filteredSubjects = subjects.filter(
      (subject) => subject?._id === event.target.value
    )
    setSelectedSubject(filteredSubjects[0])
  }
  const onDelete = (note) => {
    dispatch(fetchDeletenotes({ jwt, noteId: selectedNote?._id }))
  }

  return (
    <div className="tabs__container">
      <section>
        <h1>Notes</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Notes</li>
          </ol>
        </nav>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col25Left className="padding--0">
            <h2 className="subjects--head">Subject list</h2>
            <div className="desktop">
              {isLoadingSubjects ? (
                <Loader />
              ) : subjects?.length ? (
                subjects?.map((subject, index) => {
                  return (
                    <SubjectDoubtCard
                      key={index}
                      className={
                        selectedSubject?._id === subject?._id
                          ? 'subjectDoubtsCard--active'
                          : 'subjectDoubtsCard--noactive'
                      }
                      icon={
                        subjectIcons[subject.subject_title.toLowerCase()]
                          ? subjectIcons[subject.subject_title.toLowerCase()]
                          : subjectIcons['others']
                      }
                      subjectName={subject.subject_title}
                      subjectCode={subject.subject_code}
                      noOfTopics=""
                      notes={true}
                      onClick={() => setSelectedSubject(subject)}
                    />
                  )
                })
              ) : (
                <p>No subjects avilable</p>
              )}
            </div>
            <div className="mob padd--LR25 text-center">
              <select
                className="select__subject"
                onChange={(event) => onChangeSubjectInMob(event)}
              >
                {isLoadingSubjects ? (
                  <Loader />
                ) : subjects?.length ? (
                  subjects?.map((subject, index) => {
                    return (
                      <option value={subject?._id}>
                        {subject.subject_title}
                      </option>
                    )
                  })
                ) : (
                  <React.Fragment>
                    <option>No subjects avilable</option>
                  </React.Fragment>
                )}
              </select>
            </div>
          </Col25Left>
          <Col75Right>
            <Tabs>
              <Tab eventKey="Topic Notes" title={`Topic Notes (${notesCount})`}>
                {isNotesLoading ? (
                  <Loader />
                ) : (
                  <Accordion>
                    {notesByTopics && Object.keys(notesByTopics).length > 0
                      ? Object.keys(notesByTopics).map((key, index) => {
                          return (
                            <DoubtsMainCard
                              key={index}
                              itemNo={index}
                              title={notesByTopics[key][0]?.topic_title}
                              noOfRepies={notesByTopics[key]?.length}
                              link="/me/subjects/chapters/topics/detail"
                              onClickLink={() =>
                                onTopicSelect(notesByTopics[key][0])
                              }
                              checked={checkedIndex === index}
                              setCheckedIndex={(item) => {
                                setCheckedIndex(item)
                              }}
                            >
                              {notesByTopics[key] &&
                                notesByTopics[key]?.map((note, index) => {
                                  return (
                                    <NotesCard
                                      key={index}
                                      content={note?.note_text}
                                      notes={true}
                                      editOnClick={() => openPopup(note)}
                                      deleteOnClick={() =>
                                        openDeletePopup(note)
                                      }
                                    />
                                  )
                                })}
                            </DoubtsMainCard>
                          )
                        })
                      : noNotes()}
                  </Accordion>
                )}
              </Tab>
              <Tab
                eventKey="Live Session Notes"
                title={`Live Session Notes (${0})`}
              ></Tab>
            </Tabs>
            <Popup
              className={popClassName}
              close={() => closePopup()}
              onClick={() => onEdit()}
              buttonText="Save Note"
              popupHead="Edit Note"
            >
              <textarea
                rows="5"
                value={editedNoteText}
                onChange={(e) => setEditedNoteText(e.target.value)}
              ></textarea>
            </Popup>
            <Popup
              className={deletePopClassName}
              close={() => closeDeletePopup()}
              onClick={() => onDelete()}
              buttonText="yes"
              popupHead="Delete Note"
            >
              <h2>Are you sure want to delete?</h2>
            </Popup>
          </Col75Right>
        </LeftRightLayout>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default MyNotes
