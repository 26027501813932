import {
  APP_LOGOUT,
  GET_FACULTY_REPORT_START,
  GET_FACULTY_REPORT_SUCCESS,
  GET_FACULTY_REPORT_FAILURE,
} from '../actions'

let initialState = {
  error: false,
  isFacultyReportLoading: false,
  facultyReport: [],
}

export const facultyReport = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState

    case GET_FACULTY_REPORT_START:
      return {
        ...state,
        isFacultyReportLoading: true,
        error: false,
      }
    case GET_FACULTY_REPORT_SUCCESS:
      return {
        ...state,
        isFacultyReportLoading: false,
        error: false,
        facultyReport: action.result,
      }
    case GET_FACULTY_REPORT_FAILURE:
      return {
        ...state,
        isFacultyReportLoading: false,
        error: true,
      }

    default:
      return state
  }
}

export const getIsFacultyReportLoading = (state) =>
  state.facultyReport.isFacultyReportLoading

export const getFacultyReport = (state) => state.facultyReport.facultyReport
