import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { usePrevious } from '../../helpers'

//assets
import Card from '../../assets/molecules/card'
import Button from '../../assets/molecules/button'

//actions
//subject
import { fetchEditTopic } from '../../../redux/action/topic'
import { getFaculties as fetchFaculties } from '../../../redux/action/faculty'

//reducer
//account
import { getJwt } from '../../../redux/reducer/account'
import { groupByBatchSubject } from '../../../redux/reducer/subject'
import {
  getIsTopicsLoading,
  getIsError,
  getTopics,
} from '../../../redux/reducer/topic'
import { getChapters } from '../../../redux/reducer/chapter'
import { getBatch } from '../../../redux/reducer/ui'
import { getAllFaculties } from '../../../redux/reducer/faculty'

import { getBatches } from '../../../redux/reducer/batches'

import Loader from '../../assets/molecules/loader'

const EditTopic = () => {
  const dispatch = useDispatch()
  //get param from url
  const { subjectId, chapterId, topicId } = useParams()
  const history = useHistory()

  //selector
  const jwt = useSelector((state) => getJwt(state))
  const error = useSelector((state) => getIsError(state))
  const isLoading = useSelector((state) => getIsTopicsLoading(state))
  let batchId = useSelector((state) => getBatch(state))
  const chapters = useSelector((state) => getChapters(state))
  const topics = useSelector((state) => getTopics(state))
  const subjects = useSelector((state) => groupByBatchSubject(state))
  const batches = useSelector((state) => getBatches(state))
  const faculties = useSelector(getAllFaculties)

  const isLoadingPrev = usePrevious(isLoading)

  let [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    dispatch(fetchFaculties({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isLoading &&
      !isLoading &&
      !error
    ) {
      history.push({
        pathname: '/batch/subjects/chapters/topics',
        state: { status: 'success' },
      })
    }
    error && setErrorMessage('Something went wrong. Please try again.')
  }, [error, history, isLoading, isLoadingPrev])

  const onChange = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (
      formData?.title &&
      formData?.type &&
      formData?.contentUrl &&
      formData?.description
    ) {
      const [authorId, authorName] = formData?.faculty?.split('-')
      const data = {
        title: formData.title,
        type: formData.type,
        content_uri: formData.contentUrl,
        wysiwyg_state: formData.description,
        chapter_id: chapterId,
        author_id: authorId,
        author_name: authorName,
      }
      dispatch(fetchEditTopic({ jwt, data, topicId }))
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  const filteredListSubjects = subjects[batchId]?.filter(
    (subject) => subject.subject_id === subjectId
  )
  const currentSubject =
    filteredListSubjects?.length > 0 ? filteredListSubjects[0] : {}

  const filteredListBatches = batches.filter((bat) => bat._id === batchId)
  const currentBatch =
    filteredListBatches.length > 0 ? filteredListBatches[0] : {}

  const filteredListChapters = chapters.filter((chap) => chap._id === chapterId)

  const currentChapter =
    filteredListChapters.length > 0 ? filteredListChapters[0] : {}

  const filteredListTopic = topics.filter((topic) => topic._id === topicId)

  const currentTopic = filteredListTopic.length > 0 ? filteredListTopic[0] : {}
  let [formData, setFormData] = useState({
    title: currentTopic.title,
    type: currentTopic.type,
    contentUrl: currentTopic.content_uri,
    description: currentTopic.wysiwyg_state,
    faculty: `${currentTopic.author_id}-${currentTopic.author_name}`,
  })

  const contentTypes = ['image', 'audio', 'video']

  return (
    <div className="tabs__container">
      <section>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/dashboard">{currentBatch.title}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/batch/subjects">{currentSubject.subject_title}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/batch/subjects/chapters">{currentChapter.title}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/batch/subjects/chapters/topics">
                {currentTopic.title}
              </Link>
            </li>
            <li className="breadcrumb-item active">edit topic</li>
          </ol>
        </nav>
      </section>

      <section>
        <h4>Edit Topics</h4>
        <Card columnNo="1" title="Edit Topics" titleColor={1}>
          <input
            name="title"
            value={formData.title}
            type="text"
            placeholder="Title"
            onChange={onChange}
          />
          <select name="faculty" onChange={(e) => onChange(e)}>
            <option value={formData.faculty}>
              {formData.faculty.split('-')[1]}
            </option>
            {faculties?.map((faculty, index) => {
              return (
                <option value={`${faculty._id}-${faculty.name}`} key={index}>
                  {`${faculty.name} - (${faculty.prn})`}
                </option>
              )
            })}
          </select>
          <select name="type" onChange={(e) => onChange(e)}>
            <option value={formData.type}>{formData.type}</option>
            {contentTypes.map((contentType, index) => {
              return (
                <option value={contentType} key={index}>
                  {contentType}
                </option>
              )
            })}
          </select>
          <input
            name="contentUrl"
            value={formData.contentUrl}
            type="text"
            placeholder="Content Url"
            onChange={(e) => onChange(e)}
          />
          <textarea
            rows="18"
            name="description"
            value={formData.description}
            placeholder="Description"
            onChange={(e) => onChange(e)}
          ></textarea>
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              className="button--small"
              color="blue"
              text="Save"
              click={(e) => onSubmit(e)}
            />
          )}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </Card>
      </section>
    </div>
  )
}

export default EditTopic
