import React from 'react'

const Col100 = (props) => {
  return (
    <div className={'col-lg-12 column100 ' + props.className}>
      {props.children}
      <div className="clear"></div>
    </div>
  )
}

export default Col100
