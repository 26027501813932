import React from 'react'

const TeachersCard = (props) => {
  return (
    <div className="teachersCard">
      <input type="radio" name="accordion" id={'accItem' + props.itemNo} />
      <div className="box">
        <label
          id="title"
          className="box-title"
          htmlFor={'accItem' + props.itemNo}
        >
          <div className="teachersCard__img">
            <img src={props.icon} alt="CWX" />
            <div className="clear"></div>
          </div>
          <div className="teachersCard__content">
            <h5 className="teachersCard__name">{props.teachersName}</h5>
            <p className="teachersCard__designation">{props.designation}</p>
            <div className="clear"></div>
          </div>
        </label>
        <label className="box-close" htmlFor="acc-close"></label>
        <div className="box-content">
          <ul className="teachersSubjectCard__wrapper">{props.children}</ul>
          <div className="clear"></div>
        </div>
      </div>
    </div>
  )
}

export default TeachersCard
