import React from 'react'
import { Link } from 'react-router-dom'

const DoubtsMainCard = (props) => {
  console.log('props: ', props)
  return (
    <div className="doubtsMainCard">
      <input
        type="radio"
        name="accordion"
        id={'accItem' + props.itemNo}
        checked={props?.checked}
        onChange={() => props?.setCheckedIndex(props?.itemNo)}
      />
      <div className="box">
        <div className="box-label">
          <label
            id="title"
            className="box-title"
            htmlFor={'accItem' + props.itemNo}
          >
            <div className="doubtsMainCard__title">
              {props.title} ({props.noOfRepies})
            </div>
            {!props?.assessment ? (
              <Link
                className="doubtsMainCard__link"
                to={props.link}
                onClick={(e) => props.onClickLink(e)}
              >
                GO TO TOPIC
              </Link>
            ) : null}
            <div className="clear"></div>
          </label>
        </div>
        <label className="box-close" htmlFor="acc-close"></label>
        <div className="box-content">
          {props.children}
          <div className="clear"></div>
        </div>
      </div>
    </div>
  )
}

export default DoubtsMainCard
