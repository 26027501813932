import {
  APP_LOGOUT,
  GET_PROFILE_INFO_START,
  GET_PROFILE_INFO_SUCCESS,
  GET_PROFILE_INFO_FAILURE,
} from '../actions.js'

const initialState = {
  error: false,
  profileInfo: {},
  isLoadingProfile: false,
}

export const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_INFO_START:
      return Object.assign({}, state, {
        isLoadingProfile: true,
        error: false,
      })
    case GET_PROFILE_INFO_SUCCESS:
      return Object.assign({}, initialState, {
        profileInfo: action.data,
        isLoadingProfile: false,
        error: false,
      })
    case GET_PROFILE_INFO_FAILURE:
      return Object.assign({}, state, {
        error: true,
        isLoadingProfile: false,
      })
    case APP_LOGOUT:
      return initialState
    default:
      return state
  }
}

export const getIsError = (state) => state.profile.error
export const getProfileInfo = (state) => state.profile.profileInfo
export const getIsLoadingProfile = (state) => state.profile.isLoadingProfile
