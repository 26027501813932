import React from 'react'
import RightArrow from '../../../assets/images/rightArrow.png'

const TeachersSubjectCard = (props) => {
  return (
    <li onClick={props.onClick} className="teachersSubjectCard">
      <div className="teachersSubjectCard__indicator"></div>
      <p className="teachersSubjectCard__topic">{props.topic}</p>
      <img className="teachersSubjectCard__arrow" src={RightArrow} alt="cwx" />
      <div className="clear"></div>
    </li>
  )
}

export default TeachersSubjectCard
