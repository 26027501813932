import React from 'react'

const Col75Right = (props) => {
  return (
    <div className="col-lg-8 rightLayoutBig">
      {props.children}
      <div className="clear"></div>
    </div>
  )
}

export default Col75Right
