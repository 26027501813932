import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

//assets
import Loader from '../../assets/molecules/loader'
import Select from '../../assets/molecules/select'

//action
import { setBatch } from '../../../redux/action/ui'
import {
  fetchAssessmentList,
  fetchAssessmentByIdMarks,
} from '../../../redux/action/assessment'

//reducer
import { getJwt } from '../../../redux/reducer/account'
import {
  getAssessments,
  getIsAssessmentsLoading,
  getAssessmentMarks,
  getISAssessmentMarksLoading,
} from '../../../redux/reducer/assessment'
import { getBatches } from '../../../redux/reducer/batches'
import { groupByBatchSubject } from '../../../redux/reducer/subject'

//helpers
import { usePrevious, downloadReport } from '../../helpers'

const AssessmentMarks = () => {
  const dispatch = useDispatch()

  let jwt = useSelector((state) => getJwt(state))
  let isLoading = useSelector((state) => getIsAssessmentsLoading(state))
  let batches = useSelector((state) => getBatches(state))
  let assessments = useSelector(getAssessments)
  let assessmentMarks = useSelector(getAssessmentMarks)
  let isMarksLoading = useSelector((state) =>
    getISAssessmentMarksLoading(state)
  )
  const isMarksLoadingPrev = usePrevious(isMarksLoading)
  let subjects = useSelector((state) => groupByBatchSubject(state))

  let [batchSelected, setBatchSelected] = useState('')
  let [assessmentSelected, setAssessmentSelected] = useState({})
  let [assessmentList, setAssessementList] = useState([])
  let [reportData, setReportData] = useState({})

  useEffect(() => {
    dispatch(fetchAssessmentList({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    if (!isMarksLoading && isMarksLoadingPrev !== undefined) {
      const header = {
        sl_No: 'Sl No',
        student_name: 'Student',
        scored_marks: 'Marks Scored',
        total_marks: 'Total Marks',
        assessor_name: 'Assessor',
        number_file_submited: 'No. Files',
      }
      const data = assessmentMarks.map((marks, index) => {
        const studentName = currentBatch.students.find((stu) => {
          if (stu.id === marks.student_id) return stu
        })
        return {
          sl_No: index + 1,
          student_name: studentName?.name,
          scored_marks: marks.scored_marks,
          total_marks: marks.total_marks,
          number_file_submited: marks.number_file_submited,
          assessor_name:
            marks.assessor_name === null ? '-' : marks.assessor_name,
        }
      })
      setReportData({ header, data })
    }
  }, [assessmentMarks]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeBatch = (e) => {
    e.preventDefault()
    setBatchSelected(e.target.value)
    dispatch(setBatch(e.target.value))
    let subjectByBatch = subjects[e.target.value]
    if (subjectByBatch === undefined) {
      setAssessementList([])
      return ''
    }
    let batchAssessment = []
    assessments.filter((assess) => {
      subjectByBatch.map((sub) => {
        if (sub._id === assess.batch_subject_id) {
          batchAssessment.push(assess)
        }
      })
    })
    setAssessementList(batchAssessment)
    if (batchAssessment === []) {
      setAssessmentSelected('No assessments')
    }
  }

  const onChangeAssessment = (e) => {
    e.preventDefault()
    let id = e.target.value
    let assessment = assessmentList.find((assess) => {
      if (assess._id === e.target.value) return assess
    })
    setAssessmentSelected({
      ...assessmentSelected,
      assessment_title: assessment.title,
      assessment_id: e.target.value,
    })
    dispatch(fetchAssessmentByIdMarks({ jwt, id }))
  }

  const downloadPdf = () => {
    const data = {
      reportData: reportData,
      batchName: currentBatch.title,
      assessmentName: assessmentSelected.assessment_title,
    }
    downloadReport({
      nameOfTemplate: 'cwxMarksReport',
      dataForTemplate: data,
      downloadedFileName: `assessmentMarksReport`,
    })
  }

  const filteredList = batches.filter((bat) => bat._id === batchSelected)
  const currentBatch = filteredList.length > 0 ? filteredList[0] : {}

  return (
    <div className="tabs__container">
      <section>
        {isLoading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className="tabs__container">
              <h2>Assessment Marks</h2>
              <section>
                <nav className="padding--leftRight">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">Home</Link>
                    </li>
                    <li className="breadcrumb-item active">
                      Assessment Marks Report
                    </li>
                  </ol>
                </nav>
                <div className="clear"></div>
              </section>
              <section>
                <button className="button--blue" onClick={() => downloadPdf()}>
                  Pdf
                </button>
                <Select
                  className="float-right"
                  onChange={(e) => onChangeAssessment(e)}
                  value={assessmentSelected.assessment_id}
                >
                  <option value="" selected>
                    Select an assessment
                  </option>
                  {assessmentList ? (
                    assessmentList.map((assessment, index) => {
                      return (
                        <option value={assessment._id} key={index}>
                          {assessment.title}
                        </option>
                      )
                    })
                  ) : (
                    <option value="No Assessment">No assessment</option>
                  )}
                </Select>
                <Select
                  className="float-right"
                  onChange={(e) => onChangeBatch(e)}
                  value={batchSelected}
                >
                  <option value="" selected>
                    Select a batch
                  </option>
                  {batches.map((batch, index) => {
                    return (
                      <option value={batch._id} key={index}>
                        {batch.title}
                      </option>
                    )
                  })}
                </Select>
                <div className="clear"></div>
              </section>
            </div>
            {isMarksLoading ? (
              <Loader />
            ) : reportData?.header && reportData?.data ? (
              <section style={{ paddingTop: 20 }}>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        {Object.keys(reportData?.header)?.map((key, index) => {
                          return <th key={index}>{reportData?.header[key]}</th>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {reportData?.data?.map((report, index) => {
                        console.log(report, 'reportreport')
                        return (
                          <tr key={index}>
                            {Object.keys(reportData?.header)?.map(
                              (key, index) => {
                                return <td key={index}>{report[key]}</td>
                              }
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <div className="clear"></div>
                </div>
              </section>
            ) : null}
          </React.Fragment>
        )}
      </section>
    </div>
  )
}

export default AssessmentMarks
