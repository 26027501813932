import {
  GET_PROFILE_INFO_START,
  GET_PROFILE_INFO_SUCCESS,
  GET_PROFILE_INFO_FAILURE,
  // APP_AUTH_FAILED
} from '../actions.js'

import { checkStatus } from '../helpers.js'

//get profile info
const getProfileInfoStart = () => {
  return {
    type: GET_PROFILE_INFO_START,
  }
}

const getProfileInfoSuccess = (data) => {
  return {
    type: GET_PROFILE_INFO_SUCCESS,
    data,
  }
}

const getProfileInfoFailure = () => {
  return {
    type: GET_PROFILE_INFO_FAILURE,
  }
}

const getProfileInfo = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/users/me/profile`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const startGetProfileInfo = (data) => {
  return async (dispatch) => {
    dispatch(getProfileInfoStart())
    try {
      const receivedData = await getProfileInfo(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getProfileInfoSuccess(response?.data?.profile))
    } catch (err) {
      dispatch(getProfileInfoFailure())
    }
  }
}
