import {
  VIMEO_UPLOAD_START,
  VIMEO_UPLOAD_FILE,
  VIMEO_UPLOAD_FAIL,
} from '../actions.js'

import { checkStatus } from '../helpers.js'

const uploadVideoStart = () => {
  return {
    type: VIMEO_UPLOAD_START,
  }
}

const uploadVideoSuccess = (data) => {
  return {
    type: VIMEO_UPLOAD_FILE,
    data,
  }
}

const uploadVideoFailure = () => {
  return {
    type: VIMEO_UPLOAD_FAIL,
  }
}
const uploadVideo = (body) => {
  const payload = {
    name: body.name,
    upload: {
      approach: 'tus',
      size: body.file[0].size,
    },
  }
  const url = `https://api.vimeo.com/me/videos`
  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `bearer da9e76a2b1a1747ee87f278187c9f0dc`,
      'Content-Type': 'application/json',
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(payload),
  })
}

const patchUploadVideo = (body) => {
  const { link, file, uploadOffset } = body
  const url = link
  return fetch(url, {
    method: 'PATCH',
    headers: {
      'Tus-Resumable': '1.0.0',
      'Upload-Offset': uploadOffset,
      'Content-Type': 'application/offset+octet-stream',
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
    },
    body: file,
  })
}

export const startVideoUpload = (data) => {
  return async (dispatch) => {
    dispatch(uploadVideoStart())
    try {
      const receivedData = await uploadVideo(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      const payload = {
        link: response.upload.upload_link,
        file: data.file[0],
        uploadOffset: 0,
      }
      console.log(response, 'response')
      console.log(payload, 'payload')
      const res = await patchUploadVideo(payload)
      checkStatus(res)
      dispatch(uploadVideoSuccess(response))
    } catch (err) {
      dispatch(uploadVideoFailure())
    }
  }
}
