import React from 'react'

const StudentsCard = (props) => {
  return (
    <div className="studentsCard">
      <div className="studentsCard__img">
        <img src={props.icon} alt="CWX" />
      </div>
      <div className="studentsCard__content">
        <div>
          <h5>{props.teachersName}</h5>
          <p>{props.designation}</p>
        </div>
        {props?.showArrow ? (
          <div>
            <img
              className="rightArrow"
              src={require('../../../assets/images/rightArrow.svg')}
              alt="CWX"
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default StudentsCard
