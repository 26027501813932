import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import _ from 'underscore'

//assets
import Loader from '../../assets/molecules/loader'
import { downloadReport } from '../../helpers'

//action
import { fetchStudyPlanByTopicId } from '../../../redux/action/studyplan'
import { fetchStudentByTopicIdReport } from '../../../redux/action/reports'

//reducer
import { getJwt } from '../../../redux/reducer/account'
import {
  getIsLoadingStudyPlanByTopicId,
  getStudyPlanByTopicId,
} from '../../../redux/reducer/studyplan'
import {
  getIsStudentByTopicIdLoading,
  getStudentByTopicId,
} from '../../../redux/reducer/reports'

//helpers
import { usePrevious } from '../../helpers'

const Reports = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const [reportData, setReportData] = useState({})

  const topicId = location?.state?.topicId
  const currentTopic = location?.state?.currentTopic
  const currentBatch = location?.state?.currentBatch
  const currentSubject = location?.state?.currentSubject

  const jwt = useSelector(getJwt)
  const isLoadingStudyPlanByTopicId = useSelector(
    getIsLoadingStudyPlanByTopicId
  )
  const isLoadingStudyPlanByTopicIdPrev = usePrevious(
    isLoadingStudyPlanByTopicId
  )
  const studyPlanByTopicId = useSelector(getStudyPlanByTopicId)
  const isStudentByTopicIdLoading = useSelector(getIsStudentByTopicIdLoading)
  const isStudentByTopicIdLoadingPrev = usePrevious(isStudentByTopicIdLoading)
  const topicsViewByStudentID = useSelector(getStudentByTopicId)

  useEffect(() => {
    dispatch(fetchStudyPlanByTopicId({ jwt, id: topicId }))
    dispatch(fetchStudentByTopicIdReport({ jwt, topicid: topicId }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isLoadingStudyPlanByTopicId &&
      isLoadingStudyPlanByTopicIdPrev !== undefined &&
      !isStudentByTopicIdLoading &&
      isStudentByTopicIdLoadingPrev !== undefined
    ) {
      const header = {
        sl_No: 'Sl No',
        student: 'Student',
        status: 'View Status',
        no_of_views: 'No Of Views',
        last_date_of_view: 'Last Date Of View',
      }
      const data = currentBatch?.students?.map((stu, index) => {
        const sorted =
          topicsViewByStudentID[stu?.id] &&
          topicsViewByStudentID[stu?.id]?.length
            ? topicsViewByStudentID[stu?.id]?.sort(
                (a, b) => new Date(b?.updated_at) - new Date(a?.updated_at)
              )[0]
            : {}
        return {
          sl_No: index + 1,
          student: stu?.name,
          status:
            topicsViewByStudentID[stu?.id] &&
            topicsViewByStudentID[stu?.id]?.length
              ? 'Viewed'
              : 'Not Viewed',
          no_of_views:
            topicsViewByStudentID[stu?.id] &&
            topicsViewByStudentID[stu?.id]?.length
              ? topicsViewByStudentID[stu?.id]?.length
              : 0,
          last_date_of_view: Object.keys(sorted)?.length
            ? moment(sorted?.updated_at).format('DD-MM-YYYY | hh:mm A')
            : '-',
        }
      })
      setReportData({ header, data })
    }
  }, [isStudentByTopicIdLoading, isLoadingStudyPlanByTopicId]) // eslint-disable-line react-hooks/exhaustive-deps

  const downloadPdf = () => {
    const data = {
      reportData: reportData?.data,
      topicName: currentTopic?.title,
      batchName: currentBatch?.title,
      subjectName: currentSubject?.subject_title,
      studyPlanDate: moment(studyPlanByTopicId?.date).format(
        'dddd, Do MMM YYYY'
      ),
    }
    downloadReport({
      nameOfTemplate: 'cwxTopicWiseReport',
      dataForTemplate: data,
      downloadedFileName: `topicsReport`,
    })
  }

  return isLoadingStudyPlanByTopicId ? (
    <Loader />
  ) : (
    <div className="tabs__container">
      <section>
        <nav className="padding--leftRight">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Topics Reports</li>
          </ol>
        </nav>
        <div className="clear"></div>
      </section>
      <section>
        <button className="button--blue" onClick={() => downloadPdf()}>
          Pdf
        </button>
      </section>
      <br />
      <h3>{currentTopic?.title}</h3>
      <h3>{currentBatch?.title}</h3>
      <h3>{currentSubject?.subject_title}</h3>
      <h3>
        Study Plan Date :{' '}
        {moment(studyPlanByTopicId?.date).format('dddd, Do MMM YYYY')}
      </h3>
      {reportData?.header && reportData?.data ? (
        <section style={{ paddingTop: 20 }}>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {Object.keys(reportData?.header)?.map((key, index) => {
                    return <th key={index}>{reportData?.header[key]}</th>
                  })}
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {reportData?.data?.map((report, index) => {
                  return (
                    <tr key={index}>
                      {Object.keys(reportData?.header)?.map((key, index) => {
                        return <td key={index}>{report[key]}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <div className="clear"></div>
          </div>
        </section>
      ) : null}
      <div className="clear"></div>
    </div>
  )
}

export default Reports
