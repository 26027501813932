import React, { useEffect, useState, useRef, createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

//assests
import Loader from '../../assets/molecules/loader'

//action
import { fetchAllAssessmentQuestions } from '../../../redux/action/assessment'

//reducer
import { getJwt } from '../../../redux/reducer/account'
import {
  getIsAssessmentsLoading,
  getAssessmentQuestions,
} from '../../../redux/reducer/assessment'

const AssessmentQuestion = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const assess = location.state.assess

  //selector
  const jwt = useSelector(getJwt)
  const isLoading = useSelector(getIsAssessmentsLoading)
  const assessmentQuestions = useSelector(getAssessmentQuestions)

  useEffect(() => {
    dispatch(fetchAllAssessmentQuestions({ jwt }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const createQuestion = (question, i) => {
    switch (question.type) {
      case 'SINGLE_CHOICE':
      case 'MULTIPLE_CHOICE':
      case 'BOOLEAN':
        return (
          <div className="question__wrapper">
            <div className="question__wrap">
              <p className="assessment__question">{`${i + 1}) ${
                question.title
              }`}</p>
              <p className="assessment__marks">{question.weightage} mark(s)</p>
              <div className="clear"></div>
            </div>
            <ul className="radio__wrapper">
              {question?.answers?.map((answer, index) => (
                <li key={index}>
                  <input
                    type={
                      question.type === 'MULTIPLE_CHOICE' ? 'checkbox' : 'radio'
                    }
                    className="radio--btn"
                    name={question._id}
                    id={answer._id}
                  />
                  <label for={answer._id} className="radio--label">
                    {answer.title}
                  </label>
                </li>
              ))}
            </ul>
            <div className="clear"></div>
          </div>
        )
      case 'TEXT':
        return (
          <div className="question__wrapper">
            <div className="question__wrap">
              <p className="assessment__question">{`${i + 1})`}</p>
              <p
                className="assessment__question"
                dangerouslySetInnerHTML={{ __html: question.title }}
              ></p>
              <p className="assessment__marks">{question.weightage} mark(s)</p>
              <div className="clear"></div>
            </div>
            <textarea
              placeholder="Type your answer"
              name={question?._id}
              rows="5"
            />
          </div>
        )
      case 'UPLOAD':
        let questiontitle = ''
        if (
          question?.title?.match(/\.[0-9a-z]+$/i)?.[0] &&
          question?.title?.match(/\.[0-9a-z]+$/i)?.[0] === '.pdf'
        ) {
          questiontitle = (
            <iframe height="1200" width="90%" src={question?.title} />
          )
        } else {
          questiontitle = (
            <p
              className="assessment__question"
              dangerouslySetInnerHTML={{ __html: question.title }}
            ></p>
          )
        }
        return (
          <div className="question__wrapper">
            <div className="question__wrap">
              <p className="assessment__question">{`${i + 1})`}</p>
              {questiontitle}
              <p className="assessment__marks">{question.weightage} mark(s)</p>
              <div className="clear"></div>
            </div>
            <div className="question__wrap"></div>
          </div>
        )
      default:
        return <div />
    }
  }

  return (
    <div className="tabs__container">
      <section>
        <h1>{assess.title}</h1>
        {assess?.url && assess?.url !== 'noFiles' ? (
          <div>
            <a
              href={assess?.url}
              className={'button--blue button--blue--download'}
              download
            >
              DOWNLOAD QUESTION PAPER
            </a>
          </div>
        ) : null}
        <form className="assessment__form">
          <br />
          {isLoading ? (
            <Loader />
          ) : assessmentQuestions.filter((question) => {
              if (question.assessment_id === assess._id) return question
            })?.length ? (
            assessmentQuestions
              .filter((question) => {
                if (question.assessment_id === assess._id) return question
              })
              .map((question, index) => {
                return createQuestion(question, index)
              })
          ) : assess?.url ? (
            assess?.url !== 'noFiles' ? (
              'Question has been uploaded as a file, download to view it'
            ) : (
              'No Questions have been uploaded.'
            )
          ) : (
            'Assessment Questions not uploaded. Please add the questions'
          )}
        </form>
        <div className="text-center">
          <button
            onClick={() => history.push('/create/assessment')}
            className="button--blue"
            type="submit"
          >
            GO BACK
          </button>
        </div>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default AssessmentQuestion
