import React from 'react'

const ReplyCard = (props) => {
  return (
    <div className="replyCard">
      <div className="replyCard__line"></div>
      <div className="replyCard__wrapper">
        <div className="replyCard__profile">
          <img src={props.picture} alt="profile" />
          <p className="doubtsCard__PersonName">{props.name}</p>
        </div>
        <div className="replyCard__timeWrap">
          <p className="replyCard__time">{props.time}</p>
        </div>

        <div className="replyCard__content">
          <p>{props.content}</p>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  )
}

export default ReplyCard
