import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import _ from 'underscore'

//assets
import LeftRightLayout from '../../assets/layout/leftrightlayout'
import Col75Right from '../../assets/layout/leftrightlayout/column75Right'
import Col25Left from '../../assets/layout/leftrightlayout/column25Left'
import Select from '../../assets/molecules/select'
import StudentsCard from '../../assets/molecules/studentCardWithMarks'
import ProfilePic from '../../../assets/images/profile.png'
import Students from '../../../assets/images/students.png'
import CheckedResponses from '../../../assets/images/checkedResponses.png'
import NoSelfAssessment from '../../../assets/images/noSelfAssessment.png'
import PopupConfirm from '../../assets/layout/popupConfirm'
import Accordian from '../../assets/layout/accordion'
import Items from '../../assets/molecules/listAccordian'
import { AlertError } from '../../assets/molecules/alerts'

//actions
import { fetchBatchSubjectDataById } from '../../../redux/action/subject'

//reducer
import { getJwt } from '../../../redux/reducer/account'
import {
  getStudentsOfBatchSubject,
  getSubjects,
} from '../../../redux/reducer/subject'
import {
  getBatchSubjectId,
  getAssessmentTitle,
  getAssessmentId,
} from '../../../redux/reducer/ui'
import {
  fetchAssessmentSubmissionByAIdAndStuId,
  postAssessmentEvaluate,
  fetchAssessment,
  startEditAssessmentStatus,
} from '../../../redux/action/assessment'
import {
  getAssessmentSubmissions,
  getAssessment,
  getIsAssessmentEvaluateLoading,
  getIsError,
  getIsAssessmentSubmissionLoading,
  getIsAssessmentLoading,
} from '../../../redux/reducer/assessment'

import { usePrevious } from '../../helpers'
import Loader from '../../assets/molecules/loader'

const AssessmentSubmission = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const jwt = useSelector(getJwt)
  const batchSubjectId = useSelector(getBatchSubjectId)
  let studentsFromStore = useSelector(getStudentsOfBatchSubject)

  const assessmentTitle = useSelector(getAssessmentTitle)
  const assessmentId = useSelector(getAssessmentId)
  const assessment = useSelector(getAssessment)

  const isAssessmentSubmissionLoading = useSelector(
    getIsAssessmentSubmissionLoading
  )
  const isAssessmentEvaluateLoading = useSelector(
    getIsAssessmentEvaluateLoading
  )
  const isAssessmentEvaluateLoadingPrev = usePrevious(
    isAssessmentEvaluateLoading
  )
  const error = useSelector(getIsError)

  const subjects = useSelector(getSubjects)
  let assessmentSubmissions = useSelector(getAssessmentSubmissions)
  const isAssessmentLoading = useSelector(getIsAssessmentLoading)
  const isAssessmentLoadingPrev = usePrevious(isAssessmentLoading)

  const [showSuccess, setShowSuccess] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [serachValue, setSearchValue] = useState('')
  const [students, setStudents] = useState([])
  const [filter, setFilter] = useState('all')
  const [selectedStudent, setSelectedStudent] = useState({
    id: students[0]?.id,
    name: students[0]?.name,
  })

  useEffect(() => {
    const id = batchSubjectId
    dispatch(fetchBatchSubjectDataById({ jwt, id }))
    dispatch(fetchAssessment({ jwt, id: assessmentId }))
  }, [assessmentId, batchSubjectId, dispatch, jwt])

  useEffect(() => {
    let obj = {}
    assessmentSubmissions.forEach((submission) => {
      obj = {
        ...obj,
        [submission.question_id]: submission.scored_point || 0,
      }
    })
    setMarks(obj)
  }, [assessmentSubmissions])

  useEffect(() => {
    if (
      isAssessmentEvaluateLoadingPrev !== undefined &&
      isAssessmentEvaluateLoadingPrev !== isAssessmentEvaluateLoading &&
      !isAssessmentEvaluateLoading &&
      !error
    ) {
      dispatch(
        fetchAssessmentSubmissionByAIdAndStuId({
          jwt,
          assessmentId,
          studentId: selectedStudent?.id,
        })
      )
      dispatch(fetchAssessment({ jwt, id: assessmentId }))
    }
  }, [
    assessmentId,
    dispatch,
    error,
    isAssessmentEvaluateLoading,
    isAssessmentEvaluateLoadingPrev,
    jwt,
    selectedStudent,
  ])

  useEffect(() => {
    setFormData({})
    setErrorMsg('')
    dispatch(
      fetchAssessmentSubmissionByAIdAndStuId({
        jwt,
        assessmentId,
        studentId: selectedStudent?.id,
      })
    )
  }, [selectedStudent]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (studentsFromStore) {
      const studentsList = _.sortBy(studentsFromStore, 'name')
      setSelectedStudent(studentsList[0])
      setStudents(studentsList)
    }
  }, [studentsFromStore])

  useEffect(() => {
    if (
      isAssessmentLoadingPrev !== undefined &&
      !isAssessmentLoading &&
      !error
    ) {
      setShowSuccess(true)
    }
  }, [error, isAssessmentLoading, isAssessmentLoadingPrev])

  const filteredCurrentBatch = subjects?.filter(
    (subject) => subject._id === batchSubjectId
  )
  const currentBatch = filteredCurrentBatch.length
    ? filteredCurrentBatch[0]
    : {}

  const [formData, setFormData] = useState({})
  const [errorMsg, setErrorMsg] = useState('')
  const onchangeHandler = (e, submittedId) => {
    console.log(submittedId, 'submittedId')
    const name = e.target.name.split('-')[0]
    let value = e.target.value
    value = value === 'true' || value === true || false
    setFormData({
      ...formData,
      [submittedId]: { ...formData[submittedId], [name]: value },
    })
    errorMsg && setErrorMsg('')
  }

  const [marks, setMarks] = useState({})
  const onChangeMark = (e, question_id, weight) => {
    if (
      e.target.value === '' ||
      parseFloat(e.target.value) <= parseFloat(weight)
    ) {
      setMarks({ ...marks, [question_id]: e.target.value })
    }
  }

  const [popClassName, setPopClassName] = useState('hidePop')
  const [evaluationPopClassName, setEvaluationPopClassName] =
    useState('hidePop')
  const [confirmPopClassName, setConfirmPopClassName] = useState('hidePop')
  const openPopup = (e) => {
    e.preventDefault()
    setPopClassName('showPop')
  }
  const closePopup = (e) => {
    e.preventDefault()
    setPopClassName('hidePop')
  }

  const openMarksPopup = (e) => {
    e.preventDefault()
    setConfirm(false)
    setShowSuccess(false)
    setEvaluationPopClassName('showPop')
  }
  const closeMarksPopup = (e) => {
    e.preventDefault()
    setEvaluationPopClassName('hidePop')
  }

  const openConfirmPopup = (e) => {
    e.preventDefault()
    setConfirmPopClassName('showPop')
  }
  const closeConfirmPopup = (e) => {
    e.preventDefault()
    setConfirmPopClassName('hidePop')
  }

  const createQuestion = (submission, i) => {
    switch (submission.question_type) {
      case 'SINGLE_CHOICE':
      case 'MULTIPLE_CHOICE':
      case 'BOOLEAN':
        return (
          <div className="question__wrapper">
            <div className="question__wrap">
              <p className="assessment__question">{`${i + 1})`}</p>
              <p
                className="assessment__question"
                dangerouslySetInnerHTML={{ __html: submission.question_title }}
              ></p>
              <div className="assessment__marks">
                <span className="full">&bull;</span>
                <p>{`${submission.scored_point} out ${submission.weightage} obtained`}</p>
              </div>
              <div className="clear"></div>
            </div>
            {/* <ul className="radio__wrapper">
              {question?.answers?.map((answer, index) => (
                <li>
                  <input
                    type={
                      question.type === 'MULTIPLE_CHOICE' ? 'checkbox' : 'radio'
                    }
                    className="radio--btn"
                    name={question._id}
                    id={answer._id}
                    value={answer._id}
                    onChange={(e) => onchangeHandler(e, question, answer)}
                  />
                  <label for={answer._id} className="radio--label">
                    {answer.title}
                  </label>
                </li>
              ))}
            </ul> */}
            <div className="answer__wrap">
              <div class="form-row">
                <div class="col-md-10">
                  {/* <textarea
                    class="form-control"
                    placeholder="Enter remarks (optional)"
                  ></textarea> */}
                </div>
                <div class="col-md-2">
                  <input
                    type="number"
                    name={`scored_point-${i + 1}`}
                    value={submission.scored_point}
                    class="form-control"
                    placeholder="Marks"
                  />
                </div>
              </div>
              <div className="clear"></div>
            </div>
            <div className="clear"></div>
          </div>
        )
      case 'TEXT':
        let radioCorrectProps = {}
        let radioWrongProps = {}
        if (submission.assessed) {
          radioCorrectProps = {
            ...radioCorrectProps,
            checked: submission.correct,
          }
          radioWrongProps = {
            ...radioWrongProps,
            checked: !submission.correct,
          }
        }
        return (
          <div className="question__wrapper">
            <div className="question__wrap">
              <p className="assessment__question">{`${i + 1})`}</p>
              <p
                className="assessment__question"
                dangerouslySetInnerHTML={{ __html: submission.question_title }}
              ></p>
              <div className="assessment__marks">
                {submission.assessed ? (
                  <span className="full">&bull;</span>
                ) : null}
                {submission.assessed ? (
                  <p>{`${submission.scored_point} out ${submission.weightage} obtained`}</p>
                ) : (
                  <p>{`Marks - ${submission.weightage}`}</p>
                )}
              </div>
              <div className="clear"></div>
            </div>
            <div className="answer__wrap">
              <h6>{submission.submitted_answer_text}</h6>
            </div>
            <div className="answer__wrap">
              <div class="form-row">
                <div class="col-md-10">
                  {/* <textarea
                    class="form-control"
                    placeholder="Enter remarks (optional)"
                  ></textarea> */}
                  <ul className="radio__wrapper">
                    <li>
                      <input
                        type="radio"
                        className="radio--btn"
                        name={`correct-${i + 1}`}
                        id={submission._id + 1}
                        value={true}
                        onChange={(e) =>
                          submission?.assessed
                            ? null
                            : onchangeHandler(e, submission._id)
                        }
                        checked={
                          submission?.assessed
                            ? submission.correct
                            : formData[submission._id]?.correct
                        }
                      />
                      <label
                        htmlFor={submission._id + 1}
                        className="radio--label"
                      >
                        Correct
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        className="radio--btn"
                        name={`correct-${i + 1}`}
                        id={submission._id + 2}
                        value={false}
                        onChange={(e) =>
                          submission?.assessed
                            ? null
                            : onchangeHandler(e, submission._id)
                        }
                        checked={
                          submission?.assessed
                            ? !submission.correct
                            : !formData[submission._id]?.correct
                        }
                      />
                      <label
                        htmlFor={submission._id + 2}
                        className="radio--label"
                      >
                        Wrong
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="col-md-2">
                  <input
                    type="text"
                    name={`scored_point-${i + 1}`}
                    value={marks[submission.question_id]}
                    className="form-control"
                    placeholder="Marks"
                    onChange={(e) =>
                      submission?.assessed
                        ? null
                        : onChangeMark(
                            e,
                            submission.question_id,
                            submission.weightage
                          )
                    }
                  />
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        )
      case 'UPLOAD':
        let questiontitle = ''
        if (
          submission.question_title?.title?.match(/\.[0-9a-z]+$/i)?.[0] &&
          submission.question_title?.title?.match(/\.[0-9a-z]+$/i)?.[0] ===
            '.pdf'
        ) {
          questiontitle = (
            <iframe height="1200" width="90%" src={submission.question_title} />
          )
        } else {
          questiontitle = (
            <p
              className="assessment__question"
              dangerouslySetInnerHTML={{
                __html: submission.question_title.title,
              }}
            ></p>
          )
        }
        return (
          <div className="question__wrapper">
            <div className="question__wrap">
              <p className="assessment__question">{`${i + 1})`}</p>
              {questiontitle}
              <div className="assessment__marks">
                {submission.assessed ? (
                  <span className="full">&bull;</span>
                ) : null}
                {submission.assessed ? (
                  <p>{`${submission.scored_point} out ${submission.weightage} obtained`}</p>
                ) : (
                  <p>{`Marks - ${submission.weightage}`}</p>
                )}
              </div>
              <div className="clear"></div>
            </div>
            <div className="answer__wrap">
              {submission?.submitted_answer_ids?.map((sheet, index) => {
                return (
                  <div>
                    <a href={sheet}>Download Sheet {index + 1}</a>
                  </div>
                )
              })}
            </div>
            <div className="answer__wrap">
              <div class="form-row">
                <div class="col-md-10">
                  {/* <textarea
                    class="form-control"
                    placeholder="Enter remarks (optional)"
                  ></textarea> */}
                  <ul className="radio__wrapper">
                    <li>
                      <input
                        type="radio"
                        className="radio--btn"
                        name={`correct-${i + 1}`}
                        id={submission._id + 1}
                        value={true}
                        onChange={(e) =>
                          submission?.assessed
                            ? null
                            : onchangeHandler(e, submission._id)
                        }
                        checked={
                          submission?.assessed
                            ? submission.correct
                            : formData[submission._id]?.correct
                        }
                      />
                      <label
                        htmlFor={submission._id + 1}
                        className="radio--label"
                      >
                        Correct
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        className="radio--btn"
                        name={`correct-${i + 1}`}
                        id={submission._id + 2}
                        value={false}
                        onChange={(e) =>
                          submission?.assessed
                            ? null
                            : onchangeHandler(e, submission._id)
                        }
                        checked={
                          submission?.assessed
                            ? !submission.correct
                            : !formData[submission._id]?.correct
                        }
                      />
                      <label
                        htmlFor={submission._id + 2}
                        className="radio--label"
                      >
                        Wrong
                      </label>
                    </li>
                  </ul>
                </div>
                <div class="col-md-2">
                  <input
                    type="text"
                    name={`scored_point-${i + 1}`}
                    value={marks[submission.question_id]}
                    className="form-control"
                    placeholder="Marks"
                    onChange={(e) =>
                      submission?.assessed
                        ? null
                        : onChangeMark(
                            e,
                            submission.question_id,
                            submission.weightage
                          )
                    }
                  />
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        )
      default:
        return <div />
    }
  }

  const onSubmitHandler = (e) => {
    e.preventDefault()
    const answersHasToEvaluate = assessmentSubmissions?.filter(
      (data) => data.assessed !== true
    )
    let payload = {
      assessment_id: assessmentId,
      batch_subject_id: batchSubjectId,
      submissions: [],
    }
    if (Object.keys(formData).length === answersHasToEvaluate.length) {
      assessmentSubmissions.forEach((submitted) => {
        payload.submissions.push({
          submission_id: submitted._id,
          scored_point: Number(marks[submitted.question_id]) || 0,
          correct: formData[submitted._id]?.correct || submitted?.correct,
        })
      })
      closePopup(e)
      setErrorMsg('')
      dispatch(postAssessmentEvaluate({ jwt, payload }))
    } else {
      setErrorMsg('Please evaluate all answers')
    }
  }

  const onChangeSearch = (e) => {
    const data = e.target.value.toLowerCase()
    setSearchValue(data)
    const filteredStudents = studentsFromStore.filter((student) => {
      const studentName = student.name.toLowerCase()
      return studentName.includes(data)
    })
    setSelectedStudent(filteredStudents[0])
    setStudents(_.sortBy(filteredStudents, 'name'))
  }

  const noExaminationAvailable = () => {
    return (
      <div className="noPending">
        <div>
          <img
            src={NoSelfAssessment}
            alt="This student did not submit assessment"
          />
          <h2 className="text-center">Not Yet Submitted.</h2>
          <p>This student did not submit assessment. </p>
        </div>
      </div>
    )
  }

  const onEvaluation = () => {
    const payload = {
      status: 'EVOLUTED',
      force: true,
    }
    dispatch(startEditAssessmentStatus({ jwt, assessmentId, payload }))
    setConfirm(true)
    setConfirmPopClassName('showPop')
    setEvaluationPopClassName('hidePop')
  }

  const onMainPage = () => {
    history.push('/faculty/assessments')
  }

  return (
    <div className="body__container">
      <section>
        <h1>Evaluation</h1>
        <section>
          <nav className="padding--leftRight">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/faculty/assessments">Evaluation</Link>
              </li>
              <li className="breadcrumb-item active">{assessmentTitle}</li>
            </ol>
          </nav>
          <div className="clear"></div>
        </section>
        <div className="row">
          <div className="col-md-6 padding--0">
            {assessment?.assessment?.url ? (
              <div>
                <a
                  href={assessment?.assessment?.url}
                  className={
                    'button--blue button--blue--download button--margin'
                  }
                  download
                >
                  DOWNLOAD QUESTION PAPER
                </a>
              </div>
            ) : null}
          </div>
          <div className="col-md-6 breadcrumb__rightSide padding--0">
            <div className="customTab__dropdowns">
              <Select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="all">All responses</option>
                <option value="evaluated">Evaluated responses</option>
                <option value="notEvaluated">Not Evaluated responses</option>
              </Select>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </section>
      <section>
        <div className="card-deck">
          <div className="card">
            <div className="displayBoard">
              <div className="displayBoard__img">
                <img src={Students} alt="Total Students" />
              </div>
              <div className="displayBoard__content">
                <h5>Total Students</h5>
                <h2>{assessment?.student_count}</h2>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          <div className="card">
            <div className="displayBoard">
              <div className="displayBoard__img">
                <img src={CheckedResponses} alt="Checked Responses" />
              </div>
              <div className="displayBoard__content">
                <h5>Checked Responses</h5>
                <h2>{assessment?.submissions_evoluted}</h2>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          <div className="card">
            <div className="displayBoard">
              <div className="displayBoard__img">
                <img src={CheckedResponses} alt="Responses Received" />
              </div>
              <div className="displayBoard__content">
                <h5>Responses Received</h5>
                <h2>{assessment?.submissions_received}</h2>
              </div>
              <div className="clear"></div>
            </div>
          </div>
          {/* <div className="card">
            <div className="displayBoard">
              <div className="displayBoard__img">
                <img src={CheckedResponses} alt="Checked Responses" />
              </div>
              <div className="displayBoard__content">
                <h5>Average Score</h5>
                <h2>2 / 10</h2>
              </div>
              <div className="clear"></div>
            </div>
          </div> */}
        </div>
      </section>
      {assessment?.assessment?.status === 'CLOSED' ? (
        <section>
          <div className="row">
            <div className="col-md-6 padding--0"></div>
            <div className="col-md-6 padding--0">
              <button
                className="button--blue float-right button--margin"
                onClick={(e) => openMarksPopup(e)}
              >
                Marks Evaluated
              </button>
            </div>
            <div className="clear"></div>
          </div>
        </section>
      ) : null}

      <section>
        <LeftRightLayout className="desktop">
          <Col25Left className="padding--0">
            <h2 className="subjects--head">Submitted</h2>
            <div className="search__container">
              <input
                type="text"
                className="search"
                value={serachValue}
                onChange={onChangeSearch}
                placeholder="Search"
              />
            </div>
            {students?.length ? (
              students.map((student) => {
                return (
                  <StudentsCard
                    onClick={() =>
                      setSelectedStudent({
                        id: student.id,
                        name: student.name,
                      })
                    }
                    className={
                      selectedStudent.id === student.id ? 'active' : ''
                    }
                    picture={ProfilePic}
                    candidateName={student.name}
                    details={currentBatch?.batch_title}
                    // evaluated="true"
                    // score="4/10(HC)"
                  />
                )
              })
            ) : (
              <p className="padd--25">No Result Found</p>
            )}
          </Col25Left>
          <Col75Right>
            <h2>{selectedStudent?.name || '---'}</h2>
            {isAssessmentSubmissionLoading ? (
              <Loader />
            ) : (
              <form className="assessment__form assessment__form--evaluation">
                {assessmentSubmissions.length
                  ? assessmentSubmissions
                      .filter((sub) => {
                        if (filter === 'all' || filter === '') {
                          return true
                        } else if (filter === 'evaluated') {
                          return sub?.assessed
                        } else if (filter === 'notEvaluated') {
                          return !sub?.assessed
                        }
                      })
                      .map((submission, index) => {
                        const admin_submited_list =
                          submission?.submitted_answer_ids?.filter(
                            (ans_id) => ans_id === 'admin submit'
                          )
                        if (admin_submited_list.length > 0) {
                          return <div>IS NOT ELIGIBLE FOR THIS ASSESSMENT</div>
                        }
                        return createQuestion(submission, index)
                      })
                  : noExaminationAvailable()}
                {assessmentSubmissions?.length ? (
                  <div>
                    {assessmentSubmissions?.filter(
                      (item) => item.assessed && item.question_type === 'TEXT'
                    )?.length ||
                    assessmentSubmissions?.length ===
                      assessmentSubmissions?.filter(
                        (item) => item.assessed && item.question_type !== 'TEXT'
                      )?.length ? (
                      <div className="scoreBoard">
                        <div className="row">
                          <div className="col">
                            <h3>Questions evaluated</h3>
                            <h3>{`${
                              assessmentSubmissions?.filter(
                                (submission) => submission?.assessed
                              ).length
                            } / ${assessmentSubmissions?.length}`}</h3>
                          </div>
                          <div className="col">
                            <h3 className="text-right">Total Score</h3>
                            <h3 className="text-right">{`${assessmentSubmissions?.reduce(
                              (total, submission) =>
                                total + submission?.scored_point,
                              0
                            )} / ${assessmentSubmissions?.reduce(
                              (total, submission) =>
                                total + submission?.weightage,
                              0
                            )}`}</h3>
                          </div>
                        </div>
                        <div className="clear"></div>
                      </div>
                    ) : null}
                    {assessmentSubmissions.filter((item) => !item?.assessed)
                      ?.length ? (
                      <div className="text-center">
                        {isAssessmentEvaluateLoading ? (
                          <Loader />
                        ) : (
                          <button
                            onClick={(e) => openPopup(e)}
                            className="button--blue"
                            type="submit"
                          >
                            SUBMIT EVALUATION
                          </button>
                        )}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </form>
            )}
          </Col75Right>
        </LeftRightLayout>
        <LeftRightLayout className="mob">
          <Col25Left className="padding--0">
            <h2 className="subjects--head">Submitted</h2>
            <div className="search__container">
              <input
                type="text"
                className="search"
                value={serachValue}
                onChange={onChangeSearch}
                placeholder="Search"
              />
            </div>
            <Accordian>
              {students?.length ? (
                students.map((student, index) => {
                  return (
                    <Items
                      key={index}
                      itemNo={index}
                      onClick={() =>
                        setSelectedStudent({
                          id: student.id,
                          name: student.name,
                        })
                      }
                      className={
                        selectedStudent.id === student.id ? 'active' : ''
                      }
                      picture={ProfilePic}
                      candidateName={student.name}
                      details={currentBatch?.batch_title}
                      // evaluated="true"
                      // score="4/10(HC)"
                    >
                      <h2>{selectedStudent?.name || '---'}</h2>
                      {isAssessmentSubmissionLoading ? (
                        <Loader />
                      ) : (
                        <form className="assessment__form assessment__form--evaluation">
                          {assessmentSubmissions.length
                            ? assessmentSubmissions
                                .filter((sub) => {
                                  if (filter === 'all' || filter === '') {
                                    return true
                                  } else if (filter === 'evaluated') {
                                    return sub?.assessed
                                  } else if (filter === 'notEvaluated') {
                                    return !sub?.assessed
                                  }
                                })
                                .map((submission, index) => {
                                  return createQuestion(submission, index)
                                })
                            : noExaminationAvailable()}
                          {assessmentSubmissions?.length ? (
                            <div>
                              {assessmentSubmissions?.filter(
                                (item) =>
                                  item.assessed && item.question_type === 'TEXT'
                              )?.length ||
                              assessmentSubmissions?.length ===
                                assessmentSubmissions?.filter(
                                  (item) =>
                                    item.assessed &&
                                    item.question_type !== 'TEXT'
                                )?.length ? (
                                <div className="scoreBoard">
                                  <div className="row">
                                    <div className="col">
                                      <h3>Questions evaluated</h3>
                                      <h3>{`${
                                        assessmentSubmissions?.filter(
                                          (submission) => submission?.assessed
                                        ).length
                                      } / ${
                                        assessmentSubmissions?.length
                                      }`}</h3>
                                    </div>
                                    <div className="col">
                                      <h3 className="text-right">
                                        Total Score
                                      </h3>
                                      <h3 className="text-right">{`${assessmentSubmissions?.reduce(
                                        (total, submission) =>
                                          total + submission?.scored_point,
                                        0
                                      )} / ${assessmentSubmissions?.reduce(
                                        (total, submission) =>
                                          total + submission?.weightage,
                                        0
                                      )}`}</h3>
                                    </div>
                                  </div>
                                  <div className="clear"></div>
                                </div>
                              ) : null}
                              {assessmentSubmissions.filter(
                                (item) => !item?.assessed
                              )?.length ? (
                                <div className="text-center">
                                  {isAssessmentEvaluateLoading ? (
                                    <Loader />
                                  ) : (
                                    <button
                                      onClick={(e) => openPopup(e)}
                                      className="button--blue"
                                      type="submit"
                                    >
                                      SUBMIT EVALUATION
                                    </button>
                                  )}
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </form>
                      )}
                    </Items>
                  )
                })
              ) : (
                <p className="padd--25">No Result Found</p>
              )}
            </Accordian>
          </Col25Left>
        </LeftRightLayout>
        <PopupConfirm
          className={popClassName}
          close={(e) => closePopup(e)}
          onClick={(e) => {
            onSubmitHandler(e)
          }}
          buttonText="YES"
          cancelButtonText="CHECK AGAIN"
          popupHead="SUBMIT EVALUATION"
        >
          <h2>Are you sure you want to submit?</h2>
          <p>Please check again before submitting.</p>
          {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
        </PopupConfirm>
      </section>
      <PopupConfirm
        className={evaluationPopClassName}
        close={(e) => closeMarksPopup(e)}
        onClick={(e) => {
          onEvaluation(e)
        }}
        buttonText="Confirm"
        cancelButtonText="Cancel"
        popupHead="Marks Evaluated"
      >
        <h3>Responses Checked - {assessment?.submissions_evoluted}</h3>
        <h3>Responses Received - {assessment?.submissions_received}</h3>
      </PopupConfirm>
      {isAssessmentLoading ? (
        <Loader />
      ) : (
        error && <AlertError>Failed. Please try again.</AlertError>
      )}
      {showSuccess && confirm ? (
        <PopupConfirm
          className={confirmPopClassName}
          close={(e) => closeConfirmPopup(e)}
          onClick={() => onMainPage()}
          buttonText="Go to Evaluation Page"
          cancelButtonText="Cancel"
          popupHead="Confirm"
        >
          <h3>You have successfully evaluated the marks.</h3>
        </PopupConfirm>
      ) : null}
    </div>
  )
}

export default AssessmentSubmission
