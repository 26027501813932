import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import CustomTab from '../../assets/molecules/customTab'
import EvaluationCard from '../../assets/molecules/evalutionCard'
import NoEvaluations from '../../../assets/images/noEvaluations.svg'

//action
import {
  fetchAssessments,
  unsetAssessmentSubmissions,
} from '../../../redux/action/assessment'
import { setAssessment, setBatchSubjectId } from '../../../redux/action/ui'
import { fetchUserSubjects } from '../../../redux/action/subject'
import { fetchBatches } from '../../../redux/action/batches'

//reducer
import { getJwt } from '../../../redux/reducer/account'
import { getAssessmentsGroupByBatchSubjectId } from '../../../redux/reducer/assessment'
import { isSubjectsloading, getSubjects } from '../../../redux/reducer/subject'
import { getBatches, getIsLoading } from '../../../redux/reducer/batches'

//helpers
import { usePrevious } from '../../helpers'
import Loader from '../../assets/molecules/loader'

const MyAssessment = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  //state
  const [selectedSubject, setSelectedSubject] = useState('')
  const [selectedBatch, setSelectedBatch] = useState('')
  const [selectedTab, setSelectedTab] = useState('Pending')
  const [teacherBatches, setTeacherBatches] = useState([])
  const [teacherSubjects, setTeacherSubjects] = useState([])
  const [pendingAsseeements, setpendingAsseeements] = useState([])
  const [evaluatedAsseeements, setevaluatedAsseeements] = useState([])
  const [localLoading, setlocalLoading] = useState(true)

  //selector
  const jwt = useSelector(getJwt)
  const assessmentsGroupByBatchSubjectId = useSelector(
    getAssessmentsGroupByBatchSubjectId
  )
  const isLoadingSubjects = useSelector(isSubjectsloading)
  const isLoadingSubjectsPrev = usePrevious(isLoadingSubjects)
  const subjects = useSelector(getSubjects)
  const batches = useSelector(getBatches)
  const isLoadingBatches = useSelector(getIsLoading)
  const isLoadingBatchesPrev = usePrevious(isLoadingBatches)

  useEffect(() => {
    dispatch(fetchUserSubjects({ jwt }))
    dispatch(fetchBatches({ jwt }))
    dispatch(fetchAssessments({ jwt }))
    dispatch(unsetAssessmentSubmissions())
  }, [dispatch, jwt])

  useEffect(() => {
    if (!isLoadingSubjects && isLoadingSubjectsPrev !== undefined) {
      setSelectedSubject(subjects[0])
    }
  }, [isLoadingSubjects]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoadingBatches && isLoadingBatchesPrev !== undefined) {
      const batchIds = subjects?.map((sub) => sub?.batch_id)
      const filteredBatches = batches?.filter((batch) =>
        batchIds?.includes(batch?._id)
      )
      setTeacherBatches(filteredBatches)
      setSelectedBatch(filteredBatches[0])
    }
  }, [isLoadingBatches]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedBatch) {
      const filteredSubjects = subjects?.filter(
        (sub) => sub?.batch_id === selectedBatch?._id
      )
      setTeacherSubjects(filteredSubjects)
      setSelectedSubject(filteredSubjects[0])
    }
  }, [selectedBatch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedSubject) {
      setpendingAsseeements(
        assessmentsGroupByBatchSubjectId[selectedSubject?._id]?.filter(
          (item) =>
            item?.status !== 'EVOLUTED' &&
            item?.status !== 'CANCELED' &&
            item?.status !== 'LIVE' &&
            (item?.status === 'CLOSED' || item?.status === 'DRAFTED')
        )
      )
      setevaluatedAsseeements(
        assessmentsGroupByBatchSubjectId[selectedSubject?._id]?.filter(
          (item) => item?.status === 'EVOLUTED'
        )
      )
    }
    setlocalLoading(false)
  }, [selectedSubject]) // eslint-disable-line react-hooks/exhaustive-deps

  const onAssessmentSelect = (assessment) => {
    dispatch(setAssessment(assessment._id, assessment.title))
    dispatch(setBatchSubjectId(assessment.batch_subject_id))
    history.push(`/faculty/assessments/submissions`)
  }

  const noEval = (evalTab) => {
    return (
      <div className="noPending">
        <div>
          <img src={NoEvaluations} alt="" />
          <h2 className="text-center">
            {evalTab === 'pending'
              ? 'No pending examinations currently.'
              : 'No evaluated examinations currently.'}
          </h2>
        </div>
      </div>
    )
  }

  // const batchSubjectsId = Object.keys(assessmentsGroupByBatchSubject)

  return (
    <div className="body__container">
      <section>
        <h1>Evaluation</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Evaluation</li>
          </ol>
        </nav>
      </section>
      {localLoading ? (
        <Loader />
      ) : (
        <section>
          <section className="tabs__wrapper">
            <CustomTab
              selectedTab={selectedTab}
              tabItems={{
                Pending: pendingAsseeements ? pendingAsseeements?.length : 0,
                Evaluated: evaluatedAsseeements
                  ? evaluatedAsseeements?.length
                  : 0,
              }}
              onClickTab={(item) => setSelectedTab(item)}
              subjects={teacherSubjects}
              batches={teacherBatches}
              onChangeSubjects={(selected) => {
                setSelectedSubject(selected)
              }}
              onChangeBatches={(selected) => {
                setSelectedBatch(selected)
              }}
            />
          </section>
          <div className="evalution__gridCointainer">
            {selectedTab === 'Pending'
              ? pendingAsseeements && pendingAsseeements?.length > 0
                ? pendingAsseeements?.map((item, index) => {
                    return (
                      <EvaluationCard
                        key={index}
                        onClick={() => onAssessmentSelect(item)}
                        heading={item?.title}
                        subject={selectedSubject?.subject_title}
                        class={selectedBatch?.title}
                        evalutedResponse={`${item.submissions_evoluted}/${item.submissions_received}`}
                        currentResponse={`${item.submissions_received}/${
                          selectedBatch?.students?.length || 0
                        }`}
                        buttonColor={'blue'}
                        button={'EVALUATE NOW'}
                      />
                    )
                  })
                : noEval('pending')
              : evaluatedAsseeements && evaluatedAsseeements?.length
              ? evaluatedAsseeements?.map((item, index) => {
                  return (
                    <EvaluationCard
                      key={index}
                      onClick={() => onAssessmentSelect(item)}
                      heading={item?.title}
                      subject={selectedSubject?.subject_title}
                      class={selectedBatch?.title}
                      evalutedResponse={`${item.submissions_evoluted}/${item.submissions_received}`}
                      currentResponse={`${item.submissions_received}/${
                        selectedBatch?.students?.length || 0
                      }`}
                      buttonColor={'blue'}
                      button={'VIEW RESPONSES'}
                    />
                  )
                })
              : noEval('evaluated')}
          </div>
        </section>
      )}
    </div>
  )
}

export default MyAssessment
