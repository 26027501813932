import React from 'react'

const PopupVideo = (props) => {
  return (
    <div className={'popup__container popupVideo ' + props.className}>
      <div className="popup__box">
        <div className="popup__wrap">
          <span onClick={props.close} className="close__popup">
            x
          </span>
          <div className="popup__content">
            {props.children}
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  )
}

export default PopupVideo
