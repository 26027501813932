import React, { useState } from 'react'

const NotesCard = (props) => {
  const [optionState, setoptionState] = useState(true)

  const onToggleOptions = () => {
    setoptionState(!optionState)
  }
  return (
    <div className="notesCard">
      <div className="notesCard__content">
        <p>{props.content}</p>
      </div>
      <div
        onClick={() => onToggleOptions()}
        className="notesCard__dotsContainer"
      >
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <ul className={'options__container ' + (optionState ? '' : 'collapse')}>
          <li
            className="edit"
            onClick={() => {
              onToggleOptions()
              props.editOnClick()
            }}
          >
            Edit
          </li>
          <li
            className="delete"
            onClick={() => {
              onToggleOptions()
              props.deleteOnClick()
            }}
          >
            Delete
          </li>
        </ul>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  )
}

export default NotesCard
