import React from 'react'

const ProfileCard = (props) => {
  return (
    <div onClick={props.onClick} className={'card--click ' + props.className}>
      <div className={'col col--' + props.columnNo}>
        <div
          className={
            'card profile__card__wrapper background--color' + props.color
          }
        >
          <div className="img--card">
            <img src={props.img} alt="CWX" />
            <div className="clear"></div>
          </div>
          {props.children}
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  )
}

export default ProfileCard
