import React from 'react'

const Accordion = (props) => {
  return (
    <div className="accordion">
      {props.children}
      <input type="radio" name="accordion" id="acc-close" />
    </div>
  )
}

export default Accordion
