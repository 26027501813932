import React from 'react'

const SelfAssesmentCard = (props) => {
  return (
    <div onClick={props.onClick} className="selfAssesmentCard row">
      <div className="col-xl-3  col-lg-3  col-md-12 col-sm-12 selfAssesmentCard__img">
        <img src={props.img} alt="CWX" />
        <div className="clear"></div>
      </div>
      <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12 selfAssesmentCard__content">
        <div>
          <h3>{props.heading}</h3>
          <div className="selfAssesmentCard__details">
            <h5>
              <span>{props.subject} </span>
              {/* <span className="pipe">|</span> {props.chapter} */}
            </h5>
            {/* <p>
              Attempts: <span>{props.attempts}</span>
            </p> */}
            <p>
              Number of questions: <span>{props.noOfQuestions} </span>
              {/* <span className="pipe">|</span> Ideal Time to Complete:{' '}
              <span>{props.timeToComplete}</span> */}
            </p>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 display__flex selfAssesmentCard__wrap3">
        <div>
          <p className="selfAssesmentCard__score">
            Last score: <span>{props.score}</span>
          </p>
          <button
            onClick={props.onClick}
            className={'button--' + props.buttonColor}
          >
            {props.button}
          </button>
          {/* <p className="selfAssesmentCard__teacher">Teacher: {props.teacher}</p> */}
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  )
}

export default SelfAssesmentCard
