import {
  APP_LOGOUT,
  GET_DAILY_REPORT_START,
  GET_DAILY_REPORT_SUCCESS,
  GET_DAILY_REPORT_FAILURE,
  GET_DAILY_ATTENDANCE_BY_BATCH_START,
  GET_DAILY_ATTENDANCE_BY_BATCH_SUCCESS,
  GET_DAILY_ATTENDANCE_BY_BATCH_FAILURE,
  GET_DAILY_ATTENDANCE_BY_SUBJECT_START,
  GET_DAILY_ATTENDANCE_BY_SUBJECT_SUCCESS,
  GET_DAILY_ATTENDANCE_BY_SUBJECT_FAILURE,
  GET_WEEKLY_ATTENDANCE_START,
  GET_WEEKLY_ATTENDANCE_SUCCESS,
  GET_WEEKLY_ATTENDANCE_FAILURE,
  GET_REPORT_BY_TOPICID_START,
  GET_REPORT_BY_TOPICID_SUCCESS,
  GET_REPORT_BY_TOPICID_FAILURE,
  GET_LIVE_CLASS_ATTENDANCE_START,
  GET_LIVE_CLASS_ATTENDANCE_SUCCESS,
  GET_LIVE_CLASS_ATTENDANCE_FAILURE,
} from '../actions'

let initialState = {
  error: false,
  isDailyReportLoading: false,
  isAttendanceByBatchLoading: false,
  isAttendanceBySubjectLoading: false,
  isAttendanceByWeekLoading: false,
  isAttendanceLiveClassLoading: false,
  isStudentByTopicIdLoading: false,
  dailyReport: [],
  attendanceByBatch: [],
  attendanceByWeek: [],
  attendanceLiveClass: [],
  StudentAttendanceBySubject: [],
  StudentByTopicId: [],
}

export const studentDailyReport = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState

    case GET_REPORT_BY_TOPICID_START:
      return {
        ...state,
        isStudentByTopicIdLoading: true,
        error: false,
      }
    case GET_REPORT_BY_TOPICID_SUCCESS:
      return {
        ...state,
        isStudentByTopicIdLoading: false,
        error: false,
        StudentByTopicId: action.result,
      }
    case GET_REPORT_BY_TOPICID_FAILURE:
      return {
        ...state,
        isStudentByTopicIdLoading: false,
        error: true,
      }
    case GET_DAILY_REPORT_START:
      return {
        ...state,
        isDailyReportLoading: true,
        error: false,
      }
    case GET_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        isDailyReportLoading: false,
        error: false,
        dailyReport: action.result,
      }
    case GET_DAILY_REPORT_FAILURE:
      return {
        ...state,
        isDailyReportLoading: false,
        error: true,
      }

    case GET_DAILY_ATTENDANCE_BY_BATCH_START:
      return {
        ...state,
        isAttendanceByBatchLoading: true,
        error: false,
      }
    case GET_DAILY_ATTENDANCE_BY_BATCH_SUCCESS:
      return {
        ...state,
        isAttendanceByBatchLoading: false,
        error: false,
        attendanceByBatch: action.result,
      }
    case GET_DAILY_ATTENDANCE_BY_BATCH_FAILURE:
      return {
        ...state,
        isAttendanceByBatchLoading: false,
        error: true,
      }

    case GET_DAILY_ATTENDANCE_BY_SUBJECT_START:
      return {
        ...state,
        isAttendanceBySubjectLoading: true,
        error: false,
      }
    case GET_DAILY_ATTENDANCE_BY_SUBJECT_SUCCESS:
      return {
        ...state,
        isAttendanceBySubjectLoading: false,
        error: false,
        StudentAttendanceBySubject: action.result,
      }
    case GET_DAILY_ATTENDANCE_BY_SUBJECT_FAILURE:
      return {
        ...state,
        isAttendanceBySubjectLoading: false,
        error: true,
      }

    case GET_WEEKLY_ATTENDANCE_START:
      return {
        ...state,
        isAttendanceByWeekLoading: true,
        error: false,
      }
    case GET_WEEKLY_ATTENDANCE_SUCCESS:
      return {
        ...state,
        isAttendanceByWeekLoading: false,
        error: false,
        attendanceByWeek: action.result,
      }
    case GET_WEEKLY_ATTENDANCE_FAILURE:
      return {
        ...state,
        isAttendanceByWeekLoading: false,
        error: true,
      }

    case GET_LIVE_CLASS_ATTENDANCE_START:
      return {
        ...state,
        isAttendanceLiveClassLoading: true,
        error: false,
      }
    case GET_LIVE_CLASS_ATTENDANCE_SUCCESS:
      return {
        ...state,
        isAttendanceLiveClassLoading: false,
        error: false,
        attendanceLiveClass: action.result,
      }
    case GET_LIVE_CLASS_ATTENDANCE_FAILURE:
      return {
        ...state,
        isAttendanceLiveClassLoading: false,
        error: true,
      }

    default:
      return state
  }
}

export const getIsStudentDailyReportLoading = (state) =>
  state.studentDailyReport.isDailyReportLoading
export const getIsAttendanceBySubjectLoading = (state) =>
  state.studentDailyReport.isAttendanceBySubjectLoading
export const getIsAttendanceByBatchLoading = (state) =>
  state.studentDailyReport.isAttendanceByBatchLoading
export const getIsAttendanceByWeekLoading = (state) =>
  state.studentDailyReport.isAttendanceByWeekLoading
export const getIsAttendanceLiveClassLoading = (state) =>
  state.studentDailyReport.isAttendanceLiveClassLoading

export const getStudentDailyReport = (state) =>
  state.studentDailyReport.dailyReport
export const getAttendanceBySubject = (state) =>
  state.studentDailyReport.StudentAttendanceBySubject
export const getAttendanceByBatch = (state) =>
  state.studentDailyReport.attendanceByBatch
export const getAttendanceByWeek = (state) =>
  state.studentDailyReport.attendanceByWeek
export const getAttendanceLiveClass = (state) =>
  state.studentDailyReport.attendanceLiveClass
export const getIsError = (state) => state.studentDailyReport.error
export const getIsStudentByTopicIdLoading = (state) =>
  state.studentDailyReport.isStudentByTopicIdLoading
export const getStudentByTopicId = (state) =>
  state.studentDailyReport.StudentByTopicId
