import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { usePrevious } from '../../helpers'

//assets
import Card from '../../assets/molecules/card'
import Button from '../../assets/molecules/button'

//actions
//subject
import { fetchEditTopic } from '../../../redux/action/topic'

//reducer
//account
import { getJwt, getUsername, getUserId } from '../../../redux/reducer/account'
import {
  getIsTopicsLoading,
  getIsError,
  getTopics,
} from '../../../redux/reducer/topic'
import {
  getSubjectTitle,
  getChapterId,
  getChapterTitle,
  getTopicId,
  getTopicTitle,
} from '../../../redux/reducer/ui'

import Loader from '../../assets/molecules/loader'

const EditTopic = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  //selector
  const jwt = useSelector((state) => getJwt(state))
  const error = useSelector((state) => getIsError(state))
  const isLoading = useSelector((state) => getIsTopicsLoading(state))
  const topics = useSelector((state) => getTopics(state))
  const chapterId = useSelector(getChapterId)
  const topicId = useSelector(getTopicId)
  const subjectTitle = useSelector(getSubjectTitle)
  const chapterTitle = useSelector(getChapterTitle)
  const topicTitle = useSelector(getTopicTitle)
  const userid = useSelector(getUserId)
  const username = useSelector(getUsername)

  const isLoadingPrev = usePrevious(isLoading)

  let [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (error) {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }, [error])

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isLoading &&
      !isLoading &&
      !error
    ) {
      history.push({
        pathname: '/me/faculty/subjects/chapters/topics',
        state: { status: 'success' },
      })
    }
  }, [error, history, isLoading, isLoadingPrev])

  const onChange = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (
      formData?.title &&
      formData?.type &&
      formData?.contentUrl &&
      formData?.description
    ) {
      const data = {
        title: formData.title,
        type: formData.type,
        content_uri: formData.contentUrl,
        wysiwyg_state: formData.description,
        chapter_id: chapterId,
        author_id: userid,
        author_name: username,
      }
      dispatch(fetchEditTopic({ jwt, data, topicId }))
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  const filteredListTopic = topics.filter((topic) => topic._id === topicId)

  const currentTopic = filteredListTopic.length > 0 ? filteredListTopic[0] : {}

  let [formData, setFormData] = useState({
    title: currentTopic.title,
    type: currentTopic.type,
    contentUrl: currentTopic.content_uri,
    description: currentTopic.wysiwyg_state,
  })

  const contentTypes = ['image', 'audio', 'video']

  return (
    <div className="tabs__container">
      <section>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/dashboard">{subjectTitle}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/me/faculty/subjects/chapters">{chapterTitle}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/me/faculty/subjects/chapters/topics">
                {topicTitle}
              </Link>
            </li>
            <li className="breadcrumb-item active">edit topic</li>
          </ol>
        </nav>
      </section>

      <section>
        <h4>Edit Topics</h4>
        <Card columnNo="1" title="Edit Topics" titleColor={1}>
          <input
            name="title"
            value={formData.title}
            type="text"
            placeholder="Title"
            onChange={onChange}
          />
          <select name="type" onChange={(e) => onChange(e)}>
            <option value={formData.type}>{formData.type}</option>
            {contentTypes.map((contentType, index) => {
              return (
                <option value={contentType} key={index}>
                  {contentType}
                </option>
              )
            })}
          </select>
          <input
            name="contentUrl"
            value={formData.contentUrl}
            type="text"
            placeholder="Content Url"
            onChange={onChange}
          />
          <textarea
            rows="18"
            name="description"
            value={formData.description}
            placeholder="Description"
            onChange={onChange}
          ></textarea>
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              className="button--small"
              color="blue"
              text="Save"
              click={(e) => onSubmit(e)}
            />
          )}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </Card>
      </section>
    </div>
  )
}

export default EditTopic
