import React from 'react'

const Col75 = (props) => {
  return (
    <div className={'col-lg-8 leftLayout ' + props.className}>
      {props.children}
      <div className="clear"></div>
    </div>
  )
}

export default Col75
