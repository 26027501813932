import React, { useState } from 'react'
import RightArrow from '../../../assets/images/rightArrow.png'

const ChaptersCardWithOutProgress = (props) => {
  const [optionState, setoptionState] = useState(true)

  const onToggleOptions = () => {
    setoptionState(!optionState)
  }

  return (
    <div
      onClick={props.onClick}
      className="chaptersCard chaptersCard--withOutProgress row"
    >
      <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 chaptersCard__img">
        <img src={props.img} alt="CWX" />
        <div className="clear"></div>
      </div>
      <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 margin--autoVertical">
        <div className="chaptersCard__description">
          <h5>
            {props.chapterName}{' '}
            <img className="chaptersCard__arrow" src={RightArrow} alt="cwx" />
          </h5>
          {props.description !== '' && props.description !== undefined && (
            <React.Fragment>
              <p>{props.description}</p>
              <div className="clear"></div>
            </React.Fragment>
          )}
          {props.noOfTopics !== '' && props.noOfTopics !== undefined && (
            <p className="chaptersCard__total">
              Number of topics: <span>{props.noOfTopics}</span>
            </p>
          )}
          {props.availableTopics !== '' && props.availableTopics !== undefined && (
            <p className="chaptersCard__availableTopics">
              Available topics: <span>{props.availableTopics}</span>
            </p>
          )}
          {props.completed !== '' && props.completed !== undefined && (
            <p className="chaptersCard__noOfTopics">
              Completed: <span>{props.completed}</span>
            </p>
          )}
          {props.total !== '' && props.total !== undefined && (
            <p className="chaptersCard__total">
              Total: <span>{props.total}</span>
            </p>
          )}

          <div className="dotsContainer">
            <div className="position-relative">
              <ul
                onClick={(event) => {
                  event.stopPropagation()
                  onToggleOptions()
                }}
                className="doubtsCard__dotsContainer"
              >
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <ul
                className={
                  'options__container ' + (optionState ? '' : 'collapse')
                }
              >
                <li
                  className="edit"
                  onClick={(event) => {
                    event.stopPropagation()
                    onToggleOptions()
                    props.editOnClick()
                  }}
                >
                  Edit
                </li>
                <li
                  className="delete"
                  onClick={(event) => {
                    event.stopPropagation()
                    onToggleOptions()
                    props.deleteOnClick()
                  }}
                >
                  Delete
                </li>
              </ul>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  )
}

export default ChaptersCardWithOutProgress
