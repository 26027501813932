import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

//assets
import Card from '../assets/molecules/card'
import Button from '../assets/molecules/button'
import Loader from '../assets/molecules/loader'

//action
import {
  postForgotPassword,
  postVerifyOTP,
  postResetPassword,
} from '../../redux/action/account.js'

//reducer
import {
  getIsError,
  getMessage,
  isLoading as getIsLoading,
  getIsForgotPassword,
  getIsOTPVerifed,
  getIsPasswordChanged,
  getTempToken,
} from '../../redux/reducer/account.js'

const ChangePassword = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const isLoading = useSelector(getIsLoading)
  const isError = useSelector(getIsError)
  const message = useSelector(getMessage)
  const isForgotPassword = useSelector(getIsForgotPassword)
  const isOTPVerifed = useSelector(getIsOTPVerifed)
  const isPasswordChanged = useSelector(getIsPasswordChanged)
  const token = useSelector(getTempToken)

  const [stage, setStage] = useState('')
  const [data, setData] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setStage({ forgotPassword: true })
  }, [dispatch])

  useEffect(() => {
    isForgotPassword && setStage({ verifyOTP: true })
    isOTPVerifed && setStage({ createPassword: true })
    if (isPasswordChanged) {
      dispatch({ type: 'APP_LOGOUT' })
      history.push('/login')
    }
  }, [isForgotPassword, isOTPVerifed, isPasswordChanged, history, dispatch])

  useEffect(() => {
    isError && setErrorMessage(message)
  }, [isError, message])

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
    errorMessage !== '' && setErrorMessage('')
  }

  const onSubmitUsername = (e) => {
    e.preventDefault()
    const { username } = data
    if (username !== '' && username !== undefined && username !== null) {
      dispatch(postForgotPassword({ username }))
    } else {
      setErrorMessage('Please enter username')
    }
  }

  const onSubmitOTP = (e) => {
    e.preventDefault()
    const { otp } = data
    if (otp !== '' && otp !== undefined && otp !== null) {
      dispatch(postVerifyOTP({ otp }))
    } else {
      setErrorMessage('Please enter OTP')
    }
  }

  const onSubmitPassword = (e) => {
    e.preventDefault()
    const { password, confirmPassword } = data
    if (password !== '' && confirmPassword !== '') {
      if (password === confirmPassword) {
        dispatch(postResetPassword({ password, token }))
      } else {
        setErrorMessage('Password mismatch')
      }
    } else {
      setErrorMessage('Please enter password')
    }
  }

  return (
    <div className="tabs__container">
      <section>
        <h4>Change Password</h4>

        <Card columnNo="1" title="Change Password" titleColor={1}>
          <form>
            {stage?.forgotPassword && (
              <div>
                <input
                  onChange={(e) => onChange(e)}
                  type="text"
                  name="username"
                  value={data?.username}
                  placeholder="Enter username"
                />
                {isLoading ? (
                  <Loader />
                ) : (
                  <Button
                    className="button--small"
                    color="blue"
                    text="Generate OTP"
                    click={(e) => onSubmitUsername(e)}
                  />
                )}
              </div>
            )}

            {stage?.verifyOTP && (
              <div>
                <input
                  onChange={(e) => onChange(e)}
                  type="number"
                  name="otp"
                  value={data?.otp}
                  placeholder="Enter OTP"
                />
                {isLoading ? (
                  <Loader />
                ) : (
                  <Button
                    className="button--small"
                    color="blue"
                    text="Verify"
                    click={(e) => onSubmitOTP(e)}
                  />
                )}
              </div>
            )}

            {stage?.createPassword && (
              <div>
                <input
                  onChange={(e) => onChange(e)}
                  type="password"
                  name="password"
                  value={data?.password}
                  placeholder="Enter New Password"
                />
                <input
                  onChange={(e) => onChange(e)}
                  type="password"
                  name="confirmPassword"
                  value={data?.confirmPassword}
                  placeholder="Confirm Password"
                />
                {isLoading ? (
                  <Loader />
                ) : (
                  <Button
                    className="button--small"
                    color="blue"
                    text="Change"
                    click={(e) => onSubmitPassword(e)}
                  />
                )}
              </div>
            )}

            {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}
          </form>
        </Card>
      </section>
    </div>
  )
}

export default ChangePassword
