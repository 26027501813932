import { combineReducers } from 'redux'
import { account } from './account'
import { institution } from './institution'
import { subject } from './subject'
import { ui } from './ui'
import { batches } from './batches'
import { assessment } from './assessment'
import { chapter } from './chapter'
import { doubt } from './doubt'
import { faculty } from './faculty'
import { facultyReport } from './facultyReport'
import { note } from './note'
import { studyplan } from './studyplan'
import { topic } from './topic'
import { impressions } from './impressions'
import { onlineSession } from './onlineSession'
import { studentDailyReport } from './reports'
import { students } from './students'
import { announcement } from './announcements'
import { profile } from './profile'
import { uploadFile } from './upload'
import { uploadVimeoFile } from './vimeoUpload'

export default combineReducers({
  account,
  institution,
  subject,
  ui,
  batches,
  assessment,
  chapter,
  doubt,
  faculty,
  facultyReport,
  note,
  studyplan,
  topic,
  impressions,
  onlineSession,
  studentDailyReport,
  students,
  announcement,
  profile,
  uploadFile,
  uploadVimeoFile,
})
