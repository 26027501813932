import React from 'react'
import ProfilePic from '../../../assets/images/profile.png'
import Loader from '../../assets/molecules/loader'

const PopupForm = (props) => {
  return (
    <div className={'popup__container popupForm ' + props.className}>
      <div className="popup__box">
        <div className="popup__wrap">
          <div className="popup__head">
            <h3>{props.popupHead}</h3>

            <div className="help">
              <div className="help__text help__text--bottom">
                <img src={ProfilePic} alt="help" />
                <p>
                  <span>Need help? I can assist you further.</span>
                </p>
                <p>Tel:+91 8088100900 Email:info@nissiagency.com</p>
              </div>
              <div className="help__button">
                <span>?</span> Help
              </div>
            </div>

            <span onClick={props.close} className="close__popup">
              x
            </span>
            <div className="clear"></div>
          </div>
          <div className="popup__content">
            {props.children}
            <div className="clear"></div>
            <div className="popup__buttonWrapper">
              {props?.isLoading ? (
                <Loader />
              ) : (
                <button className="button--blue" onClick={props.onClickButton}>
                  {props.buttonText}
                </button>
              )}
            </div>
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  )
}

export default PopupForm
