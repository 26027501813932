import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { Link } from 'react-router-dom'
// import _ from 'underscore'

//assests
import Loader from '../assets/molecules/loader'
import Button from '../assets/molecules/button'
import LeftRightLayout from '../assets/layout/leftrightlayout'
import Col75Right from '../assets/layout/leftrightlayout/column75Right'
import Col25Left from '../assets/layout/leftrightlayout/column25Left'
import SubjectCard from '../assets/molecules/subjectCard'
import {
  ProfileItem,
  // ProfileCard,
  ProfileImg,
} from '../assets/molecules/profile'
import Popup from '../assets/layout/popupConfirm'
import SubjectsIcon from '../../assets/images/subjectsIcon.svg'
import EmailIcon from '../../assets/images/profile/email.svg'
import ContactNumberIcon from '../../assets/images/profile/contactnumber.svg'
import Userid from '../../assets/images/profile/userid.svg'

//images
import Password from '../../assets/images/password.png'

//action
import { postChangePassword } from '../../redux/action/account'
import { fetchUserSubjects } from '../../redux/action/subject'

//reducer
import {
  getJwt,
  getEntityId,
  getUsersInstitutionTitle,
  getUsersInstitutionNavLogo,
  getUsername,
  getMessage,
  isLoading as getisLoadingAccount,
} from '../../redux/reducer/account'
import {
  // isSubjectsloading,
  // getIsError,
  getSubjects,
} from '../../redux/reducer/subject'

import {
  getProfileInfo,
  getIsLoadingProfile,
} from '../../redux/reducer/profile'

//helpers
import { usePrevious } from '../helpers'
import { startGetProfileInfo } from '../../redux/action/profile'

const MyProfile = () => {
  const dispatch = useDispatch()

  //state
  const [popClassName, setPopClassName] = useState('hidePop')
  const [oldPassword, setoldPassword] = useState('')
  const [newPassword, setnewPassword] = useState('')
  const [reEnterPassword, setreEnterPassword] = useState('')
  const [error, setError] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  //selector
  const jwt = useSelector(getJwt)
  const entityId = useSelector(getEntityId)
  const intitutionTitle = useSelector(getUsersInstitutionTitle)
  const usersInstitutionNavLogo = useSelector(getUsersInstitutionNavLogo)
  const username = useSelector(getUsername)
  const message = useSelector(getMessage)
  const isLoadingAccount = useSelector(getisLoadingAccount)
  const isLoadingAccountPrev = usePrevious(isLoadingAccount)
  const subjects = useSelector(getSubjects)
  const isLoading = useSelector(getIsLoadingProfile)
  const profileInfo = useSelector(getProfileInfo)

  useEffect(() => {
    dispatch(startGetProfileInfo({ jwt }))
    dispatch(fetchUserSubjects({ jwt }))
  }, [dispatch, entityId, jwt])

  useEffect(() => {
    if (!isLoadingAccount && isLoadingAccountPrev !== undefined && message) {
      setError('Please check the Old Password.')
    }
  }, [isLoadingAccount, isLoadingAccountPrev, message])

  const openPopup = (note) => {
    setPopClassName('showPop')
  }

  const closePopup = () => {
    setoldPassword('')
    setnewPassword('')
    setreEnterPassword('')
    setError('')
    setShowPassword(false)
    setPopClassName('hidePop')
  }

  const onsubmit = () => {
    if (oldPassword && newPassword && reEnterPassword) {
      if (newPassword === reEnterPassword) {
        const payload = {
          old_password: oldPassword,
          new_password: newPassword,
        }
        dispatch(postChangePassword({ jwt, payload }))
      } else {
        setError('The new passwords do not match.')
      }
    } else {
      setError('Enter Required fields.')
    }
  }

  return isLoading ? (
    <Loader />
  ) : (
    <div className="tabs__container tabs--student">
      <section>
        <h1>Profile</h1>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col25Left className="padding--0">
            <h2 className="subjects--head">Personal Information</h2>
            <ProfileImg
              img={profileInfo?.avatar}
              name={profileInfo?.name}
              institutionImg={usersInstitutionNavLogo}
              institutionName={intitutionTitle}
            />
            <ProfileItem title="User ID" value={username} img={Userid} />
            <ProfileItem
              title="Email Address"
              value={profileInfo?.email?.join(', ')}
              img={EmailIcon}
            />
            <ProfileItem
              title="Contact Number"
              value={profileInfo?.mobile?.join(', ')}
              img={ContactNumberIcon}
            />
            <Button
              color="link"
              click={() => {
                openPopup()
              }}
              text="CHANGE PASSWORD"
              className="changePasswordButton"
            />
          </Col25Left>
          <Col75Right>
            <h2>Other Information</h2>
            <p>Subjects you teach</p>
            <div className="evalution__gridCointainer">
              {subjects.map((sub, index) => {
                return (
                  <SubjectCard
                    key={index}
                    icon={SubjectsIcon}
                    subjectName={sub?.subject_title}
                    subjectCode={sub?.subject_code}
                    noOfTopics={sub?.batch_title}
                    className="subjectCard--profile"
                    onClick={() => {}}
                    profile={true}
                  />
                )
              })}
            </div>
          </Col75Right>
        </LeftRightLayout>
        <Popup
          className={popClassName}
          close={() => closePopup()}
          onClick={() => onsubmit()}
          buttonText="submit"
          popupHead="Change Password"
        >
          <div className="changePassword__wrap">
            <img src={Password} alt="password" />
            <input
              placeholder="Old Password"
              type={showPassword ? 'text' : 'password'}
              name="oldpassword"
              value={oldPassword}
              onChange={(e) => setoldPassword(e.target.value)}
            />
            <div className="clear"></div>
          </div>
          <div className="changePassword__wrap">
            <img src={Password} alt="password" />
            <input
              placeholder="New Password"
              type={showPassword ? 'text' : 'password'}
              name="newpassword"
              value={newPassword}
              onChange={(e) => setnewPassword(e.target.value)}
            />
            <div className="clear"></div>
          </div>
          <div className="changePassword__wrap">
            <img src={Password} alt="password" />
            <input
              placeholder="Re-Enter New Password"
              type={showPassword ? 'text' : 'password'}
              name="reenterpassword"
              value={reEnterPassword}
              onChange={(e) => setreEnterPassword(e.target.value)}
            />
            <div className="clear"></div>
          </div>
          <button onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? 'Hide Password' : 'Show Password'}
          </button>
          <div className={error ? 'alert alert-danger' : ''}>{error}</div>
        </Popup>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default MyProfile
