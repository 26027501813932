import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { usePrevious } from '../../helpers'

//assets
import Button from '../../assets/molecules/button'
import Loader from '../../assets/molecules/loader'
import { AlertSuccess } from '../../assets/molecules/alerts'
import Select from '../../assets/molecules/select'

//actions
import { fetchStudent, editStudent } from '../../../redux/action/students'
import { fetchBatches, setStudentBatch } from '../../../redux/action/batches'
import { setBatch } from '../../../redux/action/ui'

//reducer
import { getJwt } from '../../../redux/reducer/account'
import {
  getIsLoading,
  getIsError,
  getStudents,
  getIsLoadingEdit,
} from '../../../redux/reducer/students'
import { getBatch } from '../../../redux/reducer/ui'
import {
  getBatches,
  getIsLoading as getIsLoadingBatches,
  getIsLoadingSetStudentBatch,
  getIsError as getBatchError,
} from '../../../redux/reducer/batches'

const EditStudent = () => {
  const dispatch = useDispatch()

  //state
  const [name, setName] = useState()
  const [admissionYear, setAdmissionYear] = useState()
  const [gender, setGender] = useState()
  const [email, setEmail] = useState()
  const [mobile, setMobile] = useState()
  const [prn, setPrn] = useState()
  const [motherName, setMotherName] = useState()
  const [fatherName, setFatherName] = useState()
  const [guardianName, setQuardianName] = useState()
  const [dob, setDob] = useState()
  const [allowAssessmentUpload, setAllowAssessmentUpload] = useState()
  const [showSuccess, setShowSuccess] = useState(false)
  const [localSlectedBatch, setLocalSlectedBatch] = useState('')

  //get param from url
  const { studentId } = useParams()

  //selector
  const jwt = useSelector((state) => getJwt(state))
  const isLoadingGetStudent = useSelector((state) => getIsLoading(state))
  const isLoadingGetStudentPrev = usePrevious(isLoadingGetStudent)
  const isLoadingEdit = useSelector((state) => getIsLoadingEdit(state))
  const isLoadingEditPrev = usePrevious(isLoadingEdit)
  const error = useSelector((state) => getIsError(state))
  const students = useSelector((state) => getStudents(state))
  const batchSelected = useSelector(getBatch)
  const batches = useSelector(getBatches)
  const isLoadingBatches = useSelector(getIsLoadingBatches)
  const batchError = useSelector(getBatchError)
  const isLoadingSetStudentBatch = useSelector(getIsLoadingSetStudentBatch)
  const isLoadingSetStudentBatchPrev = usePrevious(isLoadingSetStudentBatch)

  useEffect(() => {
    dispatch(fetchStudent({ jwt, studentId: studentId }))
    dispatch(fetchBatches({ jwt }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isLoadingGetStudent &&
      isLoadingGetStudentPrev !== undefined &&
      !error
    ) {
      setName(students?.name)
      setAdmissionYear(students?.admission_year)
      setGender(students?.gender)
      setEmail(students?.email?.join(','))
      setMobile(students?.mobile?.join(','))
      setPrn(students?.prn)
      setMotherName(students?.mother_name)
      setFatherName(students?.father_name)
      setQuardianName(students?.guardian_name)
      setDob(students?.date_of_birth)
      setAllowAssessmentUpload(
        students?.uploadAssessmentNotAllowed ? 'no' : 'yes'
      )
    }
  }, [isLoadingGetStudent]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isLoadingSetStudentBatch &&
      isLoadingSetStudentBatchPrev !== undefined &&
      !batchError
    ) {
      setShowSuccess(true)
      dispatch(setBatch(localSlectedBatch))
    }
  }, [isLoadingSetStudentBatch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(isLoadingEdit, 'isLoadingEdit', isLoadingEditPrev, error)
    if (!isLoadingEdit && isLoadingEditPrev !== undefined && !error) {
      setShowSuccess(true)
      setBatch()
    }
  }, [isLoadingEdit]) // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = () => {
    const data = {
      name: name,
      mother_name: motherName,
      father_name: fatherName,
      guardian_name: guardianName,
      date_of_birth: dob,
      admission_year: admissionYear,
      email: email.split(','),
      mobile: mobile.split(','),
      gender: gender,
      prn: prn,
      uploadAssessmentNotAllowed:
        allowAssessmentUpload === 'yes' ? false : true,
    }
    dispatch(editStudent({ jwt, studentId: studentId, payload: data }))
  }

  const onChangeBatch = (e) => {
    const payload = {
      student_id: studentId,
      old_batch: batchSelected,
      new_batch: e.target.value,
    }
    setLocalSlectedBatch(e.target.value)
    dispatch(setStudentBatch({ jwt, payload }))
  }

  return isLoadingGetStudent ||
    isLoadingBatches ||
    isLoadingSetStudentBatch ||
    isLoadingEdit ? (
    <Loader />
  ) : !students ? (
    <div>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Home</Link>
          </li>
          <li className="breadcrumb-item active">Student List</li>
        </ol>
      </nav>
      <h1>This person does not exist</h1>
      <Link to="/student/list">
        <button>Back</button>
      </Link>
    </div>
  ) : (
    <div className="tabs__container">
      <section>
        <h4>Edit Student</h4>

        <h5>Student Name:</h5>
        <input
          name="name"
          value={name}
          type="text"
          placeholder="Student Name"
          onChange={(e) => setName(e.target.value)}
        />
        <h5>Batch:</h5>
        <Select
          // className="float-left"
          onChange={(e) => onChangeBatch(e)}
          value={batchSelected}
        >
          <option value="">Select a Batch</option>
          {batches?.map((batch, index) => {
            return (
              <option value={batch._id} key={index}>
                {batch.title}
              </option>
            )
          })}
        </Select>
        <h5>Admission Year:</h5>
        <input
          name="admissionYear"
          value={admissionYear}
          type="text"
          placeholder="Admission Year"
          onChange={(e) => setAdmissionYear(e.target.value)}
        />
        <h5>Gender:</h5>
        <input
          name="gender"
          value={gender}
          type="text"
          placeholder="Gender"
          onChange={(e) => setGender(e.target.value)}
        />
        <h5>Email(commas seperated):</h5>
        <input
          name="email"
          value={email}
          type="text"
          placeholder="Email id"
          onChange={(e) => setEmail(e.target.value)}
        />
        <h5>Mobile(commas seperated):</h5>
        <input
          name="mobile"
          value={mobile}
          type="text"
          placeholder="Mobile No"
          onChange={(e) => setMobile(e.target.value)}
        />
        <h5>PRN:</h5>
        <input
          name="prn"
          value={prn}
          type="text"
          placeholder="PRN"
          onChange={(e) => setPrn(e.target.value)}
        />
        <h5>Mother Name:</h5>
        <input
          name="motherName"
          value={motherName}
          type="text"
          placeholder="Mother Name"
          onChange={(e) => setMotherName(e.target.value)}
        />
        <h5>Father Name:</h5>
        <input
          name="fatherName"
          value={fatherName}
          type="text"
          placeholder="Father Name"
          onChange={(e) => setFatherName(e.target.value)}
        />
        <h5>Guardian Name:</h5>
        <input
          name="guardianName"
          value={guardianName}
          type="text"
          placeholder="Guardian Name"
          onChange={(e) => setQuardianName(e.target.value)}
        />
        <h5>Date Of Birth:</h5>
        <input
          name="dob"
          value={dob}
          type="text"
          placeholder="DD/MM/YYYY"
          onChange={(e) => setDob(e.target.value)}
        />
        <h5>Allow Upload Assessment:</h5>
        <Select
          // className="float-left"
          onChange={(e) => setAllowAssessmentUpload(e.target.value)}
          value={allowAssessmentUpload}
        >
          <option value="">Select an Option</option>
          <option value={'yes'}>Yes</option>
          <option value={'no'}>No</option>
        </Select>
        <Button
          className="button--small"
          color="blue"
          text="Save"
          click={(e) => onSubmit(e)}
        />
      </section>
      {showSuccess ? (
        <AlertSuccess onIntervalDone={() => setShowSuccess(false)}>
          You have successfully edited Student details.
        </AlertSuccess>
      ) : null}
    </div>
  )
}

export default EditStudent
