import { useEffect, useRef } from 'react'
import moment from 'moment'
import jsreport from 'jsreport-browser-client-dist'

// import Physics from '../assets/images/physics.png'
// import Chemistry from '../assets/images/chemistry.png'
// import Maths from '../assets/images/maths.png'
// import Biology from '../assets/images/biology.png'

import physics from '../assets/images/subjects/physics.svg'
import others from '../assets/images/subjects/physics.svg'
import maths from '../assets/images/subjects/maths.svg'
import mathematics from '../assets/images/subjects/mathematics.svg'
import chemistry from '../assets/images/subjects/chemistry.svg'
import biology from '../assets/images/subjects/biology.svg'

export const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const subjectIcons = {
  physics,
  maths,
  mathematics,
  chemistry,
  biology,
  others,
}

export const subjectPics = {
  acc: 'https://cwxdata.s3.ap-south-1.amazonaws.com/acc.jpg',
  bma: 'https://cwxdata.s3.ap-south-1.amazonaws.com/bma.jpg',
  che: 'https://cwxdata.s3.ap-south-1.amazonaws.com/che.jpg',
  csc: 'https://cwxdata.s3.ap-south-1.amazonaws.com/csc.png',
  ele: 'https://cwxdata.s3.ap-south-1.amazonaws.com/ele.jpg',
  fre: 'https://cwxdata.s3.ap-south-1.amazonaws.com/fre.jpg',
  his: 'https://cwxdata.s3.ap-south-1.amazonaws.com/his.jpg',
  mat: 'https://cwxdata.s3.ap-south-1.amazonaws.com/mat.jpg',
  tam: 'https://cwxdata.s3.ap-south-1.amazonaws.com/tam.jpg',
  bio: 'https://cwxdata.s3.ap-south-1.amazonaws.com/bio.jpg',
  bst: 'https://cwxdata.s3.ap-south-1.amazonaws.com/bst.jpg',
  com: 'https://cwxdata.s3.ap-south-1.amazonaws.com/com.jpg',
  eco: 'https://cwxdata.s3.ap-south-1.amazonaws.com/eco.jpeg',
  eng: 'https://cwxdata.s3.ap-south-1.amazonaws.com/eng.jpg',
  hin: 'https://cwxdata.s3.ap-south-1.amazonaws.com/hin.jpg',
  kan: 'https://cwxdata.s3.ap-south-1.amazonaws.com/kan.jpg',
  phy: 'https://cwxdata.s3.ap-south-1.amazonaws.com/phy.jpg',
  tel: 'https://cwxdata.s3.ap-south-1.amazonaws.com/tel.jpg',
  mus: 'https://cwxdata.s3.ap-south-1.amazonaws.com/mus.jpg',
}

export const navList = {
  student: [
    { name: 'home', to: '/dashboard' },
    { name: 'subjects', to: '/me/subjects' },
    { name: 'doubts', to: '/me/doubts' },
    { name: 'assessments', to: '/me/assessments' },
    { name: 'notes', to: '/me/notes' },
    { name: 'online sessions', to: '/me/sessions' },
  ],
  faculty: [
    { name: 'home', to: '/dashboard' },
    { name: 'subjects', to: '/me/faculty/subjects' },
    { name: 'evaluations', to: '/faculty/assessments' },
    { name: 'online sessions', to: '/me/faculty/sessions' },
  ],
  moderator: [
    { name: 'home', to: '/dashboard' },
    { name: 'online sessions', to: '/onlinesessions/reports' },
    { name: 'study plan', to: '/create/study-plan' },
    { name: 'assessments', to: '/create/assessment' },
    { name: 'assessments marks', to: '/assessment/marks' },
    { name: 'student list', to: '/student/list' },
    { name: 'student analytics', to: '/reports' },
    { name: 'faculty list', to: '/faculty/list' },
    { name: 'faculty analytics', to: '/faculty/reports' },
  ],
  owner: [
    { name: 'home', to: '/dashboard' },
    { name: 'institutions', to: '/institutions' },
  ],
}

export const weekOfTheMonth = (date) => {
  const prefixes = ['1st', '2nd', '3rd', '4th', '5th']
  return prefixes[0 | (moment(date).date() / 7)]
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export const downloadReport = ({
  nameOfTemplate,
  dataForTemplate,
  downloadedFileName,
}) => {
  jsreport.serverUrl = 'https://jsreport.sealtabs.com'

  const request = {
    template: {
      name: nameOfTemplate,
      engine: 'handlebars',
      recipe: 'chrome-pdf',
    },
    data: dataForTemplate,
  }

  jsreport.renderAsync(request).then((res) => {
    res.download(`${downloadedFileName}.pdf`)
  })
}
