import React from 'react'

const PopupRight = (props) => {
  return (
    <div className={'popup__container popupRight ' + props.className}>
      <div className="popup__box">
        <div className="popup__wrap">
          <div className="popup__head">
            <h3>{props.popupHead}</h3>

            <span onClick={props.close} className="close__popup">
              x
            </span>
            <div className="clear"></div>
          </div>
          <div className="popup__content">
            {props.children}
            <div className="clear"></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  )
}

export default PopupRight
