import React from 'react'

const AddItems = (props) => {
  return (
    <div onClick={props.onClick} className="addItems__wrapper">
      <img src={props.imageURL} alt={props.text} />
      <p>{props.text}</p>
      <div className="clear"></div>
    </div>
  )
}

export default AddItems
