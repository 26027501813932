import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
// import { Link, useHistory, useLocation } from 'react-router-dom'

//assets

import Loader from '../../assets/molecules/loader'
import LeftRightLayout from '../../assets/layout/leftrightlayout'
import Col75Right from '../../assets/layout/leftrightlayout/column75Right'
import Col25Left from '../../assets/layout/leftrightlayout/column25Left'
import Popup from '../../assets/layout/popupConfirm'
import NoStudyPlan from '../../../assets/images/no-pending.png'
// import { AlertSuccess } from '../../assets/molecules/alerts'
// import Popup from '../../assets/layout/popup'

//action
//topic
import { fetchTopicsByChapterId, clearTopic } from '../../../redux/action/topic'
//ui
import {
  setChapter,
  setTopic,
  setSubject,
  setBatch,
  setBatchSubjectId,
} from '../../../redux/action/ui'
import {
  postStudyPlan,
  fetchStudyPlanDaily,
  deleteStudyPlan,
} from '../../../redux/action/studyplan'

import {
  clearChapters,
  fetchChaptersBySubjectId,
} from '../../../redux/action/chapter'

import {
  fetchSubjects,
  clearSubjectSelected,
} from '../../../redux/action/subject'

//reducer
//subject(chapters also implemented in subject)
import {
  groupByBatchSubject,
  getSubjects,
} from '../../../redux/reducer/subject'

import { getIsTopicsLoading, getTopics } from '../../../redux/reducer/topic'

import { getChapters } from '../../../redux/reducer/chapter'
import { getstudentStudyPlanByBatch } from '../../../redux/reducer/studyplan'

//account
import { getJwt } from '../../../redux/reducer/account'

import {
  getSubjectId,
  getBatch,
  getChapterId,
  getTopicId,
  getBatchSubjectId,
} from '../../../redux/reducer/ui'

import { getBatches } from '../../../redux/reducer/batches'

const StudyplanCreation = () => {
  const dispatch = useDispatch()
  const [date, setDate] = useState(new Date())
  const [dataChange, setDataChange] = useState(false)
  const [errorState, setErrorState] = useState('')
  const [selectedBatch, setSelectedBatch] = useState('')

  //selectors
  //account
  const jwt = useSelector(getJwt)
  //ui
  const subjectId = useSelector(getSubjectId)
  const chapterId = useSelector(getChapterId)
  const batch = useSelector(getBatch)
  const topicId = useSelector(getTopicId)
  const batchSubject = useSelector(getBatchSubjectId)

  //subject(topics)
  const subjectsByBatchId = useSelector(groupByBatchSubject)
  const subjectsAll = useSelector(getSubjects)
  const chapters = useSelector(getChapters)
  const topics = useSelector(getTopics)
  const isLoading = useSelector(getIsTopicsLoading)
  const batches = useSelector(getBatches)
  // const isStudyPlanLoading = useSelector(getIsStudentStudyPlanLoading)
  const studyplans = useSelector(getstudentStudyPlanByBatch)

  useEffect(() => {
    dispatch(
      fetchStudyPlanDaily({ date: moment(date).format('YYYY-MM-DD'), jwt })
    )
  }, [dispatch, jwt, date])

  useEffect(() => {
    dispatch(fetchTopicsByChapterId({ jwt, chapterId }))
  }, [dispatch, jwt, chapterId])

  useEffect(() => {
    dispatch(fetchChaptersBySubjectId({ jwt, subjectId }))
    if (subjectsAll.length === 0) dispatch(fetchSubjects({ jwt }))
  }, [dispatch, jwt, subjectId, subjectsAll, batches, batch])

  const onChangeChapter = (e) => {
    // e.preventDefault()
    dispatch(clearTopic())
    dispatch(setChapter(e.target.value))
    setDataChange(!dataChange)
  }

  const onChangeSubject = (e) => {
    e.preventDefault()
    const subjectArray = subjectsByBatchId[batch]?.filter(
      (subject) => subject._id === e.target.value
    )
    const subjectIdSelected = subjectArray.length > 0 ? subjectArray[0] : ''

    dispatch(clearTopic())
    dispatch(clearChapters())
    dispatch(setSubject(subjectIdSelected.subject_id))
    dispatch(setBatchSubjectId(e.target.value))
    setDataChange(!dataChange)
  }

  const onChangeBatch = (e) => {
    e.preventDefault()
    setSelectedBatch(e.target.value)
    dispatch(clearTopic())
    dispatch(clearChapters())
    dispatch(clearSubjectSelected())
    dispatch(setBatch(e.target.value))
    setDataChange(!dataChange)
  }

  const onChangeTopic = (e) => {
    e.preventDefault()
    dispatch(setTopic(e.target.value, e.target.name))
  }

  const onChangeDate = (date) => {
    setDate(date)
    dispatch(clearTopic())
    dispatch(clearChapters())
    dispatch(clearSubjectSelected())
    setDataChange(!dataChange)
  }
  const [isCreateSPClicked, setIsCreateSPClicked] = useState(false)

  const [isDeleteSPClicked, setIsDeleteSPClicked] = useState(false)
  const [deleteSPId, setDeleteSPId] = useState('')

  const [popClassName, setPopClassName] = useState('hidePop')
  const creareStudyPlanPopup = (date) => {
    setIsCreateSPClicked(true)
    setPopClassName('showPop')
    dispatch(setChapter(''))
    dispatch(setSubject(''))
    dispatch(setBatchSubjectId(''))
    dispatch(setTopic(''))
  }
  const closePopup = () => {
    setPopClassName('hidePop')
    setIsDeleteSPClicked(false)
    setIsCreateSPClicked(false)
  }

  const onClickDelete = (id) => {
    setIsDeleteSPClicked(true)
    setPopClassName('showPop')
    setDeleteSPId(id)
  }

  const deleteStudyPlanConfirmed = () => {
    setPopClassName('hidePop')
    setIsDeleteSPClicked(false)
    const id = deleteSPId
    dispatch(deleteStudyPlan({ jwt, id }))
  }

  const onSubmit = () => {
    setIsCreateSPClicked(false)
    if (batchSubject && topicId) {
      const payload = {
        date: moment(date).format('YYYY-MM-DD'),
        topic_ids: [topicId],
        batch_subject_id: batchSubject,
      }
      dispatch(postStudyPlan({ payload, jwt }))
      setPopClassName('hidePop')
    } else {
      setErrorState('Please fill in the form.')
    }
  }

  const noStudyPlanAvailable = () => {
    return (
      <div className="noPending">
        <div>
          <img src={NoStudyPlan} alt="" />
          <p className="text-center">
            Please submit the left side form to create study plan
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="tabs__container">
      <section>
        <h1>Study Plan</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">study plans</li>
          </ol>
        </nav>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col25Left className="backgroundColor">
            <h2>Create study plan</h2>
            <div className="create__studyPlan">
              <select
                onChange={(e) => onChangeBatch(e)}
                //  defaultValue={batch}
              >
                <option value="">Select a Batch</option>
                {batches?.map((batch, index) => {
                  return (
                    <option
                      value={batch._id}
                      key={index}
                      selected={batch._id === selectedBatch}
                    >
                      {batch.title}
                    </option>
                  )
                })}
              </select>
              <DatePicker
                selected={date}
                onChange={(date) => onChangeDate(date)}
                placeholderText="Select Date"
                dateFormat="dd/MM/yyyy"
              />
              <div className="clear"></div>
              <button
                className="button--blue"
                onClick={() => creareStudyPlanPopup()}
              >
                Create Study Plan
              </button>
              <div className="clear"></div>
            </div>
          </Col25Left>
          <Col75Right>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="table-responsive">
                {studyplans &&
                studyplans[batch] &&
                studyplans[batch]?.length ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sl No</th>
                        <th>Subject</th>
                        <th>chapter</th>
                        <th>Topic</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {studyplans[batch]?.map((studyplan, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{studyplan.subject_title}</td>
                            <td>{studyplan.chapter_title}</td>
                            <td>{studyplan.topic_title}</td>
                            <td>
                              <button
                                onClick={(e) => onClickDelete(studyplan._id)}
                                className={''}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                ) : (
                  noStudyPlanAvailable()
                )}
                <div className="clear"></div>
              </div>
            )}
          </Col75Right>
        </LeftRightLayout>
      </section>
      {isDeleteSPClicked && (
        <Popup
          className={popClassName}
          close={() => closePopup()}
          onClick={(e) => deleteStudyPlanConfirmed()}
          buttonText="yes"
          popupHead="Delete"
        >
          <h2>Are you sure want to delete?</h2>
        </Popup>
      )}
      {isCreateSPClicked && (
        <Popup
          className={popClassName}
          close={() => closePopup()}
          onClick={onSubmit}
          buttonText="Submit"
          popupHead="Create Study Plan"
        >
          <div className="create__studyPlan--popup">
            <div className="create__studyPlan--datepicker">
              <DatePicker
                selected={date}
                onChange={(date) => onChangeDate(date)}
                placeholderText="Select Date"
                dateFormat="dd/MM/yyyy"
              />
              <div className="clear"></div>
            </div>
            <select
              className="float-center"
              onChange={(e) => onChangeSubject(e)}
              defaultValue={subjectId}
            >
              <option value="null">Subjects</option>
              {subjectsByBatchId[batch]?.map((subject, index) => {
                return (
                  <option value={subject._id} name={subject._id} key={index}>
                    {subject.subject_title}
                  </option>
                )
              })}
            </select>
            <select
              onChange={(e) => onChangeChapter(e)}
              defaultValue={chapterId}
            >
              <option value="null">Chapter</option>
              {chapters?.map((chapter, index) => {
                return (
                  <option value={chapter._id} key={index}>
                    {chapter.title}
                  </option>
                )
              })}
            </select>
            <select onChange={(e) => onChangeTopic(e)} defaultValue={topicId}>
              <option value="null">Topics</option>
              {topics?.map((topic, index) => {
                return (
                  <option value={topic._id} name={topic.title} key={index}>
                    {topic.title}
                  </option>
                )
              })}
            </select>
            <div className="clear"></div>
          </div>
        </Popup>
      )}
      <div className="clear"></div>
    </div>
  )
}

export default StudyplanCreation
