import {
  POST_ONLINE_SESSION_START,
  POST_ONLINE_SESSION_SUCCESS,
  POST_ONLINE_SESSION_FAILURE,
  GET_ONLINE_SESSIONS_START,
  GET_ONLINE_SESSIONS_SUCCESS,
  GET_ONLINE_SESSIONS_FAILURE,
  APP_LOGOUT,
  DELETE_ONLINE_SESSION_START,
  DELETE_ONLINE_SESSION_SUCCESS,
  DELETE_ONLINE_SESSION_FAILURE,
  GET_ONLINE_SESSION_BY_ID_START,
  GET_ONLINE_SESSION_BY_ID_SUCCESS,
  GET_ONLINE_SESSION_BY_ID_FAILURE,
  EDIT_ONLINE_SESSION_BY_ID_START,
  EDIT_ONLINE_SESSION_BY_ID_SUCCESS,
  EDIT_ONLINE_SESSION_BY_ID_FAILURE,
  GET_ONLINE_SESSIONS_SUCCESS_LONG_POLLING,
  GET_ONLINE_SESSION_BY_BATCH_ID_START,
  GET_ONLINE_SESSION_BY_BATCH_ID_SUCCESS,
  GET_ONLINE_SESSION_BY_BATCH_ID_FAILURE,
  EDIT_REALOAD_SUCCESS,
  POST_CUSTOM_SESSION_START,
  POST_CUSTOM_SESSION_SUCCESS,
  POST_CUSTOM_SESSION_FAILURE,
} from '../actions'

import _ from 'underscore'

let initialState = {
  isLoading: false,
  isLoadingById: false,
  isLoadingEditById: false,
  isError: false,
  onlineSessions: [],
  onlineSession: {},
  isLoadingGetByBatchId: false,
  batchOnlineSessions: [],
  reaload: false,
}

export const onlineSession = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case GET_ONLINE_SESSION_BY_BATCH_ID_START:
      return {
        ...state,
        isLoadingGetByBatchId: true,
        isError: false,
      }
    case GET_ONLINE_SESSION_BY_BATCH_ID_SUCCESS:
      return {
        ...state,
        isLoadingGetByBatchId: false,
        isError: false,
        batchOnlineSessions: action.data,
      }
    case GET_ONLINE_SESSION_BY_BATCH_ID_FAILURE:
      return {
        ...state,
        isLoadingGetByBatchId: false,
        isError: true,
      }

    case POST_ONLINE_SESSION_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case POST_ONLINE_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      }
    case POST_ONLINE_SESSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case GET_ONLINE_SESSIONS_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case GET_ONLINE_SESSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        onlineSessions: action.online_sessions_res,
      }
    case GET_ONLINE_SESSIONS_SUCCESS_LONG_POLLING:
      return {
        ...state,
        onlineSessions: action.online_sessions_res,
      }
    case GET_ONLINE_SESSIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case GET_ONLINE_SESSION_BY_ID_START:
      return {
        ...state,
        isLoadingById: true,
        isError: false,
      }
    case GET_ONLINE_SESSION_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingById: false,
        isError: false,
        onlineSession: action.online_session,
      }
    case GET_ONLINE_SESSION_BY_ID_FAILURE:
      return {
        ...state,
        isLoadingById: false,
        isError: true,
      }
    case EDIT_ONLINE_SESSION_BY_ID_START:
      return {
        ...state,
        isLoadingEditById: true,
        isError: false,
      }
    case EDIT_ONLINE_SESSION_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingEditById: false,
        isError: false,
      }
    case EDIT_ONLINE_SESSION_BY_ID_FAILURE:
      return {
        ...state,
        isLoadingEditById: false,
        isError: true,
      }
    case DELETE_ONLINE_SESSION_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case DELETE_ONLINE_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        onlineSessions: action.data,
      }
    case DELETE_ONLINE_SESSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    case EDIT_REALOAD_SUCCESS:
      console.log(action, 'herehereh action')
      return {
        ...state,
        reaload: action.reaload,
      }
    case POST_CUSTOM_SESSION_START:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case POST_CUSTOM_SESSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      }
    case POST_CUSTOM_SESSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      }
    default:
      return state
  }
}

export const getIsLoading = (state) => state.onlineSession.isLoading
export const getReaload = (state) => state.onlineSession.reaload
export const getIsLoadingEditById = (state) =>
  state.onlineSession.isLoadingEditById
export const getIsError = (state) => state.onlineSession.isError
export const getOnlineSessions = (state) => state.onlineSession.onlineSessions
export const getIsLoadingById = (state) => state.onlineSession.isLoadingById
export const getOnlineSession = (state) => state.onlineSession.onlineSession
export const getOnlineSessionsGroupBySubject = (state) => {
  const sessions = state.onlineSession.onlineSessions.map((sess) => {
    if (sess?.subject_id) {
      sess['batch_subject_id'] = sess['subject_id']
    }
    return sess
  })
  return _.groupBy(sessions, 'batch_subject_id')
}

export const getIsLoadingGetByBatchId = (state) =>
  state.onlineSession.isLoadingGetByBatchId
export const getBatchOnlineSessions = (state) =>
  state.onlineSession.batchOnlineSessions
