import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Tab, Tabs } from 'react-bootstrap'
import moment from 'moment'
import _ from 'underscore'

// import Loader from '../assets/molecules/loader'
import SelfAssesmentCard from '../assets/molecules/selfAssesmentCard'
import ExamCard from '../assets/molecules/examCard'
import PopupCustom from '../assets/layout/popupCustom'
import NoSelfAssessment from '../../assets/images/noSelfAssessment.png'
import ProfilePic from '../../assets/images/profile.png'
import SelfAssessmentStart from '../../assets/images/selfAssessmentStart.png'

//action
import {
  fetchAssessments,
  fetchAssessmentQuestions,
  fetchSelfAssessmentsByUser,
} from '../../redux/action/assessment'
import {
  setBatchSubjectId,
  setAssessment,
  setTopic,
} from '../../redux/action/ui'

//reducer
import { getJwt } from '../../redux/reducer/account'
import {
  getAssessments,
  getIsAssessmentsLoading,
  getAssessmentQuestions,
  getSelfAssessments,
} from '../../redux/reducer/assessment'
import { getBatchSubjects } from '../../redux/reducer/subject'

import { useInterval } from '../helpers'

const MyAssessment = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  //selector
  const jwt = useSelector(getJwt)
  const assessments = useSelector(getAssessments)
  const batchSubjects = useSelector(getBatchSubjects)
  let assessmentQuestions = useSelector(getAssessmentQuestions)
  let selfAssessments = useSelector(getSelfAssessments)

  useEffect(() => {
    dispatch(fetchSelfAssessmentsByUser({ jwt }))
    dispatch(fetchAssessments({ jwt }))
  }, [dispatch, jwt])

  useInterval(() => {
    setCurrentTime(moment())
  }, 1000)

  const [evaluated, setEvaluated] = useState([])
  const [nonEvaluted, setNonEvaluated] = useState([])
  const [localSelfAssessments, setLocalSelfAssessments] = useState([])
  const [currentTime, setCurrentTime] = useState()

  useEffect(() => {
    setEvaluated(
      assessments?.filter((assessment) => assessment.status === 'EVOLUTED')
    )
    setNonEvaluated(
      assessments?.filter((assessment) => assessment.status !== 'EVOLUTED')
    )
  }, [assessments])

  useEffect(() => {
    const selfAssessmentsByTopic = _.groupBy(
      selfAssessments,
      (x) => x?.topic_id
    )
    let finalAssessments = []
    Object.keys(selfAssessmentsByTopic).forEach((key) => {
      const sortedAssessmentResults = selfAssessmentsByTopic[key]?.sort(
        (a, b) => new Date(b?.created_at) - new Date(a?.created_at)
      )
      if (sortedAssessmentResults.length > 0) {
        finalAssessments.push(sortedAssessmentResults[0])
      }
    })
    setLocalSelfAssessments(finalAssessments)
  }, [selfAssessments])

  // const onAssessmentSelect = (id, title, batchSubjectId) => {
  //   dispatch(setAssessment(id, title))
  //   dispatch(setBatchSubjectId(batchSubjectId))
  //   history.push(`/assessments/questions`)
  // }

  const getBatchSubjectTitleById = (id) => {
    const currectBatchSubjects = batchSubjects?.filter(
      (batchSubject) => batchSubject._id === id
    )
    return currectBatchSubjects?.length
      ? currectBatchSubjects[0]?.subject_title
      : ''
  }

  // const batchSubjectsId = Object.keys(assessmentsGroupByBatchSubjet)

  const [examPopClassName, setExamPopClassName] = useState('hidePop')

  const openExamPopup = () => {
    setExamPopClassName('showPop')
  }
  const closeExamPopup = () => {
    setExamPopClassName('hidePop')
  }

  const noSelfAssessmentAvailable = () => {
    return (
      <div className="noPending">
        <div>
          <img src={NoSelfAssessment} alt="No Self Assessments Available." />
          <h2 className="text-center">No Self Assessments Available.</h2>
          <p>Revise your topics daily to be prepared for Chapter Assessments</p>
        </div>
      </div>
    )
  }
  const noExaminationAvailable = () => {
    return (
      <div className="noPending">
        <div>
          <img src={NoSelfAssessment} alt="No Self Assessments Available." />
          <h2 className="text-center">No Examinations for you for now.</h2>
          <p>Do Self assessments to prepare. </p>
        </div>
      </div>
    )
  }

  const noResultsAvailable = () => {
    return (
      <div className="noPending">
        <div>
          <img src={NoSelfAssessment} alt="No Self Assessments Available." />
          <h2 className="text-center">No Results for now.</h2>
          {/* <p>Do Self assessments to prepare. </p> */}
        </div>
      </div>
    )
  }

  const [selectedAssessment, setSelectedAssessment] = useState('')
  const assessmentSelected = (assessment) => {
    dispatch(fetchAssessmentQuestions({ jwt, id: assessment?._id }))
    setSelectedAssessment(assessment)
    openExamPopup()
  }

  const onClickTakeAssessment = (id, title, batchSubjectId) => {
    dispatch(setAssessment(id, title, selectedAssessment))
    dispatch(setBatchSubjectId(batchSubjectId))
    history.push(`/assessments/questions`)
  }

  const onTakeSelfAssessment = (topicId, topicTitle, batchSubjectId) => {
    dispatch(setTopic(topicId, topicTitle))
    dispatch(setBatchSubjectId(batchSubjectId))
    history.push('/me/subjects/chapters/topics/assessment')
  }

  const onClickResult = (assessment) => {
    dispatch(setAssessment(assessment._id, assessment.title))
    dispatch(setBatchSubjectId(assessment.batch_subject_id))
    history.push(`/assessments/results`)
  }

  const getimer = (startDate) => {
    // start time and end time
    var startTime = currentTime
    var endTime = moment(startDate)

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime))

    // duration in hours
    var hours = parseInt(duration.asHours())

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60

    // duration in minutes
    var seconds = parseInt(duration.asSeconds()) % 60
    if (seconds < 0) {
      return ''
    }
    return '( ' + hours + ':' + minutes + ':' + seconds + ' )'
  }

  return (
    <div className="body__container">
      <section>
        <h1>Assesments</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Assesments</li>
          </ol>
        </nav>
        <div className="clear"></div>
      </section>
      <section className="tabs__wrapper">
        <Tabs>
          <Tab
            eventKey="examination"
            title={`Examinations (${nonEvaluted?.length + evaluated?.length})`}
          >
            <Tabs className="square__tabs square__tabs--twoItems studAssessment__tab">
              <Tab
                eventKey="examinationattemptUpcomming"
                title={`Upcoming Examinations (${
                  nonEvaluted?.filter((assessment) =>
                    moment().isSameOrBefore(moment(assessment?.due_date))
                  )?.length
                })`}
              >
                <p className="selfAssesment__info">
                  A formal test that you take to show your knowledge or ability
                  in a particular subject, or to obtain a qualification.
                </p>
                {nonEvaluted?.length
                  ? nonEvaluted
                      ?.filter((assessment) =>
                        moment().isSameOrBefore(moment(assessment?.due_date))
                      )
                      ?.sort(
                        (a, b) => new Date(a?.due_date) - new Date(b?.due_date)
                      )
                      ?.map((assessment, index) => {
                        return (
                          <ExamCard
                            key={index}
                            time={getimer(assessment?.start_date)}
                            onClick={() => {
                              !assessment.has_taken &&
                                assessment?.start_date &&
                                moment().isSameOrAfter(
                                  moment(assessment?.start_date)
                                ) &&
                                assessment?.due_date &&
                                moment().isSameOrBefore(
                                  moment(assessment?.due_date)
                                ) &&
                                assessmentSelected(assessment)
                            }}
                            heading={assessment?.title}
                            startDate={moment(assessment?.start_date).format(
                              'DD-MM-YYYY | hh:mm A'
                            )}
                            submitDate={moment(assessment?.due_date).format(
                              'DD-MM-YYYY | hh:mm A'
                            )}
                            noOfQuestions={assessment?.questions_count || 0}
                            timeToComplete="60 minutes (HC)"
                            teacherImg={ProfilePic}
                            teacher={assessment.author_name}
                            score=""
                            button={
                              !assessment.has_taken
                                ? assessment?.start_date &&
                                  moment().isSameOrAfter(
                                    moment(assessment?.start_date)
                                  ) &&
                                  assessment?.due_date &&
                                  moment().isSameOrBefore(
                                    moment(assessment?.due_date)
                                  )
                                  ? 'START EXAMINATION NOW'
                                  : moment().isAfter(
                                      moment(assessment?.due_date)
                                    )
                                  ? 'Missed'
                                  : 'Not Started'
                                : 'Submitted'
                            }
                            buttonColor={
                              !assessment.has_taken
                                ? assessment?.start_date &&
                                  moment().isSameOrAfter(
                                    moment(assessment?.start_date)
                                  ) &&
                                  assessment?.due_date &&
                                  moment().isSameOrBefore(
                                    moment(assessment?.due_date)
                                  )
                                  ? 'blue'
                                  : 'disable'
                                : 'disable'
                            }
                            disclaimer="yes"
                            message="You cannot retake the Assessments after submitting."
                          />
                        )
                      })
                  : noExaminationAvailable()}
              </Tab>
              <Tab
                eventKey="examinationattemptPrevious"
                title={`Previous Examinations (${
                  nonEvaluted?.filter((assessment) =>
                    moment().isAfter(moment(assessment?.due_date))
                  )?.length
                })`}
              >
                <p className="selfAssesment__info">
                  A formal test that you take to show your knowledge or ability
                  in a particular subject, or to obtain a qualification.
                </p>
                {nonEvaluted?.length
                  ? nonEvaluted
                      ?.filter((assessment) =>
                        moment().isAfter(moment(assessment?.due_date))
                      )
                      ?.sort(
                        (a, b) => new Date(b?.due_date) - new Date(a?.due_date)
                      )
                      ?.map((assessment, index) => {
                        return (
                          <ExamCard
                            key={index}
                            time={''}
                            onClick={() => {
                              !assessment.has_taken &&
                                assessment?.start_date &&
                                moment().isSameOrAfter(
                                  moment(assessment?.start_date)
                                ) &&
                                assessment?.due_date &&
                                moment().isSameOrBefore(
                                  moment(assessment?.due_date)
                                ) &&
                                assessmentSelected(assessment)
                            }}
                            heading={assessment?.title}
                            startDate={moment(assessment?.start_date).format(
                              'DD-MM-YYYY | hh:mm A'
                            )}
                            submitDate={moment(assessment?.due_date).format(
                              'DD-MM-YYYY | hh:mm A'
                            )}
                            noOfQuestions={assessment?.questions_count || 0}
                            timeToComplete="60 minutes (HC)"
                            teacherImg={ProfilePic}
                            teacher={assessment.author_name}
                            score=""
                            button={
                              !assessment.has_taken
                                ? assessment?.start_date &&
                                  moment().isSameOrAfter(
                                    moment(assessment?.start_date)
                                  ) &&
                                  assessment?.due_date &&
                                  moment().isSameOrBefore(
                                    moment(assessment?.due_date)
                                  )
                                  ? 'START EXAMINATION NOW'
                                  : moment().isAfter(
                                      moment(assessment?.due_date)
                                    )
                                  ? 'Missed'
                                  : moment().isBefore(
                                      moment(assessment?.start_date)
                                    )
                                  ? 'Not Started'
                                  : 'START EXAMINATION NOW'
                                : 'Submitted'
                            }
                            buttonColor={
                              !assessment.has_taken
                                ? assessment?.start_date &&
                                  moment().isSameOrAfter(
                                    moment(assessment?.start_date)
                                  ) &&
                                  assessment?.due_date &&
                                  moment().isSameOrBefore(
                                    moment(assessment?.due_date)
                                  )
                                  ? 'blue'
                                  : 'disable'
                                : 'disable'
                            }
                            disclaimer="yes"
                            message="You cannot retake the Assessments after submitting."
                          />
                        )
                      })
                  : noExaminationAvailable()}
              </Tab>
              <Tab
                eventKey="examinationResult"
                title={`Results(0)`}
                // title={`Results(${evaluated?.length})`}
              >
                <p className="selfAssesment__info">
                  Your results for the submitted examinations will appear here.
                </p>
                {noResultsAvailable()}
                {/* {evaluated?.length
                  ? evaluated.map((assessment, index) => {
                      return (
                        <ExamCard
                          key={index}
                          time={''}
                          onClick={() => onClickResult(assessment)}
                          heading={assessment?.title}
                          submitDate={moment(assessment?.updated_at).format(
                            'llll'
                          )}
                          noOfQuestions={assessment?.questions_count || 0}
                          timeToComplete="60 minutes (HC)"
                          teacherImg={ProfilePic}
                          teacher={assessment.author_name}
                          score=""
                          button="VIEW RESULTS"
                          buttonColor="blue"
                          disclaimer="no"
                          scoreDisplay="no"
                        />
                      )
                    })
                  : noResultsAvailable()} */}
              </Tab>
            </Tabs>
          </Tab>
          <Tab
            eventKey="selfAssesment"
            title={`Self Assesments (${localSelfAssessments?.length || 0})`}
          >
            <p className="selfAssesment__info">
              The purpose of self-assessment is to help the individual know the
              extent of his abilities and to improve upon them without the need
              of a performance appraiser.
            </p>
            {localSelfAssessments?.length
              ? localSelfAssessments?.map((assessment, index) => {
                  return (
                    <SelfAssesmentCard
                      key={index}
                      img="https://cwxdata.s3.ap-south-1.amazonaws.com/phy.jpg"
                      onClick={() =>
                        onTakeSelfAssessment(
                          assessment.topic_id,
                          assessment.topic_title,
                          assessment.batch_subject_id
                        )
                      }
                      heading={assessment.topic_title}
                      subject={getBatchSubjectTitleById(
                        assessment.batch_subject_id
                      )}
                      // chapter={assessment.topicName || 'HC'}
                      // attempts="3"
                      noOfQuestions={assessment?.questions?.length || 0}
                      // timeToComplete="60 minutes"
                      score={`${assessment.scored_point} /${assessment.total_point}`}
                      button="TAKE SELF ASSESMENT"
                      // teacher = "Tom, Sujay"
                    />
                  )
                })
              : noSelfAssessmentAvailable()}
          </Tab>
        </Tabs>
      </section>
      <PopupCustom
        className={examPopClassName}
        close={() => closeExamPopup()}
        popupHead="Start Examination"
      >
        <div className="self__assessment--popup">
          <img
            className="self__assessmentIcon"
            src={SelfAssessmentStart}
            alt="START SELF ASSESMENT"
          />
          <h2>{selectedAssessment?.title}</h2>
          <img
            className="assessment__teachersIcon"
            src={ProfilePic}
            alt="Teacher"
          />
          <p className="assessment__teacherName">
            Teacher: {selectedAssessment.author_name}
          </p>
          <hr></hr>
          <p className="assessment__details">
            Number of questions: <span>{assessmentQuestions?.length}</span>{' '}
            {/* <span className="pipe">|</span> Ideal Time to Complete:{' '}
            <span>60 minutes (hardcoded)</span> */}
          </p>
          <button
            onClick={(e) =>
              onClickTakeAssessment(
                selectedAssessment._id,
                selectedAssessment.title,
                selectedAssessment.batch_subject_id
              )
            }
            className="button--blue"
          >
            START EXAMINATION NOW
          </button>
          <br />
          <button
            onClick={() => closeExamPopup()}
            className="button--transparent margin--0"
          >
            LATER
          </button>
        </div>
      </PopupCustom>
      <div className="clear"></div>
    </div>
  )
}

export default MyAssessment
