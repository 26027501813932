import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import _ from 'underscore'

//assests
import Loader from '../assets/molecules/loader'
import LeftRightLayout from '../assets/layout/leftrightlayout'
import Col75Right from '../assets/layout/leftrightlayout/column75Right'
import Col25Left from '../assets/layout/leftrightlayout/column25Left'
import SubjectDoubtCard from '../assets/molecules/subjectDoubtsCard'
import ChaptersCard from '../assets/molecules/chaptersCardWithOutProgress'
import PopupCreateLiveSession from '../../components/assets/layout/popupForm'
import Popup from '../../components/assets/layout/popupConfirm'
import Select from '../assets/molecules/select'
import AddItems from '../assets/molecules/addItems'
import AddChapter from '../../assets/images/addChapter.png'
import { subjectIcons, subjectPics, usePrevious } from '../helpers'
import { AlertSuccess } from '../assets/molecules/alerts'

//action
import {
  fetchChaptersBySubjectId,
  postCreateChapter,
  deleteChapter,
  editChapter,
} from '../../redux/action/chapter'
import { fetchUserSubjects } from '../../redux/action/subject'
import { setChapter, setSubject } from '../../redux/action/ui'

//reducer
import { getJwt } from '../../redux/reducer/account'
import { isSubjectsloading, getSubjects } from '../../redux/reducer/subject'
import {
  getChapters,
  getIsChaptersLoading,
  getIsError as getIsChapterError,
} from '../../redux/reducer/chapter'

const Subjects = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const subjectPicKeys = Object.keys(subjectPics)

  //state
  const [selectedSubject, setSelectedSubject] = useState('')
  const [selectedBatch, setSelectedBatch] = useState('')
  const [formData, setFormData] = useState()
  const [popup, setPopup] = useState('hidePop')
  const [errMsg, setErrMsg] = useState('')
  const [batches, setBatches] = useState([])
  const [localSubjects, setLocalSubjects] = useState([])
  const [showSuccess, setShowSuccess] = useState(false)
  const [showEditSuccess, setShowEditSuccess] = useState(false)
  const [pressStatus, setPressStatus] = useState('')

  //selector
  const jwt = useSelector(getJwt)
  const isLoading = useSelector(isSubjectsloading)
  const subjects = useSelector(getSubjects)
  const isChapterLoading = useSelector(getIsChaptersLoading)
  const isChapterLoadingPrev = usePrevious(isChapterLoading)
  const isChapterError = useSelector(getIsChapterError)
  let chapters = useSelector(getChapters)

  useEffect(() => {
    dispatch(fetchUserSubjects({ jwt }))
  }, [dispatch, jwt])

  useEffect(() => {
    setLocalSubjects(subjects)
    subjects?.length && setSelectedSubject(subjects[0])
    subjects?.length &&
      dispatch(setSubject(subjects[0].subject_id, subjects[0].subject_title))
    let batch = subjects?.map((subject) => {
      return {
        id: subject.batch_id,
        name: subject.batch_title,
      }
    })
    setBatches(_.uniq(batch, (b) => b.id))
  }, [subjects]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedSubject)
      dispatch(
        fetchChaptersBySubjectId({
          jwt,
          subjectId: selectedSubject?.subject_id,
        })
      )
  }, [dispatch, jwt, selectedSubject])

  useEffect(() => {
    if (!isChapterLoading && !isChapterError) {
      closePopup()
      setFormData('')
    }
  }, [isChapterLoading, isChapterError])

  useEffect(() => {
    if (
      isChapterLoadingPrev !== undefined &&
      !isChapterLoading &&
      !isChapterError
    ) {
      setShowSuccess(true)
      setShowEditSuccess(true)
    }
  }, [isChapterError, isChapterLoading, isChapterLoadingPrev])

  const onChange = (e) => {
    setErrMsg('')
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    const { title, description, subjectId } = formData
    if (title && description && subjectId) {
      dispatch(postCreateChapter({ jwt, subjectId, formData }))
      const filteredSub = subjects.filter(
        (sub) => sub?.subject_id === subjectId
      )
      setSelectedSubject(filteredSub[0])
      closePopup()
    } else {
      setErrMsg('Please fill all form fields')
      setSelectedBatch('')
    }
    setFormData('')
    setSelectedBatch('')
    setPressStatus('create')
  }

  const showPopup = () => {
    setErrMsg('')
    setPopup('showPop')
    setShowSuccess(false)
    setShowEditSuccess(false)
    setPressStatus('')
  }

  const closePopup = () => {
    setPopup('hidePop')
  }

  const onChangeBatch = (e) => {
    const batchId = e.target.value
    if (batchId) {
      setSelectedBatch(batchId)
      const filteredSubjects = subjects?.filter(
        (subject) => subject.batch_id === batchId
      )
      setLocalSubjects(filteredSubjects)
      setSelectedSubject(filteredSubjects[0] ? filteredSubjects[0] : '')
    } else {
      setLocalSubjects(subjects)
    }
  }

  const onSubjectSelect = (subject) => {
    dispatch(setSubject(subject.subject_id, subject.subject_title))
    setSelectedSubject(subject)
  }

  const onChapterSelect = (chapter) => {
    const id = chapter._id,
      title = chapter.title
    dispatch(setChapter(id, title))
    history.push('/me/faculty/subjects/chapters/topics')
  }

  const [editChapterPopClassName, setEditChapterPopClassName] = useState(
    'hidePop'
  )
  const [deleteChapterPopClassName, setDeleteChapterPopClassName] = useState(
    'hidePop'
  )
  const [selectedChapter, setSelectedChapter] = useState('')
  const [isEdit, setIsEdit] = useState(false)

  const openEditChapterPopup = (chapter) => {
    setErrMsg('')
    setIsEdit(true)
    setFormData({
      title: chapter.title,
      description: chapter.description,
      subjectId: chapter.subject_id,
    })
    const chapterSubjectObj = subjects?.filter(
      (sub) => sub?.subject_id === chapter.subject_id
    )[0]
    setEditChapterPopClassName('showPop')
    setSelectedChapter(chapter)
    if (chapterSubjectObj) {
      setSelectedBatch(chapterSubjectObj?.batch_id)
    }
    setShowEditSuccess(false)
    setPressStatus('')
  }

  const openDeleteChapterPopup = (chapter) => {
    setDeleteChapterPopClassName('showPop')
    setSelectedChapter(chapter)
  }

  const closeEditChapterPopup = () => {
    setIsEdit(false)
    setFormData('')
    setSelectedBatch('')
    setEditChapterPopClassName('hidePop')
  }

  const closeDeleteChapterPopup = () => {
    setDeleteChapterPopClassName('hidePop')
  }

  const onChapterEdit = () => {
    dispatch(
      editChapter({
        jwt,
        chapterId: selectedChapter._id,
        formData,
        subjectId: selectedChapter.subject_id,
      })
    )
    closeEditChapterPopup()
    setIsEdit(false)
    setFormData('')
    setSelectedBatch('')
    setPressStatus('edit')
  }

  const onChapterDelete = () => {
    dispatch(
      deleteChapter({
        chapterId: selectedChapter._id,
        jwt,
      })
    )
    setSelectedChapter('')
    closeDeleteChapterPopup()
  }

  const [searchData, setSearchData] = useState('')
  const onChangeSearch = (e) => {
    const data = e.target.value.toLowerCase()
    setSearchData(data)
    const filteredSubjects = subjects.filter((subject) => {
      const subjectName = subject.subject_title.toLowerCase()
      return subjectName.includes(data)
    })
    setLocalSubjects(filteredSubjects)
  }
  const onChangeSubjectInMob = (event) => {
    const filteredSubjects = subjects.filter(
      (subject) => subject?._id === event.target.value
    )
    setSelectedSubject(filteredSubjects[0])
  }

  const getSubjectNameById = (id) => {
    const filteredSubject = subjects?.filter(
      (subject) => subject.subject_id === id
    )
    return filteredSubject.length ? filteredSubject[0].subject_title : ''
  }

  return (
    <div className="tabs__container">
      <section>
        <div className="row">
          <div className="col-md-8 padding--0">
            <h1>Subjects</h1>
          </div>
          <div className="col-md-4 breadcrumb__rightSide padding--0">
            <button
              onClick={() => {
                showPopup()
              }}
              className="button--blue"
            >
              ADD CHAPTER
            </button>
          </div>
          <div className="clear"></div>
        </div>
        <div className="row">
          <div className="col-md-8 padding--0">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Subjects</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-4 breadcrumb__rightSide padding--0">
            <div className="customTab__dropdowns">
              <Select name="batch" onChange={onChangeBatch}>
                <option value="">All classes ({batches.length})</option>
                {batches.map((batch) => (
                  <option value={batch.id}>{batch.name}</option>
                ))}
              </Select>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <PopupCreateLiveSession
          className={!isEdit ? popup : editChapterPopClassName}
          close={() => {
            closePopup()
            closeEditChapterPopup()
          }}
          onClickButton={!isEdit ? onSubmit : onChapterEdit}
          buttonText={isEdit ? 'EDIT CHAPTER' : 'CREATE CHAPTER'}
          popupHead={isEdit ? 'Edit Chapter' : 'Create Chapter'}
        >
          <form>
            <ol>
              <li>
                <h2>What is this chapter about?</h2>
                <p>
                  This information will help the students understand what this
                  chapter is all about.
                </p>
                <div className="form-group sessionTitle">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter chapter title"
                    name="title"
                    value={formData?.title || ''}
                    onChange={(e) => {
                      setErrMsg('')
                      onChange(e)
                    }}
                  />
                </div>
                <div className="form-group sessionDescription">
                  <textarea
                    name="description"
                    value={formData?.description || ''}
                    onChange={onChange}
                    placeholder="Chapter Description"
                  ></textarea>
                </div>
              </li>
              <li>
                <h2>Who is this chapter for?</h2>
                <p>
                  This chapter will be added to the syllabus of the students in
                  the following class.
                </p>
                <div className="form-row">
                  <div className="form-group col sessionTopic">
                    <select
                      id="inputState"
                      className="form-control"
                      name="batchId"
                      onChange={(e) => {
                        setErrMsg('')
                        setSelectedBatch(e.target.value)
                      }}
                    >
                      <option value="" selected>
                        Select Batch
                      </option>
                      {batches?.map((batch, index) => {
                        return (
                          <option
                            key={index}
                            value={batch.id}
                            selected={selectedBatch === batch.id}
                          >
                            {batch.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                {selectedBatch && (
                  <div className="form-row">
                    <div className="form-group col sessionTopic">
                      <select
                        id="inputState"
                        className="form-control"
                        name="subjectId"
                        onChange={(e) => {
                          setErrMsg('')
                          onChange(e)
                        }}
                      >
                        <option value="" selected>
                          Select Subject
                        </option>
                        {subjects
                          ?.filter(
                            (subject) => selectedBatch === subject?.batch_id
                          )
                          .map((subject, index) => {
                            return (
                              <option
                                key={index}
                                value={subject.subject_id}
                                selected={
                                  subject.subject_id === formData?.subjectId
                                    ? true
                                    : false
                                }
                              >
                                {subject.subject_title}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                  </div>
                )}
              </li>
            </ol>
            {errMsg && <div className="alert alert-danger">{errMsg}</div>}
          </form>
        </PopupCreateLiveSession>
        <div className="clear"></div>
      </section>
      <section>
        <LeftRightLayout>
          <Col25Left className="padding--0">
            <h2 className="subjects--head">Subject list</h2>
            <div className="desktop">
              <div className="search__container">
                <input
                  type="text"
                  className="search"
                  value={searchData || ''}
                  onChange={onChangeSearch}
                  placeholder="Search"
                />
              </div>
              {isLoading ? (
                <Loader />
              ) : localSubjects?.length ? (
                localSubjects?.map((subject, index) => {
                  return (
                    <SubjectDoubtCard
                      key={index}
                      className={
                        selectedSubject?._id === subject?._id
                          ? 'subjectDoubtsCard--active'
                          : 'subjectDoubtsCard--noactive'
                      }
                      icon={
                        subjectIcons[subject.subject_title.toLowerCase()]
                          ? subjectIcons[subject.subject_title.toLowerCase()]
                          : subjectIcons['others']
                      }
                      subjectName={subject.subject_title}
                      batchName={subject.batch_title}
                      subjectCode={subject.subject_code}
                      notes="ll"
                      // noOfTopics={
                      //   doubts.filter(
                      //     (doubt) => doubt?.batch_subject_id === subject?._id
                      //   ).length
                      // }
                      onClick={() => onSubjectSelect(subject)}
                    />
                  )
                })
              ) : (
                <p className="padd--25">No subjects avilable</p>
              )}
            </div>

            <div className="mob padd--LR25 text-center">
              <select
                className="select__subject"
                onChange={(event) => onChangeSubjectInMob(event)}
              >
                {isLoading ? (
                  <Loader />
                ) : localSubjects?.length ? (
                  localSubjects?.map((subject, index) => {
                    return (
                      <option value={subject?._id}>
                        {subject.subject_title}
                      </option>
                    )
                  })
                ) : (
                  <option>No subjects avilable</option>
                )}
              </select>
              {/* <select className="select__subject"  onChange={(event) => onChangeSubjectInMob(event)}>
                {isLoadingSubjects ? (
                  <Loader />
                ) : subjects?.length ? (
                  subjects?.map((subject, index) => {
                    return (
                      <option value={subject?._id}>
                        {subject.subject_title}
                      </option>
                    );
                  })
                ) : (
                  <React.Fragment>
                    <option>No subjects avilable</option>
                  </React.Fragment>
                )}
              </select> */}
            </div>
          </Col25Left>
          <Col75Right>
            <h2>
              Chapters (
              {localSubjects?.length && chapters?.length ? chapters?.length : 0}
              )
            </h2>
            {isChapterLoading ? (
              <Loader />
            ) : (
              <section className="chaptersCard--withEdit">
                {chapters?.length && localSubjects?.length
                  ? chapters?.map((chapter, index) => {
                      const subjectName = getSubjectNameById(
                        chapter.subject_id
                      ).toLowerCase()
                      const filteredKeys = subjectPicKeys.filter((key) =>
                        subjectName.includes(key)
                      )
                      const pic = subjectPics[filteredKeys[0]]
                      return (
                        <ChaptersCard
                          key={index}
                          img={pic}
                          // img="https://cwxdata.s3.ap-south-1.amazonaws.com/phy.jpg"
                          chapterName={chapter.title}
                          description={chapter.description}
                          onClick={() => {
                            onChapterSelect(chapter)
                          }}
                          noOfTopics={chapter.topic_count}
                          editOnClick={() => openEditChapterPopup(chapter)}
                          deleteOnClick={() => openDeleteChapterPopup(chapter)}
                        />
                      )
                    })
                  : null}
                <AddItems
                  imageURL={AddChapter}
                  text="+ ADD CHAPTER"
                  onClick={() => showPopup()}
                />
                <div className="clear"></div>
              </section>
            )}
            <Popup
              className={deleteChapterPopClassName}
              close={() => {
                closeDeleteChapterPopup()
              }}
              onClick={() => onChapterDelete()}
              buttonText="yes"
              popupHead="Delete Chapter"
            >
              <h2>Are you sure want to delete?</h2>
            </Popup>
          </Col75Right>
        </LeftRightLayout>
      </section>
      {showSuccess && pressStatus === 'create' ? (
        <AlertSuccess>You have successfully added a Chapter</AlertSuccess>
      ) : null}
      {showEditSuccess && pressStatus === 'edit' ? (
        <AlertSuccess>You have successfully edited a Chapter</AlertSuccess>
      ) : null}
      <div className="clear"></div>
    </div>
  )
}

export default Subjects
