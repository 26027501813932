import {
  APP_LOGOUT,
  POST_IMPRESSIONS_START,
  POST_IMPRESSIONS_SUCCESS,
  POST_IMPRESSIONS_FAILURE,
  GET_LAST_VIEWED_START,
  GET_LAST_VIEWED_SUCCESS,
  GET_LAST_VIEWED_FAILURE,
  GET_PENDING_ITEMS_START,
  GET_PENDING_ITEMS_SUCCESS,
  GET_PENDING_ITEMS_FAILURE,
} from '../actions.js'

const initialState = {
  impressions: '',
  error: false,
  message: '',
  isLoadingLastVisted: false,
  last_visited_topics: '',
  isLoadingPendingItems: false,
  pending_items: '',
}

export const impressions = (state = initialState, action) => {
  switch (action.type) {
    case APP_LOGOUT:
      return initialState
    case POST_IMPRESSIONS_START:
      return Object.assign({}, state, {
        isLoading: true,
        error: false,
        message: '',
      })
    case POST_IMPRESSIONS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: false,
        impressions: action.impressions,
      })
    case POST_IMPRESSIONS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        error: true,
      })
    case GET_LAST_VIEWED_START:
      return Object.assign({}, state, {
        isLoadingLastVisted: true,
        error: false,
        message: '',
      })
    case GET_LAST_VIEWED_SUCCESS:
      return Object.assign({}, state, {
        isLoadingLastVisted: false,
        error: false,
        last_visited_topics: action.last_visited_topics,
      })
    case GET_LAST_VIEWED_FAILURE:
      return Object.assign({}, state, {
        isLoadingLastVisted: false,
        error: true,
      })
    case GET_PENDING_ITEMS_START:
      return Object.assign({}, state, {
        isLoadingPendingItems: true,
        error: false,
        message: '',
      })
    case GET_PENDING_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        isLoadingPendingItems: false,
        error: false,
        pending_items: action.pending_items,
      })
    case GET_PENDING_ITEMS_FAILURE:
      return Object.assign({}, state, {
        isLoadingPendingItems: false,
        error: true,
      })
    default:
      return state
  }
}

export const getImpressions = (state) => state.impressions.impressions
export const getlastVisitedTopics = (state) =>
  state.impressions.last_visited_topics
export const getisLoadingLastVisted = (state) =>
  state.impressions.isLoadingLastVisted

export const getPendingTopics = (state) => state.impressions.pending_items
export const getisLoadingPendingTopics = (state) =>
  state.impressions.isLoadingPendingItems
