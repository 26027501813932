import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

//assets
import Col from '../assets/molecules/column'
import Card from '../assets/molecules/card'
import Loader from '../assets/molecules/loader'

//action
import { fetchNotesByBatchSubjectId } from '../../redux/action/note'
import { fetchTopicsByids } from '../../redux/action/topic'

//reducer
import { getIsNotesLoading, getNotes } from '../../redux/reducer/note'
import { getBatchSubjects } from '../../redux/reducer/subject'
import { getJwt } from '../../redux/reducer/account'
import { getBatchSubjectId } from '../../redux/reducer/ui'
import { getTopicsById } from '../../redux/reducer/topic'
import _ from 'underscore'

const NotesDetail = () => {
  const dispatch = useDispatch()

  const jwt = useSelector(getJwt)
  const isNotesLoading = useSelector(getIsNotesLoading)
  const batchSubjectId = useSelector(getBatchSubjectId)
  const batchSubjects = useSelector(getBatchSubjects)
  const topicsById = useSelector(getTopicsById)
  const notes = useSelector(getNotes)

  useEffect(() => {
    dispatch(fetchNotesByBatchSubjectId({ jwt, batchSubjectId }))
  }, [batchSubjectId, dispatch, jwt])

  useEffect(() => {
    const topicIdsAll = notes.map((note) => note.topic_id)
    const topicIds = _.uniq(topicIdsAll)
    dispatch(fetchTopicsByids({ jwt, topicIds }))
  }, [notes, dispatch, jwt])

  const filteredBatchSubject = batchSubjects.filter(
    (batchSubject) => batchSubject._id === batchSubjectId
  )
  const currentBatchSubject = filteredBatchSubject.length
    ? filteredBatchSubject[0]
    : {}

  return (
    <div className="tabs__container">
      <section>
        <Col no="2">
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/me/notes">{currentBatchSubject?.subject_title}</Link>
              </li>
              <li className="breadcrumb-item active">Notes</li>
            </ol>
          </nav>
        </Col>
      </section>
      <section className="margin--top100">
        {isNotesLoading ? (
          <Loader />
        ) : (
          <Card
            className="no--effect"
            columnNo="1"
            title="Notes"
            titleColor={1}
          >
            {notes.length ? (
              notes?.map((note, index) => {
                return (
                  <React.Fragment key={index}>
                    <h4>{topicsById[note.topic_id]?.title}</h4>
                    <textarea readOnly={true} value={note.note_text} rows="5" />
                  </React.Fragment>
                )
              })
            ) : (
              <p>No notes to list</p>
            )}
          </Card>
        )}
        <div className="clear"></div>
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default NotesDetail
