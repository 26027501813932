import React from 'react'
import { useSelector } from 'react-redux'
import { getPermission } from '../../redux/reducer/account.js'
import ModeratorDashboard from './moderator'
import StudentDashboard from './student'
import TeacherDashboard from './teacher'
import OwnerDashboard from './owner'

function Dashboard() {
  const entityType = useSelector((state) => getPermission(state))

  let DashboardComponent = <div />
  switch (entityType) {
    case 'moderator':
      DashboardComponent = <ModeratorDashboard />
      break
    case 'student':
      DashboardComponent = <StudentDashboard />
      break
    case 'faculty':
      DashboardComponent = <TeacherDashboard />
      break
    case 'owner':
      DashboardComponent = <OwnerDashboard />
      break
    default:
      DashboardComponent = <div> Your Login is under construction</div>
  }

  return DashboardComponent
}

export default Dashboard
