import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { usePrevious } from '../../helpers'

//assets
import Card from '../../assets/molecules/card'
import Button from '../../assets/molecules/button'

//actions
//subject
import { postBatch } from '../../../redux/action/batches'

//reducer
//account
import { getJwt } from '../../../redux/reducer/account'
import { getIsError, getIsLoading } from '../../../redux/reducer/batches'

import Loader from '../../assets/molecules/loader'

const Create = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  //selector
  const jwt = useSelector(getJwt)
  const error = useSelector(getIsError)
  const isLoading = useSelector(getIsLoading)
  const isLoadingPrev = usePrevious(isLoading)

  let [formData, setFormData] = useState({})
  let [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (error) {
      setErrorMessage('Something went wrong. Please try again.')
    }
  }, [error])

  useEffect(() => {
    if (
      isLoadingPrev !== undefined &&
      isLoadingPrev !== isLoading &&
      !isLoading &&
      !error
    ) {
      history.push({
        pathname: '/dashboard',
        state: { status: 'success' },
      })
    }
  }, [error, history, isLoading, isLoadingPrev])

  const onChange = (e) => {
    e.preventDefault()
    setFormData({ ...formData, [e.target.name]: e.target.value })
    errorMessage && setErrorMessage('')
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (
      formData?.title &&
      formData?.year &&
      formData?.year.split('-')[0].length === 4 &&
      formData?.year.split('-')[1].length === 2
    ) {
      const payload = {
        title: formData.title,
        academic_year: formData.year,
        display_order: 0,
      }
      dispatch(postBatch({ payload, jwt }))
    } else {
      setErrorMessage('Please fill the form')
    }
  }

  return (
    <div className="tabs__container">
      <section>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Home</Link>
            </li>
            <li className="breadcrumb-item active">Add Batch</li>
          </ol>
        </nav>
      </section>

      <section>
        <h4>Add Batch</h4>
        <Card columnNo="1" title="Create Batch" titleColor={1}>
          <input
            name="title"
            value={formData.title}
            type="text"
            placeholder="Title"
            onChange={onChange}
          />
          <input
            name="year"
            value={formData.year}
            type="text"
            placeholder="Academic year | Ex: 2020-21"
            onChange={onChange}
          />
          -
          {isLoading ? (
            <Loader />
          ) : (
            <Button
              className="button--small"
              color="blue"
              text="Add"
              click={(e) => onSubmit(e)}
            />
          )}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
        </Card>
      </section>
    </div>
  )
}

export default Create
