import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import Moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
//assets
import Loader from '../assets/molecules/loader'
import OnlineSessionCard from '../assets/molecules/onlineSessionCard'
import CustomTab from '../assets/molecules/customTab'
import PopupCreateLiveSession from '../../components/assets/layout/popupForm'
import NoPending from '../../assets/images/no-pending.png'
import { AlertSuccess } from '../assets/molecules/alerts'

import { subjectPics, usePrevious, useInterval } from '../helpers'

//action
import {
  fetchOnlineSessions,
  postOnlineSession,
  postCustomSession,
} from '../../redux/action/onlineSession'
import {
  fetchBatchSubjects,
  fetchBatchSubjectDataById,
} from '../../redux/action/subject'
import { fetchBatches } from '../../redux/action/batches'

//reducers
import {
  isSubjectsloading,
  getBatchSubjects,
  getBatchSubject,
} from '../../redux/reducer/subject'
import {
  getIsLoading,
  getOnlineSessions,
  getIsError,
} from '../../redux/reducer/onlineSession'
import {
  getBatches,
  getIsLoading as getIsLoadingBatches,
} from '../../redux/reducer/batches'
import { getJwt, getUserId, getEntityId } from '../../redux/reducer/account'

const OnlineSession = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  //selectors
  //account
  const jwt = useSelector((state) => getJwt(state))
  const subjects = useSelector(getBatchSubjects)
  const error = useSelector(getIsError)
  const isLoading = useSelector(getIsLoading)
  const isLoadingPrev = usePrevious(isLoading)
  const userId = useSelector(getUserId)
  const allSessions = useSelector(getOnlineSessions)
  const batches = useSelector(getBatches)
  const isLoadingSubjects = useSelector(isSubjectsloading)
  const isLoadingSubjectsPrev = usePrevious(isLoadingSubjects)
  const isLoadingBatches = useSelector(getIsLoadingBatches)
  const isLoadingBatchesPrev = usePrevious(isLoadingBatches)
  const batchSubjects = useSelector(getBatchSubject)
  const entityId = useSelector(getEntityId)

  const [popCreateLiveSession, setPopCreateLiveSession] = useState('hidePop')
  const [upcomingSessions, setUpcomingSessions] = useState([])
  const [previousSessions, setPreviousSessions] = useState([])
  const [selectedTab, setSelectedTab] = useState('Upcoming')
  const [selectedSubject, setSelectedSubject] = useState('')
  const [selectedBatch, setSelectedBatch] = useState('')
  const [teacherSubjects, setTeacherSubjects] = useState([])
  const [teacherBatches, setTeacherBatches] = useState([])
  const [errMsg, setErrMsg] = useState('')
  const [currentTime, setCurrentTime] = useState()
  const [createdSession, setcreatedSession] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [popup, setPopup] = useState('hidePop')
  const [listPopup, setListPopup] = useState('hidePop')
  const [confirmPopup, setConfirmPopup] = useState('hidePop')
  const [students, setStudents] = useState([])
  const [check, setCheck] = useState(false)

  useEffect(() => {
    dispatch(fetchOnlineSessions({ jwt }))
  }, [dispatch, jwt])

  useInterval(() => {
    setCurrentTime(Moment())
  }, 1000)

  useEffect(() => {
    if (!isLoading && isLoadingPrev !== undefined && !error) {
      if (createdSession) {
        closeLiveSessionPopup()
        dispatch(fetchOnlineSessions({ jwt }))
        setcreatedSession(false)
        setShowSuccess(true)
      } else {
        dispatch(fetchBatchSubjects({ jwt }))
      }
    } else if (error) {
      setErrMsg('Error Creating Live Session, Please Try again later.')
    }
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoadingSubjects && isLoadingSubjectsPrev !== undefined) {
      dispatch(fetchBatches({ jwt }))
    }
  }, [isLoadingSubjects]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoadingBatches && isLoadingBatchesPrev !== undefined) {
      const batchIds = subjects?.map((sub) => sub?.batch_id)
      const filteredBatches = batches?.filter((batch) =>
        batchIds?.includes(batch?._id)
      )
      setTeacherBatches(filteredBatches)
      setSelectedBatch(filteredBatches[0])
      setFormData({ ...formData, batch: filteredBatches[0]?._id })
    }
  }, [isLoadingBatches]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedBatch) {
      const filteredSubjects = subjects?.filter(
        (sub) => sub?.batch_id === selectedBatch?._id
      )
      setTeacherSubjects(filteredSubjects)
      setSelectedSubject(filteredSubjects[0])
      setFormData({ ...formData, subject: filteredSubjects[0]?._id })
    }
  }, [selectedBatch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedSubject) {
      const currentUsersSessions = allSessions.filter(
        (session) => session.author_id === userId
      )

      const upcomingSessions = currentUsersSessions.filter(
        (session) =>
          Moment(session.start_time).isSameOrAfter(new Date()) &&
          (session?.batch_subject_id === selectedSubject?._id ||
            session?.subject_id === selectedSubject?._id)
      )
      const previousSessions = currentUsersSessions.filter(
        (session) =>
          Moment(session.start_time).isBefore(new Date()) &&
          (session?.batch_subject_id === selectedSubject?._id ||
            session?.subject_id === selectedSubject?._id)
      )
      setUpcomingSessions(
        upcomingSessions.sort(
          (a, b) => new Date(a?.start_time) - new Date(b?.start_time)
        )
      )
      setPreviousSessions(
        previousSessions.sort(
          (a, b) => new Date(b?.start_time) - new Date(a?.start_time)
        )
      )
    }
  }, [selectedSubject]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (batchSubjects?.students !== []) {
      const arr = batchSubjects?.students?.map((data) => {
        return {
          id: data.id,
          name: data.name,
          checked: false,
        }
      })
      setStudents(arr)
    }
  }, [batchSubjects])

  const getSubjectNameByBatchSubjectId = (id) => {
    const filteredSubject = subjects.filter((subject) => subject._id === id)
    const currentSubject = filteredSubject.length > 0 ? filteredSubject[0] : {}
    return currentSubject.subject_title
  }

  const getBatchNameByBatchSubjectId = (id) => {
    const filteredSubject = subjects.filter((subject) => subject._id === id)
    const currentSubject = filteredSubject.length > 0 ? filteredSubject[0] : {}
    return currentSubject.batch_title
  }

  const onClickedAttend = (e, session) => {
    history.push({
      pathname: '/me/session/detail',
      state: { session },
    })
  }

  const createLiveSessionPopup = () => {
    setErrMsg('')
    setFormData({ start: new Date(), end: new Date(), batch: '', subject: '' })
    setPopCreateLiveSession('showPop')
  }
  const closeLiveSessionPopup = () => {
    setPopCreateLiveSession('hidePop')
  }

  const showPopup = () => {
    setFormData({
      batchName: '',
      batch_id: '',
      subjectName: '',
      subject_id: '',
      title: '',
      total_students: 0,
      start: new Date(),
      end: new Date(),
    })
    setPopup('showPop')
    setShowSuccess(false)
  }
  const closePopup = () => {
    setPopup('hidePop')
    setFormData({})
  }

  const getimer = (time) => {
    // start time and end time
    var startTime = currentTime
    var endTime = Moment(time)

    // calculate total duration
    var duration = Moment.duration(endTime.diff(startTime))

    // duration in hours
    var hours = parseInt(duration.asHours())

    // duration in minutes
    var minutes = parseInt(duration.asMinutes()) % 60

    // duration in minutes
    var seconds = parseInt(duration.asSeconds()) % 60
    return hours + ':' + minutes + ':' + seconds
  }

  const displaySessions = (data) => {
    return data
      ?.sort((a, b) => new Date(a?.start_time) - new Date(b?.start_time))
      ?.map((session, index) => {
        const subject = session?.batch_subject_id
          ? session?.batch_subject_id
          : session?.subject_id
        return (
          <OnlineSessionCard
            key={index}
            img={subjectPics['che']}
            onClick={(e) =>
              new Date() < new Date(session.end_time)
                ? onClickedAttend(e, session)
                : {}
            }
            heading={session.title}
            description={session?.title}
            class={getBatchNameByBatchSubjectId(subject)}
            subject={getSubjectNameByBatchSubjectId(subject)}
            date={Moment(session.start_time).format('Do MMMM YYYY')}
            timing={
              Moment(session.start_time).format('hh:mm A') +
              ' - ' +
              Moment(session.end_time).format('hh:mm A')
            }
            button="Attend"
            buttonClass={
              new Date() < new Date(session.end_time)
                ? 'button--blue'
                : 'button--disable'
            }
            moreDetails={
              selectedTab === 'Upcoming'
                ? 'in ' + getimer(session.start_time)
                : ''
            }
          />
        )
      })
  }

  const [formData, setFormData] = useState({
    start: new Date(),
    end: new Date(),
  })
  const onChange = (e) => {
    setErrMsg('')
    if (e.target.name === 'batch') {
      const filteredBatches = batches?.filter(
        (batch) => batch?._id === e.target.value
      )
      setSelectedBatch(filteredBatches[0])
      setFormData({ ...formData, subject: '', [e.target.name]: e.target.value })
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }
  }

  const onChangeDate = (name, date) => {
    setErrMsg('')
    let obj = {
      [name]: date,
    }
    if (name === 'start') {
      const altered =
        date >= formData?.end ||
        Moment(formData.end).diff(Moment(date), 'minutes') > 30
          ? new Date(date).setMinutes(date.getMinutes() + 30)
          : formData?.end
      obj['end'] = new Date(altered)
    }
    setFormData({ ...formData, ...obj })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const { title, start, end, batch, subject } = formData
    if (
      title &&
      title !== '' &&
      start < end &&
      batch !== '' &&
      subject !== ''
    ) {
      const payload = {
        title,
        start_time: start,
        end_time: end,
        batch_id: batch,
        batch_subject_id: subject,
      }
      dispatch(postOnlineSession({ jwt, payload }))
      setcreatedSession(true)
    } else {
      setErrMsg('Please fill all form fields')
    }
  }

  const onCustomChange = (e) => {
    e.preventDefault()
    if (e.target.name === 'batch') {
      const batchName = teacherBatches.find((batch) => {
        if (batch._id === e.target.value) return batch
      })
      setSelectedBatch(batchName)
      setFormData({
        ...formData,
        batch_id: e.target.value,
        batchName: batchName.title,
      })
    } else if (e.target.name === 'subject') {
      const subjectName = teacherSubjects.find((sub) => {
        if (sub._id === e.target.value) return sub
      })
      setFormData({
        ...formData,
        subject_id: e.target.value,
        subjectName: subjectName.subject_title,
      })
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    errMsg && setErrMsg('')
  }

  const onStudentSelect = (e, id) => {
    setErrMsg('')
    let checked = e.target.checked
    if (checked === false) {
      setStudents(
        students.map((stu) => {
          if (id === stu.id) {
            stu.checked = checked
          }
          return stu
        })
      )
      setCheck(false)
      const count = students.filter((stu) => stu.checked === true)
      setFormData({ ...formData, total_students: count.length })
    } else {
      setStudents(
        students.map((stu) => {
          if (id === stu.id) {
            stu.checked = checked
          }
          return stu
        })
      )
      const count = students.filter((stu) => stu.checked === true)
      if (students.length === count.length) setCheck(true)
      setFormData({ ...formData, total_students: count.length })
    }
  }

  const onSelectAll = (e) => {
    setErrMsg('')
    let checked = e.target.checked
    setCheck(e.target.checked)
    setStudents(
      students.map((stu) => {
        stu.checked = checked
        return stu
      })
    )
    const count = students.filter((stu) => stu.checked === true)
    setFormData({ ...formData, total_students: count.length })
  }

  const onDetailsSubmit = () => {
    if (
      formData.title &&
      formData.subject_id &&
      formData.start &&
      formData.end &&
      formData.start < formData.end
    ) {
      const id = formData.subject_id
      dispatch(fetchBatchSubjectDataById({ jwt, id }))
      setPopup('hidePop')
      setListPopup('showPop')
    } else {
      setErrMsg('Please fill the form')
    }
  }

  const onStudentsSubmit = () => {
    if (formData.total_students === 0) {
      setErrMsg('Please select students.')
    } else {
      setListPopup('hidePop')
      setConfirmPopup('showPop')
    }
  }

  const onCustomSubmit = () => {
    const idArr = students.filter((stu) => stu.checked === true)
    const studentId = idArr.map((arr) => arr.id)
    const payload = {
      title: formData.title,
      start_time: Moment(formData.start).utc().format('YYYY-MM-DD HH:mm:ss '),
      end_time: Moment(formData.end).utc().format('YYYY-MM-DD HH:mm:ss'),
      total_students: formData.total_students,
      student_ids: studentId,
      faculty_id: entityId,
      subject_id: formData.subject_id,
    }
    dispatch(postCustomSession({ jwt, payload }))
    setcreatedSession(true)
    setFormData({})
    setSelectedSubject('')
    setSelectedBatch('')
    setConfirmPopup('hidePop')
    setStudents([])
  }

  const noUpcomingSession = (type) => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">
            No new upcoming sessions are scheduled.
          </h2>
          <div className="noPending__button--Wrapper">
            <button
              className="button--blue"
              onClick={() => createLiveSessionPopup()}
            >
              CREATE LIVE SESSION
            </button>
            <button className="button--blue" onClick={() => showPopup()}>
              CREATE CUSTOM SESSION
            </button>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    )
  }

  const noPreviousSession = (type) => {
    return (
      <div className="noPending">
        <div>
          <img src={NoPending} alt="" />
          <h2 className="text-center">There are no previous sessions.</h2>
          <div className="noPending__button--Wrapper">
            <button
              className="button--blue"
              onClick={() => createLiveSessionPopup()}
            >
              CREATE LIVE SESSION
            </button>
            <button className="button--blue" onClick={() => showPopup()}>
              CREATE CUSTOM SESSION
            </button>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="body__container">
      {showSuccess && (
        <AlertSuccess onIntervalDone={() => setShowSuccess(false)}>
          You have successfully added the Session.
        </AlertSuccess>
      )}
      <section>
        <h1>Live Session</h1>
        <div className="row">
          <div className="col-md-6 padding--0">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Live Session</li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6 padding--0">
            <div className="button__wrapper">
              <button
                onClick={() => createLiveSessionPopup()}
                className="button--blue"
              >
                CREATE LIVE SESSION
              </button>
              <button onClick={() => showPopup()} className="button--blue">
                CREATE CUSTOM SESSION
              </button>
            </div>
          </div>
          <div className="clear"></div>
        </div>

        <PopupCreateLiveSession
          className={popCreateLiveSession}
          close={() => closeLiveSessionPopup()}
          onClickButton={onSubmit}
          buttonText="CREATE LIVE SESSION"
          popupHead="Create Live Session"
          isLoading={isLoading}
        >
          <form>
            <ol>
              <li>
                <h2>What is this Live session about?</h2>
                <p>
                  This information will help the students understand what this
                  Live Session is all about.
                </p>
                <div className="form-group sessionTitle">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter live session title"
                    name="title"
                    value={formData?.title || ''}
                    onChange={(e) => {
                      onChange(e)
                    }}
                  />
                </div>
              </li>
              <li>
                <h2>
                  Who is intended to attend this session and for which topic?
                </h2>
                <p>
                  This Live Session will be broadcasted to the students of the
                  following class for the following topic.
                </p>
                <div className="form-row">
                  <div className="form-group col-sm-12 col-md-6 sessionClass">
                    <select
                      id="batch"
                      className="form-control"
                      name="batch"
                      onChange={(e) => {
                        onChange(e)
                      }}
                      value={formData?.batch}
                    >
                      <option value="" selected>
                        Select class
                      </option>
                      {teacherBatches?.map((batch, index) => {
                        return (
                          <option
                            key={index}
                            value={batch._id}
                            selected={batch._id === selectedBatch?._id}
                          >
                            {batch.title}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 sessionTopic">
                    <select
                      id="subject"
                      className="form-control"
                      name="subject"
                      onChange={(e) => {
                        onChange(e)
                      }}
                      value={formData?.subject}
                    >
                      <option value="" selected>
                        Select Subject (optional)
                      </option>
                      {teacherSubjects?.map((subject, index) => {
                        return (
                          <option
                            key={index}
                            value={subject._id}
                            selected={
                              subject.subject_id === selectedSubject.subject_id
                            }
                          >
                            {subject.subject_title}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <h2>When do you want to schedule this session?</h2>
                <p>
                  Students will be able to join your LIVE Session on this date
                  and time.
                </p>
                <div className="form-row">
                  <div className="form-group col-sm-12 col-md-6 sessionDate">
                    <label>Start Time</label>
                    <br />
                    <DatePicker
                      selected={formData?.start}
                      onChange={(date) => onChangeDate('start', date)}
                      placeholderText="Select Date"
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      timeCaption="time"
                      minTime={
                        Moment(formData?.start).isSame(Date.now(), 'day')
                          ? new Date().setHours(new Date().getHours())
                          : new Date().setHours(0, 0, 0, 0)
                      }
                      maxTime={new Date().setHours(23, 59, 59, 999)}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      minDate={new Date()}
                    />
                  </div>
                  <div className="form-group col-sm-12 col-md-6 sessionSlot">
                    <label>End Time</label>
                    <br />
                    <DatePicker
                      selected={formData?.end}
                      onChange={(date) => onChangeDate('end', date)}
                      placeholderText="Select Date"
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      minDate={new Date(formData?.start)}
                      minTime={new Date().setHours(
                        new Date(formData?.start).getHours(),
                        new Date(formData?.start).getMinutes() + 1,
                        0,
                        0
                      )}
                      maxTime={new Date().setHours(23, 59, 59, 999)}
                    />
                  </div>
                </div>
              </li>
            </ol>
          </form>
          {errMsg && <div className="alert alert-danger">{errMsg}</div>}
        </PopupCreateLiveSession>

        <PopupCreateLiveSession
          className={popup}
          close={() => {
            closePopup()
          }}
          onClickButton={onDetailsSubmit}
          buttonText="Continue"
          popupHead="Create Custom Session"
        >
          <form>
            <ol>
              <li>
                <h2>What is this Live session about?</h2>
                <p>
                  This information will help the students understand what this
                  Live Session is all about.
                </p>
                <div className="form-group sessionTitle">
                  <input
                    name="title"
                    value={formData.title || ''}
                    type="text"
                    placeholder="Enter custom session title"
                    onChange={onCustomChange}
                    className="form-control"
                  />
                </div>
              </li>
              <li>
                <h2>
                  Who is intended to attend this session and for which topic?
                </h2>
                <p>
                  This Live Session will be broadcasted to the students of the
                  following class for the following topic.
                </p>
                <div className="form-row">
                  <div className="form-group col-sm-12 col-md-6 sessionClass">
                    <select
                      className="form-control"
                      name="batch"
                      onChange={(e) => {
                        onCustomChange(e)
                      }}
                      value={formData?.batch_id}
                    >
                      <option value="" selected>
                        Select class
                      </option>
                      {teacherBatches?.map((batch, index) => {
                        return (
                          <option
                            key={index}
                            value={batch._id}
                            selected={batch._id === selectedBatch?._id}
                          >
                            {batch.title}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 sessionTopic">
                    <select
                      className="form-control"
                      name="subject"
                      onChange={(e) => {
                        onCustomChange(e)
                      }}
                      value={formData?.subject_id}
                    >
                      <option value="" selected>
                        Select Subject
                      </option>
                      {teacherSubjects?.map((subject, index) => {
                        return (
                          <option
                            key={index}
                            value={subject._id}
                            selected={
                              subject.subject_id === selectedSubject.subject_id
                            }
                          >
                            {subject.subject_title}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <h2>When do you want to schedule this session?</h2>
                <p>
                  Students will be able to join your LIVE Session on this date
                  and time.
                </p>
                <div className="form-row">
                  <div className="form-group col-sm-12 col-md-6 sessionDate">
                    <label>Start Time</label>
                    <br />
                    <DatePicker
                      selected={formData?.start}
                      onChange={(date) => onChangeDate('start', date)}
                      placeholderText="Select Date"
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      timeCaption="time"
                      minTime={
                        Moment(formData?.start).isSame(Date.now(), 'day')
                          ? new Date().setHours(new Date().getHours())
                          : new Date().setHours(0, 0, 0, 0)
                      }
                      maxTime={new Date().setHours(23, 59, 59, 999)}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      minDate={new Date()}
                    />
                  </div>
                  <div className="form-group col-sm-12 col-md-6 sessionSlot">
                    <label>End Time</label>
                    <br />
                    <DatePicker
                      selected={formData?.end}
                      onChange={(date) => onChangeDate('end', date)}
                      placeholderText="Select Date"
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={30}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      minDate={new Date(formData?.start)}
                      minTime={new Date().setHours(
                        new Date(formData?.start).getHours(),
                        new Date(formData?.start).getMinutes() + 1,
                        0,
                        0
                      )}
                      maxTime={new Date().setHours(23, 59, 59, 999)}
                    />
                  </div>
                </div>
              </li>
            </ol>
            {errMsg && <div className="alert alert-danger">{errMsg}</div>}
            <div className="clear"></div>
          </form>
        </PopupCreateLiveSession>

        <PopupCreateLiveSession
          className={listPopup}
          close={() => {
            setListPopup('hidePop')
          }}
          onClickButton={onStudentsSubmit}
          buttonText="Continue"
          popupHead="Select Students"
        >
          <section>
            {isLoading ? (
              <Loader />
            ) : batchSubjects?.students && selectedSubject ? (
              <section style={{ paddingTop: 20 }}>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sl No.</th>
                        <th>Student Name</th>
                        <th>Batch</th>
                        <th>Subject</th>
                        <th>
                          <input
                            name="selectAllValues"
                            type="checkbox"
                            className="checkbox__wrapper"
                            onClick={onSelectAll}
                            checked={check}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {students?.map((student, index) => {
                        return (
                          <tr key={index}>
                            <th>{index + 1}</th>
                            <th>{student?.name}</th>
                            <th>{formData?.batchName}</th>
                            <th>{formData?.subjectName}</th>
                            <th>
                              <input
                                type="checkbox"
                                className="checkbox__wrapper"
                                onClick={(e) => onStudentSelect(e, student.id)}
                                checked={student.checked}
                              />
                            </th>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <div className="clear"></div>
                  <p className="selfAssesment__info">
                    The live session will be available only to those students
                    that are selected by the faculty.
                  </p>
                </div>
                {errMsg && <div className="alert alert-danger">{errMsg}</div>}
              </section>
            ) : null}
          </section>
        </PopupCreateLiveSession>
        <PopupCreateLiveSession
          className={confirmPopup}
          close={() => {
            setConfirmPopup('hidePop')
          }}
          onClickButton={onCustomSubmit}
          buttonText="Submit"
          popupHead="Confirmation"
        >
          <div className="table-responsive">
            <h2>Custom Session Details: </h2>
            <table className="table">
              <tr>
                <th>Title</th>
                <th>{formData.title}</th>
              </tr>
              <tr>
                <th>Batch</th>
                <th>{formData.batchName}</th>
              </tr>
              <tr>
                <th>Subject</th>
                <th>{formData.subjectName}</th>
              </tr>
              <tr>
                <th>Total students</th>
                <th>{formData.total_students}</th>
              </tr>
            </table>
          </div>
        </PopupCreateLiveSession>
      </section>
      <section className="tabs__wrapper">
        <CustomTab
          selectedTab={selectedTab}
          tabItems={{
            Upcoming: upcomingSessions?.length,
            Previous: previousSessions?.length,
          }}
          onClickTab={(item) => setSelectedTab(item)}
          subjects={teacherSubjects}
          onChangeSubjects={(selected) => {
            setSelectedSubject(selected)
          }}
          batches={teacherBatches}
          onChangeBatches={(selected) => {
            setSelectedBatch(selected)
          }}
          selectedSubject={selectedSubject?._id}
          selectedBatch={selectedBatch?._id}
        />
        <div className="clear"></div>
        {selectedTab === 'Upcoming' ? (
          isLoading ? (
            <Loader />
          ) : upcomingSessions?.length ? (
            displaySessions(upcomingSessions)
          ) : (
            noUpcomingSession()
          )
        ) : isLoading ? (
          <Loader />
        ) : previousSessions?.length ? (
          displaySessions(previousSessions)
        ) : (
          noPreviousSession()
        )}
        {/* <Tabs activeKey={toggleType} onSelect={(k) => setToggleType(k)}>
          <Tab
            eventKey="upcoming"
            title={`Upcoming (${upcomingSessions?.length})`}
          >
            {isLoading ? (
              <Loader />
            ) : upcomingSessions?.length ? (
              displaySessions(upcomingSessions)
            ) : (
              "No sessions"
            )}
          </Tab>
          <Tab
            eventKey="previous"
            title={`Previous (${previousSessions?.length})`}
          >
            {isLoading ? (
              <Loader />
            ) : previousSessions?.length ? (
              displaySessions(previousSessions)
            ) : (
              "No sessions"
            )}
          </Tab>
        </Tabs> */}
      </section>
      <div className="clear"></div>
    </div>
  )
}

export default OnlineSession
