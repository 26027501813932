import {
  ADD_NOTES_START,
  ADD_NOTES_SUCCESS,
  ADD_NOTES_FAILURE,
  GET_NOTES_BY_TOPIC_ID_START,
  GET_NOTES_BY_TOPIC_ID_SUCCESS,
  GET_NOTES_BY_TOPIC_ID_FAILURE,
  EDIT_NOTES_START,
  EDIT_NOTES_SUCCESS,
  EDIT_NOTES_FAILURE,
  DELETE_NOTES_START,
  DELETE_NOTES_SUCCESS,
  DELETE_NOTES_FAILURE,
  GET_NOTES_BY_BATCH_SUBJECT_ID_START,
  GET_NOTES_BY_BATCH_SUBJECT_ID_SUCCESS,
  GET_NOTES_BY_BATCH_SUBJECT_ID_FAILURE,
  DELETE_NOTE_START,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAILURE,
  // GET_NOTES_BY_SESSION_ID_START,
  // GET_NOTES_BY_SESSION_ID_SUCCESS,
  // GET_NOTES_BY_SESSION_ID_FAILURE,
} from '../actions'

import { checkStatus } from '../helpers.js'

const getNotesByTopicIdForCurrentStudentStart = () => {
  return {
    type: GET_NOTES_BY_TOPIC_ID_START,
  }
}

const getNotesByTopicIdForCurrentStudentSuccess = (data) => {
  return {
    type: GET_NOTES_BY_TOPIC_ID_SUCCESS,
    notes: data?.note,
  }
}

const getNotesByTopicIdForCurrentStudentFailure = () => {
  return {
    type: GET_NOTES_BY_TOPIC_ID_FAILURE,
  }
}

const notesByTopicIdForCurrentStudent = ({ jwt, topicId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/notes/topic/${topicId}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchNotesByTopicIdForCurrentStudent = (data) => {
  return async (dispatch) => {
    dispatch(getNotesByTopicIdForCurrentStudentStart())
    try {
      const receivedData = await notesByTopicIdForCurrentStudent(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getNotesByTopicIdForCurrentStudentSuccess(response?.data))
    } catch (err) {
      dispatch(getNotesByTopicIdForCurrentStudentFailure())
    }
  }
}

const addNotesStart = () => {
  return {
    type: ADD_NOTES_START,
  }
}

const addNotesSuccess = (data) => {
  const note = data?.note
  // const noteId = data?.note?._id
  return {
    type: ADD_NOTES_SUCCESS,
    note,
  }
}

const addNotesFailure = () => {
  return {
    type: ADD_NOTES_FAILURE,
  }
}

const addNotes = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/notes`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postAddnotes = (data) => {
  const { jwt, payload } = data
  return async (dispatch) => {
    dispatch(addNotesStart())
    try {
      const receivedData = await addNotes(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(
        fetchNotesByTopicIdForCurrentStudent({ jwt, topicId: payload.topic_id })
      )
      dispatch(addNotesSuccess(response?.data))
    } catch (err) {
      dispatch(addNotesFailure())
    }
  }
}

const editNotesStart = () => {
  return {
    type: EDIT_NOTES_START,
  }
}

const editNotesSuccess = (notes) => {
  return {
    type: EDIT_NOTES_SUCCESS,
    notes,
  }
}

const editNotesFailure = () => {
  return {
    type: EDIT_NOTES_FAILURE,
  }
}

const editNotes = ({ jwt, noteId, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/notes/${noteId}`
  const options = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const fetchEditnotes = ({ jwt, noteId, note_text }) => {
  return async (dispatch, getState) => {
    dispatch(editNotesStart())
    try {
      const payload = {
        note_text,
      }
      const receivedData = await editNotes({ jwt, noteId, payload })
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      let { notes } = getState().note
      notes = notes.map((note) =>
        note._id === noteId
          ? { ...note, note_text: response.note.note_text }
          : ''
      )
      dispatch(editNotesSuccess(notes))
    } catch (err) {
      dispatch(editNotesFailure())
    }
  }
}

//delete
const deleteNotesStart = () => {
  return {
    type: DELETE_NOTES_START,
  }
}

const deleteNotesSuccess = (data) => {
  const { note } = data
  return {
    type: DELETE_NOTES_SUCCESS,
    note,
  }
}

const deleteNotesFailure = () => {
  return {
    type: DELETE_NOTES_FAILURE,
  }
}

const deleteNotes = ({ jwt, noteId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/notes/${noteId}`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchDeletenotes = (data) => {
  return async (dispatch) => {
    dispatch(deleteNotesStart())
    try {
      const receivedData = await deleteNotes(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(deleteNotesSuccess(response?.data))
    } catch (err) {
      dispatch(deleteNotesFailure())
    }
  }
}

const getNotesByBatchSubjectIdStart = () => {
  return {
    type: GET_NOTES_BY_BATCH_SUBJECT_ID_START,
  }
}

const getNotesByBatchSubjectIdSuccess = (data) => {
  const { note } = data
  return {
    type: GET_NOTES_BY_BATCH_SUBJECT_ID_SUCCESS,
    notes: note,
  }
}

const getNotesByBatchSubjectIdFailure = () => {
  return {
    type: GET_NOTES_BY_BATCH_SUBJECT_ID_FAILURE,
  }
}

const getNotesByBatchSubjectId = ({ jwt, batchSubjectId }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/notes/batch-subject/${batchSubjectId}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchNotesByBatchSubjectId = (data) => {
  return async (dispatch) => {
    dispatch(getNotesByBatchSubjectIdStart())
    try {
      const receivedData = await getNotesByBatchSubjectId(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getNotesByBatchSubjectIdSuccess(response?.data))
    } catch (err) {
      dispatch(getNotesByBatchSubjectIdFailure())
    }
  }
}

const addSessionNote = ({ jwt, payload }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/notes/session`
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }
  return fetch(url, options)
}

export const postSessionNote = (data) => {
  const { jwt, payload } = data
  return async (dispatch) => {
    dispatch(addNotesStart())
    try {
      const receivedData = await addSessionNote(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(fetchNotesBySessionId({ jwt, id: payload.session_id }))
      dispatch(addNotesSuccess(response?.data))
    } catch (err) {
      dispatch(addNotesFailure())
    }
  }
}

const deleteNoteStart = () => {
  return {
    type: DELETE_NOTE_START,
  }
}

const deleteNoteSuccess = (notes) => {
  return {
    type: DELETE_NOTE_SUCCESS,
    notes,
  }
}

const deleteNoteFailure = () => {
  return {
    type: DELETE_NOTE_FAILURE,
  }
}

const deletenote = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/notes/${id}`
  const options = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const deleteNote = (data) => {
  return async (dispatch, getState) => {
    dispatch(deleteNoteStart())
    try {
      const receivedData = await deletenote(data)
      const validatedData = checkStatus(receivedData)
      await validatedData.json()
      let { notes } = getState().note
      notes = notes.filter((note) => note._id !== data.id)
      dispatch(deleteNoteSuccess(notes))
    } catch (err) {
      dispatch(deleteNoteFailure())
    }
  }
}

const getNotesBySessionIdStart = () => {
  return {
    type: GET_NOTES_BY_BATCH_SUBJECT_ID_START,
  }
}

const getNotesBySessionIdSuccess = (data) => {
  const { note } = data
  return {
    type: GET_NOTES_BY_BATCH_SUBJECT_ID_SUCCESS,
    notes: note,
  }
}

const getNotesBySessionIdFailure = () => {
  return {
    type: GET_NOTES_BY_BATCH_SUBJECT_ID_FAILURE,
  }
}

const getNotesBySessionId = ({ jwt, id }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/notes/session/${id}`
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  }
  return fetch(url, options)
}

export const fetchNotesBySessionId = (data) => {
  return async (dispatch) => {
    dispatch(getNotesBySessionIdStart())
    try {
      const receivedData = await getNotesBySessionId(data)
      const validatedData = checkStatus(receivedData)
      const response = await validatedData.json()
      dispatch(getNotesBySessionIdSuccess(response?.data))
    } catch (err) {
      dispatch(getNotesBySessionIdFailure())
    }
  }
}
