import {
  GET_ANNOUNCEMENTS_START,
  GET_ANNOUNCEMENTS_SUCCESS,
  GET_ANNOUNCEMENTS_FAILURE,
} from '../actions'

const getAnnouncementsStart = () => {
  return {
    type: GET_ANNOUNCEMENTS_START,
  }
}

const getAnnouncementsSuccess = ({ data }) => {
  return {
    type: GET_ANNOUNCEMENTS_SUCCESS,
    data: data?.announcements,
  }
}
const getAnnouncementsFailure = () => {
  return {
    type: GET_ANNOUNCEMENTS_FAILURE,
  }
}

const fetchDataForAnnouncements = ({ jwt }) => {
  const url = `${process.env.REACT_APP_SERVER}/api/announcements`
  const options = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
  }
  return fetch(url, options)
}
export const fetchAnnouncements = (data) => {
  return async (dispatch) => {
    dispatch(getAnnouncementsStart())
    try {
      const receivedData = await fetchDataForAnnouncements(data)
      const response = await receivedData.json()
      dispatch(getAnnouncementsSuccess(response))
    } catch (err) {
      dispatch(getAnnouncementsFailure())
    }
  }
}
